import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import chef from "../../pics/chef.jpg";
import { useSelector } from "react-redux";
function ViewService() {
  const params = useParams();
  const { serviceId } = params;
  const allServices = useSelector((state) => state.services.services);
  const [currentService, setCurrentService] = useState(
    allServices.find((service) => service._id === serviceId)
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"services"} />
      <div className="row m-0 pb-5 w-100" style={{ minHeight: "90vh" }}>
        <div className="col-sm-9 pt-5 mx-auto mt-3 row m-0">
          <div className="col-sm-6">
            <img
              src={currentService.banner}
              alt={`${currentService.title}`}
              className="w-100"
            />
          </div>
          <div className="col-sm-6 pt-3 ps-3">
            <h3 className="fw-bold">{currentService.title}</h3>
            <div>
              Category:
              <span className="badge rounded-pill bg-primary ms-2">
                {currentService.serviceCategory.title}
              </span>
            </div>
            <div className="mt-3">
              <h5>Description</h5>
              <span className="lightDark">{currentService.description}</span>
            </div>
            <div className="mt-3 d-flex flex-column">
              <span>Service Provider requirements</span>
              <span className="lightDark"> {currentService.requirements}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewService;
