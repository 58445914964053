import { useState, useRef, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "../../../styles/Profile.css";
import {
  MdOutlineNavigateNext,
  MdOutlineNavigateBefore,
  MdOutlineClose,
  MdTimer,
  MdStar,
} from "react-icons/md";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MaxProfile from "./MaxProfile";

function SPProfileSlider({
  profiles,
  currentIndex,
  closeModal,
  handleShowRequestForm,
}) {
  const swiperRef = useRef();
  const navigate = useNavigate();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(currentIndex);
  let slidesPerGroup = swiperRef?.current?.swiper?.params?.slidesPerGroup;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperCont = useRef(null);
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  // useEffect(() => {
  //   console.log("current index ", currentIndex);
  //   console.log("plength");
  //   console.log(`ALAA3`,profiles,currentSlideIndex );
  // }, []);

  return (
    <div
      className="row m-0 d-flex  justify-content-center p-1"
      id="maxProfileContainer"
      style={{
        backgroundColor: "rgba(0,0,0,0.8)",
        height: "100vh",
      }}
    >
      <div className="col-sm-10 d-flex  justify-content-center swiper_btn p-2">
        <MdOutlineClose
          size={40}
          color={"black"}
          style={{ cursor: "Pointer" }}
          className="  rounded-circle p-2 bg-light border"
          onClick={() => {
            closeModal();
          }}
        />
      </div>

      <Swiper
        // install Swiper modules
        // modules={[Navigation, Pagination, Scrollbar, A11y]}
        // modules={[Pagination, Scrollbar, A11y]}
        initialSlide={currentIndex}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onSlideChange={() => {
          console.log("swipper object");
          console.log(swiperRef?.current);
          setCurrentSlideIndex(swiperRef?.current?.swiper?.realIndex);
        }}
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        slidesPerGroup={1}
        // navigation={true}
        ref={swiperRef}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {}}
        className="col-sm-10 mx-auto row m-0 d-flex justify-content-center"
      >
        {profiles.length > 0 &&
          profiles.map((profile, index) => (
            <SwiperSlide key={index} className=" ">
              <MaxProfile
                user={profile}
                handleShowRequestForm={handleShowRequestForm}
              />
            </SwiperSlide>
          ))}
        {currentSlideIndex === 0 ? (
          <span ref={prevRef}>
            <MdOutlineNavigateBefore
              size={40}
              color={"rgb(128, 123, 123)"}
              className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light border"
            />
          </span>
        ) : (
          <span ref={prevRef}>
            <MdOutlineNavigateBefore
              size={40}
              color={"black"}
              className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light border"
            />
          </span>
        )}
        {currentSlideIndex ===
        (slidesPerGroup === 3 ? 1 : profiles.length - 1) ? (
          <span ref={nextRef}>
            <MdOutlineNavigateNext
              size={40}
              color={"rgb(128, 123, 123)"}
              ref={prevRef}
              className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light border"
            />
          </span>
        ) : (
          <span ref={nextRef}>
            <MdOutlineNavigateNext
              size={40}
              color={"black"}
              ref={prevRef}
              className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light border"
            />
          </span>
        )}
      </Swiper>
    </div>
  );
}

export default SPProfileSlider;
