import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import avatar from "../../../../pics/avatar.png";
import { MdLocationPin } from "react-icons/md";
import "../../../../styles/Dashboard/Request.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GoPrimitiveDot } from "react-icons/go";
import { BiDollar } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
function RequestItem({ request, index, showProfile }) {
  const navigate = useNavigate();
  const colorSchema = useSelector((state) => state.color.scheme);

  useEffect(() => {}, []);
  return (
    <div
      className="p-2 mt-2 col-sm-4"
      onClick={() => {
        showProfile(request.sub || request._id || request.id, index);
      }}
    >
      <div
        className=" shadow-sm rounded bg-white p-3 profileStatBox"
        style={{ position: "relative" }}
      >
        <div className="d-flex">
          <span>
            <img
              src={request.client.avatar || avatar}
              alt="pic"
              style={{ height: "40px", width: "40px", borderRadius: "20px" }}
            />
            <span className="fw-bold ms-2">
              {request.client.firstName} {request.client.lastName}
            </span>
          </span>
        </div>
        {request.status === "active" && (
          <div
            className="spinner spinner-grow spinner-grow-sm text-primary"
            style={{ position: "absolute", right: "14px", top: "10px" }}
          ></div>
        )}
        {request.status === "pending" && (
          <BsExclamation
            className="text-warning"
            size={24}
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />
        )}
        {request.status === "accepted" && (
          <AiOutlineCheck
            className="text-success"
            size={20}
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />
        )}
        {request.status === "completed" && (
          <BiDollar
            className="text-success"
            size={20}
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />
        )}
        {request.status === "rejected" && (
          <GoPrimitiveDot
            className="text-danger"
            size={24}
            style={{ position: "absolute", right: "10px", top: "0px" }}
          />
        )}

        <div className="w-100 mt-2">
          <div className="d-flex justify-content-between row mt-2">
            <span className=" fontsm1 fw-bold col-6 d-flex align-items-center w-100">
              <MdLocationPin size={15} className="me-2" />
              {request.client.placeOfResidence || "unknown location"}
            </span>
          </div>

          <div className="d-flex justify-content-between row mt-2">
            <span className=" fontsm1 fw-bold col-6">Start date</span>
            <span className="lightDark fontsm1  col-6 text-start">
              {moment(request.schedule.start).format("ll")}
            </span>
          </div>
          <div className="d-flex justify-content-between row mt-2">
            <span className=" fontsm1 fw-bold col-6">End date</span>
            <span className="lightDark fontsm1  col-6 text-start">
              {moment(request.schedule.end).format("ll")}
            </span>
          </div>
        </div>
        {request.status === "accepted" && (
          <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-success text-center pt-2 pb-2">
            <center>Job Accepted</center>
          </div>
        )}
        {request.status === "pending" && (
          <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-warning text-center pt-2 pb-2">
            <center>Waiting for {request?.provider?.firstName}</center>
          </div>
        )}
        {request.status === "rejected" && (
          <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-danger text-center pt-2 pb-2">
            <center>Job rejected.</center>
          </div>
        )}
        {request.status === "completed" && (
          <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-success text-center pt-2 pb-2">
            <center>Job completed</center>
          </div>
        )}
        <div
          className="w-100 fontsm1 mt-2 p-1 text-center rounded text-light requestBtn"
          style={{ backgroundColor: `${colorSchema?.primary}` }}
          onClick={() => {
            showProfile(request.sub || request._id || request.id, index);
          }}
        >
          view
        </div>
      </div>
    </div>
  );
}

export default RequestItem;
