import { useEffect, useState, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "../../styles/Video/videoplayer.css";
import { GrChapterNext } from "react-icons/gr";

export const VideoJS = ({ poster, videoLink, playPause }) => {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  let firstRender = true;

  const VIDEOJS_OPTIONS = {
    autoplay: true,
    controls: false,
    preload: "auto",
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
    sources: [
      {
        src: videoLink,
        type: "video/mp4",
      },
    ],
    poster: poster,
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
  };

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const placeholderEl = placeholderRef.current;
      const videoElement = placeholderEl.appendChild(
        document.createElement("video-js")
      );

      const player = (playerRef.current = videojs(
        videoElement,
        VIDEOJS_OPTIONS,
        () => {
          player.log("player is ready");
          // onReady && onReady(player);
        }
      ));

      // You can update player in the `else` block here, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(VIDEOJS_OPTIONS.autoplay);
      player.src(VIDEOJS_OPTIONS.sources);
    }
  }, [VIDEOJS_OPTIONS]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const handleplaypause = () => {
    const player = playerRef?.current;
    console.log("player");
    if (player?.paused()) {
      player?.play();
    } else {
      player?.pause();
    }
    // var Button = videojs.getComponent(<GrChapterNext color="white" />);

    // var MyButton = videojs.extend(Button, {
    //   constructor: function () {
    //     Button.apply(this, arguments);
    //     /* init */
    //   },
    //   handleClick: function () {
    //     /* do something on click */
    //   },
    // });

    // videojs.registerComponent("MyButton", MyButton);
    // player.getChild("controlBar").addChild("MyButton", {});
    // player.play();
  };

  return (
    <>
      <div
        ref={placeholderRef}
        className={"vjs-big-play-centered p-0 m-0"}
      ></div>
    </>
  );
};

export default VideoJS;
