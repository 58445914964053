import { useState, useEffect } from "react";
import mpesaLogo from "../../../pics/mpesa_logo.svg";
import { GiCash } from "react-icons/gi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MdClose } from "react-icons/md";
import swal from "sweetalert";
import { wssResolver } from "../../../features/slices/GlobalsSlice";
import { setCurrentPaymentID } from "../../../features/slices/PaymentSlice.js";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../../spinners/Spinner1";

let socket = wssResolver("mpesa");

function MpesaPayment({ paymentDetails, closeModal }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { currentPaymentID } = useSelector((state) => state.payment);
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState("");
  const [showPhoneField, setShowPhoneField] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkoutRequestID, setCheckoutRequestID] = useState("");

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  useEffect(() => {
    socket.on("connect", () => {
      //  setIsConnected(true);
    });

    socket.on("mpesa", (data) => {
      console.log("Mpesa callback data");
      console.log(data);
      const {
        status,
        data: { id },
      } = data;
      console.log("status", status);
      console.log("id", id);
      console.log("checkoutrequestid", checkoutRequestID);
      if (checkoutRequestID === currentPaymentID) {
        let alert = {
          title: "",
          message: "",
          type: "",
        };
        switch (status) {
          case "success":
            alert.title = "Payment successfull";
            alert.message = `Payment of ksh ${paymentDetails.amount} to ${paymentDetails.name} was successfull`;
            alert.type = "success";
            break;
          case "insufficient":
            alert.title = "Payment Failed";
            alert.message = `Payment failed due to insufficient funds.`;
            alert.type = "error";
            break;
          case "cancelled":
            alert.title = "Payment Failed";
            alert.message = `Payment was cancelled by the user.`;
            alert.type = "error";
            break;
          case "pin":
            alert.title = "Payment Failed";
            alert.message = `The user entered an incorrect pin.`;
            alert.type = "warning";
            break;
          default:
            alert.title = "Payment Failed";
            alert.message = `Kindly try again later.`;
            alert.type = "warning";
            break;
        }

        closeModal(alert);
        swal(alert.title, alert.message, alert.type);
      }
    });

    socket.on("disconnect", () => {
      //  setIsConnected(false);
    });

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [checkoutRequestID, closeModal, currentPaymentID, paymentDetails.amount, paymentDetails.name]);

  const handlePayment = async () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // closeModal();
    // swal("success", "Payment was successfull", "success");
    setLoading(true);

    try {
      const paymentResponse = await axiosWrapper({
        method: "post",
        url: "payments/mobile/express",
        data: { amount: Number(paymentDetails.amount), phone },
      });

      // const paymentResponse = await axios({
      //   method: "post",
      //   url: "http://localhost:4000/payments/mobile/express",
      //   data: { amount: paymentDetails.amount, phone },
      //   headers: {
      //     Authorization: "Bearer " + localStorage.getItem("accessToken"),
      //   },
      // });

      if (paymentResponse.data.success) {
        setCheckoutRequestID(paymentResponse.data.data.CheckoutRequestID);
        dispatch(
          setCurrentPaymentID(paymentResponse.data.data.CheckoutRequestID)
        );
        console.log("checkout request ID");
        console.log(paymentResponse.data.data.CheckoutRequestID);
        // setLoading(false);
        // console.log("Express Payment Response");
        // console.log(paymentResponse);
        // closeModal();
        // setLoading(false);
        // swal(
        //   "Success",
        //   `Payment of ksh ${contract.payment.amount} to ${contract.payee.firstName} was successfull`,
        //   "success"
        // );
      }
    } catch (error) {
      // setLoading(false);
      console.log("Payment error");
      console.log(error);
    }
  };


  return (
    <>
      <div className="row m-0 pt-2 pb-2">
        <div className="col-sm-6 d-flex align-items-center flex-column">
          <img src={mpesaLogo} className="w-100" alt="MpesaLogo" />
          <span className="fontsm1 lightDark">
            <b>Note</b>
            <br />
            You will receive a popup on your phone <strong>{phone}</strong> that will prompt you to enter
            your pin.
          </span>
        </div>
        <div className="col-sm-6 p-2 d-flex align-items-center flex-column">
          {showPhoneField && (
            <>
              <span
                className="m-3 w-100 d-flex justify-content-center"
                onClick={() => {
                  setShowPhoneField(false);
                }}
              >
                <MdClose className="hoverUpEffect" size={25} />
              </span>
              <div className="mb-4 mt-2">
                <PhoneInput
                  country={"ke"}
                  onChange={(n) => {
                    handlePhoneValidation();
                    setPhone("+" + n);
                  }}
                  className="form_control w-100"
                  containerClass={"tel_container"}
                  inputClass={"form_controls"}
                  inputProps={{
                    name: "phone",
                    required: true,
                    value: phone,
                  }}
                />

                <small>
                  <span className="mt-1 mb-3 text-danger">{phoneError}</span>
                </small>
              </div>
            </>
          )}

          <span>
            Pay ksh {paymentDetails.amount}/=
            {paymentDetails.name}
          </span>
          <button
            className="btn btn-success d-flex align-items-center m-3"
            onClick={handlePayment}
          >
            Pay Now <GiCash className="ms-2" size={22} />
          </button>
          <span
            className="fontsm1 hoverUpEffect"
            onClick={() => {
              setShowPhoneField(true);
            }}
          >
            Use a different number instead?
          </span>
        </div>
      </div>
      {/* {loading && <Spinner1 message="Processing payment please wait..." />} */}
    </>
  );
}

export default MpesaPayment;
