import { useEffect, useState } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import CorporatePic from "../../pics/corporate1.jpg";
import CorporatePic2 from "../../pics/corporate2.jpg";
import "../../styles/Services.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import { BsFillCheckCircleFill, BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import Photo from "../../pics/home1.jpg";
function Corporate() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const allServices = useSelector((state) => state.services.services);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const [services, setServices] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const filterServices = async () => {
    setServices(
      allServices.filter((service) =>
        service.serviceCategory.find(
          (category) => category.title === "Corporate"
        )
      )
    );
  };

  useEffect(() => {
    filterServices();
  }, []);

  return (
    <>
      <Nav currentPage={"services"} />
      <div style={{ backgroundColor: "#FFF3EF" }} className="m-0 p-0">
        <div
          className="w-100 m-0 p-0"
          style={{
            backgroundImage: `url(${CorporatePic})`,
            height: "40vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div
            className="row m-0 d-flex align-items-center"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
          >
            <div className="col-sm-9 mx-auto">
              <h1 className="text-white text-center mx-auto">Corporate</h1>
            </div>
          </div>
        </div>
        <div
          className="row m-0 w-100 pt-3 pb-3"
          style={{ backgroundColor: "#FFF3EF" }}
        >
          <div className="col-sm-9 mx-auto row pb-3 m-0">
            <div className="col-sm-6 p-2">
              <img
                src={CorporatePic2}
                alt=""
                className="w-100 mt-2 mb-2 rounded"
              />
              <p className="lightDark mt-2">
                With a high percent of families having two working parents,
                childcare is a major concern for the working class. To help ease
                the childcare burden, Maids of Honour has opened the door to
                different corporates to offer their employers sponsored child
                care program.
              </p>
              <p className="lightDark mt-2">
                Work-Family balance is the concept of balancing both one's
                work-life and family-life where both parts are not affected by
                each other. Studies shows, work-family imbalance affects the
                employers mental and physical well-being which leads to stress.
              </p>
              <br />
              <h4 className="fw-bold mt-4">
                Childcare Solutions benefits Everyone
              </h4>
              <p className="lightDark mt-3">
                By partnering with Maids of Honour, employers can provide their
                employees with easy access to backup care and discounts on
                full-time, in-home childcare placements.
              </p>
              <p className="lightDark mt-2">
                Maids of Honour is looking at providing employees with work-life
                balance as well as work-life integration which is allows
                employers to integrate work into family and family into work.
                Our aim is to provide these benefits to the different companies
                which will have a positive return on their investment in the
                form of decreased absenteeism, increased morale, lower turnover,
                improved job satisfaction and an increase in consistent
                productivity.
              </p>
              <h4 className="fw-bold mt-4">What we can do for them</h4>
              <div className="row m-0">
                <div className="col-5">
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Child minder
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Housekeeping
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Buttler
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Home nurse
                  </span>
                </div>
                <div className="col-7">
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Nanny
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Special Needs
                    care
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Personal Home
                    assistants
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-1  "></div>
            <div className="col-sm-5  p-3">
              <div className="p-4 d-flex flex-column rounded bg-white w-100">
                <h5>
                  <b>Having Questions?</b>
                </h5>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <BsFillTelephoneFill size={16} className="me-2" />{" "}
                  +254795930905
                </span>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <FaEnvelope size={16} className="me-2" />{" "}
                  info@maidsofhonour.africa
                </span>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <MdLocationOn size={22} className="me-2" /> Personal Home
                  assistants
                </span>
                <button
                  className="joinBtn ps-3 pe-3 pt-1 pb-1 w-100  mt-3 rounded fontsm"
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  contact us
                </button>
              </div>
              <br />
              <br />
              <h4>
                <b>Our corporate services</b>
              </h4>
              <br />
              <div
                className="p-2"
                style={{ height: "110vh", overflowY: "auto" }}
              >
                {services &&
                  services.map((service) => (
                    <div className="p-4 d-flex mt-3  flex-column rounded bg-white w-100">
                      <div className="w-100 d-flex align-items-center">
                        <div className="ms-2" style={{ position: "relative" }}>
                          <img
                            src={service.banner}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                          {/* <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "-20%",
                              zIndex: 4,
                              width: "40px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#fde4dd",
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <GiGraduateCap size={25} />
                          </span> */}
                        </div>
                        <div className="d-flex flex-column ms-2">
                          <span>
                            <b>{service.title}</b>
                          </span>
                          <span className="fontsm1 lightDark">
                            {service.description.substr(0, 100)}...
                          </span>
                        </div>
                      </div>
                      <button
                        className="joinBtn ps-3 bg-white pe-3 pt-1 pb-1 w-100 fw-bold  mt-3 rounded fontsm"
                        style={{
                          border: `2px solid ${colorSchema?.primary}`,
                          color: `${colorSchema?.primary}`,
                        }}
                        onClick={() => {
                          navigate(`/services/${service._id}`);
                        }}
                      >
                        show details
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="col-sm-9 mx-auto mt-2 row m-0">
            <h4>
              <b>Become a partner</b>
            </h4>
            <span className="mt-3">
              To learn more about how Maids of Honour can partner with your
              company through its Corporate Childcare Solutions program, reach
              out to us.
            </span>

            {!isAuth && (
              <div className="mt-3 col-sm-3">
                <button
                  className="joinBtn ps-3 pe-3 pt-1 pb-1 w-100  mt-3 rounded fontsm "
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={() => {
                    navigate("/client-register");
                  }}
                >
                  Sign up now
                </button>
              </div>
            )}
          </div>
        </div>
        <br />

        {/*
         *
         *
         *
         *
         *
         *
         * */}
      </div>
      <Footer />
    </>
  );
}

export default Corporate;
