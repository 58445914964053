import React from "react";
import VideoJSPlayer from "../../../components/videoPlayer/VideoJSPlayer";
import ShikoThumbnail from "../../../pics/shiko_thumbnail.PNG";
function CourseVideoPlayer({ playingLink }) {
  return (
    <div>
      <VideoJSPlayer poster={ShikoThumbnail} videoLink={playingLink} />
    </div>
  );
}

export default CourseVideoPlayer;
