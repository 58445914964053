import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSelector, useDispatch } from "react-redux";
import Home1 from "../../pics/home1.jpg";
import Client1 from "../../pics/sp/client_served1.png";
import Client2 from "../../pics/sp/sps2.png";
import SP1 from "../../pics/sp/individualsp1.png";
import SP2 from "../../pics/sp/sps_portrait.jpg";
import { MdCancel } from "react-icons/md";
import { BsFillPatchCheckFill } from "react-icons/bs";
function OurStory() {
  const colorSchema = useSelector((state) => state.color.scheme);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"about"} />
      <div className="row m-0 mb-5 pt-5 d-flex justify-content-center">
        <div className="row mt-3 col-sm-10 mx-auto ">
          <div className="col-sm-6 p-3 animate__animated animate__fadeIn ">
            <img
              src={Client1}
              className="w-100 mt-3"
              style={{
                borderTopLeftRadius: "50px",
                borderBottomRightRadius: "50px",
              }}
            />
          </div>
          <div className="col-sm-6 d-flex flex-column mx-auto  mb-2 p-3 animate__animated animate__fadeIn">
            <h4 className=" mb-3 fw-bold mt-3">
              What problems are the clients facing?
            </h4>
            <div className="ps-3">
              <span className="d-flex flex-column mt-3 ">
                <span className="d-flex align-items-center  ">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Accountability</b>
                </span>

                <span>No formal employment Governance</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Lack of security</b>
                </span>

                <span>Inability to trackdown rogue service providers</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Attrition</b>
                </span>

                <span>behavioral and skill issues</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Lack of Skill</b>
                </span>

                <span>most are not trained in their craft</span>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-4 col-sm-10 mx-auto d-flex align-items-center">
          <div className="aiSystemWords col-sm-6 d-flex flex-column mx-auto  mb-2 p-3 animate__animated animate__fadeIn">
            <h4 className="mt-2 mb-3 fw-bold ">Our Solution to the Client</h4>
            <div className="ps-3">
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Tracking and rating</b>
                </span>

                <span>
                  Clients will rate and give feedback to Sp to improve their
                  skills
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Official contracts between employers and employees</b>
                </span>

                <span>
                  Fair prices and salaries are governed by labor law-compliant
                  contracts
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Automated due diligence process</b>
                </span>

                <span>
                  Background checks and tests will ensure your homes safety
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Training and Development</b>
                </span>

                <span>
                  Continuous training to increase Service provider's
                  professionalism and skill
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Home Insurance and domestic health coverage</b>
                </span>

                <span>Homes insured to protect and cover house equipment</span>
              </span>
            </div>
          </div>
          <div className="col-sm-6 p-3  aiSystemImg">
            <img
              src={Client2}
              className="w-100 mt-3 "
              style={{
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
              }}
            />
          </div>
        </div>

        <div className="row mt-3 col-sm-10 mx-auto d-flex align-items-center">
          <div className="col-sm-6 p-3 animate__animated animate__fadeIn ">
            <img
              src={SP1}
              className="w-100 mt-3"
              style={{
                borderTopLeftRadius: "50px",
                borderBottomRightRadius: "50px",
              }}
            />
          </div>
          <div className="col-sm-6 d-flex flex-column mx-auto  mb-2 p-3 animate__animated animate__fadeIn">
            <h4 className=" mb-3 fw-bold mt-3">
              What problems are the service providers facing?
            </h4>
            <div className="ps-3">
              <span className="d-flex flex-column mt-3 ">
                <span className="d-flex align-items-center  ">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Lack Of Job Security</b>
                </span>
                <span>Lack Of Formal Agreements And Statutory Payments</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Low Salaries</b>
                </span>
                <span>Majority Paid Below Minimum Salary Range</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Low Motivation To Work Long Term</b>
                </span>
                <span>For Previous Reasons, Not Viewed as a Formal Career</span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span className="d-flex align-items-center">
                  <MdCancel className="m-0 me-2 text-danger" size={25} />
                  <b>Lack Of Skill</b>
                </span>
                <span>
                  Due to financial Constraints, they are limited to upskilling
                  opportunities
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-4 col-sm-10 mx-auto d-flex align-items-center">
          <div className="col-sm-6 d-flex flex-column mx-auto  mb-2 p-3 animate__animated animate__fadeIn aiSystemWords">
            <h4 className="mt-2 mb-3 fw-bold ">
              Our Solution to the servie provider
            </h4>
            <div className="ps-3">
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b> Labor laws compliant contract</b>
                </span>

                <span>
                  Secure contracts governed by labor law-compliant contracts
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Financial development opportunities</b>
                </span>

                <span>
                  Sacco benefits, financial literacy and management, and
                  training
                </span>
              </span>
              <span className="d-flex flex-column mt-3">
                <span>
                  <BsFillPatchCheckFill
                    size={25}
                    className="me-2 text-success"
                  />
                  <b>Affordable certified training</b>
                </span>

                <span>Continuous training offered at a discounted rate</span>
              </span>
            </div>
          </div>
          <div className="col-sm-6 p-3  aiSystemImg">
            <img
              src={SP2}
              className="w-100 mt-3"
              style={{
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
              }}
            />
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row text-light d-flex justify-content-center align-items-center p-4   m-0"
        style={{ backgroundColor: `${colorSchema?.primary}` }}
      >
        <h4 className=" text-center">Our history</h4>
      </div>
      <div className="m-0 row ps-2 pe-2 histDiv ">
        <div className="col-sm-10 mx-auto">
          <VerticalTimeline
            lineColor={`${colorSchema?.primary}`}
            animate={true}
          >
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none "
              date="2011"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
              contentStyle={{
                background: "white",
                color: "black",
                padding: "0px",
              }}
              contentArrowStyle={{
                borderRight: `7px solid  white`,
              }}
            >
              <div className="w-100 m-0 p-0 d-flex flex-column  rounded-top">
                {/* <img
                  src={Home1}
                  className="w-100"
                  style={{
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                  }}
                /> */}

                <p className="fontsm p-2">
                  Through the frustration of trying to get a fitting
                  housekeeper\nanny for her 3 young boys, MoH Founder/Ceo Shiko
                  Kihia made the decision to created a curriculum which covered
                  mentoring , housekeeping and cookery from her home in Kilimani
                  which was used as Maids of Honours first a training centre .
                </p>
              </div>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2012"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                The market responded very positively and MoH rapidly made
                traction serving almost 40 clients a month. We also branched
                into cooking classes specialised in different genres of food eg
                Breakfasts, Indigenous African foods, Healthy food For toddlers
                etc. These courses were positioned to meet the specific needs of
                clients and homes that required upskilling of their domestic
                staff .
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2014"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                We were blessed to be offered a working space at Calvary Worship
                centre as our vision to spiritually equip and mentor our
                trainees was in line with the church. We continued to expound
                our training to young adults as well as training in healthy
                cooking , upskilling in mentoring and housekeeping training for
                homes who already had helpers.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2014"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                MoH was featured in the Standard newspaper. An article which
                spiralled us to new heights of visibility with our traction
                spiking by 30 to 40 %. We were featured on the popular Breakfast
                show on Nation radio with the late Lorna Irungu who was also a
                strong supporter and ambassador of our services. We also
                partnered with banking service provider Jamii Bora bank as we
                moved to a retainer model with all our househelpers opening
                accounts in the same and being trained and developed in
                financial literacy.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2015"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                Having gone through a mini MBA with the Sinapis Organization,
                MoH came out top 3 through a business plan competition,
                eventually through the rigorous shortlisting processes and
                presentations, pitched for seed capital at the pre GES President
                Obama's visit to Kenya.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2016"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                MoH was featured on the KBC show ‘Road to success’…highlighting
                our journey and our milestones.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2017"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                MoH was chosen with other key players in the industry to
                participate in a PEPFAR funded project called Ajiri Dada based
                on mentoring and training in sex education, soft skills, first
                aid, house management, foods and nutrition and childcare. We
                collectively created a comprehensive curriculum in the same,
                with MoH developing the Foods and Nutrition aspect of it.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2021"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                MoH began sampling in creation of tutoring shoots with the idea
                that it was time to digitise and scale the business.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2022"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <p>
                MoH was accepted into the Adanian lab accelerator program,
                gained investors and currently we have relaunched as a digital
                platform Maids of Honour Africa with the vision to provide our
                service model to the rest of the continent and the world.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurStory;
