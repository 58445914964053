import React from "react";

function StripeModal() {
  return (
    <div className="d-flex w-100  justify-content-center p-5 mt-3 align-items-center mx-auto ">
      <span> Coming Soon</span>
    </div>
  );
}

export default StripeModal;
