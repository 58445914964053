import React from "react";
import Policies from "./Policies";

const TermsAndConditions = () => {
  return (
    <>
      <Policies currentPage={"terms_and_conditions"} />
    </>
  );
};

export default TermsAndConditions;
