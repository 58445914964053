import { useState, useEffect } from "react";
import Contract from "./Contract";
import NDA from "./NDA";

function Index({ closeContract, details }) {
  const [showContract, setShowContract] = useState(true);
  const handleSignContract = (isSigned) => {
    if (isSigned) {
      setShowContract(true);
    } else {
      refuseTerms();
    }
  };

  const refuseTerms = () => {
    alert("refused terms");
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      {/* {!showContract ? (
        <NDA
          proceed={handleSignContract}
          details={details}
          closeContract={closeContract}
        />
      ) : ( */}
      <Contract
        signContract={handleSignContract}
        closeContract={closeContract}
        details={details}
      />
      {/* )} */}
    </>
  );
}

export default Index;
