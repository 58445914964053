import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "../../index.css";
function Items() {
  const [allItems, setAllItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = allItems.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allItems.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allItems.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="row m-0 p-5  d-flex justify-content-center">
        {currentItems &&
          currentItems.map((item, index) => (
            <div
              className="border col-sm-3  m-1 p-5 rounded shadow text-center"
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
      <div className="w-100  p-2 d-flex justify-content-center">
        {" "}
        <ReactPaginate
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="prev"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default Items;
