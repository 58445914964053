import React from "react";
import "../../styles/Footer.css";
import Logo2 from "../../pics/logo2.png";
import Home1 from "../../pics/home1.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  setLuna,
  setGray,
  setDarkGray,
} from "../../features/slices/ColorSlice";
import { useNavigate } from "react-router-dom";
import Wave from "react-wavify";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

function Footer() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="footer-main-container m-0 "
        style={{ backgroundColor: `${colorSchema?.primary}` }}
      >
        <div className="m-0 w-100 bg-danger p-5" id="footerCtaArea">
          <div id="footerOverlay" className="p-5">
            <h2 className="mt-2 text-light text-center">
              {!isAuth ? "Join Maids of Honour now" : "Service is greatness"}
            </h2>
            <div className="mt-4 d-flex align-items-center">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                {!isAuth ? (
                  <>
                    <button
                      className="joinBtn ps-3 pe-3 pt-2 pb-2 m-3 rounded"
                      style={{ backgroundColor: `${colorSchema?.primary}` }}
                      onClick={() => {
                        window.scroll(0, 0);
                        navigate("/register");
                      }}
                    >
                      As Client
                    </button>
                    <button
                      className="joinBtn ps-3 pe-3 pt-2 pb-2 m-3 rounded"
                      style={{ backgroundColor: `${colorSchema?.primary}` }}
                      onClick={() => {
                        window.scroll(0, 0);
                        navigate("/register");
                      }}
                    >
                      As service provider
                    </button>
                  </>
                ) : (
                  <button
                    className="joinBtn ps-3 pe-3 pt-2 pb-2 m-3 rounded"
                    style={{ backgroundColor: `${colorSchema?.primary}` }}
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/dashboard");
                    }}
                  >
                    My dashboard
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Wave
          fill={colorSchema?.primary}
          paused={false}
          options={{
            height: 20,
            amplitude: 30,
            speed: 0.15,
            points: 3,
          }}
          style={{ height: "10px" }}
        /> */}
        <div className=" m-0 pt-5 pb-1">
          <div
            id="footer-container"
            className="row   p-5 d-flex justify-content-center m-0"
          >
            <div className="col-sm-4">
              <h5 className="mb-3" style={{ color: "white" }}>
                Maids of honour Africa
              </h5>
              <p className="catch-phrase pe-3 text-light fontsm">
                Our Moonshot vision is to disrupt the informal and fragmented
                domestic industry, through tech optimization, using Ai-based
                vetting and rating system, virtual upskill and use of automated
                contracts, with financial inclusion for service providers.
              </p>
              <div className="d-flex mt-2">
                <span className=" p-1 rounded-circle me-4 border-light socialMediaIcon">
                  <a
                    href="https://web.facebook.com/MaidsofHonour?_rdc=1&_rdr"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FaFacebook size={18} className="text-light socialIcon" />
                  </a>
                </span>
                <span className=" p-1 rounded-circle me-4 border-light socialMediaIcon">
                  <a
                    href="https://www.instagram.com/maidsofhonour_a/"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FaInstagram size={18} className="text-light socialIcon" />
                  </a>
                </span>
                <span className=" p-1 rounded-circle me-4 border-light socialMediaIcon">
                  <a
                    href="https://www.linkedin.com/company/maids-of-honour-africa/"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FaLinkedin size={18} className="text-light socialIcon" />
                  </a>
                </span>
                <span className=" p-1 rounded-circle me-4 border-light socialMediaIcon">
                  <a
                    href="https://twitter.com/MaidsofHonour_A"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <FaTwitter size={18} className="text-light socialIcon" />
                  </a>
                </span>
              </div>
              {/* <div className="w-100 d-flex justify-content-center">
            <img className="w-25 mx-auto" src={Logo2} alt="moh-logo" />
          </div> */}
            </div>
            <div className="col-sm-2 abouts footer-links-aside">
              <h6 className="" style={{ color: "white" }}>
                Services
              </h6>
              <span
                onClick={() => {
                  navigate("/services/process");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Our process
              </span>
              <span
                onClick={() => {
                  navigate("/services/home");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Home Service
              </span>
              <span
                onClick={() => {
                  navigate("/services/schools");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Schools
              </span>
              <span
                onClick={() => {
                  navigate("/services/hospitality");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Hospitality
              </span>
              <span
                onClick={() => {
                  navigate("/services/diplomats");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Expatriates & Diplomats
              </span>
              <span
                onClick={() => {
                  navigate("/privacy_policy");
                }}
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
              >
                Terms & Conditions
              </span>
            </div>
            <div className="col-sm-2 footerlinks footer-links-aside">
              <h6 className="" style={{ color: "white" }}>
                About
              </h6>
              <span
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About us
              </span>
              <span
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/our-story");
                }}
              >
                Our story
              </span>
              <span
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/our-staff");
                }}
              >
                Our staff
              </span>
              <span
                className="nav-link footer-links"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/faq");
                }}
              >
                FAQs
              </span>
            </div>
            <div className="col-sm-3 " id="officeDetails">
              <h6 className="" style={{ color: "white" }}>
                Our office
              </h6>
              <p className="address text-light footer-links">
                Kenya<br></br>Afya Rekod Campus<br></br>Westlands
              </p>
              <p>
                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-dark dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Change theme
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(setLuna());
                        }}
                      >
                        Pink
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(setGray());
                        }}
                      >
                        Blue
                      </span>
                    </li>
                    {/* <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(setDarkGray());
                        }}
                      >
                        Dark Gray theme
                      </span>
                    </li> */}
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="row ms-0 me-0 text-center pb-5 text-light footer-link">
          <small>
            &copy; Maids of Honour Africa {new Date(Date.now()).getFullYear()} |
            All Rights Reserved
          </small>
        </div>
      </div>
    </>
  );
}

export default Footer;
