import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/Errors/index.css";
import { useSelector } from "react-redux";
import PNFAnimation from "../../pics/404Animation.gif";
function PageNotFound() {
  const navigate = useNavigate();
  const colorSchema = useSelector((state) => state.color.scheme);
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="d-flex flex-column row w-100  m-0 container">
        <div className="col-sm-5 mx-auto">
          <center>
            <img alt="Not found" className="w-75 mx-auto" src={PNFAnimation} />
          </center>
          <h4 className="text-center">The requested page was not found</h4>
          <center>
            <button
              className="homeBtn ps-5 pe-5 pt-2 pb-2 m-3 rounded text-light"
              onClick={() => {
                navigate("/");
              }}
              style={{ backgroundColor: `${colorSchema?.primary}` }}
            >
              Go back home
            </button>
          </center>
        </div>
      </div>{" "}
    </div>
  );
}

export default PageNotFound;
