"use client"

import * as React from "react";
import {
  Stack,
  Chip,
  Badge,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton,
  Button,
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Tooltip,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Card,
  CardHeader,
  Drawer,
  List,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Settings from "@mui/icons-material/ManageAccounts";
import { useSelector, useDispatch } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import NotificationsIcon from "@mui/icons-material/NotificationsNoneOutlined';";
import CloseIcon from "@mui/icons-material/ArrowBackIos";
import ChatIcon from "@mui/icons-material/Chat";
import SchoolIcon from "@mui/icons-material/School";
import GavelIcon from "@mui/icons-material/Gavel";
import Person2Icon from "@mui/icons-material/Person2";
import Fade from "@mui/material/Fade";
import BadgeIcon from "@mui/icons-material/Badge";
// import NotificationsList from "./notifications"
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import { toast } from "react-hot-toast";
import { clearState } from "../../features/slices/AuthSlice";
import Logo2 from "../../pics/logo2.png";
import NotificationItem from "../../pages/Notifications/NotificationItem";
import {
  setRecipient,
  setChatId,
  fetchMesages,
  fetchChats,
} from "../../features/slices/MessagesSlice.js";
import { setCurrentBooking } from "../../features/slices/BookingSlice.js";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import StorageIcon from "@mui/icons-material/Storage";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#CF2680",
    color: "#CF2680",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledMenu = styled(MenuItem)(({ theme }) => ({
  "& .MuiMenuItem": {
    "&:hover": {
      backgroundColor: "blue !important",
    },
  },
  ".Mui-selected": {
    border: "3px solid red",
  },
}));

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const { user } = useSelector((state) => state.auth);
  const { data: allMessages } = useSelector((state) => state.messages.chats);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const open = Boolean(anchorEl);
  const [notificationEl, setNotificationAnchorEl] = React.useState(null);
  const openNotifications = Boolean(notificationEl);
  const currentRoute = location.pathname;
  const [anchorChat, setAnchorChat] = React.useState(null);
  const openChat = Boolean(anchorChat);
  const handleChatClick = (event) => {
    setAnchorChat(event.currentTarget);
  };
  const handleChatClose = () => {
    setAnchorChat(null);
  };

  console.log(`PATH`, currentRoute);
  const handleNotificationsClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setNotificationAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setLoading(true);

    const logoutResponses = await axiosWrapper({
      method: "get",
      url: "auth/logout",
    });

    console.log("Logout from backend but on nav");
    console.log(logoutResponses.data);

    if (logoutResponses.data.success) {
      dispatch(clearState());
      localStorage.clear();
      localStorage.removeItem("authStatus");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      toast.success("You have been logged out!");
      // setTimeout(() => {
      //   setLoading(false);
      //   window.location = "/";
      navigate("/");
      // }, 1000);
    } else {
      setLoading(false);
      toast.error("Logout failed!");
      navigate("/");
    }
  };

  const handleStartMessage = async (message) => {
    if (
      user.sub === message.participants[0]._id ||
      user._id === message.participants[0]._id
    ) {
      console.log("recipient");
      console.log(message.participants[1]);
      dispatch(setRecipient(message.participants[1]));
    } else {
      console.log("recipient");
      console.log(message.participants[0]);
      dispatch(setRecipient(message.participants[0]));
    }
    dispatch(setChatId(message._id));
    dispatch(fetchMesages(message._id));
    const bookingResponse1 = await axiosWrapper({
      method: "get",
      url: `bookings?provider=${message.participants[0]._id}&client=${message.participants[1]._id}`,
      data: "",
    });
    console.log("Check the current bookings");
    const booking1 = bookingResponse1.data.data.rows[0];
    const bookingResponse2 = await axiosWrapper({
      method: "get",
      url: `bookings?provider=${message.participants[1]._id}&client=${message.participants[0]._id}`,
      data: "",
    });
    console.log("Check the current bookings");
    const booking2 = bookingResponse2.data.data.rows[0];
    console.log("booking 1");
    console.log(booking1);
    console.log("booking 2");
    console.log(booking2);

    dispatch(setCurrentBooking(booking1 || booking2));

    // dispatch(fetchChats(user._id || user.sub || user.id));
    if (state.right) {
      navigate("/chat");
    }
  };

  return (
    <React.Fragment>
      <AppBar position={"fixed"} color="inherit" elevation={0}>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", gap: 1, flexGrow: 1 }}>
            <ListItem sx={{ m: 0 }}>
              <ListItemAvatar>
                <Avatar sx={{ height: "3rem", width: "3rem" }} src={Logo2} />
              </ListItemAvatar>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <Typography
                    // variant="h7"
                    color="text.secondary"
                    sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                  >
                    Maids of Honour 
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" color="text.secondary" sx={{marginTop:-5}}>
                    Service is greatness
                  </Typography>
                }
              />
            </ListItem>
          </Box>
          {!isSmallScreen && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <StyledMenu
                // divider={currentRoute === "/dashboard"}
                onClick={() => navigate("/dashboard")}
                sx={{
                  color:
                    currentRoute === "/dashboard"
                      ? "text.secondary"
                      : "text.primary",
                }}
              >
                <ListItemIcon>
                  <HomeIcon
                    fontSize="small"
                    sx={{
                      color:
                        currentRoute === "/dashboard"
                          ? "text.secondary"
                          : "text.primary",
                    }}
                  />
                </ListItemIcon>
                Dashboard
              </StyledMenu>
              {authUser.role?.title === "serviceProvider" ? (
                <MenuItem
                  // divider={currentRoute === "/dashboard/requests"}
                  onClick={() => navigate("/dashboard/requests")}
                  sx={{
                    color:
                      currentRoute === "/dashboard/requests"
                        ? "text.secondary"
                        : "text.primary",
                  }}
                >
                  <ListItemIcon>
                    <HomeRepairServiceIcon
                      fontSize="small"
                      sx={{
                        color:
                          currentRoute === "/dashboard/requests"
                            ? "text.secondary"
                            : "text.primary",
                      }}
                    />
                  </ListItemIcon>
                  My Jobs
                </MenuItem>
              ) : (
                <>
                  <MenuItem
                    // divider={currentRoute === "/dashboard/hire"}
                    onClick={() => navigate("/dashboard/hire")}
                    sx={{
                      color:
                        currentRoute === "/dashboard/hire"
                          ? "text.secondary"
                          : "text.primary",
                      borderBottom: "1px solid text.secondary",
                    }}
                  >
                    <ListItemIcon>
                      <GroupAddIcon
                        fontSize="small"
                        sx={{
                          color:
                            currentRoute === "/dashboard/hire"
                              ? "text.secondary"
                              : "text.primary",
                        }}
                      />
                    </ListItemIcon>
                    Hire
                  </MenuItem>
                  <MenuItem
                    dense
                    // divider={currentRoute === "/dashboard/bookings"}
                    onClick={() => navigate("/dashboard/bookings")}
                    sx={{
                      color:
                        currentRoute === "/dashboard/bookings"
                          ? "text.secondary"
                          : "text.primary",
                      borderBottom: "1px solid text.secondary",
                    }}
                  >
                    <ListItemIcon>
                      <StorageIcon
                        fontSize="small"
                        sx={{
                          color:
                            currentRoute === "/dashboard/bookings"
                              ? "text.secondary"
                              : "text.primary",
                        }}
                      />
                    </ListItemIcon>
                    Bookings
                  </MenuItem>
                </>
              )}
              <MenuItem
                // divider={currentRoute === "/dashboard/contracts"}
                onClick={() => navigate("/dashboard/contracts")}
                sx={{
                  color:
                    currentRoute === "/dashboard/contracts"
                      ? "text.secondary"
                      : "text.primary",
                }}
              >
                <ListItemIcon>
                  <GavelIcon
                    fontSize="small"
                    sx={{
                      color:
                        currentRoute === "/dashboard/contracts"
                          ? "text.secondary"
                          : "text.primary",
                    }}
                  />
                </ListItemIcon>
                Contracts
              </MenuItem>
              {authUser.role?.title === "serviceProvider" && (
                <MenuItem
                  // divider={currentRoute === "/dashboard/courses" }
                  onClick={() => navigate("/dashboard/courses")}
                  sx={{
                    color:
                      currentRoute === "/dashboard/courses"
                        ? "text.secondary"
                        : "text.primary",
                    borderBottom: "1px solid text.secondary",
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon
                      fontSize="small"
                      sx={{
                        color:
                          currentRoute === "/dashboard/courses"
                            ? "text.secondary"
                            : "text.primary",
                      }}
                    />
                  </ListItemIcon>
                  Courses
                </MenuItem>
              )}
                <MenuItem
                // divider={currentRoute === "/dashboard/contracts"}
                onClick={() => navigate("/shop")}
                sx={{
                  color:
                    currentRoute === "/shop"
                      ? "text.secondary"
                      : "text.primary",
                }}
              >
                <ListItemIcon>
                  <ShoppingCartIcon
                    fontSize="small"
                    sx={{
                      color:
                        currentRoute === "/shop"
                          ? "text.secondary"
                          : "text.primary",
                    }}
                  />
                </ListItemIcon>
                Shop
              </MenuItem>
            </Box>
          )}
          <div>
            {!isSmallScreen && (
              <>
                <Tooltip title="Messages">
                  <IconButton
                    size="large"
                    aria-label="show messages"
                    color="inherit"
                    onClick={toggleDrawer("right", true)}
                  >
                    <Badge badgeContent={allMessages.length} color="error">
                      <ChatIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notifications">
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={handleChatClick}
                  >
                    <Badge badgeContent={notifications.length} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="My Profile">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 1 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={authUser.avatar ? authUser.avatar : Avatar}
                    // sx={{ width: 32, height: 32 }}
                  />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 50,
                    height: 50,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    sx={{ height: "3rem", width: "3rem" }}
                    src={authUser.avatar ? authUser.avatar : Avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ m: 0 }}
                  primary={
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{ fontWeight: "bold" }}
                    >
                      {authUser?.firstName} {""} {authUser?.lastName}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      {authUser?.email}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <MenuItem
                onClick={() => navigate(`/`)}
              >
                <ListItemIcon>
                  <HomeIcon fontSize="small" color="secondary" />
                </ListItemIcon>
               Home
              </MenuItem>

              <MenuItem
                onClick={() => navigate(`/profile/${authUser?.role?.title}`)}
              >
                <ListItemIcon>
                  <Settings fontSize="small" color="secondary" />
                </ListItemIcon>
                My Profile
              </MenuItem>
              <MenuItem
                onClick={() => navigate(`/community/${authUser?.role?.title}`)}
              >
                <ListItemIcon>
                  <PeopleIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                Community
              </MenuItem>
              {/* <MenuItem onClick={handleChatClick}>
                <ListItemIcon>
                  <NotificationsIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                Notifications
              </MenuItem> */}

              {/* <MenuItem onClick={handleDarkModeToggle}>
                                <ListItemIcon>
                                    <Brightness4Icon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                Dark mode
                            </MenuItem> */}

              <MenuItem onClick={handleLogout}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleLogout}
                  size="small"
                  sx={{ borderRadius: "0.4rem" }}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        <Box sx={{ width: 380 }} role="presentation">
          <Card>
            <CardHeader
              avatar={
                <IconButton
                  aria-label="settings"
                  onClick={toggleDrawer("right", false)}
                >
                  <CloseIcon />
                </IconButton>
              }
              title={
                <Typography variant="subtitle1" color="inherit" noWrap>
                  Messages
                </Typography>
              }
              subheader={`You have ${allMessages?.length} chats`}
            />
          </Card>
          <>
            {loading ? (
              <div className="w-100 d-flex justify-content-center p-3">
                <div className="spinner spinner-border spinner-border-sm text-primary"></div>
              </div>
            ) : (
              <>
                {allMessages?.length > 0 ? (
                  <>
                    {allMessages.map((message, index) => (
                      // <NotificationItem
                      //   key={notification._id}
                      //   notification={notification}
                      // />
                      <List
                        key={index}
                        sx={{
                          width: "100%",
                          cursor: "pointer",
                          maxWidth: 600,
                          bgcolor: "background.default",
                        }}
                        onClick={handleStartMessage(message)}
                      >
                        <ListItem
                          secondaryAction={
                            <Typography variant="caption">
                              {moment(message.createdAt).fromNow()}
                            </Typography>
                          }
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={
                                user.sub === message.participants[0]._id
                                  ? message.participants[1]?.avatar
                                  : message.participants[0]?.avatar
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <b>
                                {user.sub === message.participants[0]._id
                                  ? message.participants[1].firstName
                                  : message.participants[0].firstName}

                                {""}

                                {user.sub === message.participants[0]._id
                                  ? message.participants[1].lastName
                                  : message.participants[0].lastName}
                              </b>
                            }
                            secondary={
                              <>
                                <Typography variant="body2">
                                  {" "}
                                  {message?.lastMessage?.message > 30
                                    ? message?.lastMessage?.message.substring(
                                        0,
                                        30
                                      ) + "..."
                                    : message?.lastMessage?.message}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </List>
                    ))}
                  </>
                ) : (
                  <div className="w-100 d-flex justify-content-center p-3">
                    <div className="alert alert-warning fontsm">
                      {`You have ${allMessages?.length} unread messages`}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </Box>
      </Drawer>
      <Menu
        id="basic-menu"
        anchorEl={anchorChat}
        open={openChat}
        onClose={handleChatClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          width: 100,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 50,
              height: 50,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ px: 2 }}>
          <Typography variant="subtitle1" color="inherit" noWrap>
            Notifications
          </Typography>
          <Divider />
          {loading ? (
            <div className="w-100 d-flex justify-content-center p-3">
              <div className="spinner spinner-border spinner-border-sm text-primary"></div>
            </div>
          ) : (
            <>
              {notifications?.length > 0 ? (
                <>
                  {notifications.map((notification) => (
                    <NotificationItem
                      key={notification._id}
                      notification={notification}
                    />
                  ))}
                </>
              ) : (
                <div className="w-100 d-flex justify-content-center p-3">
                  <div className="alert alert-warning fontsm">
                    {`You have ${notifications?.length} unread notifications`}
                  </div>
                </div>
              )}
            </>
          )}
        </Box>
      </Menu>
    </React.Fragment>
  );
}
