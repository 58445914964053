import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { logoutUser } from "../../features/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineDashboard } from "react-icons/md";
import i18next from "i18next";
import cookies from "js-cookie";
import Logo2 from "../../pics/logo2.png";
import "../../styles/Navbar.css";
import { FaBars } from "react-icons/fa";
import Avatar from "../../pics/avatar.png";
import { HiOutlineLogout } from "react-icons/hi";
// import { FaUserAlt } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import Spinner2 from "../spinners/Spinner2";
import { useMediaQuery, } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LocalAtmIcon from '@mui/icons-material/Savings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import Spinner1 from "../spinners/Spinner1";
// import { GiPowerButton } from "react-icons/gi";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";

function Nav({ currentPage }) {
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "sw",
      name: "Swahili",
      country_code: "ke",
    },
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "ar",
      name: "عربي",
      country_code: "sa",
      dir: "rtl",
    },
  ];
  // const location = useLocation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find(
    (language) => language.code === currentLanguageCode
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const authUser = useSelector((state) => state.auth.user);
  const colorSchema = useSelector((state) => state.color.scheme);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const open = Boolean(anchorEl);
  const [state, setState] = useState({
    right: false,
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const Main = [{
    name: "Home", url: "/", icon: <HomeIcon />
  },

  {
    name: "About Us", url: "/our-story", icon: <InfoIcon />
  },
  {
    name: "Contact Us", url: "/contact", icon: <CallIcon />
  }]

  const Services = [{
    name: "Our Process", url: "/services/process", icon: <AccountTreeIcon />
  },
  {
    name: "Home Service", url: "/services/home", icon: <HomeIcon />
  },
  {
    name: "Cooperate", url: "/services/corporate", icon: <BusinessIcon />
  },
  {
    name: "Schools", url: "/services/schools", icon: <SchoolIcon />
  },
  {
    name: "Hospitality", url: "/services/hospitality", icon: <FoodBankIcon />
  },
  {
    name: "Diplomatic Expatriate", url: "/services/diplomats", icon: <PeopleIcon />
  }]

  const Resources = [{
    name: "E-learning", url: "/resources/e-learning", icon: <ImportContactsIcon />
  },
  {
    name: "Sacco", url: "/resources/sacco", icon: <LocalAtmIcon />
  },
  {
    name: "Market Place", url: "/resources/market-place", icon: <ShoppingCartIcon />
  },
  {
    name: "Community", url: "/resources/community", icon: <PeopleIcon />
  }]



  const handleLogout = () => {
    dispatch(logoutUser());
    // dispatch(logout());
  };

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const [activePage, setActivePage] = useState(currentPage);

  return (
    <>
      {isLoading && <Spinner2 message={"please wait..."} />}

      <div className="">
        <nav
          className="navbar navbar-expand-lg pt-2 pb-2 "
          style={{ backgroundColor: `${colorSchema?.primary}` }}
        >
          <div className="container-fluid">
            <span
              className="navbar-brand d-flex align-items-center"
              id="navBrand"
              style={{ color: "#ffe4dd", cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                id="logo"
                src={Logo2}
                alt={"MOH logo"}
                className="me-2"
                style={{ width: "50px" }}
              />
              <span className="d-flex flex-column">
                Maids of Honour Africa
                <small className="fontsm1">Service is greatness</small>
              </span>
            </span>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FaBars className="text-white" />
            </button> */}
            {isSmallScreen ? (
              <div>
                <IconButton
                  size="large"
                  edge="start"
                  // onClick={handleClick}
                  onClick={toggleDrawer("right", true)}
                  aria-label="menu"
                  sx={{ ml: 2 }}
                >
                  <MenuIcon sx={{ fill: "#fff" }} />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={state['right']}
                  onClose={toggleDrawer("right", false)}
                >
                  <Box
                    sx={{ width: 200 }}
                    role="presentation"
                    onClick={toggleDrawer("right", false)}
                    onKeyDown={toggleDrawer("right", false)}
                  >
                    <List>
                      {Main.map((text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton onClick={() => {
                            navigate(text.url);
                          }}>
                            <ListItemIcon>
                              {text.icon}
                            </ListItemIcon>
                            <ListItemText primary={text.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                    <Divider />
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          Our Services
                        </ListSubheader>
                      }
                    >
                      {Services.map((text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton onClick={() => {
                            navigate(text.url);
                          }}>
                            <ListItemIcon>
                              {text.icon}
                            </ListItemIcon>
                            <ListItemText primary={text.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                    <Divider />
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          Our Resources
                        </ListSubheader>
                      }
                    >
                      {Resources.map((text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton onClick={() => {
                            navigate(text.url);
                          }}>
                            <ListItemIcon>
                              {text.icon}
                            </ListItemIcon>
                            <ListItemText primary={text.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                    <Divider />
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                          navigate("/login");
                        }}>
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText primary="Login" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                </Drawer>
                {/* <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => {
                    navigate("/");
                  }}>Home</MenuItem>
                  <MenuItem onClick={() => {
                    navigate("/services/home");
                  }}>Services</MenuItem>
                  <MenuItem onClick={() => {
                    navigate("/resources/e-learning");
                  }}>Resources</MenuItem>
                  <MenuItem onClick={() => {
                    navigate("/our-story");
                  }}>About</MenuItem>
                  <MenuItem onClick={() => {
                    navigate("/contact");
                  }}>Contact</MenuItem>
                  <MenuItem onClick={() => {
                    navigate("/login");
                  }}>Login</MenuItem>
                </Menu> */}
              </div>
            ) : (
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 pe-3 mb-lg-0">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setActivePage("");
                    }}
                  >
                    <Link
                      to={"/"}
                      className={`nav-link ${activePage === "" ? "activeTab" : ""
                        } `}
                      aria-current="page"
                    >
                      {t("nav.home")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown me-2">
                    <span
                      className={`nav-link dropdown-toggle `}
                      // className={`nav-link dropdown-toggle ${
                      //   activePage === "services" ? "activeTab" : ""
                      // } `}
                      role="button"
                      id="navbarScrollingDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setActivePage("services");
                      }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <span
                        className={` ${activePage === "services" ? "activeTab" : ""
                          } `}
                      >
                        Services
                      </span>
                    </span>

                    <ul
                      className="dropdown-menu p-0"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <span
                        className="dropdown-item ps-3  rounded-top d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/services/process");
                        }}
                      >
                        <span className="d-flex align-items-center w-75  pt-2  pb-2 drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Our process
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3   d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/services/home");
                        }}
                      >
                        {/* <OverlayTrigger
                        placement={"right"}
                        overlay={
                          <Tooltip id={`tooltip-left}`} className="font-sm1">
                            Coming soon
                          </Tooltip>
                        }
                      >
                        <span className="d-flex align-items-center w-75 pt-2 pb-2">
                          <AiOutlineRight className="me-2" />
                          Home
                        </span>
                      </OverlayTrigger> */}

                        <span className="d-flex align-items-center w-75 pt-2 pb-2">
                          <AiOutlineRight className="me-2" />
                          Home Service
                        </span>
                      </span>

                      <span
                        className="dropdown-item ps-3   d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/services/corporate");
                        }}
                      >
                        <span className="d-flex pt-2 pb-2 w-75 align-items-center">
                          <AiOutlineRight className="me-2" />
                          Corporate
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3   d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/services/schools");
                        }}
                      >
                        <span className="d-flex pt-2 pb-2 align-items-center w-75">
                          <AiOutlineRight className="me-2" />
                          Schools
                        </span>
                      </span>
                      <span
                        className="dropdown-item  ps-3 rounded-bottom d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/services/hospitality");
                        }}
                      >
                        <span className="d-flex pb-2 pt-2 pb-2 align-items-center w-75">
                          <AiOutlineRight className="me-2" />
                          Hospitality
                        </span>
                      </span>
                      {/* <span
                      className="dropdown-item ps-3  rounded-bottom d-flex align-items-center border-bottom"
                      onClick={() => {
                        navigate("/services/service-apartments");
                      }}
                    >
                      <span className="d-flex pt-2 pb-2 align-items-center ">
                        <AiOutlineRight className="me-2" />
                        Service Apartments
                      </span>
                    </span> */}
                      <span
                        className="dropdown-item ps-3  rounded-bottom d-flex align-items-center"
                        onClick={() => {
                          navigate("/services/diplomats");
                        }}
                      >
                        <span className="d-flex pt-2 pb-2 align-items-center ">
                          <AiOutlineRight className="me-2" />
                          Diplomatic and <br />
                          Expatriate communities
                        </span>
                      </span>
                    </ul>
                  </li>
                  <li className="nav-item dropdown me-2">
                    <span
                      className={`nav-link dropdown-toggle  `}
                      // className={`nav-link dropdown-toggle ${
                      //   activePage === "resources" ? "activeTab" : ""
                      // } `}
                      role="button"
                      id="resourcesDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => {
                        setActivePage("resources");
                      }}
                    >
                      <span
                        className={` ${activePage === "resources" ? "activeTab" : ""
                          } `}
                      >
                        Resources
                      </span>
                    </span>

                    <ul
                      className="dropdown-menu p-0"
                      aria-labelledby="resourcesDropdown"
                    >
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-top d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/resources/e-learning");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          E-learning
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/resources/sacco");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Sacco
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/resources/market-place");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Market Place
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center"
                        onClick={() => {
                          navigate("/resources/community");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Community
                        </span>
                      </span>
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown me-2">
                  <span
                    className={`nav-link dropdown-toggle ${
                      activePage === "services" ? "active" : ""
                    } `}
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                   
                    Resources
                  </span>
                  <ul
                    className="dropdown-menu p-0 text-white"
                    aria-labelledby="navbarScrollingDropdown"
                    style={{
                      backgroundColor: `${colorSchema?.primary}`,
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <span
                      className="dropdown-item text-white ps-3 pt-3 pb-3 rounded-top d-flex align-items-center border-bottom"
                      onClick={() => {
                        navigate("/services/client");
                      }}
                    >
                    
                      E-learning
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3  d-flex align-items-center  border-bottom"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Sacco
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3 d-flex align-items-center  border-bottom"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Market place
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3 rounded-bottom d-flex align-items-center"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Community
                    </span>
                  </ul>
                </li> */}
                  <li className="nav-item dropdown me-2">
                    <span
                      className={`nav-link dropdown-toggle `}
                      // className={`nav-link dropdown-toggle ${
                      //   activePage === "about" ? "activeTab" : ""
                      // } `}
                      role="button"
                      id="navbarScrollingDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setActivePage("about");
                      }}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <span
                        className={` ${activePage === "about" ? "activeTab" : ""
                          } `}
                      >
                        About
                      </span>
                    </span>
                    <ul
                      className="dropdown-menu p-0"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-top d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/about-us");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          About us
                        </span>
                      </span>
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center border-bottom"
                        onClick={() => {
                          navigate("/our-story");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Our story
                        </span>
                      </span>
                      {/* <span
                      className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center border-bottom"
                      onClick={() => {
                        navigate("/our-staff");
                      }}
                    >
                      <span className="d-flex align-items-center drop-item-arrow">
                        <AiOutlineRight className="me-2" />
                        Our Staff
                      </span>
                    </span> */}
                      <span
                        className="dropdown-item ps-3 pt-3 pb-2 rounded-bottom d-flex align-items-center "
                        onClick={() => {
                          navigate("/careers");
                        }}
                      >
                        <span className="d-flex align-items-center drop-item-arrow">
                          <AiOutlineRight className="me-2" />
                          Careers
                        </span>
                      </span>
                    </ul>
                  </li>
                  {/* <li className="nav-item dropdown me-2">
                  <span
                    className={`nav-link dropdown-toggle ${
                      activePage === "services" ? "active" : ""
                    } `}
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                  
                    About
                  </span>
                  <ul
                    className="dropdown-menu p-0 text-white"
                    aria-labelledby="navbarScrollingDropdown"
                    style={{
                      backgroundColor: `${colorSchema?.primary}`,
                      boxShadow:
                        " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <span
                      className="dropdown-item text-white ps-3 pt-3 pb-3 rounded-top d-flex align-items-center border-bottom"
                      onClick={() => {
                        navigate("/services/client");
                      }}
                    >
                      About us
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3  d-flex align-items-center  border-bottom"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Our Story
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3  d-flex align-items-center  border-bottom"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Our Staff
                    </span>
                    <span
                      className="dropdown-item  text-white ps-3 pt-3 pb-3  d-flex align-items-center"
                      onClick={() => {
                        navigate("/services/sp");
                      }}
                    >
                      Careers
                    </span>
                  </ul>
                </li> */}

                  <li className="nav-item">
                    <Link
                      to={"/contact"}
                      className={`nav-link ${activePage === "contact" ? "activeTab" : ""
                        } `}
                      aria-current="page"
                      onClick={() => {
                        setActivePage("contact");
                      }}
                    >
                      {t("nav.contact")}
                    </Link>
                  </li>

                  {isAuth ? (
                    <>
                      <li className="nav-item dropdown me-2">
                        <span
                          className="nav-link dropdown-toggle m-0 mt-2 "
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={authUser.avatar ? authUser.avatar : Avatar}
                            id="profilePhoto"
                            className="me-1"
                            alt=""
                          />
                          <span className="mt-2">Hi, {authUser?.firstName}</span>
                        </span>
                        <ul
                          className="dropdown-menu p-0"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <span
                            className="dropdown-item d-flex align-items-center m-0 ps-3 pt-3 pb-2 rounded-top"
                            onClick={() => {
                              navigate("/dashboard");
                            }}
                          >
                            <MdOutlineDashboard size={16} className="me-2" />
                            Dashboard
                          </span>

                          {/* <span className="dropdown-item d-flex align-items-center m-0 ps-3 pt-3 pb-2 ">
                          <FiSettings size={14} className="me-2" />
                          Account settings
                        </span> */}

                          <hr className="dropdown-divider m-0" />

                          <span
                            className="dropdown-item d-flex align-items-center m-0 ps-3 pt-3 pb-2 rounded-bottom"
                            onClick={handleLogout}
                          >
                            <HiOutlineLogout size={16} className="me-2" />
                            Logout
                          </span>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <li className="nav-item  nav-item-login">
                      <Link
                        className="nav-link auth_btn text-dark hoverDownEffect"
                        style={{ cursor: "pointer" }}
                        to="/login"
                      >
                        Login
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <div className="dropdown">
                      {/* <button
                      className="btn mt-3 btn-link text-light d-flex align-items-center dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MdLanguage
                        size={20}
                        className="text-white me-1"
                        style={{ color: "white !important" }}
                      />
                      <span
                        style={{}}
                        className={`flag-icon flag-icon-${
                          languages.find(
                            ({ code, name, country_code }) =>
                              code === i18next.language
                          ).country_code
                        }`}
                      ></span>
                    </button> */}
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span className="dropdown-item-text">
                            {t("language")}
                          </span>
                        </li>
                        {languages.map(({ code, name, country_code }) => (
                          <li key={code}>
                            <button
                              className="dropdown-item"
                              onClick={() => i18next.changeLanguage(code)}
                              disabled={code === currentLanguage.code}
                            >
                              <span
                                style={{
                                  opacity: `${code === currentLanguage.code ? "40%" : "100%"
                                    }`,
                                }}
                                className={`flag-icon flag-icon-${country_code} mx-2`}
                              ></span>
                              {name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      </div>
    </>
  );
}

export default Nav;
