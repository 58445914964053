import "./App.css";
import { useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//libraries
import { Toaster } from "react-hot-toast";
// import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { getDesignTokens } from "./styles/theme";

//Auth imports
import Login from "./pages/auth/Login";
import ClientOnboarding from "./pages/auth/Onboarding/Client.jsx";
import SPOnboarding from "./pages/auth/Onboarding/ServiceProvider.jsx";
import Registration from "./pages/auth/Registration.jsx";

// Auth Redirects
import GoogleRedirect from "./pages/auth/SocialAuth/GoogleRedirect";
import VerifyEmail from "./pages/auth/Verifications/VerifyEmail";
import VerifyPhone from "./pages/auth/Verifications/VerifyPhone";
import ResetPassword from "./pages/auth/Resets/ResetPassword";

//Home
import Home from "./pages/Home";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs";

//Dashboard
import Dashboard from "./pages/Dashboard/Dashboard";
import Market from './pages/Market';

//Utils
import ProtectedRoute from "./Utils/ProtectedRoute";
import PageNotFound from "./pages/Error/PageNotFound";
import EasyImageCropper from "./components/ImageUtils/EasyImageCropper.jsx";

//Services
import OurProcess from "./pages/services/OurProcess/OurProcess";
import HomeService from "./pages/services/HomeService";
import Corporate from "./pages/services/Corporate";
import Schools from "./pages/services/Schools";
import Hospitality from "./pages/services/Hospitality";
import ServiceApartments from "./pages/services/ServiceApartments";
import Diplomats from "./pages/services/Diplomats";

//Resources
import ELearning from "./pages/resources/ELearning";
import Sacco from "./pages/resources/Sacco";
import MarketPlace from "./pages/resources/MarketPlace";
import Community from "./pages/resources/Community";

//About us
import OurStory from "./pages/About/OurStory";
import OurStaff from "./pages/About/OurStaff";
import Careers from "./pages/About/Careers";

//Terms & Conditions
import TermsAndConditions from "./pages/policies/TermsAndConditions";
import TermsOfUse from "./pages/policies/TermsOfUse";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";

//Courses
import AllCourses from "./pages/Courses/AllCourses";
import CourseContent from "./pages/Courses/CourseContent/CourseContent";

//For Service Provider
import ServiceProviderDashboard from "./pages/Dashboard/serviceProvider/ServiceProviderDashboard";
import Requests from "./pages/Dashboard/serviceProvider/Requests/Requests";
import SPProfile from "./pages/profile/sp/Profile";

//For Client
import Bookings from "./pages/Dashboard/client/Bookings/Bookings";
import Hire from "./pages/Dashboard/client/Hire";
import ClientProfile from "./pages/profile/client/Profile";

//temp
import Faq from "./pages/help/Faq.jsx";
import Messenger from "./pages/Messages/Messenger/Messenger";
import NotificationsMessenger from "./pages/Notifications/NotificationsMessenger";
import TestVideo from "./pages/vd/TestVideo";
import RateReview from "./components/Rating/RateReview";
import Items from "./components/Pagination/Items";
import SPProfileSlider from "./pages/profile/sp/SPProfileSlider.jsx";
import Assessment from "./pages/assessment/Assessment.jsx";
import PaymentModal from "./components/Payment/PaymentModal";
import Contract from "./pages/contracts/AllContracts.jsx";
import ContractDetails from "./components/Negotiation/ContractDetails";
import { wssResolver } from "./features/slices/GlobalsSlice";
import PDFComponent from "./components/pdf/PDFComponent";
import Vetting from "./pages/Vetting/Vetting";
import ServicesMin from "./components/Home/ServicesMin";
import ViewService from "./components/Home/ViewService";
import VettingPending from "./pages/Vetting/VettingPending";
import OurDifference from "./components/Home/OurDifference";
import { BsArrowUpSquareFill } from "react-icons/bs";
import AuthLayout from "./components/layouts/authLayout.js";
import AppLayout from "./components/layouts/appLayout.js";

let socket = wssResolver("rtc");

function App() {
  const [mode, setMode] = useState("light");
  useMemo(() => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }, []);
  const [showScroller, setShowScroller] = useState();
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    AOS.init();
    window.addEventListener("scroll", () => {
      if (window.scrollY > 800) {
        setShowScroller(true);
      } else {
        setShowScroller(false);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Landing page routes */}
          <Route path="/" element={<Home />} />
          {/* Services */}
          <Route path="/services/process" element={<OurProcess />} />
          <Route path="/services/home" element={<HomeService />} />
          <Route path="/services/corporate" element={<Corporate />} />
          <Route path="/services/schools" element={<Schools />} />
          <Route path="/services/hospitality" element={<Hospitality />} />
          <Route path="/services/:serviceId" element={<ViewService />} />
          <Route
            path="/services/service-apartments"
            element={<ServiceApartments />}
          />
          <Route path="/services/diplomats" element={<Diplomats />} />

          {/* Resources */}
          <Route path="/resources/e-learning" element={<ELearning />} />
          <Route path="/resources/sacco" element={<Sacco />} />
          <Route path="/resources/market-place" element={<MarketPlace />} />
          <Route path="/resources/community" element={<Community />} />

          {/* About */}
          <Route path="/about-us" element={<About />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/our-staff" element={<OurStaff />} />
          <Route path="/careers" element={<Careers />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/terms_and_conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />

          {/* Auth routes */}
          <Route
            path="/login"
            element={
              <AuthLayout title="Login to your Account"sec1={5} sec2={7} >
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="/register"
            element={
              <AuthLayout title="Register for an Account" sec1={5} sec2={7}>
                <Registration />
              </AuthLayout>
            }
          />
          <Route
            path="/client-onboarding"
            element={
              <AuthLayout title="Client Onboarding" sec1={5} sec2={7}>
                <ClientOnboarding />
              </AuthLayout>
            }
          />
          <Route
            path="/serviceProvider-onboarding"
            element={
              <AuthLayout title="Service Provider Onboarding" sec1={6} sec2={6}>
                <SPOnboarding />
              </AuthLayout>
            }
          />
          {/* Social Auth  */}
          <Route path="/auth/google/" element={<GoogleRedirect />} />

          {/* Redirects */}
          <Route
            path="/verify-phone"
            element={
              <ProtectedRoute>
                <VerifyPhone />
              </ProtectedRoute>
            }
          />
          <Route path="/auth/verify-email" element={<VerifyEmail />} />
          <Route
            path="/auth/reset"
            element={
              <AuthLayout title="Reset Password" sec1={5} sec2={7}>
                <ResetPassword />
              </AuthLayout>
            }
          />

          {/* Protected routes */}

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <AppLayout>
                  <Dashboard socket={socket} />
                </AppLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ProtectedRoute>
                <AppLayout>
                  <Market />
                </AppLayout>
              </ProtectedRoute>
            }
          />
          {/*Client */}
          <Route
            path="/dashboard/bookings"
            element={
              <ProtectedRoute>
                <AppLayout>
                  <Bookings />
                </AppLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/community/client"
            element={
              <ProtectedRoute>
                <Community />
              </ProtectedRoute>
            }
          />
          {/*Client */}
          <Route
            path="/community/provider"
            element={
              <ProtectedRoute>
                <Community />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/Hire"
            element={
              <ProtectedRoute>
                <AppLayout>
                  <Hire />
                </AppLayout>
              </ProtectedRoute>
            }
          />
          {/* sp requests routes */}
          <Route
            path="/dashboard/requests"
            element={
              <ProtectedRoute>
                <AppLayout>
                  <Requests />
                </AppLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/courses"
            element={
              <AppLayout>
                <AllCourses />
              </AppLayout>
            }
          />

          <Route
            path="/dashboard/contracts"
            element={
              <AppLayout>
                <Contract />
              </AppLayout>
            }
          />
          <Route
            path="/dashboard/courses/course-content"
            element={<CourseContent />}
          />

          {/* profiles */}
          <Route
            path="/profile/serviceProvider"
            element={
              <AppLayout>
                <SPProfile />
              </AppLayout>
            }
          />
          <Route
            path="/profile/client"
            element={
              <AppLayout>
                <ClientProfile />
              </AppLayout>
            }
          />

          <Route path="/faq" element={<Faq />} />

          {/* Temporary */}
          <Route path="/chat" element={<Messenger socket={socket} />} />
          <Route
            path="/notifications/:id"
            element={<NotificationsMessenger />}
          />
          <Route path="/video" element={<TestVideo />} />
          <Route path="/dash" element={<ServiceProviderDashboard />} />

          <Route path="/course" element={<CourseContent />} />
          <Route path="/rate" element={<RateReview />} />
          <Route path="/crop" element={<EasyImageCropper />} />
          <Route path="/profile" element={<SPProfileSlider />} />
          <Route path="/assessment" element={<Assessment />} />
          <Route path="/paginate" element={<Items />} />
          <Route path="/payment" element={<PaymentModal />} />

          <Route path="/negotiate" element={<ContractDetails />} />
          <Route path="/pdf" element={<PDFComponent />} />
          <Route path="/employer-vetting" element={<Vetting />} />
          <Route path="/services" element={<ServicesMin />} />
          <Route path="/difference" element={<OurDifference />} />
          <Route path="/vetting-pending" element={<VettingPending />} />

          {/* Errors */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      {showScroller && (
        <BsArrowUpSquareFill
          className="toTop animate__animated animate__pulse animate__infinite"
          size={35}
          onClick={() => {
            window.scrollTo({
              left: 0,
              top: 0,
              behavior: "smooth",
            });
          }}
          color={"#323a6d"}
        />
      )}
      <Toaster position="top-right" />
    </ThemeProvider>
  );
}

export default App;
