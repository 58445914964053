import React from "react";
import moment from "moment";

function OneOff({ details }) {
  return (
    <>
      <div className="p-3">
        <div className="fontsm">
          This is a binding contract by law that Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </div>
        <div className="mt-3 fontsm">
          <span className="mb-3">
            <u>
              <b>The Parties involved</b>
            </u>
          </span>
          <br />
          <br />
          The client
          <span className="ms-1 me-1">
            <b>
              <u>
                {details.client.firstName} {details.client.lastName}
              </u>
            </b>
          </span>
          is employing the service providerprovider
          <span className="ms-1 me-1">
            <b>
              <u>
                {details.provider.firstName} {details.provider.lastName}
              </u>
            </b>
          </span>
          has agreed to an offer of employment from upon agreement to the terms
          and conditions listed herein.
        </div>
        <div className="mt-3 fontsm">
          <span className="mb-3">
            <u>
              <b>Duration of Contract</b>
            </u>
          </span>
          <br />
          <br />
          This Contract shall have a duration of months from the
          <span className="ms-1 me-1">
            <b>
              <u>
                {moment(
                  moment(details?.schedule?.start)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .split(" ")[0]
                ).format("Do MMMM  YYYY")}
              </u>
            </b>
          </span>
          that assumes his/her duties upto date
          <span className="ms-1 me-1">
            <b>
              <u>{moment(
                  moment(details?.schedule?.end)
                    .format("YYYY-MM-DD HH:mm:ss")
                    .split(" ")[0]
                ).format("Do MMMM  YYYY")}
                
              </u>
            </b>
          </span>
          and shall be reviewed and revisited after months/year(s).
        </div>
        <div className="mt-3 fontsm">
          <span className="mb-3">
            <u>
              <b>Job Description</b>
            </u>
          </span>
          <br />
          <br />
          The Job type is <b>"come and go"</b> meaning the service provider will
          be coming in daily.Et ul nisi ut aliquip ex ea commodo consequat. nisi
          ut aliquip ex ea commodo consequat.
         
          nisi ut aliquip ex ea commodo consequat. nisi ut aliquip ex ea commodo
          consequat. nisi ut aliquip ex ea commodo consequat. nisi ut aliquip ex
          ea commodo consequat.
        </div>
        <div className="mt-3 fontsm">
          <u>
            <b>Salary/Compensation</b>
          </u>
          <br />
          <br />
          The client will be paid a monthly salary of {
            details.payment.amount
          }{" "}
          be nisi ut aliquip ex ea commodo consequat. nisi ut aliquip ex ea
          commodo consequat.
        </div>
      </div>
    </>
  );
}

export default OneOff;
