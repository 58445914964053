import React from "react";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Dashboard/Notifications.css";
import { AiOutlineClose } from "react-icons/ai";
import { closeTabs } from "../../features/slices/DashboardControlSlice";

function NotificationsContainer() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const dispatch = useDispatch();
  return (
    <>
      <div className="row m-0 pb-2 fixed-bottom" id="notificationsContainer">
        <div
          className="col-sm-4 p-0 ms-auto shadow bg-white border m-3 rounded "
          id="prevNotificationsCont"
        >
          <Notifications />
        </div>
      </div>
    </>
  );
}

export default NotificationsContainer;
