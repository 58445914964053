import { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import Home1 from "../../pics/home1.jpg";

export default function App() {
  const [courses, setCourses] = useState([
    {
      src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
      type: "video/mp4",
    },
    {
      src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
      type: "video/mp4",
    },
    {
      src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      type: "video/mp4",
    },
    {
      src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
      type: "video/mp4",
    },
  ]);

  const [playingLink, setPlayingLink] = useState(courses[1].src);
  console.log("App.render");
  useEffect(() => {
    console.log("App.mount");
    return () => console.log("App.unmount");
  }, []);

  const handlePlaylist = (index) => {
    setPlayingLink(courses[index].src);
    console.log("playing " + index);
  };

  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <VideoJS poster={Home1} videoLink={playingLink} />
      <p>
        <button
          className="btn btn-primary m-1 btn-sm"
          onClick={() => {
            handlePlaylist(0);
          }}
        >
          Play Bigger Escapes
        </button>
      </p>
      <p>
        <button
          className="btn btn-primary m-1 btn-sm"
          onClick={() => {
            handlePlaylist(1);
          }}
        >
          Play Elephants Dream
        </button>
      </p>
      <p>
        <button
          className="btn btn-primary m-1 btn-sm"
          onClick={() => {
            handlePlaylist(2);
          }}
        >
          Play Bunny
        </button>
      </p>
      <p>
        <button
          className="btn btn-primary m-1 btn-sm"
          onClick={() => {
            handlePlaylist(3);
          }}
        >
          Play Bigger Blazes
        </button>
      </p>
    </div>
  );
}
