import { useEffect, useState } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import "../../styles/About.css";
import MissionPic from "../../pics/sp/client_served1.png";
import VisionPic from "../../pics/sp/sps3.png";
import Avatar from "../../pics/avatar.png";
import Jb1 from "../../pics/jb1.jpg";
import Vic1 from "../../pics/vic1.jpg";
import Shiko1 from "../../pics/shiko1.jpg";
import Michael1 from "../../pics/michael1.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ConfettiExplosion from "react-confetti-explosion";
function About() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setIsExploding(true);
  }, []);
  return (
    <>
      <Nav currentPage={"about"} />

      <div
        id="about-container"
        className="d-flex flex-column align-items-center pt-3  ps-0 pe-0"
        style={{ backgroundColor: "#f8f9fas" }}
      >
        <div className="col-sm-10 d-flex flex-column mt-5 mb-2 p-2 animate__animated animate__fadeInDown">
          <h1
            id="moonShotText"
            className="mt-5 mb-3 fw-bold text-center"
            style={{ fontSize: "40px", minHeight: "50vh" }}
          >
            {/* {isExploding && (
              <div className="w-100 d-flex justify-content-center">
                <ConfettiExplosion />
              </div>
            )} */}
            Our Moonshot vision is to disrupt the informal and fragmented
            domestic industry, through tech optimization, using Ai-based vetting
            and rating system, virtual upskilling and use of automated
            contracts, with financial inclusion for service providers.
          </h1>
        </div>

        <br />

        <div
          id="moreInfoContainer"
          className=" col-sm-12 m-0 p-0 mt-5"
          style={{ backgroundColor: `${colorSchema?.primary}` }}
        >
          <div
            id="missionContainer"
            data-aos="fade-up"
            className="row d-flex p-0 m-0   mx-auto "
          >
            <div className="col-sm-6 ratingSystemImg d-flex justify-content-center m-0 p-0">
              <img
                src={MissionPic}
                alt="rating img"
                className="w-100 m-0  statImage"
                // style={{ minHeight: "550px" }}
              />
            </div>
            <div className="col-sm-6 d-flex  ratingSystemText row m-0 mt-3">
              <div className="d-flex justify-content-center text-white  pt-3 flex-column col-sm-10 mx-auto ">
                <h1 className="m-3">
                  <b>Our Mission to the African Home</b>
                </h1>

                <p className="mt-1 p-3 missionTxt sizeNormal ">
                  To restore integrity, professionalism, skill, and ethic to the
                  industry by providing vetted,rated and trained domestic
                  technicians who are continually upskilled in housemanagent,
                  nutrition and childcare,to avail bespoke solutions for
                  individual homes.
                </p>
              </div>
            </div>
          </div>
          <div id="" data-aos="fade-up" className="row    mx-auto">
            <div className="col-sm-6 d-flex align-items-center  text-white">
              <div className="d-flex justify-content-center align-items-center text-white  flex-column col-sm-10 mx-auto ">
                <h1
                  className="m-3"
                  //  style={{ color: "#e44977" }}
                >
                  <b>Our vision to the African service provider</b>
                </h1>

                <p className="mt-1 p-3 visionTxt sizeNormal">
                  To create a community of skilled Domestic workers with access
                  to development training in various key areas based on their
                  specialised skill set while create avenues for financial
                  inclusion and empowerment.
                </p>
              </div>
            </div>
            <div className="col-sm-6 p-0  d-flex justify-content-center">
              <img
                src={VisionPic}
                alt="rating image"
                className="w-100  m-0 statImage"
                // style={{ minHeight: "550px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div data-aos="fade-up" className="row m-0 w-100 pt-5 pb-5 mb-3">
        <div className="col-sm-10 row mx-auto">
          <div className="col-sm-12 mx-auto m-2 ">
            <h3 className="mb-1">Our team</h3>
            <p>Our top and dedicated professionals.</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img src={Shiko1} alt="CET" className="w-100 mb-2 teamImage" />
            </span>
            <h5>
              <b>Everlyn Kihia</b>
            </h5>
            <p>Chief Executive Officer</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img src={Michael1} alt="PM" className="w-100 mb-2 teamImage" />
            </span>
            <h5>
              <b>Michael Odhiambo</b>
            </h5>
            <p>Project Manager</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img src={Vic1} alt="SE" className="w-100 mb-2 teamImage" />
            </span>
            <h5>
              <b>Victor Kamoto</b>
            </h5>
            <p>Software Engineer </p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img src={Jb1} alt="SE" className="w-100 teamImage mb-2" />
            </span>
            <h5>
              <b>Jonathan Baraza</b>
            </h5>
            <p>Software Engineer</p>
          </div>
        </div>
      </div> */}
      {/* <div
        data-aos="fade-up"
        className="row text-light d-flex justify-content-center align-items-center p-4 d-none  m-0"
        style={{ backgroundColor: `${colorSchema?.primary}` }}
      >
        <h4 className=" text-center">Our history</h4>
      </div>
      <div className="m-0 row ps-2 pe-2 histDiv d-none">
        <div className="col-sm-10 mx-auto">
          <VerticalTimeline
            lineColor={`${colorSchema?.primary}`}
            animate={true}
          >
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2012"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
              contentStyle={{
                background: "white",
                color: "black",
              }}
              contentArrowStyle={{
                borderRight: `7px solid  white`,
              }}
            >
              <h5>
                <b>The idea came</b>
              </h5>
              <p>
                Lorem ipsum dolor sit amet. Eos galisum repellat qui laborum non
                debitis autem! Eos provident temporibus qui recusandae neque non
                dolorum tempore aut quia nobis et molestiae officia ut enim
                optio. In quibusdam error ea animi quidem et libero molestiae
                qui quia repudiandae.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2014"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <h5>
                <b>And then this</b>
              </h5>
              <p>
                Lorem ipsum dolor sit amet. Eos galisum repellat qui laborum non
                debitis autem! Eos provident temporibus qui recusandae neque non
                dolorum tempore aut quia nobis et molestiae officia ut enim
                optio. In quibusdam error ea animi quidem et libero molestiae
                qui quia repudiandae.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2018"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <h5>
                <b>And then this</b>
              </h5>
              <p>
                Lorem ipsum dolor sit amet. Eos galisum repellat qui laborum non
                debitis autem! Eos provident temporibus qui recusandae neque non
                dolorum tempore aut quia nobis et molestiae officia ut enim
                optio. In quibusdam error ea animi quidem et libero molestiae
                qui quia repudiandae.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work shadow-none"
              date="2022"
              iconStyle={{
                background: `${colorSchema?.primary}`,
                color: `${colorSchema?.primary}`,
                transform: "scale(0.6)",
              }}
            >
              <h5>
                <b>And then this</b>
              </h5>
              <p>
                Lorem ipsum dolor sit amet. Eos galisum repellat qui laborum non
                debitis autem! Eos provident temporibus qui recusandae neque non
                dolorum tempore aut quia nobis et molestiae officia ut enim
                optio. In quibusdam error ea animi quidem et libero molestiae
                qui quia repudiandae.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default About;
