import React from "react";
import Policies from "./Policies";
import Accordion from "react-bootstrap/Accordion";
import "../../components/Home/Footer";
import Footer from "../../components/Home/Footer";
const TermsOfUse = () => {
  return (
    <>
      <Policies currentPage={"terms_of_use"} />
      <div className="row m-0">
        <div className="col-sm-8 mx-auto p-2 mt-4 d-flex flex-column">
          <div className="mx-auto text-center mt-3 fw-bold">TERMS OF USE</div>
          <span className="mt-3 lightDark">
            <span className="mt-3 lightDark">
              These Terms of Use (“Terms”, “Terms of Service”) contained herein
              on this webpage, shall govern your use of this App, located at
              https://maidsofhonour.africa (together or individually “Service”)
              operated by Maids of Honour-Africa (“Company”, “we”, “our”, “us”).
              These Terms apply in full force and effect to your use of this App
              and by using this App, you expressly accept all terms and
              conditions contained herein in full. You must not use this App, if
              you have any objection to any of these Terms.
            </span>
          </span>
          <Accordion defaultActiveKey="" className="mt-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="text-dark">
                1. Content
              </Accordion.Header>
              <Accordion.Body>
                The Content found on or through the Service is the property of
                the Company or used with permission from a third party. You may
                not distribute, modify, transmit, reuse, download, repost, copy,
                or use the said Content, whether in whole or in part, for
                commercial purposes or for personal gain, without prior written
                permission from us.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="text-dark">
                2. Prohibited Uses
              </Accordion.Header>
              <Accordion.Body>
                You may use this Service only for lawful purposes. <br />
                You agree not to use Service:
                <ul className="mt-2">
                  <li className="mt-1">
                    In any way that violates any applicable national or
                    international laws or regulations.
                  </li>
                  <li className="mt-1">
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content or otherwise.
                  </li>
                  <li className="mt-1">
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail”, “chain
                    letter,” “spam,” or any other similar solicitation.
                  </li>
                  <li className="mt-1">
                    To impersonate or attempt to impersonate the Company, a
                    Company employee, another user, or any other person or
                    entity.
                  </li>
                  <li className="mt-1">
                    In any way that infringes upon the rights of others, or in
                    any way is illegal, threatening, fraudulent, or harmful, or
                    in connection with any unlawful, illegal, fraudulent, or
                    harmful purpose or activity.
                  </li>
                  <li className="mt-1">
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of Service, or which, as
                    determined by us, may harm or offend Company or users of
                    Service or expose them to liability.
                  </li>
                </ul>
                <span className="mt-2 mb-1">
                  {" "}
                  Additionally, you agree not to:
                </span>
                <ul>
                  <li>
                    Use Service in any manner that could disable, overburden,
                    damage, or impair Service or interfere with any other
                    party’s use of Service, including their ability to engage in
                    real-time activities through Service.
                  </li>
                  <li>
                    Use any robot, spider, or other automatic devices process,
                    or means to access Service for any purpose, including
                    monitoring or copying any of the material on Service.
                  </li>
                  <li>
                    Use any manual process to monitor or copy any of the
                    material on Service or for any other unauthorized purpose
                    without our prior written consent.
                  </li>
                  <li>
                    Use any device, software, or routine that interferes with
                    the proper working of Service.
                  </li>
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs, or
                    other material which is malicious or technologically
                    harmful.
                  </li>
                  <li>
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of Service, the server on which
                    Service is stored, or any server, computer, or database
                    connected to Service.
                  </li>
                  <li>
                    Attack Service via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </li>
                  <li>
                    Take any action that may damage or falsify Company rating.
                  </li>
                  <li>
                    Otherwise attempt to interfere with the proper working of
                    Service.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="text-dark">
                3. Accounts
              </Accordion.Header>
              <Accordion.Body>
                When you create an account with us, you guarantee that you are
                above at least the age of 18 years or older, and that the
                information you provide us is accurate, complete, and current at
                all times. Inaccurate, incomplete, or obsolete information may
                result in the immediate termination of your account on Service.
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account. You may not use as
                a username the name of another person or entity or that is not
                lawfully available for use, a name or trademark that is subject
                to any rights of another person or entity other than you,
                without appropriate authorization. You may not use as a username
                any name that is offensive, vulgar or obscene. We reserve the
                right to refuse service, terminate accounts, remove or edit
                content, or cancel orders in our sole discretion.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="text-dark">
                4. Intellectual Property Rights
              </Accordion.Header>
              <Accordion.Body>
                The Service and its original content (excluding Content provided
                by users), features and functionality are and will remain the
                exclusive property of the Company and its licensors. The Service
                is protected by copyright, trademark, and applicable laws of the
                relevant countries. Our trademarks may not be used in connection
                with any product or service without the prior written consent of
                the Company.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="text-dark">
                5. Error Reporting and Feedback
              </Accordion.Header>
              <Accordion.Body>
                You may provide us either directly at
                info@maidsofhonour.africa or via third party sites and tools
                with information and feedback concerning errors, suggestions for
                improvements, ideas, problems, complaints, and other matters
                related to our Service (“Feedback”).
                <br />
                <br />
                <span className="mt-2 mb-2">
                  You acknowledge and agree that:
                </span>
                <br />
                <ul>
                  <li>
                    You shall not retain, acquire or assert any intellectual
                    property right or other right, title or interest in or to
                    the Feedback;
                  </li>
                  <li>
                    The Company may have development ideas similar to the
                    Feedback;
                  </li>
                  <li>
                    The Feedback does not contain confidential information or
                    proprietary information from you or any third party; and
                  </li>
                  <li>
                    The Company is not under any obligation of confidentiality
                    with respect to the Feedback.
                  </li>
                </ul>
                In the event the transfer of the ownership to the Feedback is
                not possible due to applicable mandatory laws, you grant Company
                and its affiliates an exclusive, transferable, irrevocable,
                free-of-charge, sub-licensable, unlimited and perpetual right to
                use (including copy, modify, create derivative works, publish,
                distribute and commercialize) Feedback in any manner and for any
                purpose.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="text-dark">
                6. Links to Other Web Sites
              </Accordion.Header>
              <Accordion.Body>
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by the company. The
                Company has no control over, and assumes no responsibility for
                the content, privacy policies, or practices of any third party
                web sites or services. We do not warrant the offerings of any of
                these entities/individuals or their websites. You acknowledge
                and agree that company shall not be responsible or liable,
                directly or indirectly, for any damage or loss caused or alleged
                to be caused by or in connection with use of or reliance on any
                such content, goods or services available on or through any such
                third party web sites or services. We strongly advise you to
                read the terms of service and privacy policies of any third
                party web sites or services that you visit.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="text-dark">
                7. Disclaimer of warranty
              </Accordion.Header>
              <Accordion.Body>
                The Service is provided by the Company on an “as is” and “as
                available” basis. The Company makes no representations or
                warranties of any kind, express or implied, as to the operation
                of their service, or the information, content or materials
                included therein. You expressly agree that your use of the
                service, their content, and any services or items obtained from
                us is at your sole risk. Neither the Company nor any person
                associated with Company makes any warranty or representation
                with respect to the completeness, security, reliability,
                quality, accuracy, or availability of the services. Without
                limiting the foregoing, neither the Company nor anyone
                associated with Company represents or warrants that the
                services, their content, or any services or items obtained
                through the services will be accurate, reliable, error-free, or
                uninterrupted, that defects will be corrected, that the services
                or the server that makes it available are free of viruses or
                other harmful components or that the services or any services or
                items obtained through the services will otherwise meet your
                needs or expectations. The Company hereby disclaims all
                warranties of any kind, whether express or implied, statutory,
                or otherwise, including but not limited to any warranties of
                merchantability, non-infringement, and fitness for particular
                purpose. This does not affect any warranties which cannot be
                excluded or limited under applicable law.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header className="text-dark">
                8. Limitation of Liability
              </Accordion.Header>
              <Accordion.Body>
                Except as prohibited by law, you will hold us and our officers,
                directors, employees, and agents harmless for any indirect,
                punitive, special, incidental, or consequential damage, however
                it arises (including attorneys’ fees and all related costs and
                expenses of litigation and arbitration, or at trial or on
                appeal, if any, whether or not litigation or arbitration is
                instituted), whether in an action of contract, negligence, or
                other tortious action, or arising out of or in connection with
                this agreement, including without limitation any claim for
                personal injury or property damage, arising from this agreement
                and any violation by you of any federal, state, or local laws,
                statutes, rules, or regulations, even if company has been
                previously advised of the possibility of such damage. Except as
                prohibited by law, if there is liability found on the part of
                the Company, it will be limited to the amount paid for the
                products and/or services, and under no circumstances will there
                be consequential or punitive damages.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header className="text-dark">
                9. Indemnification
              </Accordion.Header>
              <Accordion.Body>
                You hereby indemnify to the fullest extent the Company from and
                against any and all liabilities, costs, demands, causes of
                action, damages and expenses (including reasonable attorney’s
                fees) arising out of or in any way related to your breach of any
                of the provisions of these Terms.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header className="text-dark">
                10. Termination
              </Accordion.Header>
              <Accordion.Body>
                We may terminate or suspend your account and bar access to the
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of Terms and
                Conditions. If you wish to terminate your account, you may
                simply discontinue using the Service by opting out. All
                provisions of Terms and Conditions which by their nature should
                survive termination shall survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                indemnity and limitations of liability.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header className="text-dark">
                11. Withdrawal of Service
              </Accordion.Header>
              <Accordion.Body>
                We reserve the right to withdraw or amend our Service, and any
                service or material we provide via Service, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of Service is unavailable at any time or
                for any period. From time to time, we may restrict access to
                some parts of Service, or the entire Service, to users,
                including registered users.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header className="text-dark">
                12. Amendments to Terms
              </Accordion.Header>
              <Accordion.Body>
                We may amend Terms at any time by posting the amended terms on
                this site. It is your responsibility to review these Terms
                periodically. Your continued use of the Service following the
                posting of revised Terms you shall be deemed that you have
                understood, accepted and agreed to the changes. You are expected
                to check this page frequently so you are aware of any changes,
                as they are binding on you. By continuing to access or use our
                Service after any revisions become effective, you agree to be
                bound by the revised terms. If you do not agree to the new
                terms, you are no longer authorized to use Service.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header className="text-dark">
                13. Waiver and Severability
              </Accordion.Header>
              <Accordion.Body>
                No waiver by the Company of any term or condition set forth in
                Terms shall be deemed a further or continuing waiver of such
                term or condition or a waiver of any other term or condition,
                and any failure of Company to assert a right or provision under
                Terms shall not constitute a waiver of such right or provision.
                If any provision of Terms is held by a court or other tribunal
                of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header className="text-dark">
                14. Governing Law
              </Accordion.Header>
              <Accordion.Body>
                These Terms shall be governed and construed in accordance with
                the laws of Kenya, which governing law applies to agreement
                without regard to its conflict of law provisions. Our failure to
                enforce any right or provision of these Terms will not be
                considered a waiver of those rights. If any provision of these
                Terms is held to be invalid or unenforceable by a court, the
                remaining provisions of these Terms will remain in effect. These
                Terms constitute the entire agreement between us regarding our
                Service and supersede and replace any prior agreements we might
                have had between us regarding Service.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header className="text-dark">
                15. Acknowledgement
              </Accordion.Header>
              <Accordion.Body>
                By using service or other services provided by us, you
                acknowledge that you have read and understood these terms of
                service and agree to be bound by them.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header className="text-dark">
                16. Contact Us
              </Accordion.Header>
              <Accordion.Body>
                Please send your feedback, comments, and requests for technical
                info by email: <b>info@maidsofhonour.africa</b>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;

