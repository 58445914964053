/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { updateUserDetails } from "../../../features/slices/AuthSlice";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import "../../../styles/Profile.css";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClientProfileDetails from "./ClientProfileDetails";
import Avatar from "../../../pics/avatar.png";
import { AiFillStar } from "react-icons/ai";
import { MdLocationOn, MdOutlineWork } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from "moment";
import DashboardLayout from "../../Dashboard/DashboardLayout";
import { styled } from "@mui/material/styles";
import { calculateUserRating } from "../../../Utils/userStats";
import {
  Alert,
  CardMedia,
  Button,
  Box,
  Typography,
  Paper,
  Stack,
  Chip,
  Divider,
  AlertTitle,
  CardContent,
  Card,
  ListItemText, ListItemAvatar,
  ListItem,
  TextField
} from "@mui/material";
import {  ref, getDownloadURL, uploadBytes } from "firebase/storage";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import { GiCash } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import { BsInfoCircle } from "react-icons/bs";
import {
  FaAward,
  FaBriefcase,
  FaHourglassStart,
  FaUserFriends,
} from "react-icons/fa";
import { AiFillTrophy, AiOutlineCopy } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dialog from '@mui/material/Dialog';
import storage from '../../../Utils/firebaseConfig';
import { toast } from "react-hot-toast";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import { TextField } from "formik-mui";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const referralCode = useSelector((state) => state.referral.referralCode);
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const [avatar, setAvatar] = useState("")
  const [uploading, setUploading] = useState(false)
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const [isProfileCompleted, setIsProfileCompleted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const colorSchema = useSelector((state) => state.color.scheme);
  const [userRating, setUserRating] = useState("wait");
  const [open, setOpen] = React.useState(false);


  const handleUpload = (event) => {
    setUploading(true)
    const files = event.target.files;
    const myFiles = Array.from(files);
    const filePath = `profile/${myFiles[0]?.name}`
    const storageRef = ref(storage, filePath);
    // upload image to firebase
    uploadBytes(storageRef, myFiles[0]).then((snapshot) => {
      // get uploaded image
      const bucketRef = ref(storage, snapshot.metadata.fullPath);
      getDownloadURL(bucketRef)
        .then(async (avatar) => {
          // update user profile
          setAvatar(avatar);
          setUploading(false)
          toast.success("Profile photo  successfully updated");
          const response = await axiosWrapper({
            method: "patch",
            url: `users/${user.sub ? user.sub : user.id}`,
            data: { avatar },
          });

          dispatch(updateUserDetails({
            ...user,
            avatar,
          }))
        })
    });
  };



  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode?.code);
    setTooltipText("Code Copied!");
    setTimeout(() => {
      setTooltipText("Copy to clipboard");
    }, 2000);
  };

  const handleClickOpen = () => {
    alert("hello")
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchProfileDetails = async () => {
    setIsLoading(true);
    const response = await axiosWrapper({
      method: "get",
      url: `users/${user.sub ? user.sub : user.id}`,
      data: "",
    });

    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${user.sub ? user.sub : user.id}`,
    });

    dispatch(
      updateUserDetails({
        ...response.data.data,
        rating: getReviews.data.data.rows,
      })
    );
    let rating = calculateUserRating({
      ...response.data.data,
      rating: getReviews.data.data.rows,
    });

    setUserRating(rating);
    setIsLoading(false);
  };

  const closeUpdateForm = () => {
    window.scroll(0, 0);
    setShowUpdateForm(false);
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    // if (!isProfileComplete && isAuth) {
    //   setShowUpdateForm(true);
    // }
    fetchProfileDetails();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [showUpdateForm]);

  return (
    // <DashboardLayout>
    <>
      <div>
        {showUpdateForm ? (
          <ClientProfileDetails closeForm={closeUpdateForm} />
        ) : (
          <>

            <div className="row m-0 pt-4 profileCont h-100">
              <div className="col-sm-10 mx-auto ">

                {!isProfileComplete && (
                  <Alert
                    severity="warning"
                    sx={{ mb: 0.5 }}
                    onClose={() => setIsProfileCompleted(false)}

                  >
                    <AlertTitle>Incomplete Profile </AlertTitle>
                    Your profile is incomplete! Kindly provide further
                    details.
                  </Alert>
                )}
                {/* {!payment && (
                    <Alert
                      severity="info"
                      onClose={() => setPayment(false)}
                      action={
                        <Button
                          color="primary"
                          onClick={handleClickOpen}
                          size="small">
                          Pay Now
                        </Button>
                      }

                    >
                      <AlertTitle>Registration Fee </AlertTitle>
                      Your account is still pending a registration fee! Kindly click pay now to gain access a variety of items
                    </Alert>
                  )} */}
              </div>

              <div className="col-sm-10 mx-auto row m-0">
                <div className="d-flex w-100 flex-wrap justify-content-between align-items-center ">
                  <span>MY PROFILE</span>
                  {referralCode?.code && (
                    <span className="d-flex align-items-center">
                      Your referral code is
                      <span className="ms-2 text-success">
                        <b>{referralCode.code}</b>
                      </span>
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-left}`} className="font-sm1">
                            {tooltipText}
                          </Tooltip>
                        }
                      >
                        <span>
                          <AiOutlineCopy
                            className="ms-2 hoverUpEffect"
                            onClick={handleCopy}
                            size={18}
                          />
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-left}`} className="font-sm1">
                            This referal code is very beneficial
                          </Tooltip>
                        }
                      >
                        <span>
                          <BsInfoCircle className="ms-2 hoverUpEffect" size={18} />
                        </span>
                      </OverlayTrigger>
                    </span>
                  )}
                </div>

                <span id="line" className="m-1"></span>
              </div>

              <Box>
                <Grid
                  container
                  spacing={4}
                  mb={4}
                  px={{ xs: 1, sm: 2, md: 10, lg: 20 }}
                >
                  <Grid item xs={12}>
                    <Card
                      elevation={0}
                      sx={{
                        display: "flex",
                        borderRadius: "6px",
                        boxShadow: "10px 10px 8px rgb(157 168 189 / 17%)",
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={avatar || user.avatar}
                        alt="Profile"
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Typography component="div" variant="h5">
                              {user.firstName} {user.lastName}
                            </Typography>
                          </Stack>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            component="div"
                          >
                            {user.bio}
                          </Typography>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            pl: 1,
                            pb: 1,
                          }}
                        >
                          <IconButton aria-label="upload picture" component="label" >
                            <input hidden accept="image/*" type="file" onChange={handleUpload}
                              disabled={uploading} />
                            <AddAPhotoIcon color="secondary" />
                          </IconButton>
                          <Button
                            startIcon={<EditIcon />}
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setShowUpdateForm(true);
                            }}
                            sx={{ borderRadius: "8px", px: 1, bgcolor: "text.secondary" }}
                          >
                            Edit profile
                          </Button>
                        
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        borderRadius: "8px",
                        p: 2,
                        boxShadow: "10px 10px 8px rgb(157 168 189 / 17%)",
                      }}
                    >
                      <Divider>
                        <Chip label="Personal Details" />
                      </Divider>
                      <div style={{ margin: "1rem" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            Phone Number:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.phone}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            Email:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.email}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            Location:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.placeOfResidence}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            Gender:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.gender}
                          </Typography>
                        </Stack>
                        {/* <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                              Marital Status:
                            </Typography>
                            <Typography
                              component="div"
                              variant="body2"
                              sx={{ fontWeight: "bold" }}
                            >
                              {user.maritalStatus}
                            </Typography>
                          </Stack> */}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        borderRadius: "8px",
                        p: 2,
                        boxShadow: "10px 10px 8px rgb(157 168 189 / 17%)",
                      }}
                    >
                      <Divider>
                        <Chip label=" Service Details" />
                      </Divider>
                      <div style={{ margin: "1rem" }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            Preferred age group:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.ageGroup}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <Typography component="div" variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }}>
                            household Members:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {user.specialNeeds ? user.specialNeeds : "None"}
                          </Typography>
                        </Stack>
                        <Typography gutterBottom variant="overline" color="text.secondary" sx={{ fontWeight: "bold" }} >
                          What services I am looking for:
                        </Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                          {user.skills?.map((skill, index) => (
                            <div key={index}>
                              <Chip  sx={{  borderRadius: "8px", bgcolor: "#FDEDED" }}
                                 label={skill.title} />
                            </div>
                          ))}
                        </Stack>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>

              {/* <div className="col-sm-10 mx-auto bg-white border p-3 rounded row m-1 ">
                  <div className="col-sm-6 d-flex flex-column">
                    <CardMedia
                      component="img"
                      sx={{ borderRadius: "50%",width: 251,height: 251 }}
                      src={user.avatar ? user.avatar : Avatar}
                      alt="Profilephoto"
                    />

                    <div className="mt-4 mb-3">
                      {user?.bio && (
                        <ListItem>
                          <ListItemText primary="Bio" secondary={user?.bio} />
                        </ListItem>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 p-3">
                    <div className="d-flex justify-content-between align-items-start p-2">
                      <div className="d-flex flex-column lightText">
                        <h3>
                          <b>
                            {user.firstName} {user.lastName}
                          </b>
                        </h3>
                        <span>
                          <small>
                            <AiFillStar className="me-1 text-warning" />
                            {userRating === "wait" ? (
                              <div className="spinner-border spinner spinner-border-sm text-success me-1"></div>
                            ) : (
                              userRating
                            )}{" "}
                            ratings
                          </small>
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-column p-2 mt-3">
                      <span className="fontsm mb-1">
                        <b>Personal Information</b>
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Phone Number: </b>
                        {user.phone}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Email: </b>
                        {user.email}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Location: </b>
                        {user.placeOfResidence}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Age: </b>
                        {new Date().getFullYear() -
                          new Date(user.dob).getFullYear()}{" "}
                        years
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Gender: </b>
                        {user.gender}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>ID number: </b>
                        {user.idNo}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Marital Status: </b>
                        {user.maritalStatus}
                      </span>
                    </div>
                    <div className="d-flex flex-column p-2 mt-3">
                      <span className="fontsm mb-1">
                        <b>Professional Information</b>
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Preferred age group: </b>
                        {user.ageGroup}
                      </span>
                      <span className="fontsm1 mt-2">
                        <b>Family special needs: </b>
                        {user.specialNeeds ? user.specialNeeds : "None"}
                      </span>

                      <span className="text-dark fontsm1 mt-4">
                        <b>What services I am looking for: </b>
                      </span>
                      <div className="d-flex flex-wrap lightDark fontsm">
                        {user.skills?.map((skill, index) => (
                          <div
                            key={index}
                            className="badge me-2 mt-2 p-2 rounded-pill "
                            style={{ backgroundColor: `#242b51` }}
                          >
                            {skill.title}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap m-3">
                      <button
                        className="btn  btnUpdateProfile  ps-4 pe-4 text-light  me-3 mt-3"
                        style={{ backgroundColor: `${colorSchema?.primary}` }}
                        onClick={() => {
                          setShowUpdateForm(true);
                        }}
                      >
                        Update your profile
                      </button>
                      {!user?.idFront &&
                        !user.idBack &&
                        !user.conductCert &&
                        !user.avatar &&
                        !user.placeOfResidence && (
                          <button
                            className="btn  btnUpdateProfile ps-4 pe-4   me-3 mt-3"
                            onClick={() => {
                              setShowUpdateForm(true);
                            }}
                            style={{
                              border: `2px solid ${colorSchema?.primary}`,
                              color: `${colorSchema?.primary}`,
                            }}
                          >
                            Complete profile
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-12 mt-3 p-2"></div>
                </div>  */}
            </div>
          </>
        )}
      </div>
    </>
    // </DashboardLayout>
  );
}

export default Profile;
