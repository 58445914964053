import { useState, useEffect } from "react";
import Password1 from "../../../pics/password1.gif";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import { BiShow, BiHide, BiBorderAll } from "react-icons/bi";
import {
  setUserFromSocialLogin,
  updateUserDetails,
} from "../../../features/slices/AuthSlice";
import axios from "axios";
import Spinner1 from "../../../components/spinners/Spinner1";
import "../../../styles/Auth.css";
function ResetPassword() {
  const backendUrl = useSelector((state) => state.globals.backendUrl);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const colorSchema = useSelector((state) => state.color.scheme);
  const [email, setEmail] = useState(localStorage.getItem("resetEmail"));
  const [accessToken, setAccessToken] = useState(query.get("access"));
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  // const verifyOTP = async () => {
  //   try {
  //     const updateResponse = await axios({
  //       method: "post",
  //       url: backendUrl + `auth/otp/email/verify`,
  //       headers: {
  //         Authorization: "Bearer " + accessToken,
  //       },
  //     });
  //     setIsLoading(false);
  //     console.log("updateResponse");
  //     console.log(updateResponse);

  //     if (updateResponse.data.success) {
  //       toast.success("Verification success!");
  //     }
  //   } catch (error) {
  //     console.log("otp error");
  //     console.log(error);
  //     console.log(error.response.data.message);
  //     toast.error(error.response.data.message);
  //   }
  // };

  const handleReset = async () => {
    if (!password) {
      setPasswordError("Please enter your new password");
    } else if (password.length < 7) {
      setPasswordError("Password too short");
    } else if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("The two passwords dont match");
    } else {
      setPasswordError("");
      setConfirmPasswordError("");
      setIsResetLoading(true);
      try {
        const resetResponse = await axios({
          method: "patch",
          url: backendUrl + `auth/reset`,
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          data: {
            email,
            password,
          },
        });
        setIsResetLoading(false);
        console.log("resetResponse");
        console.log(resetResponse);

        if (resetResponse.data.success) {
          toast.success("Password reset was successful!");
          navigate("/login");
        }
      } catch (error) {
        setIsResetLoading(false);
        console.log("rest error error");
        console.log(error);
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        setOtpError(true);
      }
    }
  };

  const sendOTP = async () => {
    const recipients = {
      recipients: [email],
    };
    setResendLoading(true);
    try {
      const response = await axios.post(
        backendUrl + "auth/reset/email",
        recipients
      );

      setResendLoading(false);
      console.log("reset response");

      if (response.data.success) {
        toast.success("Check your email for further instructions");
      }
    } catch (error) {
      setResendLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const checkMatching = async () => {
    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    // verifyOTP();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner1 message={"Verifying, please wait..."} />
      ) : (
        <>
          {otpError ? (
            <div className="d-flex flex-direction-column m-0 justify-content-center align-items-center p-3 row">
              <div className="col-sm-12 row m-0 mx-auto d-flex flex-column align-items-center justify-content-center">
                <div
                  className="d-flex w-100 justify-content-center align-items-center flex-column mt-3"
                  style={{ height: "80vh" }}
                >
                  <h3 className="m-3 text-center">Your OTP Expired!</h3>
                  <button
                    className="btn_submit mt-2 p-2 text-light rounded d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: `${colorSchema?.primary}` }}
                    onClick={sendOTP}
                  >
                    {resendLoading ? "Sending OTP..." : "Resend OTP"}
                  </button>
                </div>
              </div>
            </div>
        
          ) : (
            <div className="d-flex flex-direction-column m-0 justify-content-center align-items-center p-3 row">
              <div className="col-sm-12 row m-0 mx-auto d-flex flex-column align-items-center justify-content-center mt-3">
                {/* <h4 className="text-center mt-5">
                  <b>Reset Password</b>
                </h4>

                <img alt="phone" className="w-50 m-3" src={Password1} /> */}
                <span className="" style={{ position: "relative" }}>
                  <input
                    type={`${showPassword ? "text" : "password"}`}
                    className={`form-control ${
                      passwordsMatch && "border-success text-success"
                    } text-center mt-3 p-2`}
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyUp={() => {
                      checkMatching();
                    }}
                  />
                  {showPassword ? (
                    <BiShow
                      className="pwd_toggle_icon2"
                      size={20}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <BiHide
                      className="pwd_toggle_icon2"
                      size={20}
                      color={"#bcbcbc"}
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </span>
                {passwordError && (
                  <center>
                    <small>
                      <span className="text-danger text-center mx-auto w-100 mt-1 mb-3">
                        {passwordError}
                      </span>
                    </small>
                  </center>
                )}
                <span style={{ position: "relative" }}>
                  <input
                    type={`${showPassword2 ? "text" : "password"}`}
                    className={`form-control ${
                      passwordsMatch && "border-success text-success"
                    } text-center mt-3 p-2`}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    onKeyUp={() => {
                      checkMatching();
                    }}
                  />
                  {showPassword2 ? (
                    <BiShow
                      className="pwd_toggle_icon2"
                      size={20}
                      onClick={() => setShowPassword2(false)}
                    />
                  ) : (
                    <BiHide
                      className="pwd_toggle_icon2"
                      size={20}
                      color={"#bcbcbc"}
                      onClick={() => setShowPassword2(true)}
                    />
                  )}
                </span>
                {confirmPasswordError && (
                  <center>
                    <small>
                      <span className="text-danger text-center mx-auto w-100 mt-1 mb-3">
                        {confirmPasswordError}
                      </span>
                    </small>
                  </center>
                )}
                <div className="d-flex w-100 justify-content-center mt-3">
                  <button
                    type="submit"
                    className="btn_submit mt-2 p-2 text-light rounded d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: `${colorSchema?.primary}` }}
                    onClick={handleReset}
                  >
                    {!isResetLoading ? (
                      "Reset Password"
                    ) : (
                      <>
                        Resetting...
                        <div
                          className="spinner-border text-light ms-3 spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ResetPassword;
