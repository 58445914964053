import { useState, useEffect, useRef } from "react";
import "../../styles/Stats.css";
import Home1 from "../../pics/home1.jpg";
import ai1 from "../../pics/ai1.jpg";
import bc1 from "../../pics/bc2.jpg";
import { useSelector, useDispatch } from "react-redux";
import CountUp, { useCountUp } from "react-countup";
// import u_community from "../../pics/undraw/community.png";
import u_community from "../../pics/community1.jpg";
import u_sacco from "../../pics/sacco1.png";
import u_work_placement from "../../pics/sp/chef2.jpg";

import { useNavigate } from "react-router-dom";
function Stats() {
  const navigate = useNavigate();
  const yosRef = useRef(null);
  const hcRef = useRef(null);
  const dwRef = useRef(null);
  const csRef = useRef(null);
  const { start: startCounter1 } = useCountUp({
    ref: yosRef,
    start: 0,
    end: 11,
    delay: 0,
    duration: 3,
  });

  const { start: startCounter2 } = useCountUp({
    ref: hcRef,
    start: 0,
    end: 1000,
    delay: 0,
    duration: 3,
  });
  const { start: startCounter3 } = useCountUp({
    ref: dwRef,
    start: 0,
    end: 1500,
    delay: 0,
    duration: 3,
  });
  const { start: startCounter4 } = useCountUp({
    ref: csRef,
    start: 0,
    end: 98,
    delay: 0,
    duration: 3,
  });

  const colorSchema = useSelector((state) => state.color.scheme);
  const statsRef = useRef(null);
  let startCounter = false;
  const isAuth = useSelector((state) => state.auth.isAuth);

  const isInViewport = (element) => {
    const rect = element?.getBoundingClientRect();
    return (
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const yosInterval = setInterval(() => {
      if (isInViewport(statsRef?.current)) {
        if (!startCounter) {
          startCounter1();
          startCounter2();
          startCounter3();
          startCounter4();
          startCounter = true;
        }
      }
    }, 100);

    return () => {
      clearInterval(yosInterval);
    };
  }, []);
  return (
    <>
      <div
        data-aos="fade-up"
        style={{ backgroundColor: `${colorSchema?.primary}` }}
        className="w-100 m-0 pt-2 pb-2"
      >
        <div
          id="statsContainer"
          ref={statsRef}
          style={{ backgroundColor: `${colorSchema?.primary}` }}
          className="w-100 d-flex justify-content-center mt-2 ps-3 pe-3 pt-5 pb-5 m-0 row text-light "
        >
          <div className="pt-5 row pb-5 m-0 d-flex justify-content-center">
            <div className="col-sm-5 row mt-3 pt-2 pb-2">
              <div className="d-flex col-6 flex-column justify-content-center align-items-center ">
                <h4 className="stat-heading d-flex">
                  <div ref={yosRef} />+
                </h4>
                <h6 className="stat-body text-center">Years of service</h6>
              </div>
              <div className=" col-6 d-flex flex-column  justify-content-center align-items-center ">
                <h4 className="stat-heading d-flex">
                  <div ref={hcRef} />
                </h4>
                <h6 className="stat-body text-center">Happy customers</h6>
              </div>
            </div>

            <div className="col-sm-5 row mt-3 pt-2 pb-2">
              <div className="col-6 d-flex flex-column  justify-content-center align-items-center ">
                <h4 className="stat-heading d-flex d-flex">
                  <div ref={dwRef} />+
                </h4>
                <h6 className="stat-body text-center">Domestic workers</h6>
              </div>
              <div className="col-6 d-flex flex-column justify-content-center  align-items-center ">
                <h4 className="stat-heading d-flex">
                  <div ref={csRef} />%
                </h4>
                <h6 className="stat-body text-center">Customer satisfaction</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="moreInfoContainer"
        className="w-100 m-0 pt-3 pb-5 ps-1 pe-1 bg-white"
      >
        <div
          id="infoContainer"
          data-aos="fade-up"
          className="row d-flex pt-2 pb-2 mt-5 w-75 mx-auto "
        >
          <div
            className="col-sm-6  d-flex justify-content-center"
            style={{ cursor: "help !important" }}
          >
            <img
              src={u_community}
              alt="rating img"
              style={{ cursor: "help !important" }}
              className="statsPhotoPic w-100 ms-2 me-2  w-100  animate__zoomIn animate__animated"
            />
          </div>
          <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
            <h4 className="">
              <b>Community</b>
            </h4>
            <p className="mt-2  w-100 ">
              At Maids of Honour, we strive to provide a safe and inclusive
              platform for all domestic workers under our purview to share their
              thoughts and experiences. Unfortunately, the needs and concerns of
              domestic workers are often neglected. However, through our
              community, we offer a space for them to express themselves, be
              heard, and stay informed. Our aim is to provide a platform where
              domestic workers can connect with one another, gain access to
              valuable resources, and receive support and education.
            </p>
            {!isAuth && (
              <button
                className="joinBtn ps-3 pe-3 pt-1 pb-1  mt-3 rounded"
                style={{ backgroundColor: `${colorSchema?.primary}` }}
                onClick={() => {
                  navigate("login");
                }}
              >
                Join Community
              </button>
            )}
          </div>
        </div>
        <div
          id="infoContainer1"
          data-aos="fade-up"
          className="row  pt-2 pb-2 mt-5 w-75 mx-auto"
        >
          <div className="col-sm-6 p-3  d-flex justify-content-center  flex-column">
            <h4 className="">
              <b>Sacco</b>
            </h4>
            <p className="mt-1  w-100 sizeNormal">
              At Maids of Honour, our mission is to empower our members by
              providing timely and innovative financial solutions that can
              transform their lives both socially and economically. Our SACCO is
              dedicated to expanding the financial knowledge of all domestic
              workers, and we strive to provide continuous education and
              guidance to help them achieve financial freedom. Our ultimate goal
              is to promote financial stability and independence among our
              members and help them build a better future for themselves and
              their families.
            </p>
            <button
              className="joinBtn ps-3 pe-3 pt-1 pb-1  mt-3 rounded"
              style={{ backgroundColor: `${colorSchema?.primary}` }}
              onClick={() => {
                navigate("resources/sacco");
              }}
            >
             Learn more
            </button>
          </div>
          <div className="col-sm-6 d-flex justify-content-center ">
            <img
              src={u_sacco}
              alt="rating img"
              className="w-100 statsPhotoPic  ms-2 me-2 mt-3 mb-3 statImage"
            />
          </div>
        </div>
        <div
          id="infoContainer2"
          data-aos="fade-up"
          className="row infoContainer pt-2 pb-2 d-flex mt-5 w-75 mx-auto"
        >
          <div className="col-sm-6 d-flex justify-content-center aiSystemImg position-relative ">
            <img
              src={u_work_placement}
              alt="ai img"
              className="w-100 statsPhotoPic  ms-2 me-2 mt-3 mb-3 statImage"
            />
          </div>
          <div className="col-sm-6 d-flex justify-content-center p-3 aiSystemText flex-column">
            <h4 className="">
              <b>Work Placement</b>
            </h4>
            <p className="mt-1   w-100 sizeNormal">
              Once a domestic worker completes our comprehensive training,
              receives mentoring, and passes our assessments, they are eligible
              to be listed on our platform and be considered for hire. We take
              great care to ensure that all domestic workers on our platform
              have the necessary skills and qualifications to provide
              exceptional service to our clients. Our commitment to excellence
              and thorough screening process enables us to connect qualified
              domestic workers with potential employers, promoting long-term job
              stability and satisfaction for both parties.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stats;
