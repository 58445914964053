import React from "react";
import "../../styles/Dashboard/Messages.css";
import Messages from "./Messages";

function MessagesContainer() {
  return (
    <div className="row m-0 pb-2 fixed-bottom" id="messagesContainer">
      <div
        className="col-sm-4 p-0 pb-2 ms-auto shadow bg-white border m-3 rounded "
        id="messageGlimpseContainer"
      >
        <Messages />
      </div>
    </div>
  );
}

export default MessagesContainer;
