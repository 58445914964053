import { useState, useRef, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "../../../styles/Profile.css";
import {
  MdOutlineNavigateNext,
  MdOutlineNavigateBefore,
  MdTimer,
  MdStar,
} from "react-icons/md";
import { BiPlayCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileCard from "./ProfileCard";
import SPProfileSlider from "./SPProfileSlider";
import Spinner1 from "../../../components/spinners/Spinner1";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import RequestFormModal from "../../Dashboard/serviceProvider/Requests/RequestFormModal";
import Pricing from "../../../components/Home/Pricing";
import ClientSubscriptionModal from "../../../components/Home/ClientSubscriptionModal";

function ProfileCardSwiper() {
  //subscription
  const { isSubscribed } = useSelector((state) => state.subscription);
  const [showMaxProfileSlider, setShowMaxProfileSlider] = useState(false);
  const swiperRef = useRef();
  const navigate = useNavigate();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  let slidesPerGroup = swiperRef?.current?.swiper?.params?.slidesPerGroup;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperCont = useRef(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const [maxSwiperCurrentIndex, setMaxSwiperCurrentIndex] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [profilesLoading, setProfilesLoading] = useState(false);

  const [searchLimit, setSearchLimit] = useState(9);
  const [searchOffset, setSearchOffset] = useState(0);
  const [searchParam, setSearchParam] = useState("");

  const [showRequestForm, setShowRequestForm] = useState(false);
  const [currentSp, setCurrentSp] = useState("");

  const showCurrentProfile = (sub, inx) => {
    const spProfile = profiles.find((sp) => sp._id === sub);
    const index = profiles.indexOf(spProfile);
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
    if (isSubscribed) {
      setMaxSwiperCurrentIndex(index);
      setShowMaxProfileSlider(true);
    } else {
      setShowPaymentModal(true);
    }
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    fetchServiceProviders();
    return () => {};
  }, []);

  const fetchServiceProviders = async () => {
    setProfilesLoading(true);
    const response = await axiosWrapper({
      method: "get",
      // url: `users?role=serviceProvider`,
      url: `users?role=serviceProvider&limit=${searchLimit}&offset=${searchOffset}${
        searchParam && `&skills=${searchParam}`
      }`,
      data: "",
    });

    setProfiles(response.data.data.rows);

    console.log("providers data");
    console.log(response.data);
    setProfilesLoading(false);
  };
  // const fetchServiceProviders = async () => {
  //   setProfilesLoading(true);
  //   const response = await axiosWrapper({
  //     method: "get",
  //     url: `users?role=serviceProvider`,
  //     data: "",
  //   });

  //   setProfiles(response.data.data.rows);

  //   setProfilesLoading(false);
  // };

  const handleShowRequestForm = async (user) => {
    setCurrentSp(user);
    setShowMaxProfileSlider(false);
    setShowRequestForm(true);
    console.log("showing request for ");
    console.log(user);
  };

  return (
    <>
      <div
        id="profileCardSwiperContainer"
        className="w-100 row p-3 m-0 pb-2 pt-5"
        style={{ backgroundColor:"#F4F7FE" }}
      >
        <div className="col-sm-10 mx-auto row m-0">
          <h5 className="col-sm-12">
            <b>Our trained service providers</b>
          </h5>
          <span id="line" className="m-3"></span>
          <div style={{ position: "relative" }}>
            {!profilesLoading ? (
              <Swiper
                // install Swiper modules
                // modules={[Navigation, Pagination, Scrollbar, A11y]}
                // modules={[Pagination, Scrollbar, A11y]}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                onSlideChange={() => {
                  setCurrentSlideIndex(swiperRef?.current?.swiper?.realIndex);
                }}
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={window.screen.width > 700 ? 4 : 1}
                slidesPerGroup={window.screen.width > 700 ? 4 : 1}
                // navigation={true}
                ref={swiperRef}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => {}}
                className="pt-3 pb-5 ps-2 pe-2 d-flex justify-content-center  mx-auto"
              >
                {profiles.length > 0 &&
                  profiles.map((profile, index) => (
                    <SwiperSlide
                      key={index}
                      className=" rounded d-flex flex-column border  shadow p-2 rounded bg-light"
                    >
                      <ProfileCard
                        index={index}
                        user={profile}
                        showProfile={showCurrentProfile}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : (
              <div style={{ minHeight: "30vh", position: "relative" }}>
                <Spinner1 message={"Fetching service providers..."} />
              </div>
            )}
          </div>

          {currentSlideIndex === 0 ? (
            <span ref={prevRef}>
              <MdOutlineNavigateBefore
                size={40}
                color={"rgb(128, 123, 123)"}
                className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light"
              />
            </span>
          ) : (
            <span ref={prevRef}>
              <MdOutlineNavigateBefore
                size={40}
                color={"black"}
                className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light"
              />
            </span>
          )}
          {currentSlideIndex === (slidesPerGroup === 3 ? 1 : 6) ? (
            <span ref={nextRef}>
              <MdOutlineNavigateNext
                size={40}
                color={"rgb(128, 123, 123)"}
                ref={prevRef}
                className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light"
              />
            </span>
          ) : (
            <span ref={nextRef}>
              <MdOutlineNavigateNext
                size={40}
                color={"black"}
                ref={prevRef}
                className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light"
              />
            </span>
          )}
        </div>
      </div>
      {showPaymentModal && (
        <ClientSubscriptionModal
          closeModal={() => {
            setShowPaymentModal(false);
          }}
        />
      )}
      {showMaxProfileSlider && (
        <SPProfileSlider
          currentIndex={maxSwiperCurrentIndex}
          profiles={profiles}
          handleShowRequestForm={handleShowRequestForm}
          closeModal={() => {
            document.body.style.overflow = "visible";
            setShowMaxProfileSlider(false);
          }}
        />
      )}

      {showRequestForm && (
        <RequestFormModal
          sp={currentSp}
          closeRequestForm={() => {
            setShowRequestForm(false);
            document.body.style.overflow = "visible";
          }}
        />
      )}
    </>
  );
}

export default ProfileCardSwiper;
