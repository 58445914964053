import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EmplyerVettingSchema } from "../../Utils/validations/ProfileValidations.js";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";

import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import "react-phone-input-2/lib/style.css";

import "../../styles/ProfileDetails.css";

import { MultiSelect } from "react-multi-select-component";

import { FaCheckSquare } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import Spinner1 from "../../components/spinners/Spinner1.jsx";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

import Logo2 from "../../pics/logo2.png";
import { MdCelebration } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import VerifyPhone from "./VerifyPhone.jsx";

import { backendUrl } from "../../features/slices/GlobalsSlice.js";
import axios from "axios";

//
import { useLocation } from "react-router-dom";
import ProgressBar1 from "../../components/ProgressBars/ProgressBar1.jsx";
import ConfettiExplosion from "react-confetti-explosion";

import Banner from "../../pics/sp/sps_portrait.jpg";
import PrizeAnimation from "../../pics/prize.gif";

function Vetting({ closeForm }) {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const employerId = query.get("employer");
  const providerId = query.get("provider");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isExploding, setIsExploding] = useState(false);

  const [serviceProvider, setServiceProvider] = useState("");

  const [showSuccessDiv, setShowSuccessDiv] = useState(false);

  const colorSchema = useSelector((state) => state.color.scheme);

  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  //ratings
  const [attitude, setAttitude] = useState("");
  const [conduct, setConduct] = useState("");
  const [etiquette, setEtiquette] = useState("");
  const [communication, setCommunication] = useState("");
  const [grooming, setGrooming] = useState("");
  const [hygiene, setHygiene] = useState("");
  const [wouldRecommend, setWouldRecommend] = useState("");

  //rating errors
  const [groomingError, setGroomingError] = useState("");
  const [conductError, setConductError] = useState("");
  const [etiquetteError, setEtiquetteError] = useState("");
  const [communicationError, setCommunicationError] = useState("");
  const [attitudeError, setAttitudeError] = useState("");
  const [hygieneError, setHygieneError] = useState("");
  const [wouldRecommendError, setWouldRecommendError] = useState("");

  //view states
  const [isLoading, setIsLoading] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [showRatingForm, setShowRatingForm] = useState(false);

  const [rawData, setRawData] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(EmplyerVettingSchema) });

  const [provider, setProvider] = useState({
    firstName: "Jane",
    lastName: "Doe",
  });

  //verify if token is valid
  // const handleVerify = async () => {
  //   const accessToken = query.get("access");
  //   try {
  //     const verificationResponse = await axios({
  //       method: "post",
  //       url: backendUrl + `auth/otp/sms/verify`,
  //       headers: {
  //         Authorization: "Bearer " + accessToken,
  //       },
  //     });

  //     console.log("Email verification Response");
  //     console.log(verificationResponse);
  //     if (verificationResponse.data.success) {
  //       toast.success("Email verified!");
  //       navigate("/dashboard");
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("Verification error");
  //     console.log(error);
  //     console.log(error.response.data.message);
  //     toast.error(error.response.data.message);
  //   }
  // };

  useEffect(() => {
    console.log("employer id");
    console.log(employerId);
    console.log("provider id");
    console.log(providerId);
    // fetchSPDetails();
  }, []);

  // const fetchSPDetails = async () => {
  //   const response = await axiosWrapper({
  //     method: "get",
  //     url: `users/${employerId}`,
  //     data: "",
  //   });

  //   setServiceProvider(response.data.data);
  //   console.log("Vetted SP");
  //   console.log(response.data.data);
  //   setIsLoading(false);
  // };

  const handleValidateData = async (data) => {
    setRawData(data);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setProgressBarPercentage(50);
    setShowRatingForm(true);
  };

  const clearErrors = () => {
    setGroomingError("");
    setConductError("");
    setEtiquetteError("");
    setAttitudeError("");
    setHygieneError("");
  };

  const handleValidations = async () => {
    clearErrors();
    if (
      !grooming ||
      !conduct ||
      !etiquette ||
      !attitude ||
      !hygiene ||
      !communication ||
      wouldRecommend === ""
    ) {
      if (!grooming) {
        setGroomingError("Kindly provide a rating for grooming");
      }
      if (!conduct) {
        setConductError("Kindly provide a rating for conduct");
      }
      if (!etiquette) {
        setEtiquetteError("Kindly provide a rating for etiquette");
      }
      if (!attitude) {
        setAttitudeError("Kindly provide a rating for manners");
      }
      if (!hygiene) {
        setHygieneError("Kindly provide a rating for hygiene");
      }

      if (!communication) {
        setCommunicationError("Kindly provide a rating for communication");
      }
      if (wouldRecommend === "") {
        setWouldRecommendError("Kindly select one option");
      }
    } else {
      handleSubmitData();
    }
  };

  const handleSubmitData = async () => {
    setIsLoading(true);
    let workUnits = rawData.workDurationUnit;
    delete rawData.workDurationUnit;

    let uploadData = "";

    let ratingData = {
      attitude,
      communication,
      conduct,
      etiquette,
      grooming,
      hygiene,
      review: rawData.comments,
      reviewer: employerId,
      reviewee: providerId,
    };

    const createReview = await axiosWrapper({
      method: "post",
      url: `reviews`,
      data: ratingData,
      // headers: {
      //   Authorization: "Bearer " + localStorage.getItem("refreshToken"),
      // },
    });

    console.log("review data upload");
    console.log(createReview);
    console.log(createReview.data.success);
    uploadData = {
      ...rawData,
      workDuration: `${rawData.workDuration} ${workUnits}`,
      employer: employerId,
      wouldRecommend,
    };
    console.log("Data to upload");
    console.log(uploadData);
    if (createReview.data.success) {
      const response = await axiosWrapper({
        method: "post",
        url: `referrals/employers/answers`,
        data: uploadData,
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("refreshToken"),
        // },
      });

      // console.log("Vetting response");
      // console.log(response);
      setIsLoading(false);

      if (response.data.success) {
        swal(
          "Success",
          "Your response has been saved successfully!",
          "success"
        ).then(() => {
          setShowSuccessDiv(true);
          setIsExploding(true);
        });
      } else {
        toast.error("Failed to complete your vetting, please try again!");
      }
    } else {
      setIsLoading(false);
      toast.error("Failed to complete your vetting, please try again!");
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <Spinner1 message={"Loading, please wait..."} />
        ) : (
          <>
            {showVerifyPhone ? (
              <VerifyPhone />
            ) : (
              <>
                <div className="row w-100 ms-0 me-0 ps-0 pe-0 pt-0  auths profileDetailsCont">
                  <div
                    className="col-sm-6 ps-5 pe-5 pb-5 pt-3 row mt-0 ms-0 me-0 mb-3"
                    id="authCover2"
                    style={{ height: "100vh", overflowY: "auto" }}
                  >
                    <span className="mx-auto  d-flex align-items-center col-sm-10 p-2">
                      <img
                        id="logo"
                        src={Logo2}
                        alt={"MOH logo"}
                        className="me-2"
                        style={{ width: "50px" }}
                      />
                      <span className="d-flex flex-column">
                        <span style={{ fontSize: "20px", color: "#9b717d" }}>
                          Maids Of Honour Africa
                        </span>
                        <small style={{ color: "#9b717d" }}>
                          Service is greatness
                        </small>
                      </span>
                    </span>
                    {!showSuccessDiv ? (
                      <>
                        <div className="mx-auto col-sm-10">
                          <ProgressBar1 completed={progressBarPercentage} />
                        </div>

                        {!showRatingForm ? (
                          <span className="mx-auto  col-sm-10">
                            <h5 className="mt-4 mb-3 w-100 d-flex align-items-center justify-content-between">
                              <b>Employer Vetting details</b>
                            </h5>

                            <form
                              onSubmit={handleSubmit(handleValidateData)}
                              autoComplete="off"
                            >
                              <div className="mb-4 mt-3">
                                <label
                                  htmlFor="employerName"
                                  className="form-label m-0"
                                >
                                  Your Name
                                </label>
                                <input
                                  type="text"
                                  name="employerName"
                                  className="form_control"
                                  id="employerName"
                                  {...register("employerName")}
                                />
                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.employerName?.message}
                                    </span>
                                  </small>
                                )}
                              </div>

                              <div className="mb-4 ">
                                <label
                                  htmlFor="jobTitle"
                                  className="form-label m-0"
                                >
                                  Service provider's job title
                                </label>
                                <input
                                  type="text"
                                  name="jobTitle"
                                  className="form_control"
                                  id="jobTitle"
                                  {...register("jobTitle")}
                                />
                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.jobTitle?.message}
                                    </span>
                                  </small>
                                )}
                              </div>

                              <div className="mb-4 mt-3">
                                <label
                                  htmlFor="jobDescription"
                                  className="form-label m-0"
                                >
                                  Service provider's job description
                                </label>
                                <textarea
                                  type="text"
                                  name="jobDescription"
                                  className="rounded p-2 w-100 mt-1"
                                  rows={4}
                                  style={{ border: "1px solid #c2c2c2" }}
                                  id="jobDescription"
                                  {...register("jobDescription")}
                                ></textarea>

                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.jobDescription?.message}
                                    </span>
                                  </small>
                                )}
                              </div>
                              <div className="mb-4 mt-1">
                                <label
                                  htmlFor="workDuration"
                                  className="form-label m-0"
                                >
                                  Service provider's work duration
                                </label>

                                <input
                                  type="number"
                                  name="workDuration"
                                  className="form_control fontsm"
                                  id="workDuration"
                                  {...register("workDuration")}
                                />

                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.workDuration &&
                                        "Kindly specify your previous work duration"}
                                    </span>
                                  </small>
                                )}
                              </div>
                              <div className="mb-4 mt-1">
                                <label
                                  htmlFor="workDurationUnit"
                                  className="form-label m-0"
                                >
                                  The above duration is in?
                                </label>

                                <select
                                  className="form-select"
                                  id="workDurationUnit"
                                  name="workDurationUnit"
                                  style={{ cursor: "pointer", outline: "none" }}
                                  {...register("workDurationUnit")}
                                >
                                  <option
                                    className="p-2 border rounded"
                                    style={{ cursor: "pointer" }}
                                    value=""
                                  >
                                    Choose one
                                  </option>
                                  <option
                                    className="p-2"
                                    style={{ cursor: "pointer" }}
                                    value={"months"}
                                  >
                                    Months
                                  </option>
                                  <option
                                    className="p-2"
                                    style={{ cursor: "pointer" }}
                                    value={"years"}
                                  >
                                    Years
                                  </option>
                                </select>
                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.workDurationUnit &&
                                        " Kindly select an option above"}
                                    </span>
                                  </small>
                                )}
                              </div>

                              <div className="mb-4 mt-1">
                                <label
                                  htmlFor="salary"
                                  className="form-label m-0"
                                >
                                  Service provider's salary
                                </label>
                                <input
                                  type="number"
                                  name="salary"
                                  className="form_control"
                                  id="salary"
                                  {...register("salary")}
                                />
                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.salary &&
                                        "Kindy enter your previous salary"}
                                    </span>
                                  </small>
                                )}
                              </div>
                              <div className="mb-4 mt-3">
                                <label
                                  htmlFor="comments"
                                  className="form-label m-0"
                                >
                                  Kindly describe his/her work ethic, conduct
                                  and behaviour
                                </label>
                                <textarea
                                  type="text"
                                  name="comments"
                                  className="rounded p-2 w-100 mt-1"
                                  rows={4}
                                  style={{ border: "1px solid #c2c2c2" }}
                                  id="comments"
                                  {...register("comments")}
                                ></textarea>

                                {errors && (
                                  <small>
                                    <span className="mt-1 mb-3 text-danger">
                                      {errors?.comments?.message}
                                    </span>
                                  </small>
                                )}
                              </div>

                              <div className="d-flex">
                                <button
                                  type="submit"
                                  className="btn_submit mt-2 p-2 text-light rounded m-2"
                                  style={{
                                    backgroundColor: `${colorSchema?.primary}`,
                                  }}
                                >
                                  Proceed
                                </button>
                              </div>
                            </form>
                          </span>
                        ) : (
                          <div className="mx-auto mt-2  col-sm-10">
                            <div className="card-body d-flex flex-column align-items-center">
                              <div className="w-100 ">
                                <h5>
                                  <b>Kindly rate your service provider</b>
                                </h5>
                                <div className="w-100 d-flex justify-content-between mt-2  align-items-center ">
                                  <span className=" ">1. Attitude</span>
                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setAttitude(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>
                                {attitudeError && (
                                  <span className="text-danger">
                                    <small>{attitudeError}</small>
                                  </span>
                                )}
                              </div>
                              <div className="w-100">
                                <div className="w-100 d-flex justify-content-between  align-items-center ">
                                  <span className="  ">
                                    2. Behavior/Conduct
                                  </span>
                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setConduct(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>{" "}
                                {conductError && (
                                  <span className="text-danger">
                                    <small>{conductError}</small>
                                  </span>
                                )}
                              </div>
                              <div className="w-100">
                                <div className="w-100 d-flex justify-content-between  align-items-center ">
                                  <span className="">3. Etiquette/Manners</span>
                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setEtiquette(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>
                                {etiquetteError && (
                                  <span className="text-danger">
                                    <small>{etiquetteError}</small>
                                  </span>
                                )}
                              </div>

                              <div className="w-100">
                                <div className="w-100 d-flex justify-content-between  align-items-center ">
                                  <span className="  ">4. Communication</span>

                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setCommunication(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>
                                {communicationError && (
                                  <span className="text-danger">
                                    <small>{communicationError}</small>
                                  </span>
                                )}
                              </div>
                              <div className="w-100">
                                <div className="w-100 d-flex justify-content-between  align-items-center ">
                                  <span className="  ">5. Good grooming</span>

                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setGrooming(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>
                                {groomingError && (
                                  <span className="text-danger">
                                    <small>{groomingError}</small>
                                  </span>
                                )}
                              </div>

                              <div className="w-100">
                                <div className="w-100 d-flex justify-content-between  align-items-center ">
                                  <span className="  ">6. Hygiene</span>
                                  <ReactStars
                                    count={5}
                                    onChange={(rating) => {
                                      setHygiene(rating);
                                    }}
                                    classNames={
                                      "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                                    }
                                    size={30}
                                    isHalf={true}
                                    activeColor="#ffd700"
                                  />
                                </div>
                                {hygieneError && (
                                  <span className="text-danger">
                                    <small>{hygieneError}</small>
                                  </span>
                                )}
                                <div className="w-100 d-flex flex-column justify-content-start flex-c mt-3">
                                  <span className="">
                                    Would you recommend him/her to other
                                    clients?
                                  </span>
                                  <div className="form-check mt-3">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      onClick={() => {
                                        setWouldRecommend(true);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check mt-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      onClick={() => {
                                        setWouldRecommend(false);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                {wouldRecommendError && (
                                  <span className="text-danger">
                                    <small>{wouldRecommendError}</small>
                                  </span>
                                )}

                                <button
                                  type="submit"
                                  className="btn_submit mt-3 p-2 text-light rounded "
                                  onClick={handleValidations}
                                  style={{
                                    backgroundColor: `${colorSchema?.primary}`,
                                  }}
                                >
                                  Finish
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className="col-sm-10 mx-auto"
                        style={{ height: "100vh" }}
                      >
                        <div className="p-3  mt-3 d-flex flex-column text-center align-items-center">
                          {isExploding && (
                            <div className="w-100 d-flex justify-content-center">
                              <ConfettiExplosion />
                            </div>
                          )}
                          <img
                            src={PrizeAnimation}
                            alt="Prize"
                            className="animate__animated animate__bounceInDown"
                            style={{ width: "250px" }}
                          />
                          <h5>
                            <b> We have great news</b>
                            {/* <MdCelebration
                              size={28}
                              className="text-warning ms-2"
                            /> */}
                          </h5>
                          <div style={{ fontSize: "18px" }}>
                            You have been awarded a free course by Maids of
                            Honour Africa.
                          </div>
                          <div style={{ fontSize: "16px" }} className="mt-4">
                            Kindly Create an account to redeem your prize.
                          </div>

                          <center>
                            <button
                              type="submit"
                              className="btn_submit mt-4 p-2 text-light rounded m-3 animate__animated animate__pulse"
                              onClick={() => {
                                navigate("/client-register");
                              }}
                              style={{
                                backgroundColor: `${colorSchema?.primary}`,
                              }}
                            >
                              create an account
                              {/* <AiOutlineRight className="ms-2" size={22} /> */}
                            </button>
                          </center>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-sm-6 p-0 "
                    id="vetArea"
                    style={{ minHeight: "100vh", objectFit: "fill" }}
                  >
                    <img
                      style={{ minHeight: "100vh" }}
                      src={Banner}
                      alt="banner pic"
                      className="w-100 m-0 bannerImage animate__animated  animate__zoomIn"
                    />
                  </div>
                  <div id="auth_gradient"></div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Vetting;
