import React from "react";
import "../../styles/Banner.css";
import Home1 from "../../pics/sp/buttler1.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import { Box, Stack, Typography, Avatar, useMediaQuery, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function SpBanner() {
  const navigate = useNavigate();
  const colorSchema = useSelector((state) => state.color.scheme);
  // const isAuth = useSelector((state) => state.auth.isAuth);
  // const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <div
      className="row m-0 mt-4 mb-4 pt-5 pb-5 p-3  d-flex justify-content-around align-items-center"
      style={{ overflowY: "hidden" }}
    >
      <div className="col-sm-5 mt-3 mb-3 " data-aos="fade-right">
        <img src={Home1} className="w-100" alt="homepic" />
      </div>
      {/* <div id="banner_gradient"></div> */}
      <div className="col-sm-5 mt-3 mb-3" data-aos="fade-left">
        <h1 className="cta1txt   ms-auto mt-4 ">
          Taking your
          <span className="ms-2" style={{ color: "#CF2680" }}>
            career<span className="text-dark">,</span>
          </span>
          <br /> to the
          <span style={{ color: "#CF2680" }}> next level</span>
        </h1>
        <p className="mt-3">
          Our platform connects you to thousands of clients near you. You get to choose when you want to work and where you want to work.
        </p>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} p={2}>
            <Button
              fullWidth
              sx={{ borderRadius: "0.4rem" ,backgroundColor: `${colorSchema?.primary}`}}
              variant="contained"
              onClick={() => {
                navigate("/register");
              }}
            >
              Become a Service Provider
            </Button>
            {/* <Stack spacing={2} direction={!isSmallScreen ? "row" : "column"} >
              <button
                type="submit"
                onClick={() => navigate('/login')}
                className="btn_submit mt-2 p-2 text-light rounded m-2"
                style={{ backgroundColor: `${colorSchema?.primary}` }}
              >
                 Join Now
              </button>
              <span
                type="submit"
                className="btn_skip mt-2 p-2 bg-white d-flex justify-content-center align-items-center rounded m-2"
                onClick={() => navigate('/register')}
                style={{
                  color: `${colorSchema?.primary}`,
                  border: `1px solid ${colorSchema?.primary}`,
                }}
              >
                Join
              </span>
            </Stack> */}
          </Grid>
        </Grid>
      </div>
    </div>
    // <>
    //    {isSmallScreen ?
    //     (
    //       <div
    //         className="row m-0 bg-light "
    //         style={{ minHeight: "88vh", position: "relative" }}
    //       >
    //         <div
    //           className="col-sm-5 pt-1 d-flex justify-content-center  flex-column p-1"
    //           id="ctaMainContainer"
    //         >
    //           <div className=" w-100 ps-3 ms-2  mx-auto" id="ctaContainer">
    //             <h1 style={{ fontSize: isSmallScreen ? "2rem" : "2.8rem" }} className="cta1txt mx-auto mt-4 ms-2 animate__animated animate__fadeInDown">
    //               Taking your{" "}
    //               <span style={{ color: "#CF2680" }}>
    //                 career<span className="text-dark">,</span>
    //               </span>
    //               <br /> to the
    //               <span style={{ color: "#CF2680" }}> next level</span>
    //             </h1>
    //             <p
    //               style={{ fontSize: "18px" }}
    //               className="mt-3 animate__animated ms-2  animate__fadeInUp"
    //             >
    //               Our platform connects you to thousands of clients near you. You get to choose when you want to work and where you want to work.
    //             </p>
    //             <Grid container spacing={2}>
    //               <Grid item xs={12} md={6} p={2}>
    //                 <Button
    //                   color="primary"
    //                   fullWidth
    //                   sx={{ borderRadius: "0.6rem" }}
    //                   variant="contained"
    //                   onClick={() => {
    //                     navigate(isAuth ? "/dashboard" : "/register");
    //                   }}
    //                 >
    //                   Join Maids of Honour
    //                 </Button>
    //               </Grid>
    //             </Grid>
    //           </div>
    //         </div>

    //         <div
    //           className="col-sm-7 m-0 animate__animated animate__fadeIn d-flex align-items-center  p-0"
    //           style={{
    //             objectFit: "contain",
    //           }}
    //         >
    //           <img
    //             style={{
    //               height: "70%",
    //             }}
    //             src={Home1}
    //             alt="banner pic"
    //             className="w-70 m-0 bannerImage animate__animated  animate__zoomIn"
    //           />
    //         </div>



    //         <div id="banner_gradient"></div>
    //       </div>
    //     ) : (
    //       <div
    //         className="row m-0 bg-light "
    //         style={{ minHeight: "88vh", position: "relative" }}
    //       >

    //         <div
    //           className="col-sm-7 m-0 animate__animated animate__fadeIn d-flex align-items-center  p-0"
    //           style={{
    //             objectFit: "contain",
    //           }}
    //         >
    //           <img
    //             style={{
    //               height: "70%",
    //             }}
    //             src={Home1}
    //             alt="banner pic"
    //             className="w-70 m-0 bannerImage animate__animated  animate__zoomIn"
    //           />
    //         </div>

    //         <div
    //           className="col-sm-5 pt-4 d-flex justify-content-center  flex-column p-1"
    //           id="ctaMainContainer"
    //         >
    //           <div className=" w-100 ps-3 ms-2  mx-auto" id="ctaContainer">
    //             <h1 className="cta1txt mx-auto mt-4 ms-2 animate__animated animate__fadeInDown">
    //               Taking your{" "}
    //               <span style={{ color: "#CF2680" }}>
    //                 career<span className="text-dark">,</span>
    //               </span>
    //               <br /> to the
    //               <span style={{ color: "#CF2680" }}> next level</span>
    //             </h1>
    //             <p
    //               style={{ fontSize: "18px" }}
    //               className="mt-3 animate__animated ms-2  animate__fadeInUp"
    //             >
    //               Our platform connects you to thousands of clients near you. You get to choose when you want to work and where you want to work.
    //             </p>
    //             <Grid container spacing={2}>
    //               <Grid item xs={12} md={6} p={2}>
    //                 <Button
    //                   color="primary"
    //                   fullWidth
    //                   sx={{ borderRadius: "0.6rem" }}
    //                   variant="contained"
    //                   onClick={() => {
    //                     navigate("/register");
    //                   }}
    //                 >
    //                   Join Maids of Honour
    //                 </Button>
    //               </Grid>
    //             </Grid>
    //             {/* )} */}
    //           </div>
    //         </div>

    //         <div id="banner_gradient"></div>
    //       </div>
    //     )}
    // </>
    // <Box sx={{ flexGrow: 1, py: 2, px: 2 }}>
    //   <Container disableGutters maxWidth="lg" component="main" sx={{ p: 2, }}>
    //     <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
    //       <Grid item sm={12} md={7}>
    //         <Avatar variant="square"
    //           sx={{ height: "90%", width: "90%",borderRadius:"0.3rem"  }}
    //           src={Home1}
    //           alt="moh"
    //         />
    //       </Grid>
    //       <Grid item sm={12} md={5} mt={2}>
    //         <Typography variant="h2" gutterBottom sx={{ fontWeight: "bold" }}>
    //           Taking your <span style={{ color: "#CF2680" }}>career</span> <br></br>to the next level
    //         </Typography>
    //         <Typography variant="h6" gutterBottom sx={{ mb: 4, color: "grey" }}>
    //         Our platform connects you to thousands of clients near you. You get to choose when you want to work and where you want to work.
    //         </Typography>
    //         <Stack spacing={2} direction={!isSmallScreen ? "row" : "column"} >
    //           <Button fullWidth sx={{ borderRadius: "0.6rem" }} variant="contained" onClick={() => navigate('/login')}>Join Maids of Honour</Button>
    //           <Button fullWidth variant="text" onClick={() => navigate('/register')} endIcon={<ArrowForwardIcon />}>Become a Service Provider</Button>
    //         </Stack>
    //       </Grid>
    //     </Grid>
    //   </Container>
    // </Box>

  );
}

export default SpBanner;
