import { useState } from "react";
import moment from "moment";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import ViewNotification from "./ViewNotification";
function NotificationsMessenger() {
  const navigate = useNavigate();

  return (
    <>
      <div className="row m-0" id="notificationsMessengerContainer">
        <div
          className="col-sm-4 border p-3 pb-5"
          id="notificationsMessengerSideMessages"
        >
          <BiArrowBack
            className="ms-1"
            size={25}
            onClick={() => {
              navigate("/dashboard");
            }}
            style={{ cursor: "pointer" }}
          />
          <Notifications />
        </div>
        <div
          id="viewNotificationDiv"
          className="col-sm-8 p-0 m-0 border-none"
          style={{ position: "relative" }}
        >
          <ViewNotification />
        </div>
      </div>
    </>
  );
}

export default NotificationsMessenger;
