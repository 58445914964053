import { useState, useEffect, useRef } from "react";
import "../../styles/videocall/videocall.css";
import logo from "../../pics/logo2.png";
import Avatar from "../../pics/avatar.png";
import { FiPhoneCall } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-hot-toast";

const peerConnection = new RTCPeerConnection({
  iceServers: [
    {
      urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"],
    },
  ],
  iceCandidatePoolSize: 10,
});

function VideoChatModal({ socket, endVideoChat }) {
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const recipient = useSelector((state) => state.messages.recipient);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [source, setSource] = useState(user._id || user.sub || user.id);
  const [target, setTarget] = useState(recipient._id);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const localRef = useRef();
  const remoteRef = useRef();
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [showCallBtn, setShowCallBtn] = useState(true);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });
    socket.on("disconnect", () => {
      setIsConnected(false);
    });
    socket.on("offer", (data) => {
      console.log(
        `${recipient.firstName} ${recipient.lastName} is video calling you.`
      );
      console.log("offer", data);
      if (!peerConnection.currentRemoteDescription) {
        const offerDescription = new RTCSessionDescription(data);
        peerConnection.setRemoteDescription(offerDescription);

        setShowAnswer(true);
      }
    });
    socket.on("answer", (data) => {
      console.log("answer", data);
      if (!peerConnection.currentRemoteDescription) {
        const answerDescription = new RTCSessionDescription(data);
        peerConnection.setRemoteDescription(answerDescription);
      }

      setShowCallBtn(false);
      setWaitingForResponse(false);
    });
    socket.on("error", (data) => {
      console.log("error", data);
    });
    socket.on("notification", (data) => {
      console.log("notification", data);
    });
    socket.on("iceCandidate", (data) => {
      console.log("iceCandidate", data);
      const candidate = new RTCIceCandidate(data);
      peerConnection.addIceCandidate(candidate);
    });

    socket.on("end", () => {
      alert("hello");
      toast.success("Video chat terminated");
      endVideoChat();
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("offer");
      socket.off("answer");
      socket.off("error");
      socket.off("notification");
      socket.off("iceCandidate");
    };
  }, []);

  useEffect(() => {
    peerConnection.onicecandidate = (e) => {
      if (e.candidate) {
        console.log("onicecandidate", e.candidate);
        socket.emit("iceCandidate", {
          source,
          target,
          data: e.candidate,
        });
      }
    };
  }, [source, target]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        setLocalStream(stream);
        let remoteStream = new MediaStream();
        setRemoteStream(remoteStream);
      });
  }, []);

  useEffect(() => {
    if (localStream) {
      localRef.current.srcObject = localStream;

      localStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });
    }
  }, [localStream]);

  useEffect(() => {
    handleJoin();
    if (remoteStream) {
      remoteRef.current.srcObject = remoteStream;

      peerConnection.ontrack = (e) => {
        console.log("ontrack", e);
        e.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
      };
    }
  }, [remoteStream]);

  const handleCall = async () => {
    const offerDescription = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offerDescription);

    const offer = {
      type: offerDescription.type,
      sdp: offerDescription.sdp,
    };
    socket.emit("offer", {
      source,
      target,
      data: offer,
    });
    setWaitingForResponse(true);
  };

  const handleAnswer = async () => {
    const answerDescription = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answerDescription);

    const answer = {
      type: answerDescription.type,
      sdp: answerDescription.sdp,
    };
    socket.emit("answer", {
      source,
      target,
      data: answer,
    });

    setShowCallBtn(false);
    setShowAnswer(false);
  };

  const handleJoin = () => {
    socket.emit("join", { userId: source });
  };

  const handleEndVideoCall = async () => {
    //close modal for now

    const exit = await swal({
      title: "Terminate Video Chat",
      text: "Are you sure you want to terminate this video chat?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (exit) {
      socket.emit("end");
      endVideoChat();
    }
  };
  return (
    <div id="vcContainer">
      <div id="mainDiv" className="  p-3 row m-0">
        <div className="row m-0 col-sm-8 mx-auto bg-light rounded ps-3 pe-3 pb-4 pt-4">
          <div class="d-flex align-items-center justify-content-center mt-2 col-sm-12 fw-bold">
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{
                width: "50px",
              }}
            />
            <span className="ms-2 headerNormal">
              Maids of Honour Video Chat
            </span>
            {/* <p>Connected: {"" + isConnected}</p> */}
          </div>
          <div className="col-sm-12 mt-2 mb-2 d-flex justify-content-end">
            {showAnswer && (
              <button
                type="button"
                class="btn btn-success me-3 font-sm d-flex align-items-center animate__animated animate__pulse animate__infinite"
                onClick={handleAnswer}
              >
                <FiPhoneCall className="me-2" /> answer
              </button>
            )}
            {showCallBtn && (
              <button
                type="button"
                class="btn btn-primary me-3"
                onClick={handleCall}
              >
                {!waitingForResponse ? (
                  "Start call"
                ) : (
                  <div>
                    {" "}
                    <div className="spinner-grow spinner spinner-grow-sm text-light"></div>{" "}
                    Waiting for response...
                  </div>
                )}
              </button>
            )}
            <button
              type="button"
              class="btn btn-danger font-sm d-flex align-items-center "
              onClick={handleEndVideoCall}
            >
              End Call
            </button>
          </div>

          <div class="col-sm-6 mt-3">
            <span>
              <h5 className="mx-auto text-center fw-bold">
                {user?.firstName} {user?.lastName}
              </h5>
              <video
                id="local"
                ref={localRef}
                autoPlay
                playsInline
                className="border rounded bg-dark"
                style={{
                  width: "100%",
                  minHeight: "300px",
                  //   height: "100%",
                }}
              ></video>
            </span>
            <div className="d-flex align-items-center ps-1 pe-1 mt-2">
              {/* <input
                placeholder="source"
                onChange={(e) => setSource(e.target.value)}
                className="form-control me-2 p-2"
              />
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleJoin}
              >
                Join
              </button> */}
              {/* {socket.connected && (
                <div className="alert alert-success p-1 fontsm1 ms-1">
                  You are Connected
                </div>
              )} */}
            </div>
          </div>

          <div class="col-sm-6 mt-3">
            <span>
              <h5 className="mx-auto text-center fw-bold">
                {recipient.firstName} {recipient.lastName}{" "}
              </h5>
              <video
                id="remote"
                ref={remoteRef}
                // poster={Avatar}
                autoPlay
                playsInline
                className="border rounded bg-dark"
                style={{
                  width: "100%",
                  minHeight: "300px",
                  posterHeight: "300px",
                }}
              ></video>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoChatModal;
