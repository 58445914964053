import React from "react";
import "../../styles/Partners.css";
import { Grid, Avatar, Typography } from "@mui/material";

function Partners() {
  return (
    <div className="row m-0 w-100 pt-5 pb-5 mt-1" id="partnersContainer" style={{backgroundColor:"#fff"}}>
      <div className="row mx-auto col-sm-8 p-3">
        <Typography
          variant="h4"
          align="center"
          sx={{ fontWeight: "bold" }}
          color="text.primary"
          gutterBottom
        >
          Our Partners.
        </Typography>
        {/* <Typography variant="h6" align="center" sx={{ color: "grey" }} component="p">
        We strive to work with the best companies in the world.
        </Typography> */}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={3} >
            <Avatar src="https://i0.wp.com/newtelegraphng.com/wp-content/uploads/2023/07/Adanian-Labs.png?fit=591%2C302&ssl=1" variant="square" sx={{ width: "10rem", height: "5.5rem", p: 2 }} />
          </Grid>
          <Grid item xs={6} md={3} >
            <Avatar src="https://pbs.twimg.com/profile_images/1105075996368297984/Wfltb3Ep_400x400.png" variant="square" sx={{ width: "10rem", height: "5.5rem", p: 2 }} />
          </Grid>
          <Grid item xs={6} md={3} >
            <Avatar src="https://healthtech.salientadvisory.com/wp-content/uploads/sites/2/2021/05/Afya-Rekod-Logo.jpg" variant="square" sx={{ width: "10rem", height: "5.5rem", p: 2 }} />
          </Grid>
          <Grid item xs={6} md={3} >
            <Avatar src="https://pbs.twimg.com/profile_images/1412342064449216516/-Z9vVdfj_400x400.png" variant="square" sx={{ width: "10rem", height: "5.5rem", p: 2 }} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Partners;
