/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
// import { BsDot } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import "../../styles/Dashboard/Notifications.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import swal from "sweetalert";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
// import { t } from "i18next";
import { toast } from "react-hot-toast";
// import Spinner2 from "../../components/spinners/Spinner2";
import Spinner1 from "../../components/spinners/Spinner1";
import { clearNotifications } from "../../features/slices/NotificationsSlice";
// import { closeTabs, setTab } from "../../features/slices/DashboardControlSlice";
function ViewNotification() {
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { id } = params;
  const { notifications } = useSelector((state) => state.notifications);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setNotification(notifications.find((notif) => notif._id === id));
  }, []);

  const handleDelete = async () => {
    const confirmation = await swal({
      title: "Warning?",
      text: "Are you sure you want to delete this notification?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmation) {
      //go on and delete
      setLoading(false);
      try {
        const response = await axiosWrapper({
          method: "delete",
          url: `comms/notifications/${id}`,
        });
        console.log("response data");
        console.log(response);
        setLoading(false);
        if (response.data.success) {
          toast.success("Notification deleted");
          dispatch(clearNotifications());

          navigate("/dashboard");
        } else {
          toast.error("Failed to delete notification, try again later");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error("Failed to delete notification, try again later");
      }
    }
  };
  return (
    <div className="w-100  d-flex flex-column justify-content-between">
      <div className="d-flex p-3 border-bottom w-100 align-items-center ">
        <BiArrowBack
          className="me-3 ms-1"
          size={20}
          id="notificationBackArrow"
          color={"black"}
          onClick={() => {
            navigate("/dashboard");
          }}
          style={{ cursor: "pointer", display: "none" }}
        />
        <div className="notificationIcon p-2 rounded-circle m-2 border d-flex align-items-center">
          <IoMdNotifications size={25} className="" />
        </div>
        <div className="d-flex w-100 p-2 flex-column ">
          <div className="d-flex justify-content-between align-items-center">
            <span className="fontsm">
              <b> {notification?.entity}</b>
            </span>
            <span className="fontsm1 lightDark">
              {" "}
              {moment(notification?.createdAt).fromNow()}
            </span>
          </div>
        </div>
      </div>

      <div
        className="d-flex w-100 p-5 flex-columnjustify-content-center align-items-center"
        style={{ minHeight: "40vh", backgroundColor: "#f4f5f7" }}
      >
        <div className="w-100  text-center  justify-content-between align-items-center">
          <span className="notificationGlimpseCont col-12">
            <span className=" lightDark mt-1 notificationGlimpse">
              {notification?.message}
            </span>
          </span>
        </div>
      </div>
      <span className="w-100 d-flex justify-content-end p-3 border">
        <MdDelete
          className="text-danger m-3"
          size={25}
          style={{ cursor: "pointer" }}
          onClick={handleDelete}
        />
      </span>
      {loading && <Spinner1 message={"Please wait"} />}
    </div>
  );
}

export default ViewNotification;
