import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SPRequiredProfileDetailsSchema } from "../../../Utils/validations/ProfileValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  registerUser,
  updateUserDetails,
} from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../../../styles/ProfileDetails.css";
import { useLocation } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import axios from "axios";

import { FaCheckSquare } from "react-icons/fa";
import ProgressBar1 from "../../../components/ProgressBars/ProgressBar1";
import Spinner2 from "../../../components/spinners/Spinner2";
import ServicesSelector from "../../../components/Services/ServicesSelector";
import {
  fetchServicesAutocomplete,
  fetchUserServicesAutocomplete,
} from "../../../features/slices/ServiceSlice";
import { Button, Avatar, Divider, TextField, Grid, MenuItem } from "@mui/material";
import { setPrevEmployer } from "../../../features/slices/EmployerSlice";
function SPRequiredDetails({ submitDetails }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    errorMessage,
    prevPath,
    isAuth,
    user,
    isProfileComplete,
  } = useSelector((state) => state.auth);
  const colorSchema = useSelector((state) => state.color.scheme);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneRef = useRef();
  const [phoneError, setPhoneError] = useState("");
  const [skillError, setSkillError] = useState("");
  const [selected, setSelected] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [skills, setSkills] = useState([]);

  const [changes, setChanges] = useState(false);

  let firstRender = true;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(SPRequiredProfileDetailsSchema) });

  const handleRegister = async (data) => {
    if (!phone || phoneError || !selected || skillError) {
      console.log("no phone", phone);
      handlePhoneValidation(phone);
    } else {
      let skillData;
      if (changes) {
        if (skills[0]?._id) {
          skillData = skills.map((skill) => {
            return skill._id;
          });
          console.log("skills parsed");

          console.log(skillData);
        } else {
          console.log("skills original");
          console.log(skills);
          skillData = skills;
        }
        window.scroll(0, 0);
        setIsUpdateLoading(true);
        let fullData = {
          ...data,
          phone,
          skills: skillData,
        };
        if (!fullData.trainingDetails) {
          delete fullData.trainingDetails;
        }
        const response = await axiosWrapper({
          method: "patch",
          url: `users/${user.sub ? user.sub : user.id}`,
          data: { ...fullData, dob: fullData.dob.toISOString() },
        });

        if (response.data.success) {
          const response2 = await axiosWrapper({
            method: "get",
            url: `users/${user.sub ? user.sub : user.id}`,
            data: "",
          });

          const employersData = await axiosWrapper({
            method: "get",
            url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
              }`,
          });

          const prevEmployerData = employersData.data.data.rows[0];
          dispatch(setPrevEmployer(prevEmployerData));

          const fullUserData = {
            ...response2.data.data,
            prevEmployer: prevEmployerData,
          };

          dispatch(updateUserDetails(fullUserData));
          setIsUpdateLoading(false);
          toast.success("Details updated!");
          submitDetails();
        } else {
          toast.error("Failed to update your profile, please try again!");
        }
      } else {
        submitDetails();
      }
    }
  };

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }

    if (!selected?.length > 0) {
      setSkillError("Select atleast one skill");
    } else {
      setSkillError("");
    }
  };

  useEffect(() => {
    dispatch(fetchServicesAutocomplete());
    let fetchUrl = "";
    const userSkills = user?.skills;
    userSkills?.forEach((skill, index) => {
      fetchUrl = fetchUrl + `${index !== 0 ? "&" : ""}skills=` + skill._id;
    });

    dispatch(fetchUserServicesAutocomplete(fetchUrl));
  }, []);

  useEffect(() => {
    setSkills(
      selected.map((entry) => {
        return entry.value;
      })
    );

    if (selected?.length > 0) {
      handlePhoneValidation();
    }
    if (phone?.length > 0) {
      handlePhoneValidation();
    }
  }, [selected, phone]);

  useEffect(() => {
    if (user.phone?.length > 0) {
      if (user.phone?.charAt(0) === "+") {
        setPhone(user.phone);
      } else {
        setPhone("+" + user.phone);
      }
    }

    if (user.skills) {
      const selectedSkills = user.skills.map((skill) => {
        return { label: skill, value: skill };
      });
      console.log("selectedSkills");
      console.log(selectedSkills);
      setSelected(selectedSkills);
    }
  }, []);

  return (
    <>
      {isUpdateLoading && <Spinner2 message={"Updating..."} />}
      <form onSubmit={handleSubmit(handleRegister)} className="bg-white">
        <Grid
          container
          mb={2}
          py={location.pathname === "/serviceProvider-onboarding" ? 1: 3}
          px={location.pathname === "/serviceProvider-onboarding" ? 1: 15}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 3, md: 6 }}
        >
          {!location.pathname === "/serviceProvider" && (
          <Grid item xs={12}>
            <div style={{ marginBottom: "-1px" }}>
              <small>
                <span>Phone Number</span>
              </small>
              <PhoneInput
                country={"ke"}
                label="Phone Number"
                onChange={(n) => {
                  handlePhoneValidation(n);
                  setPhone("+" + n);
                  setChanges(true);
                }}
                inputStyle={{
                  width: "100%",
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  value: phone,
                }}
              />
              <small>
                <span className="mt-1 mb-1 text-danger">{phoneError}</span>
              </small>
            </div>
          </Grid>
        )}
          <Grid item xs={12} md={6}>
            <TextField
              label="First Name"
              size="small"
              hidden={location.pathname === "/serviceProvider-onboarding"}
              error={errors && errors?.firstName?.message}
              helperText={errors && errors?.firstName?.message}
              type="text"
              name="firstName"
              onKeyUp={() => {
                setChanges(true);
              }}
              className="form_control"
              id="first_name"
              defaultValue={user?.firstName}
              {...register("firstName")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Last Name"
              size="small"
              hidden={location.pathname === "/serviceProvider-onboarding"}
              error={errors && errors?.lastName?.message}
              helperText={errors && errors?.lastName?.message}
              type="text"
              name="lastName"
              onKeyUp={() => {
                setChanges(true);
              }}
              className="form_control"
              id="first_name"
              defaultValue={user?.lastName}
              {...register("lastName")}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              label="Email Address"
              size="small"
              hidden={location.pathname === "/serviceProvider-onboarding"}
              error={errors && errors?.email?.message}
              helperText={errors && errors?.email?.message}
              readOnly
              type="email"
              name="email"
              value={user?.email}
              className="form_control"
              id="exampleInputName"
              {...register("email")}
            />
          </Grid>

          {/* <Grid item xs={12} md={12}>
            <TextField
              label="Place of Residence"
              size="small"
              error={errors && errors?.placeOfResidence?.message}
              helperText={errors && errors?.placeOfResidence?.message}
              type="text"
              name="placeOfResidence"
              placeHolder="Your location"
              value={user?.placeOfResidence}
              className="form_control"
              id="exampleInputName"
              {...register("placeOfResidence")}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Date of Birth"
              size="small"
              error={errors && errors?.dob?.message}
              helperText={errors && errors?.dob?.message}
              type="date"
              name="dob"
              onKeyUp={() => {
                setChanges(true);
              }}
              onClick={() => {
                setChanges(true);
              }}
              defaultValue={
                user.dob ? moment(user?.dob).format("YYYY-MM-DD") : ""
              }
              className="form_control"
              id="dob"
              {...register("dob")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="ID Number"
              size="small"
              error={errors && errors?.idNo?.message}
              helperText={errors && errors?.idNo?.message}
              type="text"
              name="idNo"
              onKeyUp={() => {
                setChanges(true);
              }}
              className="form_control"
              defaultValue={user?.idNo}
              id="idNo"
              {...register("idNo")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Gender"
              select
              fullWidth
              size="small"
              id="gender"
              name="gender"
              // error={errors && errors?.gender.message}
              // helperText={errors && errors?.gender?.message}
              defaultValue={user?.gender}
              onClick={() => {
                setChanges(true);
              }}
              {...register("gender")}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Marital Status"
              select
              fullWidth
              size="small"
              // error={errors && errors?.maritalStatus.message}
              // helperText={errors && errors?.maritalStatus?.message}
              id="maritalStatus"
              name="maritalStatus"
              onClick={() => {
                setChanges(true);
              }}
              defaultValue={user?.maritalStatus}
              {...register("maritalStatus")}
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Level Of Education"
              select
              size="small"
              id="loe"
              fullWidth
              error={errors && errors?.educationLevel?.message}
              helperText={errors && errors?.educationLevel?.message}
              {...register("educationLevel")}
              defaultValue={user?.educationLevel}
              onClick={() => {
                setChanges(true);
              }}
            >
              <MenuItem value="primary">Primary</MenuItem>
              <MenuItem value="highschool">High School</MenuItem>
              <MenuItem value="degree">University</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Training Experience"
              size="small"
              select
              fullWidth
              error={errors && errors?.idNo?.message}
              helperText={errors && errors?.idNo?.message}
              name="previousTraining"
              id="training"
              {...register("previousTraining")}
              defaultValue={user?.previousTraining}
              onClick={() => {
                setChanges(true);
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Years of Experience"
              size="small"
              error={errors && errors?.yearsOfExperience?.message}
              helperText={errors && errors?.yearsOfExperience?.message}
              type="number"
              name="yearsOfExperience"
              className="form_control"
              defaultValue={user?.yearsOfExperience}
              onKeyUp={() => {
                setChanges(true);
              }}
              id="totalExp"
              {...register("yearsOfExperience")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Work Arrangement"
              select
              fullWidth
              size="small"
              id="spType"
              name="workArrangement"
              {...register("workArrangement")}
              onChange={() => {
                setChanges(true);
              }}
              defaultValue={user?.workArrangement}
            >
              <MenuItem value="live in">Live in (live with the client)</MenuItem>
              <MenuItem value="come and go">Come and go (daily)</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label="Tell us about yourself"
              helperText={
                errors
                  ? errors?.bio?.message
                  : `Do you have any children? If yes, how
              many and what are their ages? Do you own any pets?`
              }
              multiline
              type="text"
              name="bio"
              onKeyUp={() => {
                setChanges(true);
              }}
              rows={2}
              defaultValue={user?.bio}
              id="bio"
              {...register("bio")}
            />
          </Grid>
          <Grid item xs={12} >
            <div>
              <label htmlFor="spType" className="form-label m-0">
                Skilled in:
              </label>
              <ServicesSelector
                setValue={(data) => {
                  setChanges(true);
                  console.log(data);
                  setSelected(data);
                }}
                multiple={true}
              />

              <small>
                <span className=" text-danger">{skillError}</span>
              </small>
            </div>
          </Grid>
        </Grid>
        {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
        {/* <div style={{ paddingLeft: "2px" }}>
            <label htmlFor="exampleInputPhone" className="form-label m-0">
              {t("user.first_name")}
            </label>
            <input
              type="text"
              name="firstName"
              defaultValue={user?.firstName}
              className="form_control"
              onKeyUp={() => {
                setChanges(true);
              }}
              id="first_name"
              {...register("firstName")}
            />

            {errors && (
              <small>
                <span className="mt-1 mb-3 text-danger">
                  {errors?.firstName?.message}
                </span>
              </small>
            )}
          </div>
          <div>
            <label htmlFor="last_name" className="form-label m-0">
              {t("user.last_name")}
            </label>
            <input
              type="text"
              name="lastName"
              onKeyUp={() => {
                setChanges(true);
              }}
              defaultValue={user?.lastName}
              className="form_control"
              id="last_name"
              {...register("lastName")}
            />

            {errors && (
              <small>
                <span className=" text-danger">
                  {errors?.lastName?.message}
                </span>
              </small>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="phoneInput" className="form-label m-0 mb-1">
            {t("user.phone")}
          </label>

          <PhoneInput
            country={"ke"}
            onChange={(n) => {
              handlePhoneValidation();
              setPhone("+" + n);
              setChanges(true);
            }}
            className="form_control w-100"
            containerClass={"tel_container"}
            inputClass={"form_controls"}
            inputProps={{
              name: "phone",
              required: true,
              value: phone,
              placeholder: "eg +254...",
            }}
          />

          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">{phoneError}</span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="email_input" className="form-label m-0">
            {t("user.email")}
          </label>
          <input
            type="email"
            name="email"
            className="form_control"
            onKeyUp={() => {
              setChanges(true);
            }}
            id="email_input"
            value={user?.email}
            {...register("email")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.email?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="dob" className="form-label m-0">
            {t("user.dob")}
          </label>
          <input
            type="date"
            name="dob"
            onKeyUp={() => {
              setChanges(true);
            }}
            onClick={() => {
              setChanges(true);
            }}
            defaultValue={
              user.dob ? moment(user?.dob).format("YYYY-MM-DD") : ""
            }
            className="form_control"
            id="dob"
            {...register("dob")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.dob?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="idNo" className="form-label m-0">
            {t("user.id_number")}
          </label>
          <input
            type="number"
            name="idNo"
            onKeyUp={() => {
              setChanges(true);
            }}
            className="form_control"
            defaultValue={user?.idNo}
            id="idNo"
            {...register("idNo")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.idNo?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="gender" className="form-label m-0">
            {t("user.gender")}
          </label>

          <select
            name="gender"
            id="gender"
            defaultValue={user?.gender}
            onClick={() => {
              setChanges(true);
            }}
            className=" form-select  form_control "
            {...register("gender")}
          >
            <option value=""> Select one</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.gender?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="gender" className="form-label m-0">
            {t("user.levelOfEducation")}
          </label>

          <select
            id="loe"
            className=" form-select  form_control "
            {...register("educationLevel")}
            defaultValue={user?.educationLevel}
            onClick={() => {
              setChanges(true);
            }}
          >
            <option value="">Select one</option>
            <option value="primary">Primary</option>
            <option value="highschool">Highschool</option>
            <option value="degree">Degree</option>
          </select>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.educationLevel?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="training" className="form-label m-0">
            {t("user.hadTraining")}
          </label>

          <select
            name="previousTraining"
            id="training"
            className=" form-select  form_control "
            {...register("previousTraining")}
            defaultValue={user?.previousTraining}
            onClick={() => {
              setChanges(true);
            }}
          >
            <option value={""}>Choose one</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.hadTraining?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="trainingDetailsSelect" className="form-label m-0">
            {t("user.trainingDetails")}
          </label>

          <input
            type="text"
            name="trainingDetails"
            className="form_control"
            onKeyUp={() => {
              setChanges(true);
            }}
            defaultValue={user?.trainingDetails}
            id="trainingDetails"
            {...register("trainingDetails")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.trainingDetails?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="spType" className="form-label m-0">
            Skilled on:
          </label>
          <ServicesSelector
            setValue={(data) => {
              setChanges(true);
              console.log(data);
              setSelected(data);
            }}
            multiple={true}
          />

          <small>
            <span className="mt-1 mb-3 text-danger">{skillError}</span>
          </small>
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="trainingDetailsSelect" className="form-label m-0">
            {t("user.totalExp")}
          </label>

          <input
            type="number"
            name="yearsOfExperience"
            className="form_control"
            defaultValue={user?.yearsOfExperience}
            onKeyUp={() => {
              setChanges(true);
            }}
            id="totalExp"
            {...register("yearsOfExperience")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.yearsOfExperience?.message}
              </span>
            </small>
          )}
        </div>

        <div className="mb-3 mt-1">
          <label htmlFor="spType" className="form-label m-0">
            "Live in " or "Come and go"
          </label>

          <select
            id="spType"
            name="workArrangement"
            className=" form-select  form_control "
            {...register("workArrangement")}
            onChange={() => {
              setChanges(true);
            }}
            defaultValue={user?.workArrangement}
          >
            <option selected value={""}>
              Choose one
            </option>
            <option value="live in">Live in</option>
            <option value="come and go">Come and go</option>
          </select>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.workArrangement?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label htmlFor="placeOfResidence" className="form-label m-0">
            Tell us abit about yourself
          </label>
          <textarea
            type="text"
            name="bio"
            className="form_control  rounded p-2 mt-1"
            style={{ border: "1px solid #c2c2c2" }}
            defaultValue={user?.bio}
            rows={4}
            onKeyUp={() => {
              setChanges(true);
            }}
            id="bio"
            {...register("bio")}
          ></textarea>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.bio?.message}
              </span>
            </small>
          )}
        </div> */}


        <div className="d-flex">
          <span
            type="submit"
            className="btn_skip mt-2 p-2 bg-white d-flex justify-content-center align-items-center rounded m-2"
            onClick={() => {
              submitDetails();
            }}
            style={{
              color: `${colorSchema?.primary}`,
              border: `1px solid ${colorSchema?.primary}`,
            }}
          >
            Next
          </span>

          <button
            type="submit"
            onClick={handlePhoneValidation}
            className="btn_submit mt-2 p-2 text-light rounded m-2"
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
}

export default SPRequiredDetails;
