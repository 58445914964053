import { useEffect } from "react";
import { IoMdNotifications } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import "../../styles/Dashboard/Messages.css";
import Shiko1 from "../../pics/shiko1.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setRecipient,
  setChatId,
  fetchMesages,
  fetchChats,
} from "../../features/slices/MessagesSlice.js";
import { setCurrentBooking } from "../../features/slices/BookingSlice.js";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import moment from "moment";
function MessageItem({ message }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const recipient = useSelector((state) => state.messages.recipient);
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const chatId = useSelector((state) => state.messages.chatId);
  let messageTxt =
    "Brian Mwangi added a new group assignment have you seen it tho?I can imagine";

  const handleStartMessage = async () => {
    if (
      user.sub === message.participants[0]._id ||
      user._id === message.participants[0]._id
    ) {
      console.log("recipient");
      console.log(message.participants[1]);
      dispatch(setRecipient(message.participants[1]));
    } else {
      console.log("recipient");
      console.log(message.participants[0]);
      dispatch(setRecipient(message.participants[0]));
    }
    dispatch(setChatId(message._id));
    dispatch(fetchMesages(message._id));
    const bookingResponse1 = await axiosWrapper({
      method: "get",
      url: `bookings?provider=${message.participants[0]._id}&client=${message.participants[1]._id}`,
      data: "",
    });
    console.log("Check the current bookings");
    const booking1 = bookingResponse1.data.data.rows[0];
    const bookingResponse2 = await axiosWrapper({
      method: "get",
      url: `bookings?provider=${message.participants[1]._id}&client=${message.participants[0]._id}`,
      data: "",
    });
    console.log("Check the current bookings");
    const booking2 = bookingResponse2.data.data.rows[0];
    console.log("booking 1");
    console.log(booking1);
    console.log("booking 2");
    console.log(booking2);

    dispatch(setCurrentBooking(booking1 || booking2));

    // dispatch(fetchChats(user._id || user.sub || user.id));
    if (location.pathname !== "/chat") {
      navigate("/chat");
    }
  };

  return (
    <div
      className="w-100 p-1  messageItemContainer border-bottom d-flex justify-content-between"
      style={{
        backgroundColor: `${message?._id === chatId ? "#f0f6ff" : ""}`,
      }}
      onClick={handleStartMessage}
    >
      <div className="d-flex w-100 align-items-center ">
        <div className="m-2">
          <img
            src={
              user.sub === message.participants[0]._id
                ? message.participants[1]?.avatar
                : message.participants[0]?.avatar
            }
            className="messageIcon"
            alt="user pic"
          />
        </div>
        <div className="d-flex w-100 p-2 flex-column ">
          <div className="d-flex justify-content-between align-items-center">
            <span className="fontsm">
              <b>
                {user.sub === message.participants[0]._id
                  ? message.participants[1].firstName
                  : message.participants[0].firstName}
                {user.sub === message.participants[0]._id
                  ? message.participants[1].lastName
                  : message.participants[0].lastName}
              </b>
            </span>
            <span className="fontsm1 lightDark">
              {moment(message.createdAt).fromNow()}
            </span>
          </div>
          <div className="w-100 row justify-content-between align-items-center">
            <span className="col-11">
              <span
                className={`fontsm1 lightDark mt-1 ${
                  !message?.lastMessage?.delivered && "fw-bold text-dark"
                }`}
              >
                {message?.lastMessage?.message > 30
                  ? message?.lastMessage?.message.substring(0, 30) + "..."
                  : message?.lastMessage?.message}
              </span>
            </span>
            <span className="col-1">
              <BsDot color="green" className="ms-auto" size={25} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageItem;
