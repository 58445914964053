import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../features/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
//validations
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../Utils/validations/AuthValidations";

import { useTranslation } from "react-i18next";
import "../../styles/Auth.css";
import google from "../../pics/google.png";
import { Button, Avatar, Divider, TextField, Grid, InputAdornment, IconButton,Box } from "@mui/material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Spinner2 from "../../components/spinners/Spinner1";

function Login() {
  const backendUrl = useSelector((state) => state.globals.backendUrl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModal = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const { isLoading, isError, errorMessage, prevPath, isAuth } = useSelector(
    (state) => state.auth
  );
  const colorSchema = useSelector((state) => state.color.scheme);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleLogin = async (data) => {
    dispatch(loginUser({ ...data, deviceId: uuidv4() }));
  };

  const handleGoogleLogin = async () => {
    window.open(backendUrl + "auth/google", "_self");
  };

  useEffect(() => {
    if (isAuth) {
      if (prevPath) {
        navigate(prevPath);
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuth]);

  const handleReset = async () => {
    if (!resetEmail) {
      setResetError("Kindly enter your email");
    } else {
      setResetLoading(true);
      const recipients = {
        recipients: [resetEmail],
        deviceId: uuidv4(),
      };

      try {
        const response = await axios.post(
          backendUrl + "auth/reset/email",
          recipients
        );

        setResetLoading(false);
        console.log("reset response");

        if (response.data.success) {
          localStorage.setItem("resetEmail", resetEmail);
          toast.success("Check your email for further instructions");
          setResetEmail("");
          closeModal.current?.click();
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  return (
    <React.Fragment>
      <div>
        {isLoading && <Spinner2 message={"Redirecting to Dashboard..."} />}
        <form className="px-30" onSubmit={handleSubmit(handleLogin)}>
          <Button
            startIcon={
              <Avatar src={google} sx={{ height: "1rem", width: "1rem" }} />
            }
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: "8px" }}
            onClick={handleGoogleLogin}
          >
            Sign in with Google
          </Button>

          <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
            Or with email and password
          </Divider>

          <Grid
            container
            mb={2}
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 3, md: 5 }}
          >
            <Grid item xs={12} >
              <TextField
                label="Email"
                placeholder="Enter your email address"
                fullWidth
                type="email"
                name="email"
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  }
                }}
                id="exampleInputName"
                {...register("email")}
                error={errors && errors?.email?.message}
                helperText={errors && errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                placeholder="Enter your password"
                fullWidth
                id="exampleInputPassword1"
                {...register("password")}
                error={errors && errors?.password?.message}
                helperText={errors && errors?.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "10px",
                  }
                }}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {!isLoading ? (
              "Login to continue"
            ) : (
              <>
                Authenticating
                <div
                  className="spinner-border text-light ms-3 spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            )}
          </Button>
          <br />
          <br />
          <br />
          <small className="mt-1 d-flex flex-column align-items-center">
            <span className=" mx-auto mb-1">
              {t("auth.does_not_have_account")}
            </span>
            <span className="d-flex justify-content-center">
              <b>
                <small
                  className="signup_text me-3"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                 CREATE  YOUR ACCOUNT
                </small>
              </b>
            </span>
            <span className="d-flex justify-content-center mt-2">
              <b>
                <small
                  className="signup_text"
                  data-bs-toggle="modal"
                  data-bs-target="#emailModal"
                  style={{
                    cursor: "pointer",
                    color: `${colorSchema?.primary}`,
                  }}
                >
                  Forgot Password ?
                </small>
              </b>
            </span>
          </small>
        </form>
      </div>
      <div
        className="modal fade"
        id="emailModal"
        tabindex="-1"
        aria-labelledby="emailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body mt-3 mb-3">
              <div className="form-group ps-3 pe-3">
                <div className="d-flex justify-content-end">
                  <button
                    ref={closeModal}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <center>
                  <label className=" mb-3" htmlFor="resetEmail">
                    Kindly enter your email address
                  </label>
                </center>
                <input
                  id="resetEmail"
                  type={"email"}
                  name={"email"}
                  className="form-control mx-auto"
                  value={resetEmail}
                  onChange={(e) => {
                    setResetEmail(e.target.value);
                  }}
                />
              </div>

              {resetError && (
                <small>
                  <span className="text-danger mt-1 mb-3">{resetError}</span>
                </small>
              )}

              <div className="w-100 d-flex justify-content-center mt-3">
                <button
                  type="button"
                  class="rounded btn btn-sm text-white ps-3 pe-4 pt-1 pb-1"
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={handleReset}
                >
                  {resetLoading ? (
                    <span className="d-flex align-items-center">
                      Please wait
                      <span className="spinner spinner-border text-light spinner-border-sm ms-2"></span>
                    </span>
                  ) : (
                    <span>Proceed</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
