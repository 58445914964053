import * as yup from "yup";
const FILE_SIZE = 10240 * 10240;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/jfif",
  "image/JPG",
  "image/JPEG",
  "image/GIF",
  "image/PNG",
  "image/JFIF",
];
export const profileSchema = yup.object().shape({
  image: yup
    .mixed()
    .required("A file is required")
    .test(
      "fileSize",
      "File too large",
      (value) => value[0] && value[0].size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_FORMATS.includes(value[0].type)
    ),
  phone: yup.number().integer().positive().required(),
  location: yup.string().required(),
  bio: yup.string().required(),
});

export const SPRequiredProfileDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),

  email: yup.string().email().required(),
  dob: yup
    .date("Please select a valid date")
    .required("Please enter your date of birth"),
  idNo: yup.number().positive().required("Please enter your id"),
  gender: yup.string().required("Please select gender"),
  educationLevel: yup.string().required(),
  previousTraining: yup
    .string()
    .required("You must specify whether you've had training"),
  trainingDetails: yup.string(),
  // skills: yup.array().required(),
  yearsOfExperience: yup.number().required("please enter years of experience"),
  workArrangement: yup.string().required("Please select one"),
  bio: yup.string().required("Please enter something about you."),
});

export const SPProfileDetails2Schema = yup.object().shape({
  // idPhoto: yup
  //   .mixed()
  //   .required("A file is required")
  //   .test("fileChosen", "No file chosen", (value) => {
  //     return value.length > 0;
  //   })
  //   .test("fileSize", "File too large", (value) => {
  //     return value[0] && value[0]?.size <= FILE_SIZE;
  //   })
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     (value) => value[0] && SUPPORTED_FORMATS.includes(value[0]?.type)
  //   ),
  // certOfGoodConduct: yup
  //   .mixed()
  //   .required("A file is required")
  //   .test("fileChosen", "No file chosen", (value) => {
  //     return value.length > 0;
  //   })
  //   .test("fileSize", "File too large", (value) => {
  //     console.log(value[0]?.size);
  //     return value[0] && value[0]?.size <= FILE_SIZE;
  //   })
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     (value) => value[0] && SUPPORTED_FORMATS.includes(value[0]?.type)
  //   ),
  // passportPhoto: yup
  //   .mixed()
  //   .required("A file is required")
  //   .test("fileChosen", "No file chosen", (value) => {
  //     return value.length > 0;
  //   })
  //   .test("fileSize", "File too large", (value) => {
  //     console.log(value[0]?.size);
  //     return value[0] && value[0]?.size <= FILE_SIZE;
  //   })
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     (value) => value[0] && SUPPORTED_FORMATS.includes(value[0]?.type)
  //   ),
  // prevWorkDuration: yup
  //   .number("Kindly enter your previous work description")
  //   .required("Kindly enter your previous work description")
  //   .positive(),
  // prevWorkDurationUnit: yup.string().required("Kindly select one option"),
  prevJobDescription: yup
    .string()
    .required("Kindly specify your previous job description"),
  prevEmployerName: yup
    .string()
    .required("Kindly enter your previous employer's name"),
  prevJobTitle: yup.string().required("Kindly enter your previous job title"),

  prevSalary: yup.number().positive().required("Please enter your last salary"),
  placeOfResidence: yup
    .string()
    .required("Please enter your place of residence"),
    nhif:yup.string(),
    nssf:yup.string(),
});

export const ClientRequiredProfileDetailsSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),

  dob: yup
    .date("Please select a valid date")
    .required("Please enter your date of birth"),

  gender: yup.string().required("Please select gender"),
  idNo: yup.number().positive().required("Please enter your id").min(5),
  email: yup.string().email().required("Please enter your email"),
  placeOfResidence: yup
    .string()
    .required("Please enter your place of residence"),
  maritalStatus: yup.string().required("Please select your marital status"),
  bio: yup.string().required("Please enter something about you."),
});
export const ClientProfileDetails2Schema = yup.object().shape({
  ageGroup: yup.string().required("Please select your prefered age group"),

  changedHelp: yup.string(),
  changeReason: yup.string(),
  specialNeeds: yup.string(),
});

export const EmplyerVettingSchema = yup.object().shape({
  employerName: yup.string().required("Kindly enter your name"),
  jobTitle: yup.string().required("Kindly specify the job title"),
  jobDescription: yup.string().required("Kindly specify the job description"),
  workDuration: yup
    .number("Kindly enter the work duration")
    .required("Kindly enter the work duration")
    .positive(),
  workDurationUnit: yup.string().required("Kindly select one option"),
  salary: yup
    .number()
    .positive()
    .required("Please specified the salary you paid."),
  comments: yup.string(),
});
