import { useEffect, useState } from "react";

import "../../../../styles/Dashboard/Booking.css";
import { BsSearch, BsChevronDown } from "react-icons/bs";
import Spinner1 from "../../../../components/spinners/Spinner1";
import { axiosWrapper } from "../../../../Utils/AxiosWrapper/index.js";
import ReactPaginate from "react-paginate";
import MaxRequestSlider from "./MaxRequestSlider";
import RequestItem from "./RequestItem";
import DashboardNav from "../../DashboardNav";
import DashboardFooter from "../../DashboardFooter";
import { useSelector } from "react-redux";
import DashboardLayout from "../../DashboardLayout";
import { Box, Grid, useMediaQuery, Container } from '@mui/material';

function Requests() {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [showMaxRequestSlider, setShowMaxRequestSlider] = useState(false);
  const [maxSwiperCurrentIndex, setMaxSwiperCurrentIndex] = useState(0);

  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const [queryParam, setQueryParam] = useState("all");

  useEffect(() => {
    fetchBookings();
    return () => { };
  }, []);

  const fetchBookings = async () => {
    setRequestsLoading(true);
    const response = await axiosWrapper({
      method: "get",
      url: `bookings/?provider=${user.sub || user._id || user.id}`,
      data: "",
    });
    console.log("My sp requests");
    console.log(response.data.data.rows);
    // setRequests(response.data.data.rows);
    setRequests(response.data.data.rows);

    setRequestsLoading(false);
  };

  const filterBookings = async () => {
    setRequestsLoading(true);
    if (queryParam === "all") {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?provider=${user.sub || user._id || user.id}`,
        data: "",
      });
      console.log("My sp requests");
      console.log(response.data.data.rows);
      setRequests(response.data.data.rows);
      setRequestsLoading(false);
    } else {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?provider=${user.sub || user._id || user.id
          }&status=${queryParam}`,
        data: "",
      });
      console.log("My sp requests");
      console.log(response.data.data.rows);

      setRequests(response.data.data.rows);
      setRequestsLoading(false);
    }
  };

  useEffect(() => {
    // setRequestsLoading(true);
    filterBookings();
  }, [queryParam]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const showCurrentProfile = (sub, inx) => {
    const spProfile = requests.find((sp) => sp._id === sub);
    const index = requests.indexOf(spProfile);
    console.log("index tes" + index);
    setMaxSwiperCurrentIndex(index);
    setShowMaxRequestSlider(true);

    document.body.style.overflow = "hidden";
  };

  //PAGINATION
  const [itemsPerPage, setItemsPerPage] = useState(4);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = requests.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(requests.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % requests.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Container maxWidth="lg" component="main" sx={{ p: 2 }} >
      <div className="col-sm-9 d-flex flex-column mb-1 p-2">
        <span className="mb-1">MY JOB REQUESTS</span>
        <span id="line" className="mt-2 mb-1"></span>
      </div>
      <div className="col-sm-12 mx-auto d-flex row m-0">
        <div className="w-100 mt-2 mb-2 d-flex flex-wrap justify-content-start">
          <div className="btn-group fontsm ">
            <button
              type="button"
              className={`${queryParam === "all" ? "activeBookingTab" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setQueryParam("all");
              }}
            >
              All
            </button>
          </div>
          <div className="btn-group ">
            <button
              type="button"
              className={`${queryParam === "active" ? "bg-primary text-white" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setQueryParam("active");
              }}
            >
              Active
            </button>
          </div>
          <div className="btn-group ">
            <button
              type="button"
              className={`${queryParam === "pending" ? "bg-warning text-white" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setQueryParam("pending");
              }}
            >
              Pending
            </button>
          </div>

          <div className="btn-group ">
            <button
              type="button"
              className={`${queryParam === "accepted" ? "bg-success text-white" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setQueryParam("accepted");
              }}
            //   style={{ backgroundColor: `${colorSchema?.primary}` }}
            >
              Accepted
            </button>
          </div>
          <div className="btn-group  d-flex align-items-center justify-content-between">
            <button
              type="button"
              className={`${queryParam === "completed" ? "bg-dark text-white" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                setQueryParam("completed");
              }}
              style={{ fontSize: "14px" }}
            //   style={{ backgroundColor: `${colorSchema?.primary}` }}
            >
              Completed
            </button>
          </div>
          <div className="btn-group  d-flex align-items-center justify-content-between">
            <button
              type="button"
              className={`${queryParam === "rejected" ? "bg-danger text-white" : ""
                } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                setQueryParam("rejected");
              }}
              style={{ fontSize: "14px" }}
            //   style={{ backgroundColor: `${colorSchema?.primary}` }}
            >
              Rejected
            </button>
          </div>
          {/* <div className="btn-group  d-flex align-items-center justify-content-between">
                  <button
                    type="button"
                    className={`${
                      queryParam === "cancelled" ? "activeBookingTab" : ""
                    } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => {
                      setQueryParam("cancelled");
                    }}
                    style={{ fontSize: "14px" }}
                    //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                  >
                    Cancelled
                  </button>
                </div> */}
        </div>
        {!requestsLoading ? (
          <>
            {!requestsLoading && requests.length > 0 ? (
              <>
                <div
                  className="row d-flex m-0 pt-3"
                  style={{ minHeight: "60vh" }}
                >
                  {currentItems.map((request, index) => (
                    <RequestItem
                      key={index}
                      index={index}
                      request={request}
                      showProfile={showCurrentProfile}
                    />
                  ))}
                </div>
                <div className="w-100  p-2 d-flex justify-content-center">
                  <ReactPaginate
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="prev"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: "70vh" }}
              >
                <div className="text-center m-2 alert alert-info fontsm ">
                  You currently dont have any{" "}
                  {queryParam !== "all" && <span>{queryParam}</span>}{" "}
                  requests. Kindly check in later.
                </div>
              </div>
            )}
          </>
        ) : (
          <div style={{ position: "relative", minHeight: "70vh" }}>
            <Spinner1 message={"Fetching your requests..."} />
          </div>
        )}
      </div>
      {showMaxRequestSlider && (
        <MaxRequestSlider
          currentIndex={maxSwiperCurrentIndex}
          requests={requests}
          closeModal={() => {
            document.body.style.overflow = "visible";
            setShowMaxRequestSlider(false);
          }}
        />
      )}
    </Container>

  );
}

export default Requests;
