/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from "react";
import "../../../styles/Dashboard/Messages.css";
import ChatItem from "./ChatItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchMesages } from "../../../features/slices/MessagesSlice";

function Chat() {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const bottomRef = useRef(null);
  const chatId = useSelector((state) => state.messages.chatId);
  const chats = useSelector((state) => state.messages.messages.data);
  const {
    loading: messagesLoading,
    error: messagesError,
  } = useSelector((state) => state.messages.messages);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const scrollToBottom = async () => {
    bottomRef?.current?.scrollIntoView({
      behaviour: "smooth",
    });
  };

  useEffect(() => {
    if (!chats.length > 0) {
      dispatch(fetchMesages(chatId));
    }
  }, []);

  return (
    <div
      id="chatContainer"
      ref={containerRef}
      className="d-flex flex-column  pt-4"
    >
      {!messagesError ? (
        <>
          {messagesLoading ? (
            <div className="w-100 p-2 d-flex justify-content-center">
              <div className="text-success spinner-border spinner-border-sm "></div>
            </div>
          ) : (
            <>
              {chats &&
                chats.map((chat, index) => (
                  <ChatItem key={index} chat={chat} />
                ))}
            </>
          )}
        </>
      ) : (
        <div className="pt-2 ps-3 pe-3">
          <div className="text-center mx-auto fontsm1  alert alert-danger m-2">
            Currently unable to to fetch your messages
          </div>
        </div>
      )}
      <span ref={bottomRef}></span>
    </div>
  );
}

export default Chat;
