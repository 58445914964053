import React from "react";
import "../../styles/ProfileDetails.css";
import { BsCheckCircleFill } from "react-icons/bs";
function FileUploadStatus({ isLoading, isUploaded, isPresent }) {
  return (
    <>
      {isLoading && (
        <div
          className="spinner-border-sm my-auto spinner-border text-success uploadStatus"
          role="status"
        ></div>
      )}
      {!isLoading && isUploaded && (
        <BsCheckCircleFill className="uploadStatus text-success" size={18} />
      )}
      {!isLoading && !isUploaded && isPresent && (
        <BsCheckCircleFill className="uploadStatus text-secondary" size={18} />
      )}
    </>
  );
}

export default FileUploadStatus;
