/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import "../../styles/Dashboard/Dashboard.css";
// import {
//   updateUserDetails,
//   checkIfProfileIsComplete,
// } from "../../features/slices/AuthSlice";
// import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
// import MessagesContainer from "../Messages/MessagesContainer";
// import NotificationsContainer from "../Notifications/NotificationsContainer";
// import { fetchChats } from "../../features/slices/MessagesSlice";
// import { setActivePage } from "../../features/slices/DashboardControlSlice";
// import { fetchServicesAutocomplete } from "../../features/slices/ServiceSlice";
// import { setReferralCode } from "../../features/slices/ReferralSlice";
// import { setSubscription } from "../../features/slices/SubscriptionSlice";
// import { fetchNotifications } from "../../features/slices/NotificationsSlice";
// import { toast } from "react-hot-toast";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import * as React from 'react';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Typography, Rating, CardMedia, MenuItem, useMediaQuery, CardContent, CardActions, Paper, Grid, Card, Divider, Button, Chip, Stack, List, Container, CardHeader } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
// import SchoolIcon from '@mui/icons-material/School';
// import StarRateIcon from '@mui/icons-material/StarRate';
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import StorageIcon from "@mui/icons-material/Storage";
// import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import profileAvatar from "../../pics/avatar.png";
// import butler from "../../pics/services/butler.jpg";
// import chef from "../../pics/services/chef.png";
// import driver from "../../pics/services/driver.png";
// import gardener from "../../pics/services/gardener.png";
// import house from "../../pics/services/house-keeper.jpg";
// import nanny from "../../pics/services/nanny.jpg";
// import pet from "../../pics/services/pet.png";
// import laundry from "../../pics/services/laundry.png";
// import special from "../../pics/services/special.png";
// import repair from "../../pics/services/repair.png";
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';

// export const data = [
//   {
//     icon: house,
//     title: "Cleaning Services",
//     body: "House cleaning of living spaces, including dusting, vacuuming, mopping, and tidying up.",
//   },
//   {
//     icon: "https://media.istockphoto.com/id/1300081308/vector/disabled-elderly-woman-and-her-grandchildren-visiting-nursing-home-nursing-home-concept.jpg?s=612x612&w=0&k=20&c=T8XSP7rt7pq9YYYQa2C-GAhDZv00qYieZjaY5iPvtEI=",
//     title: "Elderly Care",
//     body: "Assisting senior citizens with daily activities, such as bathing, dressing, and medication management.",
//   },
//   {
//     icon: chef,
//     title: "Cooking ",
//     body: "Catering for events or special occasions. Meal planning and grocery shopping as well as preparing daily meals.",
//   },
//   {
//     icon: nanny,
//     title: "Childcare & Babysit",
//     body: "Caring for children, including feeding, bathing, and playing with them.Supervising and assisting with homework.",
//   },
//   {
//     icon: pet,
//     title: "Pet Sitting",
//     body: "Feeding and providing water for pets.Walking dogs and pet exercise.Cleaning and maintaining pet enclosures ",
//   },
//   {
//     icon: "https://media.istockphoto.com/id/509586837/vector/african-american-nurse-and-her-patient.jpg?s=612x612&w=0&k=20&c=wtsrcADn_38CI-RB93CV6PK85MCjNfWG-Zr9gW4ijeE=",
//     title: "Home Nurse",
//     body: "Medical and nursing care within the home for individuals with medical conditions.Rehabilitation services.Rehabilitation services.",
//   },
//   {
//     icon: butler,
//     title: "Butler",
//     body: "Coordinates all domestic staff members, including housekeepers, chefs, gardeners, and chauffeurs.",
//   },

//   {
//     icon: driver,
//     title: "Driver",
//     body: "Drives individuals or families to daily commutes, special occasions, or as needed ",
//   },
//   {
//     icon: gardener,
//     title: "Gardening",
//     body: "planting flowers, lawn mowing and maintaining gardens ",
//   },
//   {
//     icon: special,
//     title: "Special Needs Care",
//     body: "Its main focus is to provide care to disabled babies, teens and adults. ",
//   },
//   {
//     icon: laundry,
//     title: "Laundry & Ironing",
//     body: "Washing, drying, and folding clothes.Ironing and steaming garments.Dry cleaning services for delicate items.",
//   },
//   {
//     icon: repair,
//     title: "Home Repair",
//     body: "From plumbing to electrical, we've got you covered with reliable home repair services. ",
//   },
// ];


function Market() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const isSmallScreen = useMediaQuery("(max-width: 600px)");
  //   const { user, isAuth } = useSelector((state) => state.auth);
  //   const [bookings, setBookings] = useState();
  //   const [profiles, setProfiles] = useState([]);
  //   const [open, setOpen] = React.useState(false);
  //   const [justify, setJustify] = React.useState('one time');

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const [openIt, setOpenIt] = React.useState(false);

  //   const handleClickOpenIt = () => {
  //     setOpenIt(true);
  //   };

  //   const handleCloseIt = () => {
  //     setOpenIt(false);
  //   };

  //   const handleBook = () => {
  //     setOpen(false);
  //     setOpenIt(true);
  //     toast.success("Service booked successfully")

  //   }

  //   const fetchProfileDetails = async () => {
  //     // setLoading(true);
  //     document.body.style.overflow = "hidden";
  //     const response = await axiosWrapper({
  //       method: "get",
  //       url: `users/${user.sub ? user.sub : user.id}`,
  //       data: "",
  //     });
  //     const referralCode = await axiosWrapper({
  //       method: "get",
  //       url: `referrals/codes?referrer=${user.sub ? user.sub : user.id}`,
  //     });

  //     const subscriptionData = await axiosWrapper({
  //       method: "get",
  //       url: `users/subscriptions?user=${user.sub ? user.sub : user.id}`,
  //     });

  //     // console.log("subscription data");
  //     console.log("subscription data", subscriptionData.data.data);

  //     dispatch(setSubscription(subscriptionData.data.data));

  //     const getReviews = await axiosWrapper({
  //       method: "get",
  //       url: `reviews?reviewee=${user.sub ? user.sub : user.id}`,
  //     });

  //     const spProfiles = await axiosWrapper({
  //       method: "get",
  //       // url: `users?role=serviceProvider`,
  //       url: `users?role=serviceProvider&limit=${4}`,
  //       data: "",
  //     });
  //     console.log(`SPS1`, spProfiles)
  //     setProfiles(spProfiles.data.data.rows);
  //     const mybookings = await axiosWrapper({
  //       method: "get",
  //       url: `bookings/?client=${user.sub || user._id || user.id}`,
  //       data: "",
  //     });
  //     console.log("My bookings", mybookings.data.data.rows);
  //     setBookings(mybookings.data.data.rows);

  //     dispatch(setReferralCode(referralCode.data.data.rows[0]));
  //     const employersData = await axiosWrapper({
  //       method: "get",
  //       url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
  //         }`,
  //     });
  //     const prevEmployerData = employersData.data.data.rows[0];
  //     const userData = {
  //       ...response.data.data,
  //       prevEmployer: prevEmployerData,
  //       rating: getReviews.data.data.rows,
  //     };
  //     dispatch(updateUserDetails(userData));

  //     const isProfileComplete = checkIfProfileIsComplete(userData);
  //     if (
  //       user.role.title === "serviceProvider" &&
  //       isProfileComplete &&
  //       user.yearsOfExperience > 0
  //     ) {
  //       const prevEmployerAnswers = await axiosWrapper({
  //         method: "get",
  //         url: `referrals/employers/answers?employer=${userData.prevEmployer._id}`,
  //         data: "",
  //       });

  //       if (prevEmployerAnswers.data.data.count === 0) {
  //         return navigate("/vetting-pending");
  //       }
  //     }

  //     if (isAuth && !isProfileComplete) {
  //       document.body.style.overflow = "visible";
  //       dispatch(setActivePage("profile"));
  //       navigate(`/${user?.role?.title}-onboarding`);
  //     } else {
  //       // setLoading(false);
  //       document.body.style.overflow = "visible";
  //     }
  //   };

  //   useEffect(() => {
  //     dispatch(fetchChats(user.sub || user._id || user.id));
  //     dispatch(fetchNotifications(user.sub || user._id || user.id));
  //     dispatch(fetchServicesAutocomplete());
  //     return () => { };
  //   }, []);

  //   useEffect(() => {
  //     // if (!user.phoneVerified) {
  //     //   navigate("/verify-phone");
  //     // }
  //     fetchProfileDetails();
  //   }, []);

  //   useEffect(() => {
  //     // console.log("Auth status changed bana!");
  //     if (!isAuth) {
  //       navigate("/");
  //     }
  //   }, [isAuth, navigate]);

  //   //join room
  //   const dummyState = "";

  //   const joinRoom = (userId) => {
  //     socket.emit("join", { userId });
  //   };

  //   useEffect(() => {
  //     joinRoom(user.sub || user._id);
  //   }, [dummyState, joinRoom, user._id, user.sub]);



  //   //handle toast notifications
  //   useEffect(() => {
  //     socket.on("notification", (data) => {
  //       toast.success(data.message);
  //     });
  //     return () => {
  //       socket.off("notification");
  //     };
  //   }, []);


  //   //dashboard stats
  const userStats = [

    {
      title: "House Manager Uniforms",
      count: "2,000",
      icon: "black",
      pic: "https://3.imimg.com/data3/FC/KL/MY-18943233/t-500x500.jpg"
    },
    {
      title: "House Manager Uniforms",
      count: "2,500",
      icon: "grey",
      pic: "https://1.bp.blogspot.com/_sblfejvo4ew/SyTyrPL6sWI/AAAAAAAAACk/HRFGM4PjSvA/s400/Maid%27s+Uniform.jpg"
    },
    {
      title: "Nursing Unifrom",
      count: "1,500",
      icon: "scrubs",
      pic: "https://5.imimg.com/data5/JX/VC/QF/SELLER-95104207/uniform-trader-scrub-suit--500x500.jpg"

    },
    {
      title: "Nursing Unifrom",
      count: "1,500",
      icon: "scrubs",
      pic: "https://5.imimg.com/data5/JX/VC/QF/SELLER-95104207/uniform-trader-scrub-suit--500x500.jpg"

    },
    {
      title: "House Manager Uniforms",
      count: "2,000",
      icon: "black",
      pic: "https://3.imimg.com/data3/FC/KL/MY-18943233/t-500x500.jpg"
    },
    {
      title: "House Manager Uniforms",
      count: "2,500",
      icon: "grey",
      pic: "https://1.bp.blogspot.com/_sblfejvo4ew/SyTyrPL6sWI/AAAAAAAAACk/HRFGM4PjSvA/s400/Maid%27s+Uniform.jpg"
    },


  ];


  return (
    <Container maxWidth="md" component="main" >
      <Grid container spacing={3} sx={{ mb: 6, p: 1 }}>
        {userStats.map((listing, index) => (
          <Grid key={index} item md={4} sm={12}>
            <Card sx={{ minWidth: isSmallScreen ? 320 : 270, borderRadius: "0.5rem", cursor: "pointer", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} onClick={handleClickOpen}>
              <CardMedia
                component="img"
                height="200"
                image={listing.pic}
                alt="service provider"
              />
              <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle2" color="text.primary" component='div'>
                  {listing.title}
                </Typography>
                <Stack
                  direction="row" spacing={1}
                  justifyContent="space-between"
                >
                  <Typography variant="h6" color="text.secondary" component='div'>
                    Ksh {listing.count}
                  </Typography>
                  <Chip label={listing.icon} size="small" />
                </Stack>
                {/* <Stack
                        direction="row" spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <Typography variant="caption" color="primary" component='div'>
                        {listing.count}
                        </Typography>
                        <Typography variant="caption" color="primary" component='div'>
                            Cook
                        </Typography>
                    </Stack> */}
              </CardContent>
              <CardActions disableSpacing>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  size="small"
                  onClick={handleClickOpen}
                >
                  Purchase
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Coming Soon !"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Maids of Honour market place is coming soon, with different proffesional products best suited for your service provider.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button color="primary"
            variant="contained" onClick={handleClose}>Check back later</Button>
        </DialogActions>
      </Dialog>
      {/* <div >
        <Stack direction="row" justifyContent="space-between" spacing={3} mt={1}>
          <div className="col-sm-0 d-flex flex-column">
            <Typography variant="h6">Hello, {user.firstName}</Typography>
            <span id="line" className="mt-2 mb-1"></span>
          </div>
          {!isSmallScreen &&
            <div>
              {user.role.title === "serviceProvider" ? (
                <Button
                  startIcon={<SchoolIcon />}
                  sx={{ borderRadius: "8px", px: 1, bgcolor: "text.secondary" }}
                  size="small"
                  variant="contained"
                  color='secondary'
                  onClick={() => navigate('/dashboard/courses')}

                >
                  View Our Courses
                </Button>
              ) : (
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ borderRadius: "10px", bgcolor: "text.secondary" }}
                  size="small"
                  variant="contained"
                  color='secondary'
                  onClick={handleClickOpen}

                >
                  Book A Service
                </Button>
              )}
            </div>
          }
          {openIt && (
            <Dialog
              open={openIt}
              onClose={handleCloseIt}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Your Service Provider"}
              </DialogTitle>
              <DialogContent>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div>
                    <Avatar
                      alt="SP"
                      src="https://firebasestorage.googleapis.com/v0/b/nomadic-coast-307006.appspot.com/o/profile%2FSheila%20(2).png?alt=media&token=b0d1eadd-6a5b-4be2-8e71-32264d3082fb"
                      sx={{
                        justifyContent: "center",
                        height: "7.5rem",
                        width: "7.5rem",
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      component="div"
                      gutterBottom
                    >
                     Sheila Chepkngeno
                    </Typography>
                  </div>
                  <div>
                  <Rating name="read-only" value={4} readOnly />
                  </div>
                </Stack>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  Your Services
                </Divider>
                <DialogContentText id="alert-dialog-description">
                 House Cleaning, Elderly Care
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ px: 2 }}>
              <Button fullWidth variant="outlined" onClick={handleCloseIt}>Cancel</Button>
                <Button fullWidth variant="contained" onClick={handleCloseIt}>Pay Now</Button>

              </DialogActions>
            </Dialog>
          )}
          {open && (
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Request a microservice</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You can book a quick one off service for a desired  period of time.
                </DialogContentText>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  What type of service are you looking for
                </Divider>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  select
                  label="Select Tasks"
                  fullWidth
                >
                  <MenuItem value="cleaning">House Cleaning</MenuItem>
                  <MenuItem value="laundry">Laundry & Ironing</MenuItem>
                  <MenuItem value="chef">Meal Preparation</MenuItem>
                  <MenuItem value="baby sitting">Baby sitting</MenuItem>
                  <MenuItem value="airbnb">AirBnb Services</MenuItem>
                  <MenuItem value="pet sitting">Pet sitting & Pet Care</MenuItem>
                </TextField>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  When would you like the service to be done
                </Divider>

                <Grid
                  container
                  mb={2}
                  mt={2}
                  rowSpacing={2}
                  columnSpacing={{ xs: 2, sm: 3, md: 6 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} md={12}>
                      <DateTimePicker
                        fullWidth
                        slotProps={{ textField: { fullWidth: true } }}
                        label="Date"
                      />

                    </Grid>
                    <Grid item xs={12} md={12}>

                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">How Often</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="onetime" control={<Radio />} label="One Time" />
                          <FormControlLabel value="regular" control={<Radio />} label="Regular" />

                        </RadioGroup>
                      </FormControl>

                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button fullWidth variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button fullWidth variant="contained" onClick={handleBook}>Book</Button>
              </DialogActions>
            </Dialog>
          )}
        </Stack>
        <Grid container spacing={3} mt={0.3} alignItems="flex-end" mb={2}>
          {userStats.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              md={6}
              lg={3}
            >
              <Card sx={{ borderRadius: "0.6rem", bgcolor: 'background.paper', boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} elevation={0}>
                <CardHeader
                  sx={{ fontWeight: "bold" }}
                  avatar={
                    <Avatar sx={{ bgcolor: "#fff7f5" }} aria-label="label">
                      {tier.icon}
                    </Avatar>
                  }
                  title={
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>{tier.count}</Typography>
                  } subheader={tier.title}
                />
              </Card>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            md={8}
          >
            <Paper sx={{ borderRadius: "0.6rem", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%) ' }} elevation={0}>
              {user.role.title === "client" ? (
                <List sx={{ p: 3.5, borderRadius: "0.7rem", minHeight: 360 }}>
                  <Typography sx={{ fontWeight: "bold" }}>Recommended Service Providers</Typography>
                  {profiles.map((profile, index) => (
                    <div key={index}>
                      <ListItem
                        secondaryAction={
                          <>
                            {!isSmallScreen &&
                              <Chip size="small" color="primary" sx={{ fontSize: "0.7rem", borderRadius: "5px" }} label='Hire' />
                            }
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar variant="square" sx={{ height: "3rem", width: "3rem", borderRadius: "0.1rem" }} src={profileAvatar} />
                        </ListItemAvatar>
                        <ListItemText primary={profile.firstName + " " + profile.lastName} secondary={
                          <Stack
                            direction="row" spacing={0.5}
                            justifyContent="flex-start"
                            divider={<Divider orientation="vertical" flexItem />}

                          >
                            {profile.skills.map((skill, index) => (
                              <div key={index}>
                                <Chip size="small" sx={{ fontSize: "0.7rem", borderRadius: "8px", bgcolor: "#fff7f5" }} label={skill.title} />
                              </div>
                            ))
                            }
                          </Stack>
                        } />
                      </ListItem>

                      <Divider variant="inset" component="li" />
                    </div>
                  ))
                  }
                </List >
              ) : (
                <List sx={{ p: 3.5, borderRadius: "0.7rem" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Recommended Courses</Typography>
                  {videoUrls?.map((video, index) => (
                    <div key={index}>
                      <ListItem
                        sx={{ cursor: "pointer" }}
                        secondaryAction={
                          <>
                            {!isSmallScreen && <Chip onClick={() => navigate("/dashboard/courses/course-content")} size="small" color="primary" sx={{ fontSize: "0.7rem", borderRadius: "5px" }} label='View Course' />}
                          </>}

                      >
                        <ListItemAvatar>
                          <Avatar variant="square" sx={{ height: "3rem", width: "3rem", borderRadius: "0.1rem" }} src={video.logo} />
                        </ListItemAvatar>
                        <ListItemText primary={video.title} secondary={
                          <Stack
                            direction="row" spacing={0.5}
                            justifyContent="flex-start"
                          >
                            <div>
                              <Typography noWrap variant="caption" sx={{ textOverflow: "ellipsis" }}>{video.info.substring(0, 20)}..</Typography>
                            </div>
                          </Stack>
                        } />
                      </ListItem>

                      <Divider variant="inset" component="li" />
                    </div>
                  ))
                  }
                </List >
              )}
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Paper sx={{ borderRadius: "0.6rem", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} elevation={0}>
              <Typography sx={{ px: 2, pt: 1, fontWeight: "bold" }}>My Calender</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
            </Paper>
          </Grid>

        </Grid>
      </div> */}
    </Container>
  );
}

export default Market;