import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import LoadingCrane from "../../pics/comming-soon-crane.gif";
function MarketPlace() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"resources"} />
      <div className="row m-0 ps-2 pe-2 pt-4" style={{ minHeight: "100vh" }}>
        <div className="col-sm-4 mx-auto ">
          <center>
            <b>
              <h3>Our Market Place</h3>
            </b>
          </center>
          <img src={LoadingCrane} alt="coming soon" className="w-100" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MarketPlace;
