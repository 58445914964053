import { useState } from "react";
import "../../../styles/Course/Courses.css";
import { useSelector } from "react-redux";
import photo from "../../../pics/cooking1.jpg";
import { FaCheckCircle, FaLock } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Subscription from "../../../components/Home/Subscription";
function CoursePaymentModal({ handlePayNow, closeModal }) {
  const { currentCourse } = useSelector((state) => state.courses);
  const [activeTab, setActiveTab] = useState("fullSubscription");
  const colorSchema = useSelector((state) => state.color.scheme);
  return (
    <div
      className="coursePaymentModalContainer row m-0 "
      style={{ zIndex: "200000" }}
    >
      <div
        className="col-sm-10 mx-auto p-0 rounded bg-white row m-2"
        style={{ minHeight: "70vh", position: "relative" }}
      >
        <div
          className="col-sm-4 p-0 border-right"
          style={{
            position: "relative",
            minHeight: "40vh",
            // top: "0",
            // bottom: "0",
            // left: "0",
            // right: "0",
            backgroundImage: `url(${photo})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        >
          {/* <img src={photo} alt="course thumbnail" className="w-100 rounded" /> */}
          <div
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              backgroundColor: "rgba(0,0,0,0.8)",
              borderBottomLeftRadius: "5px",
              borderTopLeftRadius: "5px",
            }}
            className="d-flex justify-content-center align-items-center flex-column "
          >
            <FaLock size={50} className="text-light m-3" />
          </div>
        </div>
        <div className="col-sm-8 p-3 border-right">
          <div className="d-flex w-100 justify-content-end">
            <MdClose
              onClick={closeModal}
              size={30}
              style={{ cursor: "pointer" }}
              className="hoverDownEffect"
            />
          </div>
          <span className="fontsm text-center mx-auto badge rounded-pill mb-2 bg-warning">
            Payment Required
          </span>
          <div>
            <ul className="nav nav-tabs">
              <li
                className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("fullSubscription")}
              >
                <span
                  className={`nav-link ${
                    activeTab === "fullSubscription" ? "active fw-bold" : ""
                  } text-black`}
                  aria-current="page"
                  href="#"
                >
                  Monthly Subscription (recommended)
                </span>
              </li>
              <li
                className="nav-item"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab("courseOnly")}
              >
                <span
                  className={`nav-link text-black ${
                    activeTab === "courseOnly" ? "active fw-bold" : ""
                  }`}
                  href="#"
                >
                  This course only
                </span>
              </li>
            </ul>
          </div>
          {activeTab === "fullSubscription" ? (
            <div className="p-3">
              <span className=" d-flex flex-column">
                <span className="mt-2">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />
                  Automated Contracts between you and the Domestic help
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />
                  The ability to get a new trained domestic help from our
                  platform with no extra charges
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />
                  Resources: Automated Time planners, Menu planners, Shopping
                  list and more
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />
                  Access to all our monthly development training content and
                  courses at the tap of a button for your staff and home.
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />{" "}
                  Access to our community, and have the ability to connect and
                  learn from different people.
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />{" "}
                  Access to all our Trained Domestic Help
                </span>
                <span className="mt-3">
                  <FaCheckCircle
                    color={colorSchema?.primary}
                    className="me-2"
                  />{" "}
                  Personalized newsletter to meet the needs of your home.
                </span>
              </span>
              <div className="d-flex justify-content-end p-3">
                <button
                  className="btn btn-success"
                  onClick={() => handlePayNow("subscription")}
                >
                  Subscribe Now (Ksh 1000)
                </button>
              </div>
            </div>
          ) : (
            <div className="p-3 mt-3">
              <h4 className="mb-2 pb-2">{currentCourse.title}</h4>
              <div className="fontsm">{currentCourse.description}</div>
              <br />
              <div className="d-flex flex-column align-items-start justify-content-start">
                <span className="lightDark fw-bold fontsm1">Note</span>

                <span
                  className="lightDark fontsm1 "
                  style={{ lineHeight: "120%" }}
                >
                  Kindly complete payment in order to access this course and
                  earn a certification.
                </span>
              </div>
              <div className="d-flex justify-content-end p-3">
                <button
                  className="btn btn-success btn-sm fontsm mt-3"
                  onClick={() => handlePayNow("course")}
                >
                  Pay ksh {currentCourse.fee.amount}/=
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoursePaymentModal;
