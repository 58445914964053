/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SPprofile from "../profile/sp/Profile";
import ClientProfile from "../profile/client/Profile";
import ClientDashboard from "./client/ClientDashboard";
import DashboardNav from "./DashboardNav";
import DashboardFooter from "./DashboardFooter";
import Requests from "./serviceProvider/Requests/Requests";
import Bookings from "./client/Bookings/Bookings";
import "../../styles/Dashboard/Dashboard.css";
import {
  updateUserDetails,
  checkIfProfileIsComplete,
} from "../../features/slices/AuthSlice";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import Hire from "./client/Hire";
import MessagesContainer from "../Messages/MessagesContainer";
import ServiceProviderDashboard from "./serviceProvider/ServiceProviderDashboard";
import NotificationsContainer from "../Notifications/NotificationsContainer";
import AllCourses from "../Courses/AllCourses";
import CourseContent from "../Courses/CourseContent/CourseContent";
import { setPrevEmployer } from "../../features/slices/EmployerSlice";
import Header from "../../components/NavBars/header";
import Footer from "../../components/NavBars/footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import{ Link,Typography,Container,Box} from '@mui/material';

// import Waiting from "../Waiting";
// import Messages from "../Messages/Messages";
// import Courses from "../Courses/Courses";
// import SPDashboard from "./serviceProvider/SPDashboard";
// import PlayerContainer from "../../components/PlayerContainer";

function DashboardLayout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const [selectedTab, setSelectedTab] = useState("");
  const activeTab = useSelector((state) => state.dashboardControl.active);

  const fetchProfileDetails = async () => {
    const response = await axiosWrapper({
      method: "get",
      url: `users/${user.sub ? user.sub : user.id}`,
      data: "",
    });

    let userData = { ...response.data.data };

    if (
      user.role.title === "serviceProvider" &&
      isProfileComplete &&
      user.yearsOfExperience > 0
    ) {
      const employersData = await axiosWrapper({
        method: "get",
        url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
          }`,
      });

      const prevEmployerData = employersData.data.data.rows[0];

      userData = {
        ...response.data.data,
        prevEmployer: prevEmployerData,
      };
      dispatch(setPrevEmployer(prevEmployerData));
    }

    dispatch(updateUserDetails(userData));

    const isProfileCompleted = checkIfProfileIsComplete(userData);

    if (isAuth && !isProfileCompleted) {
      setSelectedTab("profile");
    }
  };

  useEffect(() => {
    // if (!user.phoneVerified) {
    //   navigate("/verify-phone");
    // }
    fetchProfileDetails();
  }, []);

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  useEffect(() => {
    setTab();
  }, [selectedTab]);

  const setTab = () => {
    if (!user || !isAuth) {
      return navigate("/");
    }
    switch (selectedTab) {
      case "":
        return user?.role?.title === "client" ? (
          <ClientDashboard
            setTab={(tab) => {
              setSelectedTab(tab);
            }}
          />
        ) : (
          // <Waiting />
          // <Waiting />
          <ServiceProviderDashboard
            setTab={(tab) => {
              setSelectedTab(tab);
            }}
          />
          // <SPDashboard
          //   setTab={(tab) => {
          //     setSelectedTab(tab);
          //   }}
          // />
          // <Waiting />
        );
        break;
      case "profile":
        return user?.role?.title === "client" ? (
          <ClientProfile />
        ) : (
          <SPprofile />
        );
        break;
      case "requests":
        // return <PlayerContainer />;
        // return <Waiting />;
        return user?.role?.title === "client" ? <Bookings /> : <Requests />;
        break;
      case "courses":
        // return <Waiting />;
        return (
          <AllCourses
            selectTab={(tabName) => {
              setSelectedTab(tabName);
            }}
          />
        );

        break;
      case "course-content":
        // return <Waiting />;
        return <CourseContent />;

        break;
      case "hire":
        return <Hire />;
        // return <Waiting />;
        break;

      default:
        return navigate("/");
    }
  };
  return (
    <React.Fragment>
      <Header />
      {children}
      {isSmallScreen ? <Footer /> : (
          <Box
            component="footer"
            sx={{
              py:1,
              px:1,
              backgroundColor: "text.secondary"
            }}
          >
            <Container maxWidth="sm">
              <Typography variant="body2" sx={{color:"#fff",fontWeight:"bold"}}>
              {'Copyright © '}
                <Link color="inherit" href="/">
                  Maids of Honour Africa
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
              </Typography>
            </Container>
          </Box>
        )}
    </React.Fragment>
  );
}

export default DashboardLayout;
