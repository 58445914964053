/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
//translation
// import { useTranslation} from "react-i18next";
import Nav from "../components/Home/Nav";
import Banner from "../components/Home/Banner";
import Services from "../components/Home/ServicesMin";
import Stats from "../components/Home/Stats";
import Partners from "../components/Home/Partners";
import Footer from "../components/Home/Footer";
// import Process from "../components/Home/Process";
// import ProcessSwipper from "../components/Home/ProcessSwipper";
import Pricing from "../components/Home/plans";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../features/slices/AuthSlice";
import { axiosWrapper } from "../Utils/AxiosWrapper/index";
import MissionVision from "../components/Home/MissionVission";
import SpBanner from "../components/Home/SpBanner";
// import ProcessIcons from "../components/Home/ProcessIcons";
import OurDifference from "../components/Home/OurDifference";

function Home() {
  // const { t } = useTranslation();
  const { user, isAuth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const fetchProfileDetails = async () => {
    const response = await axiosWrapper({
      method: "get",
      url: `users/${user.sub ? user.sub : user.id}`,
      data: "",
    });

    dispatch(updateUserDetails(response.data.data));
  };
  useEffect(() => {
    window.scroll(0, 0);
    if (isAuth) {
      fetchProfileDetails();
    }
  }, []);
  return (
    <>
      <div
        className="p-0 m-0"
        style={{
          backgroundColor: "#f8f9fa",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Nav currentPage={""} />
        <Banner />
        <OurDifference />
        <SpBanner />
        <Services />
        <Stats />
        <Pricing />
        <Partners />
        <Footer />
      </div>
    </>
  );
}

export default Home;
