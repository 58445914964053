import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../Utils/AxiosWrapper";

export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async () => {
    const servicesData = await axiosWrapper({
      method: "get",
      url: "services",
    });
    console.log("services data");
    console.log(servicesData.data.data.rows);
    let allServices = servicesData.data.data.rows;

    return await sortServicesOrder(allServices);
  }
);

export const fetchServicesAutocomplete = createAsyncThunk(
  "services/getServices",
  async () => {
    const servicesData = await axiosWrapper({
      method: "get",
      url: "services/autocomplete",
    });

    return servicesData.data.data.rows;
  }
);
export const fetchUserServicesAutocomplete = createAsyncThunk(
  "services/getUserServices",
  async (skillIds) => {
    const servicesData = await axiosWrapper({
      method: "get",
      url: `services/autocomplete?${skillIds}`,
    });

    console.log("user services data");
    console.log(servicesData);

    return servicesData.data.data;
  }
);

const sortServicesOrder = async (allServices) => {
  let sortedServices = [];
  let Housekeeper = allServices.find(
    (service) => service.title === "Housekeeper"
  );
  let Nanny = allServices.find((service) => service.title === "Nanny");
  let Butler = allServices.find((service) => service.title === "Butler");
  let ProfessionalChef = allServices.find(
    (service) => service.title.trim() === "Professional Chef"
  );
  let GeneralChef = allServices.find(
    (service) => service.title === "General chef"
  );
  let HomeNurse = allServices.find((service) => service.title === "Home Nurse");

  let remServices = await allServices.filter(
    (svc) =>
      ![
        "Housekeeper",
        "Nanny",
        "Butler",
        "Professional Chef ",
        "General chef",
        "Home Nurse",
      ].includes(svc.title)
  );

  sortedServices = [
    Housekeeper,
    Nanny,
    Butler,
    ProfessionalChef,
    GeneralChef,
    HomeNurse,
    ...remServices,
  ];

  return sortedServices;
};

const initialState = {
  loading: false,
  error: false,
  services: JSON.parse(localStorage.getItem("services")) || [],
  autoComplete: [],
  userAutoComplete: [],
};

const ServiceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchServices.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.services = action.payload;
      localStorage.setItem("services", JSON.stringify(action.payload));
    },
    [fetchServices.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchServicesAutocomplete.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchServicesAutocomplete.fulfilled]: (state, action) => {
      state.loading = false;
      state.autoComplete = action.payload;
    },
    [fetchServicesAutocomplete.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchUserServicesAutocomplete.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUserServicesAutocomplete.fulfilled]: (state, action) => {
      state.loading = false;
      state.userAutoComplete = action.payload;
    },
    [fetchUserServicesAutocomplete.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default ServiceSlice.reducer;
