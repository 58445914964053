import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { registerSchema } from "../../../Utils/validations/AuthValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { registerUser } from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import Spinner1 from "../../../components/spinners/Spinner1";
import Spinner2 from "../../../components/spinners/Spinner2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../../../styles/ProfileDetails.css";
import { AiOutlineClose } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";

import { MultiSelect } from "react-multi-select-component";

import { FaCheckSquare } from "react-icons/fa";
import ProgressBar1 from "../../../components/ProgressBars/ProgressBar1";
import SPRequiredDetails from "./SPRequiredDetails";
import SPAdditionalDetails from "./SPAdditionalDetails";

function SPProfileDetails({ closeForm }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage, prevPath, isAuth } = useSelector(
    (state) => state.auth
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneRef = useRef();
  const [phoneError, setPhoneError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema) });
  const [showPrevIcon, setShowPrevIcon] = useState(false);

  const handleRegister = (data) => {
    if (!phone || phoneError) {
      console.log("no phone", phone);
      handlePhoneValidation(phone);
    } else {
      let { firstName, lastName, email, password } = data;

      // console.log({ firstName, lastName, phone, email, password });
      dispatch(registerUser({ firstName, lastName, phone, email, password }));
      // toast.success("Registration successfull");
      // navigate("/");
    }
  };

  const handlePhoneValidation = (num) => {
    if (!num) {
      setPhoneError("Phone number required");
    } else if (num.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  // useEffect(() => {
  //   if (isAuth) {
  //     navigate("/");
  //   }
  // }, [isAuth]);

  // if (isLoading) {
  //   return <Spinner1 message={"Creating your account..."} />;
  // }

  const options = [
    { label: "House Managers", value: "House Managers" },
    { label: "Nannies", value: "Nannies" },
    { label: "Cooks", value: "Cooks" },
    { label: "Chefts", value: "Chefts" },
    { label: "Home Tutors / Nannies", value: "Home Tutors / Nannies" },
    { label: "Elderly Care", value: "Elderly Care" },
    { label: "Home Nurses", value: "Home Nurses" },
    { label: "Special Needs", value: "Special Needs" },
    {
      label: "Buttlers/ Home personal assistants",
      value: "Buttlers/ Home personal assistants",
    },
  ];

  const [selected, setSelected] = useState([]);
  const [completed, setCompleted] = useState(0);

  const handleInsert = (e) => {
    e.preventDefault();
    setCompleted(50);
  };

  const nextDetails = () => {
    window.scroll(0, 0);
    setCompleted(50);
    setShowPrevIcon(true);
  };
  const closeUpdateForm = () => {
    closeForm();
  };
  return (
    <>
      {isLoading && <Spinner2 message={"Authenticating..."} />}
      {/* <div
        className="row w-100 ms-0 me-0 ps-0 pe-0 pt-0  auths profileDetailsCont"
        style={{ position: "relative" }}
      >
        <div
          className="col-sm-12 ps-5 pe-5 pb-5 pt-3 row mt-0 ms-0 me-0 mb-3"
          id=""
        > */}
          {/* <span className="mx-auto   col-sm-12"> */}
            {/* <h4 className="mt-4 mb-3 w-100 d-flex align-items-center justify-content-between">
              <b>Service Provider profile details</b>
              <span className="d-flex align-items-center">
                {showPrevIcon && (
                  <BiArrowBack
                    className="closeFormBtn me-2"
                    size={18}
                    onClick={() => {
                      setCompleted(0);
                      setShowPrevIcon(false);
                    }}
                  />
                )}
                <AiOutlineClose
                  onClick={() => {
                    closeForm();
                  }}
                  className="closeFormBtn"
                  size={18}
                />
              </span>
            </h4> */}

            <ProgressBar1 completed={completed} />
            {completed === 0 ? (
              <SPRequiredDetails submitDetails={nextDetails} />
            ) : (
              <SPAdditionalDetails closeForm={closeUpdateForm} />
            )}
          {/* </span> */}
        {/* </div> */}
        {/* <div
          className="col-sm-6 p-0"
          id="loginPicArea"
          style={{
            backgroundPosition: "right",
            backgroundSize: "contain",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div id="auth_gradient"></div> */}
      {/* </div> */}
     </>
  );
}

export default SPProfileDetails;
