import { useState, useRef, useEffect } from "react";
import ShikoThumbnail from "../../../pics/shiko_thumbnail.PNG";
import { MdStar } from "react-icons/md";
import "../../../styles/Course/Courses.css";
import CoursePlaylistContainer from "./CoursePlaylistContainer";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import { BiPlayCircle, BiVideoRecording } from "react-icons/bi";
import { AiOutlineInfoCircle, AiOutlineClockCircle } from "react-icons/ai";
import { BiInfoCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import moment, { HTML5_FMT } from "moment";
import Spinner1 from "../../../components/spinners/Spinner1";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCourse,
  setCurrentUnits,
  getAssessments,
} from "../../../features/slices/CourseSlice";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import { Box, Grid, useMediaQuery, Container, Chip, IconButton, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';

function CoursePreview({
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuth } = useSelector((state) => state.auth);
  const [showMore, setShowMore] = useState(false);
  const downRef = useRef(null);
  const { courses, loading } = useSelector((state) => state.courses);
  const [units, setUnits] = useState([]);
 
  const handleViewCourse = () => {
    if (!isAuth) return navigate("/dashboard");
    // dispatch(getAssessments(course._id));
    // dispatch(setCurrentCourse(course));
    dispatch(setCurrentUnits(units));
    navigate("/dashboard/courses/course-content");
  };

  // useEffect(() => {
  //   if (user.firstName) {
  //     getCourseUnits();
  //   }
  // }, []);

  // const getCourseUnits = async () => {
  //   const unitsResponse = await axiosWrapper({
  //     method: "get",
  //     url: `courses/units?course=${course._id}`,
  //   });

  //   setUnits(unitsResponse.data.data.rows);
  // };
  return (
    <>
      
        <Card sx={{ maxWidth: 340, borderRadius: "0.7rem", cursor: "pointer", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} onClick={() => handleViewCourse(true)}>
          <CardHeader
            avatar={
              <Avatar src='logo.png' aria-label="moh" />
            }
            action={
              <Chip label="Paid course" size="small" />
            }
            title="Mentorship 101"
            subheader={<Typography variant="caption" color="text.secondary" >

              by Madam Shiko
            </Typography>}
          />
          <CardMedia
            component="img"
            height="180"
            image="https://res.cloudinary.com/de3eoq2qb/image/upload/v1674036258/shiko_thumbnail_txfd2s.png"
            alt="thumbnail"
          />
          <CardContent>
            <Typography variant="caption" color="text.secondary">
              {/* {moment(course.createdAt).fromNow()} */}
              5 months ago
            </Typography>
            <Typography variant="body2" color="text.primary">
              This course quips you with the necessary skills to work as a maids of honour house manager ...
            </Typography>
          </CardContent>
        </Card >
      
    </>
  );
}

export default CoursePreview;
