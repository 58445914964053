import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';
import Container from '@mui/material/Container';
// import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tiers = [
    {
        title: 'Service Providers',
        subheader: 'Registration Fee',
        price: '500',
        description: [
            "Online Assessment & Certification",
            "Access to Mentorship videos",
            "Downloadable working tools",
            'Access Sacco Savings',
            "Access to Community",
            'Monthly Newsletter',
           
        ],
        buttonText: 'Register',
        buttonVariant: 'outlined',
    },
    {
        title: 'Clients',
        subheader: 'Service Fee',
        price: '1,000',
        description: [
            'Registration and Profiling of domestic staff',
            "Access to Service Provider Profile",
            "Rate & Vet Service Providers",
            "Access to Community",
            'Monthly Newsletter',
            "Customer Support",
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
];

const service = [
    {
        title: 'Basic Plan',
        subheader: 'Registration Fee',
        price: '250',
        description: [
            "Online Assessment & Certification",
            "Access to Mentorship videos",
            "Downloadable working tools",
            "Access to Community",
            'Monthly Newsletter',
        ],
        buttonText: 'Sign up Today',
        buttonVariant: 'outlined',
    },
    {
        title: 'Premium Plan',
        subheader: 'Service Fee',
        price: '500',
        description: [
            "Online Assessment & Certification",
            "Access to Mentorship videos",
            "Downloadable working tools",
            'Access Sacco Savings',
            "Access to Community",
            'Monthly Newsletter',
            'Agency placement',
            "Customer Support",
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
];



export default function Pricing() {
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    // const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1, py: 2, px: 2 }}>
            {/* pricing */}
            <Container disableGutters maxWidth="md" component="main" sx={{ mb: 4, mt: 6 }}>
                <Typography
                    variant="h4"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    color="text.primary"
                    gutterBottom
                >
                    Our Plans
                </Typography>
                <Typography variant="h6" align="center" sx={{ color: "grey" }} component="p">
                    Choose a subscription plan that best suits your needs
                </Typography>
            </Container>
            <Box sx={{ width: '100%' }}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                    {/* <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="For Clients" {...a11yProps(0)} />
                        <Tab label="For Service Providers" {...a11yProps(1)} />
                    </Tabs> */}
                {/* </Box> */}
                {/* <CustomTabPanel value={value} index={0}> */}
                <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'Enterprise' ? 12 : 6}
                            md={6}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h4" variant="h4" color="text.primary">
                                            Ksh. {tier.price}
                                        </Typography>
                                        {/* <Typography variant="h6" color="text.secondary">
                                            /mo
                                        </Typography> */}
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                sx={{ fontWeight: "bold", color: "grey" }}
                                                variant="subtitle1"
                                                align="center"
                                                key={line}
                                            >
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        onClick={() => navigate(tier.title === "Enterprise" ? "/contact" : '/register')}
                                        variant={tier.buttonVariant}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
                {/* </CustomTabPanel>
                <CustomTabPanel value={value} index={1}> */}
                
            {/* <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {service.map((tier) => (
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'Enterprise' ? 12 : 6}
                            md={6}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h4" variant="h4" color="text.primary">
                                            Ksh. {tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /mo
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                sx={{ fontWeight: "bold", color: "grey" }}
                                                variant="subtitle1"
                                                align="center"
                                                key={line}
                                            >
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        onClick={() => navigate(tier.title === "Enterprise" ? "/contact" : '/register')}
                                        variant={tier.buttonVariant}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            </CustomTabPanel> */}
            </Box>
            {/* <Footer /> */}
        </Box>
    );
}