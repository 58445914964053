import React from "react";
import "../../../styles/Course/Courses.css";
import { BiPlayCircle } from "react-icons/bi";
function CoursePlaylistContainer({
  index,
  title,
  duration,
  currentlyPlaying,
  playingLink,
  setCurrentlyPlaying,
  showQuiz,
  setShowQuiz,
}) {
  return (
    <div
      className={`coursePlaylistContent d-flex align-items-center justify-content-between fontsm m-2 ${
        currentlyPlaying === playingLink && !showQuiz
          ? "text-success fw-bold"
          : "text-dark"
      }`}
      onClick={() => {
        setShowQuiz(0, false);
        setCurrentlyPlaying(index, playingLink);
      }}
    >
      <div className="d-flex align-items-center fontsm">
        {currentlyPlaying && (
          <BiPlayCircle className="playlistPlayButton" size={25} />
        )}
        <div className="ms-2 me-2" style={{ cursor: "pointer" }}>
          <span className="me-1 fontsm">{index}.</span>
          <span className="fontsm">{title}</span>
        </div>
      </div>
      <span className="fontsm1">{duration}</span>
    </div>
  );
}

export default CoursePlaylistContainer;
