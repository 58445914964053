import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
function ChatItem({ chat }) {
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    console.log("chat id");
    console.log(chat);
  }, []);

  return (
    <div
      id="chatItemContainer"
      className={`d-flex w-100 ps-3 pe-3 pt-1 pb-1  ${chat.initiator === user.sub || user._id
          ? "justify-content-end"
          : "justify-content-start"
        }`}
    >
      <div
        style={{ maxWidth: "60%" }}
        className={`d-flex flex-column ${chat.initiator === user.sub || user._id
            ? "align-items-end"
            : "align-items-start"
          }`}
      >
        <div
          className="chat pt-2 pb-2 ps-3 pe-3 fontsm1"
          style={{
            borderRadius: "20px",
            backgroundColor: `${chat.initiator === user.sub || user._id ? "#b7edcb" : "#E91E63"
              }`,
          }}
        >
          {chat.message}
        </div>
        <span
          className={`lightDark fontsm2 text-light mt-1 bg-success${chat.initiator === user.sub || user._id ? "me-1" : "ms-1"
            }`}
        >
          {moment(chat.ISOString).format("hh:mm a")}
        </span>
      </div>
    </div>
  );
}

export default ChatItem;
