import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../slices/AuthSlice";
import ColorReducer from "../slices/ColorSlice";
import GlobalsReducer from "../slices/GlobalsSlice";
import DashboardControlReducer from "../slices/DashboardControlSlice";
import CourseReducer from "../slices/CourseSlice";
import MessageReducer from "../slices/MessagesSlice";
import BookingReducer from "../slices/BookingSlice";
import PaymentReducer from "../slices/PaymentSlice";
import ServiceReducer from "../slices/ServiceSlice";
import EmployerReducer from "../slices/EmployerSlice";
import ReferralReducer from "../slices/ReferralSlice";
import SubscriptionReducer from "../slices/SubscriptionSlice";
import NotificationsReducer from "../slices/NotificationsSlice";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    color: ColorReducer,
    globals: GlobalsReducer,
    dashboardControl: DashboardControlReducer,
    courses: CourseReducer,
    messages: MessageReducer,
    booking: BookingReducer,
    payment: PaymentReducer,
    services: ServiceReducer,
    employer: EmployerReducer,
    referral: ReferralReducer,
    subscription: SubscriptionReducer,
    notifications: NotificationsReducer,
  },
});

export default store;
