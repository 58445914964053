import { useState, useRef, useEffect } from "react";
import "../../../styles/Dashboard/Messages.css";
import { MdSend, MdAttachFile } from "react-icons/md";
import ContractDetailsModal from "../../../components/Negotiation/ContractDetailsModal";
import { useSelector } from "react-redux";
function SendMessage({ sendText, showContract, socket }) {
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const attachFile = useRef();
  const recipient = useSelector((state) => state.messages.recipient);
  const handleSendMessage = async () => {
    if (message) {
      sendText(message);
      setMessage("");
    }
  };

  return (
    <div className="w-100 m-0 p-2" id="sendMessage">
      <ContractDetailsModal showContract={showContract} socket={socket} />

      <div className="p-2 d-flex align-items-center  mx-auto p-0 justify-content-end">
        <span id="inputContainer" className="w-100">
          <input
            id="messageInput"
            className="w-100  pt-2 pb-2 ps-4 pe-4"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && message) {
                handleSendMessage();
              }
            }}
            autoComplete={"off"}
          />
          <input
            type={"file"}
            name="attachment"
            style={{ display: "none" }}
            ref={attachFile}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
          <MdAttachFile
            id="attachIcon"
            size={20}
            onClick={() => {
              attachFile?.current?.click();
            }}
          />
        </span>
        <span
          id="sendMessageBtn"
          className={`p-2 ms-2 ${
            message ? "bg-success" : "bg-secondary"
          }  d-flex rounded-circle align-items-center justify-content-center `}
          onClick={handleSendMessage}
          style={{ cursor: `${message ? "pointer" : "default"}` }}
        >
          <MdSend size={22} className="m-1" id="sendIcon" color="white" />
        </span>
      </div>
    </div>
  );
}

export default SendMessage;
