import { useState, useEffect } from "react";
import "../../styles/Course/Courses.css";
import { BsSearch, BsChevronDown } from "react-icons/bs";

import CoursePreview from "./CourseContent/CoursePreview";
import { getCourses } from "../../features/slices/CourseSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../../components/spinners/Spinner1";
import DashboardNav from "../Dashboard/DashboardNav";
import DashboardFooter from "../Dashboard/DashboardFooter";
import DashboardLayout from "../Dashboard/DashboardLayout";

function Courses({ selectTab }) {
  const dispatch = useDispatch();
  const { courses, loading } = useSelector((state) => state.courses);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    dispatch(getCourses(offset));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        className="m-0 pt-5 "
        style={{
          backgroundColor: "#f8f9fa",
          minHeight: "100vh",
          paddingTop: "10vh",
        }}
      >
        <div className="row m-0 pt-3 d-flex justify-content-center">
          <div className="col-sm-9 d-flex flex-column mb-1 p-2">
            {/* <h6 className="about-head mt-4">Our courses</h6> */}
            {/* <h4 className="mt-2 mb-1">Want to learn something new?</h4> */}
            <span className="mb-1">OUR COURSES</span>
            <span id="line" className="mt-2 mb-1"></span>
            {/* <span className="text mt-4 mb-2">
              Our courses help you upskill and hopefully earn more by providing
              greater value to you employer.
            </span> */}
          </div>
        </div>
        <div className="row m-0   d-flex justify-content-center">
          <div className="col-sm-8 d-flex flex-column mb-2 p-2">
            <div className="w-100 mt-1" id="searchDiv">
              <input
                className="form-control ps-4 pt-2 pb-2 pe-4 mb-2"
                placeholder="Search"
                id="searchInput"
              />
              <BsSearch className="searchSPIcon" size={20} color={"#ced4da"} />
            </div>
            <div className="w-100 mt-2 mb-3 d-flex flex-wrap justify-content-around">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Category
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Action</span>

                  <span className="dropdown-item p-2">Action 1</span>

                  <span className="dropdown-item p-2">Action 2</span>
                </ul>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Duration
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Action</span>

                  <span className="dropdown-item p-2">Action 1</span>

                  <span className="dropdown-item p-2">Action 2</span>
                </ul>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Ratings
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Action</span>

                  <span className="dropdown-item p-2">Action 1</span>

                  <span className="dropdown-item p-2">Action 2</span>
                </ul>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Language
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Action</span>

                  <span className="dropdown-item p-2">Action 1</span>

                  <span className="dropdown-item p-2">Action 2</span>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-8  mb-5 p-1 row m-0">
            {!loading ? (
              <>
                {courses &&
                  courses.map((course, index) => (
                    <div className="col-sm-4 mb-3">
                      <CoursePreview
                        key={index}
                        index={index}
                        course={course}
                        selectTab={selectTab}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <div style={{ position: "relative", minHeight: "30vh" }}>
                <Spinner1 message={"Fetching courses..."} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;
