import { useState, useRef, useEffect } from "react";
import "../../../styles/Dashboard/Dashboard.css";
import VideoJS from "../../vd/VideoJS";
import staffOne from "../../../pics/staffOne.png";
import DashboardBanner from "../DashboardBanner";
import { useSelector } from "react-redux";
import TrendingCourses from "../../Courses/TrendingCourses";
import ProfileCardSwiper from "../../profile/sp/ProfileCardSwiper";
import SPStats from "./SPStats";
function ServiceProviderDashboard({ setTab }) {
  const { user, isAuth } = useSelector((state) => state.auth);
  const colorSchema = useSelector((state) => state.color.scheme);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="row m-0 profileCont h-100 p-0">
      {/* <DashboardBanner setTab={setTab} /> */}
      <SPStats />
      <TrendingCourses selectTab={setTab} />
    </div>
  );
}

export default ServiceProviderDashboard;
