import { useState, useEffect } from "react";
import "../../styles/Dashboard/Messages.css";
import MessageItem from "./MessageItem";
import { BsSearch } from "react-icons/bs";
import { BiExpand } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { closeTabs } from "../../features/slices/DashboardControlSlice";
import {
  fetchChats,
  clearRecipient,
} from "../../features/slices/MessagesSlice";
import { useDispatch, useSelector } from "react-redux";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index.js";

import { useNavigate } from "react-router-dom";
function Messages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const {
    loading: messagesLoading,
    data: allMessages,
    error: messagesError,
  } = useSelector((state) => state.messages.chats);

  const [messages, setMessages] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchChats(user.sub || user._id || user.id));
  //   return () => {};
  // }, []);

  return (
    <>
      <div className="w-100  p-3 rounded m-0">
        <span className="d-flex align-items-center justify-content-between expandIcon">
          <h5 className="m-0 p-0">
            <b>Chats</b>
          </h5>
          <span className="d-flex">
            <FiEdit className="me-3" />
            <BiExpand className="me-3" />
            <AiOutlineClose
              onClick={() => {
                dispatch(closeTabs());
              }}
            />
          </span>
        </span>
        <div className="w-100 mt-3" id="searchDiv">
          <input
            className="form-control ps-5 pt-2 pe-4"
            style={{ backgroundColor: "#f0f2f5" }}
            placeholder="Search"
            id="searchInput"
          />
          <BsSearch className="searchIcon" size={20} color={"#3d4650"} />
        </div>
      </div>
      {messagesError ? (
        <div className="pt-2 ps-3 pe-3">
          <div className="text-center mx-auto fontsm1  alert alert-danger m-2">
            Currently unable to to fetch your messages
          </div>
        </div>
      ) : (
        <>
          {messagesLoading ? (
            <div className="w-100 p-2 d-flex justify-content-center">
              <div className="text-primary spinner-border spinner-border-sm "></div>
            </div>
          ) : (
            <>
              {allMessages.length > 0 ? (
                <div className="messagesScrollContainer">
                  {allMessages &&
                    allMessages.map((message, index) => (
                      <MessageItem key={index} message={message} />
                    ))}
                </div>
              ) : (
                <div className="pt-2 ps-3 pe-3">
                  <div className="text-center mx-auto fontsm1  alert alert-warning m-2">
                    You have no messages currently
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Messages;
