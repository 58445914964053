import { useState, useEffect } from "react";
import OTP1 from "../../pics/otp1.gif";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function VerifyPhone() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backendUrl = useSelector((state) => state.globals.backendUrl);
  const [employer, setEmployer] = useState("");

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const colorSchema = useSelector((state) => state.color.scheme);

  const [expiryCounter, setExpiryCounter] = useState(300);

  let initOTPsent = false;

  const handleVerify = async () => {
    if (!otp) {
      setOtpError("Enter otp");
    } else {
      console.log("Now sending OTP");
      setOtpError("");
      setIsUpdateLoading(true);
      try {
        const updateResponse = await axios({
          method: "post",
          url: backendUrl + `auth/otp/sms/verify`,
          data: {
            guess: otp,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("otpToken"),
          },
        });
        // setIsUpdateLoading(false);
        console.log("updateResponse");
        console.log(updateResponse);

        if (updateResponse.data.success) {
          //successfully checkted otp
        }
      } catch (error) {
        setIsUpdateLoading(false);
        console.log("otp error");
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  // const sendOTP = async () => {
  //   const recipients = {
  //     recipients: [employer.phone],
  //   };
  //   // if (isResending) {
  //   setResendLoading(true);

  //   const otpResponse = await axios({
  //     method: "post",
  //     url: "auth/otp/sms",
  //     data: recipients,
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("refreshToken"),
  //     },
  //   });
  //   setResendLoading(false);
  //   console.log("otpResponse from sending otp");
  //   console.log(otpResponse.data);
  //   if (otpResponse.data.success) {
  //     localStorage.setItem("otpToken", otpResponse.data.data.otpToken);
  //     toast.success("Kindly check your phone for the OTP");
  //     setExpiryCounter(300);
  //   } else {
  //     toast.error("OTP could not be send, try again later");
  //   }
  //   // }
  // };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  useEffect(() => {
    setTimeout(() => {
      if (expiryCounter > 0) {
        setExpiryCounter(expiryCounter - 1);
      }
    }, 1000);
  }, [expiryCounter]);

  return (
    <div className="d-flex flex-direction-column m-0 justify-content-center align-items-center p-3 row">
      {/* {isUpdateLoading && <Spinner1 message={"please wait..."} />} */}
      <div className="col-sm-4 row m-0 mx-auto d-flex flex-column align-items-center justify-content-center mt-3">
        <h4 className="text-center mt-5">
          <b>OTP sent</b>
        </h4>
        <span className="text-center mt-2">
          We have sent a One Time Password to your phone
        </span>

        <img alt="phone" className="w-50 m-3" src={OTP1} />

        <input
          type={"text"}
          className="form-control text-center mt-3"
          placeholder="Enter OTP here"
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
        />
        <div className="d-flex w-100 justify-content-center mt-3">
          <button
            type="submit"
            onClick={handleVerify}
            className="btn_submit mt-2 p-2 text-light rounded d-flex align-items-center justify-content-center"
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            {!isUpdateLoading ? (
              "Verify"
            ) : (
              <>
                Verifying...
                <div
                  className="spinner-border text-light ms-3 spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            )}
          </button>
        </div>
        {expiryCounter === 0 ? (
          <div className="d-flex w-100 justify-content-center mt-3">
            <span
              className="text-primary m-3 mx-auto"
              style={{ cursor: "pointer" }}
              // onClick={sendOTP}
            >
              {resendLoading ? "Sending OTP..." : "Resend OTP"}
            </span>
          </div>
        ) : (
          <div className="d-flex w-100 justify-content-center">
            <span className="text-dark m-3 mx-auto">
              <small>
                You can resend after <b> {fancyTimeFormat(expiryCounter)}</b>
              </small>
            </span>
          </div>
        )}

        <div className="d-flex w-100 justify-content-center mt-3">
          <span className="text-danger">{otpError}</span>
        </div>
      </div>
    </div>
  );
}

export default VerifyPhone;
