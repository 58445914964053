import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSubscribed: localStorage.getItem("isSubscribed"),
  subscription: JSON.parse(localStorage.getItem("subscription")) || [],
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      state.subscription = action.payload;
      localStorage.setItem("subscription", JSON.stringify(action.payload));
      if (action.payload.count > 0) {
        state.isSubscribed = true;
        localStorage.setItem("isSubscribed", true);
      } else {
        state.isSubscribed = false;
        localStorage.setItem("isSubscribed", false);
      }
    },
  },
});

export const { setSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
