import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../../pics/avatar.png";
import { AiFillStar } from "react-icons/ai";
import "../../../styles/Profile.css";
import moment from "moment";
import { FaBriefcase } from "react-icons/fa";
import RequestFormModal from "../../Dashboard/serviceProvider/Requests/RequestFormModal";
import Select from "react-select";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import { calculateUserRating } from "../../../Utils/userStats";
import PreviousRatings from "../../../components/Rating/PreviousRatings";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdVerified } from "react-icons/md";
function MaxProfile({ user, handleShowRequestForm }) {
  const [prevEmployer, setPrevEmployer] = useState("");
  const piRef = useRef(null);
  const bioRef = useRef(null);
  const [prevEmployerLoading, setPrevEmployerLoading] = useState(true);
  const [userRating, setUserRating] = useState("wait");
  const colorSchema = useSelector((state) => state.color.scheme);
  const [showPreviousRatings, setShowPreviousRatings] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const fetchPrevEmployerDetails = async () => {
    const employersData = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${user.sub || user._id}`,
    });

    const prevEmployerData = employersData.data.data.rows[0];

    setPrevEmployer(prevEmployerData);
    console.log("employer");
    console.log(prevEmployerData);
    setPrevEmployerLoading(false);
  };

  const fetchRating = async () => {
    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${user.sub || user._id || user.id}`,
    });

    let rating = calculateUserRating({
      ...user,
      rating: getReviews.data.data.rows,
    });

    setUserRating(`${rating}(${getReviews.data.data?.rows?.length})`);
  };

  const checkIfVerified = async () => {
    const employersData = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${user._id}`,
    });
    const prevEmployerData = employersData.data.data.rows[0];

    //checking employer answers
    const prevEmployerAnswers = await axiosWrapper({
      method: "get",
      url: `referrals/employers/answers?employer=${prevEmployerData._id}`,
      data: "",
    });

    if (prevEmployerAnswers?.data?.data?.count > 0) {
      setIsVerified(true);
    }
  };

  useEffect(() => {
    piRef.current.click();
    bioRef.current.click();
    if (user?.yearsOfExperience > 0) {
      checkIfVerified();
    }
    fetchPrevEmployerDetails();
    fetchRating();
  }, []);

  return (
    <>
      <div
        id="maxProfileModalContainer"
        className="col-sm-10 mx-auto bg-white p-3 rounded row "
        style={{
          overflowY: "scroll",
          maxHeight: "95vh",
          position: "relative",
        }}
      >
        <div className="col-sm-6 d-flex flex-column">
          <img
            className=" maxProfileAvatarPic rounded"
            src={user.avatar ? user.avatar : Avatar}
            alt={"Profilephoto"}
          />
          <div className="mt-4 mb-3" id="maxProfileBio1">
            {user?.bio && (
              <span className="fontsm lightDark ms-0 ps-0">
                <p className="text-dark mt-1 mb-2">
                  <b>Bio</b>
                </p>
                {user?.bio}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-6 p-3">
          <div className="d-flex justify-content-between align-items-start p-2">
            <div className="d-flex flex-column lightText w-100">
              <span className="d-flex align-items-center justify-content-between  w-100 flex-wrap m-0">
                <h3 className="d-flex align-items-center">
                  <b>
                    {user.firstName} {user.lastName}
                  </b>
                  {isVerified && (
                    <OverlayTrigger
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-verified`} className="fontsm">
                          user is verified
                        </Tooltip>
                      }
                    >
                      <span>
                        <MdVerified className="text-primary ms-2" size={25} />
                      </span>
                    </OverlayTrigger>
                  )}
                </h3>

                <button
                  type="button"
                  className="text-light rounded d-flex align-items-center   pt-1 pb-1 ps-4 pe-4 hoverDownEffect fontsm"
                  style={{
                    backgroundColor: `${colorSchema?.primary}`,
                    border: "none",
                  }}
                  onClick={() => {
                    handleShowRequestForm({
                      ...user,
                      prevEmployer,
                    });
                  }}
                >
                  Hire
                  <FaBriefcase className="ms-1" />
                </button>
              </span>
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  <small>
                    <AiFillStar className="me-1 text-warning" />
                    {userRating === "wait" ? (
                      <div className="spinner-border spinner spinner-border-sm text-success me-1"></div>
                    ) : (
                      userRating
                    )}{" "}
                    ratings
                  </small>
                </span>
                <span
                  type="button"
                  className=" fontsm  "
                  data-bs-toggle="modal"
                  data-bs-target="#previousRatingsModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPreviousRatings(true);
                  }}
                >
                  see all ratings
                </span>
              </div>
            </div>
          </div>

          <div class="accordion p-0" id="accordionExample">
            <div class="accordion-item">
              <span class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="fontsm mb-1">
                    <b>Personal Information</b>
                  </span>
                </button>
              </span>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body ">
                  <div className="d-flex flex-column p-0 m-0">
                    <span className="fontsm1 mt-2">
                      <b>Location: </b>
                      {user.placeOfResidence}
                    </span>
                    <span className="fontsm1 mt-2">
                      <b>Age: </b>
                      {new Date().getFullYear() -
                        new Date(user.dob).getFullYear()}{" "}
                      years
                    </span>
                    <span className="fontsm1 mt-2">
                      <b>Gender: </b>
                      {user.gender}
                    </span>
                    {/* <span className="fontsm1 mt-2">
                      <b>ID number: </b>
                      {user.idNo}
                    </span> */}
                    <span className="fontsm1 mt-2">
                      <b>Level of education: </b>
                      {user.educationLevel}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="accordion p-0 mt-2"
            id="maxProfileBio2"
            style={{ display: "none" }}
          >
            <div class="accordion-item">
              <span class="accordion-header" id="bioHeading">
                <button
                  ref={bioRef}
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#bioCollapse"
                  aria-expanded="true"
                  aria-controls="bioCollapse"
                >
                  <span className="fontsm mb-1">
                    <b>My bio</b>
                  </span>
                </button>
              </span>
              <div
                id="bioCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="bioHeading"
                data-bs-parent="#maxProfileBio2"
              >
                <div class="accordion-body ">
                  {user?.bio && (
                    <span className="fontsm lightDark ms-0 ps-0">
                      <p className="text-dark mt-1 mb-2">
                        <b>Bio</b>
                      </p>
                      {user?.bio}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="accordion p-0 mt-2" id="accordionExample">
            <div class="accordion-item">
              <span class="accordion-header" id="headingOne">
                <button
                  ref={piRef}
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#professionalCollapse"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="fontsm mb-1">
                    <b>Professional Information</b>
                  </span>
                </button>
              </span>
              <div
                id="professionalCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body ">
                  <div className="d-flex flex-column p-2 mt-1">
                    <span className="fontsm1 ">
                      <b>Professional training: </b>
                      {user.trainingDetails || "None"}
                    </span>
                    <span className="fontsm1 mt-2">
                      <b>Years of Experience: </b>
                      {user.yearsOfExperience}
                    </span>

                    <span className="fontsm1 mt-2">
                      <b>Job type: </b>
                      {user.workArrangement}
                    </span>
                    {user.yearsOfExperience > 0 && (
                      <>
                        <span className="fontsm1 mt-2">
                          <b>Last salary: </b>
                          {prevEmployerLoading ? (
                            <div className="spinner spinner-grow spinner-grow-sm text-success ms-1"></div>
                          ) : (
                            <span>{prevEmployer?.prevSalary}</span>
                          )}
                        </span>
                        <span className="fontsm1 mt-2">
                          <b>Previous job description: </b>
                          {prevEmployerLoading ? (
                            <div className="spinner spinner-grow spinner-grow-sm text-success ms-1"></div>
                          ) : (
                            <span>{prevEmployer?.prevJobDescription}</span>
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-dark fontsm mt-4">
            <b>Skilled In: </b>
          </div>
          <div className="d-flex flex-wrap lightDark fontsm">
            {user.skills?.map((skill, index) => (
              <div
                key={index}
                className="badge me-2 mt-1 p-2 rounded-pill "
                style={{ backgroundColor: `#242b51` }}
              >
                {skill?.title}
              </div>
            ))}
          </div>
        </div>
        <PreviousRatings
          provider={user.sub || user._id || user.id}
          showModal={showPreviousRatings}
          handleClose={() => {
            setShowPreviousRatings(false);
          }}
        />
      </div>
    </>
  );
}

export default MaxProfile;
