import { useState, useEffect, useRef } from "react";
import "../../../styles/Dashboard/Hire.css";
import { BsSearch, BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import MinProfile from "../../profile/sp/MinProfile";
import { useNavigate } from "react-router-dom";
import SPProfileSlider from "../../profile/sp/SPProfileSlider";
import ProfileCard from "../../profile/sp/ProfileCard";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import Spinner1 from "../../../components/spinners/Spinner1";
import ReactPaginate from "react-paginate";
import DashboardLayout from "../DashboardLayout";
import Select from "react-select";
import { fetchServicesAutocomplete } from "../../../features/slices/ServiceSlice";
import RequestFormModal from "../serviceProvider/Requests/RequestFormModal";
import ClientSubscriptionModal from "../../../components/Home/ClientSubscriptionModal";
import { Box, Grid, useMediaQuery, Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function Hire() {
  //subscription
  const { isSubscribed } = useSelector((state) => state.subscription);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const colorSchema = useSelector((state) => state.color.scheme);
  const navigate = useNavigate();
  const [showMaxProfileSlider, setShowMaxProfileSlider] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [maxSwiperCurrentIndex, setMaxSwiperCurrentIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const [profiles, setProfiles] = useState([]);
  const [profilesLoading, setProfilesLoading] = useState(false);
  const [searchOption, setSearchOption] = useState("");
  const [profilesCount, setProfilesCount] = useState(0);

  const [searchLimit, setSearchLimit] = useState(8);
  const [searchOffset, setSearchOffset] = useState(0);

  const [currentItems, setCurrentItems] = useState([]);

  const [searchBy, setSearchBy] = useState("service");
  const [searchParam, setSearchParam] = useState("");

  const [showRequestForm, setShowRequestForm] = useState(false);

  const [currentSp, setCurrentSp] = useState("");

  //for services
  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  const { autoComplete, loading: servicesLoading } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(fetchServicesAutocomplete());
    fetchServiceProviders();
    return () => { };
  }, [searchOffset, searchParam]);

  const fetchServiceProviders = async () => {
    setProfilesLoading(true);
    const response = await axiosWrapper({
      method: "get",
      // url: `users?role=serviceProvider`,
      url: `users?role=serviceProvider&limit=${searchLimit}&offset=${searchOffset}${searchParam && `&skills=${searchParam}`
        }`,
      data: "",
    });

    setProfiles(response.data.data.rows);
    console.log("esipiss", response.data.data.rows);
    console.log(response.data.data.rows);
    setCurrentItems(response.data.data.rows);
    setProfilesCount(response.data.data.count);
    console.log("providers data");
    console.log(response.data);
    setProfilesLoading(false);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const showCurrentProfile = (sub, inx) => {
    const spProfile = profiles.find((sp) => sp._id === sub);

    const index = profiles.indexOf(spProfile);

    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
    if (!isSubscribed || isSubscribed === "false") {
      //  setShowPaymentModal(false);
       setShowMaxProfileSlider(true);
       setMaxSwiperCurrentIndex(inx);
    } else {
      setMaxSwiperCurrentIndex(inx);
      setShowMaxProfileSlider(true);
    }
    document.body.style.overflow = "hidden";
  };

  //PAGINATION
  const [itemsPerPage, setItemsPerPage] = useState(searchLimit);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(searchOffset);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = profiles.slice(itemOffset, endOffset);
  // setCurrentItems(profiles.slice(itemOffset, endOffset));
  const pageCount = Math.ceil(profilesCount / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("hello", event);
    const newOffset = (event.selected * itemsPerPage) % profilesCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log("new offset", newOffset);
    setSearchOffset(newOffset);
    setItemOffset(newOffset);
  };

  const handleShowRequestForm = async (user) => {
    setCurrentSp(user);
    setShowMaxProfileSlider(false);
    setShowRequestForm(true);
    // console.log("showing request for ");
    // console.log(user);
  };

  return (
    // <DashboardLayout>
      <>
        <div
          style={{ backgroundColor: "#F4F7FE", height: "100%" }}
        >
          <Container maxWidth="lg" component="main"  >
            <div className="col-sm-12  mx-auto d-flex flex-column mt-2 mb-2 p-2 pt-2">
              {/* <h6 className="hire-head mt-4">OUR SERVICE PROVIDERS</h6> */}
              <span className="mb-1">OUR SERVICE PROVIDERS</span>

              <span id="line" className="mt-2 mb-1"></span>
              {/* <span className="text mt-4 mb-2">
              Our services helps you live smarter, giving you time to focus on
              what's most important. Our skilled professionals go above and
              beyond on every job.
            </span> */}
              <div
                className="w-100 mt-3  border bg-white row m-0 p-0"
                id="searchDiv"
                style={{ borderRadius: "20px" }}
              >
                <div
                  className="col-2 d-flex align-items-center  border-right pt-1 pb-1 ps-2 "
                  style={{
                    borderRight: "1px solid #dde1e5",
                    backgroundColor: "#e9eced",
                    borderBottomLeftRadius: "20px",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  {/* <select
                name="searchOption"
                id="selectOption"
                className="   outline-none"
                style={{
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  outline: "none !important",
                }}
                onChange={(e) => {
                  setSearchOption(e.target.value);
                }}
              >
                <option
                  value={"name"}
                  className="p-4"
                  style={{ cursor: "pointer", padding: "15px !important" }}
                >
                  Name
                </option>
                <option
                  value="location"
                  className="p-2"
                  style={{ cursor: "pointer" }}
                >
                  Location
                </option>
              </select> */}
                  <button
                    className=" light-dark d-flex align-items-center justify-content-center"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="searchDropBtn"
                    style={{
                      fontSize: "14px",
                      outline: "none !important",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <span className=" " id="searchText">
                      {searchBy}
                    </span>
                    <BsChevronDown color="black" className="ms-3" />
                  </button>
                  {/* <ul className="dropdown-menu p-0">
                    <span
                      className="dropdown-item p-2 border-bottom fontsm"
                      onClick={() => setSearchBy("service")}
                    >
                      Service
                    </span> */}
                  {/* <span
                    className="dropdown-item p-2 border-bottom  fontsm"
                    onClick={() => setSearchBy("name")}
                  >
                    Name
                  </span>
                  <span
                    className="dropdown-item p-2 fontsm"
                    onClick={() => setSearchBy("location")}
                  >
                    Location
                  </span> */}
                  {/* </ul> */}
                </div>
                <div className="col-10 row m-0  p-1">
                  {searchBy !== "service" ? (
                    <input
                      className="form-control pt-2 pb-2 pe-4 border-none w-100 outline-none"
                      placeholder={`Serching by ${searchBy}...`}
                      id=""
                      style={{
                        border: "none",
                        outline: "none !imporant",
                      }}
                      onChange={(e) => setSearchParam(e.target.value)}
                      value={searchParam}
                    />
                  ) : (
                    <div style={{}} className=" col-10  p-0 m-0">
                      <Select
                        options={autoComplete}
                        placeholder={!isSmallScreen ? "What services are you looking for..." : "Type a service..."}
                        isLoading={servicesLoading}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "100% !important",
                            border: "none !important",
                          }),
                        }}
                        onChange={(e) => setSearchParam(e.value)}
                      />  
                      {/* <Autocomplete
                      options={autoComplete}
                      placeholder={"What service are you looking for?"}
                      sx={{
                        width: "100% !important",
                        border: "none !important", borderRadius: "10px"
                      }}
                      renderInput={(params) => <TextField {...params} label="What service are you looking for" onChange={(e) => setSearchParam(e.value)} />}
                    /> */}

                    </div>
                  )}

                  <div className="col-1 justify-content-end d-flex">
                    {/* <BsSearch
                    className={`searchSPIcon `}
                    style={{ cursor: `${searchParam ? "pointer" : ""}` }}
                    size={20}
                    color={searchParam ? "#298543" : "#ced4da"}
                  /> */}
                  </div>
                </div>
              </div>

              {/* <div className="w-100 mt-2 mb-3 d-flex flex-wrap justify-content-start">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Salary
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Ascending</span>
                  <span className="dropdown-item p-2">Descending</span>
                </ul>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Arrangement
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Live in</span>
                  <span className="dropdown-item p-2">Come and go</span>
                </ul>
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn light-dark rounded d-flex align-items-center justify-content-between "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "14px" }}
                  //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                >
                  Age group
                  <BsChevronDown color="black" />
                </button>
                <ul className="dropdown-menu p-0">
                  <span className="dropdown-item p-2">Live in</span>
                  <span className="dropdown-item p-2">Come and go</span>
                </ul>
              </div>
            </div> */}
              {!profilesLoading ? (
                <div className=" mt-4 pb-5 row d-flex justify-content-center ">
                  {currentItems.length > 0 ? (
                    <>
                      {currentItems &&
                        currentItems.map((profile, index) => (
                          <div
                            key={index}
                            className="col-sm-3 mx-auto d-flex justify content-center  pt-3 pb-3 ps-2 pe-2 mb-4"
                          >
                            <div
                              className="w-100 rounded bg-white pb-2 ms-1 me-1 shadow rounded  profileStatBox"
                              style={{ borderRadius: "20px" }}
                            >
                              <ProfileCard
                                index={index}
                                user={profile}
                                showProfile={showCurrentProfile}
                              />
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div
                      className="row m-0 w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "50vh" }}
                    >
                      <div className="m-2 alert col-sm-8  alert-warning p-2 fontsm text-center mx-auto">
                        No Service Providers found
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ position: "relative", minHeight: "60vh" }}>
                  <Spinner1 message={"Fetching service providers..."} />
                </div>
              )}
            </div>
          </Container>
          <div className="w-100  p-2 d-flex justify-content-center">
            <ReactPaginate
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="prev"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>

        {showPaymentModal && (
          <ClientSubscriptionModal
            closeModal={() => {
              setShowPaymentModal(false);
            }}
          />
        )}

        {showMaxProfileSlider && (
          <SPProfileSlider
            currentIndex={maxSwiperCurrentIndex}
            profiles={profiles}
            handleShowRequestForm={handleShowRequestForm}
            closeModal={() => {
              document.body.style.overflow = "visible";
              setShowMaxProfileSlider(false);
            }}
          />
        )}

        {showRequestForm && (
          <RequestFormModal
            sp={currentSp}
            closeRequestForm={() => {
              setShowRequestForm(false);
              document.body.style.overflow = "visible";
            }}
          />
        )}
      </>
    // </DashboardLayout>
  );
}

export default Hire;
