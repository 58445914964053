import { useEffect } from "react";
import Footer from "../components/Home/Footer";
import Nav from "../components/Home/Nav";
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import "../styles/Contact.css";

import { contactSchema } from "../Utils/validations/ContactValidations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

function ContactUs() {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactSchema) });

  const colorSchema = useSelector((state) => state.color.scheme);
  const sendMessage = async (data) => {
    console.log("Message sent");
    console.log(data);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Nav currentPage={"contact"} />
      <div
        className="row m-0 d-flex justify-content-center pt-5 pb-5 ps-2 pe-2"
        id="contact-container"
      >
        <div className="col-sm-4 d-flex flex-column mt-2 mb-4">
          <h6 className="contact-head">CONTACT US</h6>

          <h3>How can I help you?</h3>
          <span id="line" className="mt-2 mb-1"></span>
          <span className="text mt-2 mb-2">
            Our services helps you live smarter, giving you time to focus on
            what's most important. Our skilled professionals go above and beyond
            on every job.
          </span>
          <div className="infoDiv mt-4 p-2 rounded d-flex align-items-center">
            <a href="tel:+254795930905" className="call-btn">
              <BsTelephone size={15} className="ms-1 me-2" /> +254795930905
            </a>
          </div>
          <div className="infoDiv mt-4 p-2 rounded d-flex align-items-center">
            <a href="https://wa.me/+254795930905" className="call-btn">
              <BsWhatsapp size={15} className="ms-1 me-2" /> Text us on whatsapp
            </a>
          </div>
          <div className="infoDiv mt-4 p-2 rounded d-flex align-items-center">
            <a href="mailto:info@maidsofhonour.africa" className="call-btn">
              <BiEnvelope size={15} className="ms-1 me-2" />
              info@maidsofhonour.africa
            </a>
          </div>
          <div className="infoDiv mt-4 p-2 rounded d-flex align-items-center">
            <GrLocation size={15} className="ms-1 me-2" /> Afya Rekod Campus,
            Nairobi, Kenya
          </div>

          <h6 className="mt-4 mb-2 fw-bold">You can also find us on:</h6>
          <div className="d-flex mt-2">
            <span className="border p-2 rounded-circle me-4 border-dark socialMediaIcon">
              <a
                href="https://web.facebook.com/MaidsofHonour?_rdc=1&_rdr"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FaFacebook size={22} className="text-dark socialIcon" />
              </a>
            </span>
            <span className="border p-2 rounded-circle me-4 border-dark socialMediaIcon">
              <a
                href="https://www.instagram.com/maidsofhonour_a/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FaInstagram size={22} className="text-dark socialIcon" />
              </a>
            </span>
            <span className="border p-2 rounded-circle me-4 border-dark socialMediaIcon">
              <a
                href="https://www.linkedin.com/company/maids-of-honour-africa/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FaLinkedin size={22} className="text-dark socialIcon" />
              </a>
            </span>
            <span className="border p-2 rounded-circle me-4 border-dark socialMediaIcon">
              <a
                href="https://twitter.com/MaidsofHonour_A"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FaTwitter size={22} className="text-dark socialIcon" />
              </a>
            </span>
          </div>
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-5 mt-4 mb-4">
          <form
            onSubmit={handleSubmit((data) => console.log("Message sent",data))}
            id="contactForm"
            className="p-4 rounded d-flex flex-column "
          >
            <div className="form-group">
              <input
                type={"text"}
                name="fullName"
                placeholder="Full Name"
                className="form-controls p-3 mt-4 rounded"
                {...register("fullName")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.fullName?.message}
                  </span>
                </small>
              )}
            </div>
            <div className="form-group">
              <input
                type={"email"}
                name="email"
                placeholder="E-mail address"
                className="form-controls p-3 mt-4 rounded"
                {...register("email")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.email?.message}
                  </span>
                </small>
              )}
            </div>
            <div className="form-group">
              <input
                type={"text"}
                name="subject"
                placeholder="Subject"
                className="form-controls p-3 mt-4 rounded"
                {...register("subject")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.subject?.message}
                  </span>
                </small>
              )}
            </div>
            <div className="form-group">
              <textarea
                type={"text"}
                name="message"
                placeholder="Write us a message"
                className="form-controls p-3 mt-4 rounded"
                rows={4}
                {...register("message")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.message?.message}
                  </span>
                </small>
              )}
            </div>
            <input
              style={{ backgroundColor: `${colorSchema?.primary}` }}
              type={"submit"}
              name="submit"
              value={"submit"}
              className="submitBtn p-2 rounded mt-4"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
