import { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import "../../styles/Rating.css";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import moment from "moment";
import Avatar from "boring-avatars";

function Rating({ ratingData, index, count }) {
  const [loading, setLoading] = useState(false);
  const [employer, setEmployer] = useState();

  useEffect(() => {
    fetchUser();
    console.log("ratingData");
    console.log(ratingData);
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    const response = await axiosWrapper({
      method: "get",
      url: `referrals/employers/answers?employer=${ratingData?.reviewer}`,
    });

    setEmployer(response.data.data.rows[0]);
    setLoading(false);
  };
  return (
    <>
      {!loading ? (
        <div
          className={`w-100 d-flex flex-column fontsm mb-2 ${
            index !== count - 1 && "border-bottom"
          } pb-2  m-0`}
        >
          <div className="d-flex row align-items-center m-0">
            {/* <img src={Avatar} alt="user" className="col-2" /> */}
            <span className="col-1   me-3  ">
              <Avatar
                size={40}
                className="w-100"
                name={employer?.employerName}
                variant="marble"
                colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
              />
            </span>
            <div className="d-flex flex-column col-8">
              <span className="fontsm ">
                <b>{employer?.employerName}</b>
              </span>
              <div className="d-flex align-items-center">
                <ReactStars
                  count={5}
                  value={ratingData.rating}
                  isHalf={true}
                  activeColor="#ffd700"
                  size={20}
                />

                <span className="fontsm1 ms-4">
                  <b>{moment(ratingData?.createdAt).fromNow()}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="fontsm mt-1">{ratingData?.review}</div>
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center p-2">
          <div className="spinner spinner-border spinner-border-sm text-primary"></div>
        </div>
      )}
    </>
  );
}

export default Rating;
