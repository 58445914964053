import { useEffect, useState } from "react";
import Nav from "../../../components/Home/Nav";
import Footer from "../../../components/Home/Footer";
import "../../../styles/Services.css";

import { useSelector, useDispatch } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SPProcess from "./SPProcess";
import ClientProcess from "./ClientProcess";

function OurProcess() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const [activeTab, setActiveTab] = useState("sp");
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"services"} />

      <div
        id="about-container"
        className="d-flex flex-column align-items-center pt-3 pb-2 row m-0 w-100   ps-1 pe-2"
      >
        <div className="col-sm-9   ms-2  me-2 mx-auto    d-flex flex-column mt-4 mb-2 p-2">
          <h4 className="mt-2 mb-1">OUR PROCESS</h4>
          <span id="line" className="mt-2 mb-1"></span>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTab("sp")}
            >
              <span
                className={`nav-link ${
                  activeTab === "sp" ? "active fw-bold" : ""
                } text-black`}
                aria-current="page"
                href="#"
              >
                Service provider
              </span>
            </li>
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTab("client")}
            >
              <span
                className={`nav-link text-black ${
                  activeTab === "client" ? "active fw-bold" : ""
                }`}
                href="#"
              >
                Client
              </span>
            </li>
          </ul>
        </div>

        {activeTab === "sp" ? <SPProcess /> : <ClientProcess />}
      </div>

      <Footer />
    </>
  );
}

export default OurProcess;
