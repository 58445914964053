import { useState, useEffect } from "react";
import OTP1 from "../../../pics/otp1.gif";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import axios from "axios";
import {
  setUserFromSocialLogin,
  updateUserDetails,
} from "../../../features/slices/AuthSlice";
import { v4 as uuidv4 } from "uuid";
import Spinner1 from "../../../components/spinners/Spinner1";
function VerifyEmail() {
  const backendUrl = useSelector((state) => state.globals.backendUrl);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const accessToken = query.get("access");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isResendLoading, setIsResetLoading] = useState(false);
  const [emailError, setEmailError] = useState();
  const [email, setEmail] = useState(localStorage.getItem("resetEmail"));

  const colorSchema = useSelector((state) => state.color.scheme);

  let initOTPsent = false;
  let isSent = false;

  const handleVerify = async () => {
    const accessToken = query.get("access");

    try {
      const verificationResponse = await axios({
        method: "post",
        url: backendUrl + `auth/otp/email/verify`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      console.log("Email verification Response");
      console.log(verificationResponse);
      if (verificationResponse.data.success) {
        toast.success("Email verified!");
        navigate("/dashboard");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Verification error");
      console.log(error);
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleResend = async () => {
    const recipients = {
      recipients: [email],
      deviceId: uuidv4(),
    };

    if (!email) {
      setEmailError("Kindly enter your email");
    } else {
      try {
        setIsResetLoading(true);
        const resendResponse = await axios({
          method: "post",
          url: backendUrl + `auth/otp/email`,
          data: recipients,
        });
        setIsResetLoading(false);
        if (resendResponse.data.success) {
          toast.success("Success! Kindly check your email.");
        }
        console.log(resendResponse);
        console.log("resend response");
      } catch (error) {
        setIsResetLoading(false);
        console.log("Resend error");
        console.log(error);
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (!isSent) {
      handleVerify();
      isSent = true;
    }
  }, []);

  return (
    <div className="d-flex flex-direction-column m-0 justify-content-center align-items-center p-3 row">
      {isLoading ? (
        <Spinner1 message={"please wait..."} />
      ) : (
        <div className="col-sm-4 row m-0  p-5 mx-auto d-flex flex-column align-items-center justify-content-center mt-3">
          <center>
            <h5>
              <b>Your Email OTP expired!</b>
            </h5>
          </center>

          <div className="mb-4 mt-5">
            <input
              type="email"
              name="email"
              className="form_control mt-2 fontsm"
              id="exampleInputName"
              value={email}
              placeholder="Kindly enter your email address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <small>
              <center>
                <span className="mt-1 mb-3 text-danger">{emailError}</span>
              </center>
            </small>
          </div>
          <button
            type="submit"
            className="btn_submit mt-2 p-2 text-light rounded d-flex align-items-center justify-content-center"
            style={{ backgroundColor: `${colorSchema?.primary}` }}
            onClick={handleResend}
          >
            {!isResendLoading ? (
              "Resend Email verification"
            ) : (
              <>
                Resending...
                <div
                  className="spinner-border text-light ms-3 spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden"></span>
                </div>
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
