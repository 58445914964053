import { PaletteMode } from "@mui/material";
// import { Fira_Sans } from "next/font/google";

// export const roboto = Fira_Sans({
//   weight: ["300", "400", "500", "700"],
//   subsets: ["latin"],
//   display: "swap",
//   fallback: ["Helvetica", "Arial", "sans-serif"],
// });

export const getDesignTokens = (mode) => ({
  palette: {
    primary: { main: "#CF2680", secondary: "#15192E" },
    secondary: { main: "#000", secondary: "#e44977" },
    warning: { main: "#E54976" },
    contrastText: "#fff3ef",
    divider: "#aab4be",
    text: {
      primary: "#15192E",
      secondary: "#CF2680",
    },
    contrast: { main: "#CCF9FF" },
    background: {
      default: "#F4F7FE",
      paper: "#FFFF",
    }
  },
  typography: {
    fontFamily:  [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h6: {
      fontSize: "15pt",
    },
    subtitle1: {
      fontSize: "12pt",
    },
    body1: {
      fontSize: "10pt",
    },
  },
});