import { useEffect, useState } from "react";

import "../../../styles/Services.css";

import u_register from "../../../pics/undraw/register.png";
import u_verify from "../../../pics/undraw/verify.png";
import u_training from "../../../pics/undraw/train.png";
import u_profile from "../../../pics/undraw/profile.png";
import u_interview from "../../../pics/undraw/interview.png";
import u_payment from "../../../pics/undraw/payment.png";
import u_deployment from "../../../pics/undraw/deployment.png";
import u_rating from "../../../pics/undraw/rating.png";

import { useSelector, useDispatch } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Link } from "react-router-dom";
import ProcessSwipper from "../../../components/Home/ProcessSwipper";
function ClientProcess() {
  return (
    <div id="moreInfoContainer" className=" col-sm-9 m-0 pb-5 ps-1 pe-1">
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2  infoContainer mt-2  mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_register}
            alt="mentoring pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>1. Register</b>
          </h5>
          <p className="mt-1    ">
            The first step is to register with Maids Of Honour.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row infoContainer  pt-2 pb-2 mt-2 mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>2. Profiling</b>
          </h5>
          <p className="mt-1   ">
            The profiles short listed will be based on the service you asked for
            in the registration process
          </p>
          <p className="mt-1   ">
            You will then make initial contact through the platform on the
            different Service providers you like.
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_verify}
            alt="training pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2 infoContainer  mt-2  mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_interview}
            alt="placement pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>3. Negotiations </b>
          </h5>
          <p className="mt-1 ">
            You can then negotiate with the service providers you have
            shortlisted to determine which one will work best for you.
          </p>
          <p className="mt-1 ">
            This may involve discussing things like rates, schedules, and the
            specific cleaning tasks you need completed.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row  pt-2 pb-2 mt-2 infoContainer mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>4. Contract</b>
          </h5>
          <p className="mt-1 ">
            Once you've chosen a maid and agreed on the terms, you'll sign a
            contract with Service provider.
          </p>
          <p className="mt-1 ">
            This contract will outline the terms of the agreement, such as the
            length of the contract, the frequency of the services, etc.
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_profile}
            alt="connect pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2  infoContainer mt-2 mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_payment}
            alt="freedom pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>5. Payment</b>
          </h5>
          <p className="mt-1 ">
            After the contract is signed, you'll need to make a placement fee
            payment to Maids of Honour.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row  pt-2 pb-2 mt-2 infoContainer mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>6. Rating</b>
          </h5>
          <p className="mt-1 ">
            After the maid has completed their work, you'll be asked to rate the
            service provided by the maid.
          </p>
          <p className="mt-1 ">
            This rating will help Maids of Honour ensure that they are providing
            high-quality service to all of their clients.
          </p>
          <p className="mt-1 ">
            Rating includes but not limited to: attitude, Conduct, Etiquette,
            grooming
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_rating}
            alt="connect pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
    </div>
  );
}

export default ClientProcess;
