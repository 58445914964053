import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  active: "profile",
  activePage: localStorage.getItem("activePage") || "",
};

const DashboardControlSlice = createSlice({
  name: "dashboardControl",
  initialState,
  reducers: {
    closeTabs: (state, action) => {
      state.active = "";
    },
    setTab: (state, { payload }) => {
      state.active = payload;
    },
    setActivePage: (state, { payload }) => {
      state.activePage = payload;
      localStorage.setItem("activePage", payload);
    },
  },
});

export const { closeTabs, setTab, setActivePage } =
  DashboardControlSlice.actions;
export default DashboardControlSlice.reducer;
