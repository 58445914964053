import { useState, useEffect } from "react";
import "../../styles/assessment/index.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
function Results({ score, percentageScore, total, time, closeResults }) {
  const [isPassed, setIsPassed] = useState((score / total) * 100 >= 70);
  useEffect(() => {
    console.log("Percentage score");
    console.log((score / total) * 100);
  }, []);
  return (
    <>
      <div id="resultModalContainer" className="row m-0">
        <div
          className={`d-flex flex-column shadow align-items-center text-white col-sm-4 rounded p-5 ${
            isPassed ? "bg-success" : "bg-danger"
          } `}
        >
          {isPassed ? (
            <BsFillCheckCircleFill className="text-white mb-4" size={50} />
          ) : (
            <AiFillCloseCircle className="text-white mb-4" size={50} />
          )}

          <h4>{percentageScore}</h4>

          {isPassed ? (
            <span className="text-center">Good job!</span>
          ) : (
            <span className="text-center">
              That was a great attempt!.
              <br /> Kindly review the course and try again
            </span>
          )}

          {/* <span className="text-center mt-2">Your time {time}</span> */}

          <button
            className="btn btn-light btn-sm mt-4 fontsm"
            onClick={closeResults}
          >
            Continue to courses
          </button>
        </div>
      </div>
    </>
  );
}

export default Results;
