import React from "react";
import ReactToPrint from "react-to-print";
import MyDocument2 from "./MyDocument2";
import MyContract from "./MyContract";

class PDFComponent extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <button className="btn btn-primary">Print to PDF!</button>
          )}
        />
        <MyDocument2 ref={(response) => (this.componentRef = response)} />
      </div>
    );
  }
}
export default PDFComponent;
