import React from "react";
import { useSelector } from "react-redux";
import "../../styles/About.css";
function MissionVission() {
  const colorScheme = useSelector((state) => state.color.scheme);
  return (
    <div
      data-aos="fade-up"
      style={{
        backgroundColor: `${colorScheme?.primary}`,
        minHeight: "70vh",
      }}
      className="pt-5 pb-5 d-flex justify-content-center align-items-center  row m-0 fontsm"
    >
      <div className="w-100 d-flex justify-content-center row m-0 p-0 ">
        <div
          className="col-sm-5 text-light mt-3 mb-3 pt-4 pb-4 ps-2 pe-2 d-flex flex-column "
          data-aos="fade-up"
        >
          <h3 className=" ps-3">Our Mission to the African Home</h3>

          <span
            className="mt-1 p-3 text-light  w-100"
            style={{ fontSize: "18px" }}
          >
            To restore integrity, professionalism, skill, and ethic to the
            industry by providing vetted,rated and trained domestic technicians
            who are continually upskilled in housemanagent, nutrition and
            childcare,to avail bespoke solutions for individual homes.
          </span>
        </div>
        <div
          className="col-sm-5 text-light mt-3 mb-3 pt-4 pb-4  ps-2 pe-2 d-flex flex-column  "
          data-aos="fade-up"
        >
          <h3 className=" ps-3">Our vision to the African worker</h3>

          <span className="mt-1 p-3 text-light " style={{ fontSize: "18px" }}>
            To create a community of skilled Domestic workers with access to
            development training in various key areas based on their specialized
            skill set while create avenues for financial inclusion and
            empowerment.
          </span>
        </div>
      </div>
    </div>
  );
}

export default MissionVission;
