import { useEffect, useState } from "react";

import "../../../styles/Services.css";

import u_register from "../../../pics/undraw/register.png";
import u_verify from "../../../pics/undraw/verify.png";
import u_training from "../../../pics/undraw/train.png";
import u_profile from "../../../pics/undraw/profile.png";
import u_interview from "../../../pics/undraw/interview.png";
import u_payment from "../../../pics/undraw/payment.png";
import u_deployment from "../../../pics/undraw/deployment.png";

import { useSelector, useDispatch } from "react-redux";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Link } from "react-router-dom";
import ProcessSwipper from "../../../components/Home/ProcessSwipper";
function SPProcess() {
  return (
    <div id="moreInfoContainer" className=" col-sm-9 m-0 pb-5 ps-1 pe-1">
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2  infoContainer mt-2  mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_register}
            alt="mentoring pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>1. Register</b>
          </h5>
          <p className="mt-1    ">
            Once you’re ready to begin your journey, your first step is to
            register with Maids Of Honour. Once you are registered, you begin
            the process of finding the right client or Service Provider for your
            family.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row infoContainer  pt-2 pb-2 mt-2 mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>2. Verify</b>
          </h5>
          <p className="mt-1   ">
            The Verification process provides the evidence that the client and
            service provider has met all requirements asked for and functional
            and allocated baselines.
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_verify}
            alt="training pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2 infoContainer  mt-2  mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_training}
            alt="placement pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>3. Mentoring and training </b>
          </h5>
          <p className="mt-1 ">
            Users are able to access the Mentoring and training resource
            materials. Each training material will be followed up by testing
            materials to ensure understanding of the material. This is a
            continuous process as new trainings are released.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row  pt-2 pb-2 mt-2 infoContainer mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>4. Profiling</b>
          </h5>
          <p className="mt-1 ">
            Once you’ve found a candidate you wish to hire, present your
            candidate with initial interest. Upon the service provider's
            acceptance, both candidates are able to start first contact.
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_profile}
            alt="connect pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2  infoContainer mt-2 mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_interview}
            alt="freedom pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>5. Interview</b>
          </h5>
          <p className="mt-1 ">
            Once you’ve had a chance to review the service provider profiles, an
            interview will be arranged through telephone and in person
            interviews if needed with the Service provider you are interested in
            learning more about.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row  pt-2 pb-2 mt-2 infoContainer mx-auto"
      >
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column">
          <h5>
            <b>6. Payment</b>
          </h5>
          <p className="mt-1 ">
            After an amount agreed on with the service provider, a contract
            between the service provider and Client will be signed and final
            payment made. The payment process has been made easy with a few
            clicks.
          </p>
        </div>
        <div className="col-sm-6  d-flex justify-content-center">
          <img
            src={u_payment}
            alt="connect pic"
            className="w-100  ms-2 me-2 mt-3 mb-3 statImage"
          />
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="row d-flex pt-2 pb-2  infoContainer mt-2 mx-auto "
      >
        <div className="col-sm-6 ratingSystemImg d-flex justify-content-center">
          <img
            src={u_deployment}
            alt="freedom pic"
            className="w-100 ms-2 me-2 mt-3 mb-3  statImage"
          />
        </div>
        <div className="col-sm-6 d-flex justify-content-center p-3 flex-column ratingSystemText">
          <h5>
            <b>7. Deployment</b>
          </h5>
          <p className="mt-1 ">
            Service Providers will start work on the day agreed upon.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SPProcess;
