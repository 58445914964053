import React from "react";
import "../../styles/spinner.css";
import { ImSpinner2 } from "react-icons/im";
function Spinner2({ message }) {
  return (
    <div className="cont2">
      <div className="d-flex justify-content-center align-items-center">
        <ImSpinner2 id="spinnerIcon1" size={30} className="m-3" />
        <small>{message}</small>
      </div>
    </div>
  );
}

export default Spinner2;
