/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../../../../pics/avatar.png";
import "../../../../styles/Profile.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiFillStar,
} from "react-icons/ai";
import { MdVerified } from "react-icons/md";
import {
  setRecipient,
  fetchChats,
  setChatId,
  fetchMesages,
} from "../../../../features/slices/MessagesSlice";
import { setCurrentBooking } from "../../../../features/slices/BookingSlice.js";
import { axiosWrapper } from "../../../../Utils/AxiosWrapper/index.js";
import Spinner1 from "../../../../components/spinners/Spinner1";
import { toast } from "react-hot-toast";
import { calculateUserRating } from "../../../../Utils/userStats";
// import MaxProfile from "../../../profile/sp/MaxProfile";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Grid } from "@mui/material";

function MaxRequestItem({ request, rejected }) {
  // const colorSchema = useSelector((state) => state.color.scheme);
  // const [showRequestForm, setShowRequestForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userRating, setUserRating] = useState("wait");
  const [isVerified, setIsVerified] = useState(false);
  const { user } = useSelector(
    (state) => state.auth
  );
  const piRef = useRef(null);
  // const bioRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    piRef.current.click();
    fetchRating();
    if (request?.provider?.yearsOfExperience > 0) {
      checkIfVerified();
    }
    // bioRef.current.click();
    // piRef.current.click();
  }, []);

  const fetchRating = async () => {
    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${
        user.role.title === "client" ? request.provider._id : request.client._id
      }`,
    });

    let rating = calculateUserRating({
      ...user,
      rating: getReviews.data.data.rows,
    });

    setUserRating(`${rating}(${getReviews.data.data?.rows?.length})`);
  };

  const checkIfVerified = async () => {
    const employersData = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${request.provider._id}`,
    });
    const prevEmployerData = employersData.data.data.rows[0];

    //checking employer answers
    const prevEmployerAnswers = await axiosWrapper({
      method: "get",
      url: `referrals/employers/answers?employer=${prevEmployerData._id}`,
      data: "",
    });

    if (prevEmployerAnswers?.data?.data?.count > 0) {
      setIsVerified(true);
    }
  };

  const handleStartChat = async () => {
    setLoading(true);
    try {
      const response = await axiosWrapper({
        method: "post",
        url: `comms/chats`,
        data: {
          participants: [request.client._id, user.sub || user._id],
        },
      });

      console.log("response from create chat");
      console.log(response);
      if (response.data.success) {
        dispatch(setChatId(response.data.data._id));
        dispatch(fetchMesages(response.data.data._id));
        dispatch(setRecipient(request.client));
        dispatch(fetchChats(user._id || user.sub || user.id));

        const updateStatus = await axiosWrapper({
          method: "patch",
          url: `bookings/${request._id}`,
          data: {
            status: "pending",
          },
        });

        console.log("response from updating status for booking");
        console.log(updateStatus);
        if (response.data.success) {
          dispatch(setCurrentBooking(request));
          navigate("/chat");
          //  setLoading(false);
          // toast.success("Response rejected successfully!");
          //  rejected();
          // dispatch(setChatId(response.data.data._id));
          // dispatch(fetchMesages(response.data.data._id));
          // dispatch(setRecipient(request.client));
          // dispatch(fetchChats(user._id || user.sub || user.id));
          // navigate("/chat");
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("Could not respond right now, try again later.");
    }
  };

  const handleRejectOffer = async () => {
    setLoading(true);
    try {
      const response = await axiosWrapper({
        method: "patch",
        url: `bookings/${request._id}`,
        data: {
          status: "rejected",
        },
      });

      console.log("response from rejecting booking");
      console.log(response);
      if (response.data.success) {
        // navigate("/dashboard/");
        rejected();
        // navigate("/dashboard/requests");
        setLoading(false);
        toast.success("Request rejected successfully!");
        // rejected();
        // dispatch(setChatId(response.data.data._id));
        // dispatch(fetchMesages(response.data.data._id));
        // dispatch(setRecipient(request.client));
        // dispatch(fetchChats(user._id || user.sub || user.id));
        // navigate("/chat");
      }
    } catch (error) {
      console.log("error from request");
      console.log(error);
      setLoading(false);
      toast.error("Could not respond right now, try again later.");
    }
  };
  return (
    <>
      {loading && <Spinner1 message={"Please wait.."} />}
      <div
        id="maxProfileModalContainer"
        className="col-sm-10 mx-auto bg-white p-3 rounded row "
        style={{ overflowY: "scroll", maxHeight: "95vh", position: "relative" }}
      >
        <div className="col-sm-6 d-flex flex-column">
          {user.role.title === "serviceProvider" ? (
            <>
              <img
                className=" maxProfileAvatarPic rounded"
                src={request.client.avatar ? request.client.avatar : Avatar}
                alt={"Profilephoto"}
              />
            </>
          ) : (
            <>
              <img
                className=" maxProfileAvatarPic rounded"
                src={request.provider.avatar ? request.provider.avatar : Avatar}
                alt={"Profilephoto"}
              />
            </>
          )}

          <div className="mt-4 mb-3" id="maxProfileBio1">
            {user.role.title === "serviceProvider" ? (
              <>
                <span className="fontsm lightDark ms-0 ps-0">
                  <p className="text-dark mt-1 mb-2">
                    <b>CLIENT'S BIO</b>
                  </p>
                  {request.client.bio}
                </span>
              </>
            ) : (
              <>
                
                <span className="d-flex align-items-center justify-content-between  w-100 flex-wrap m-0">
                <h3 className="d-flex align-items-center">
                  <b>
                    {user.role.title === "serviceProvider" ? (
                      <>
                        {request.client.firstName} {request.client.lastName}
                      </>
                    ) : (
                      <>
                        {request.provider.firstName} {request.provider.lastName}
                      </>
                    )}
                  </b>
                  {isVerified && (
                    <OverlayTrigger
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-verified`} className="fontsm">
                          You are verified
                        </Tooltip>
                      }
                    >
                      <span>
                        <MdVerified className="text-primary ms-2" size={25} />
                      </span>
                    </OverlayTrigger>
                  )}
                </h3>
                <span>
                  <small>
                    <AiFillStar className="me-1 text-warning" />
                    {userRating === "wait" ? (
                      <div className="spinner-border spinner spinner-border-sm text-success me-1"></div>
                    ) : (
                      userRating
                    )}
                    rating
                  </small>
                </span>
              </span>
              <span className="fontsm lightDark ms-0 ps-0 mb-2">
                  <p className="text-dark mt-1 mb-2">
                    <b>SERVICE PROVIDER'S BIO</b>
                  </p>
                  {request.provider.bio}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="col-sm-6 p-3">
          <div className="d-flex justify-content-between align-items-start p-2">

            <div className="d-flex flex-column lightText w-100">
             
              {user.role.title === "serviceProvider" &&
                request.status === "pending" && (
                  <span className="d-flex align-items-center row   w-100 flex-wrap m-0">
                    <button
                      type="button"
                      className="text-light justify-content-center btn btn-success rounded d-flex align-items-center btn-sm col-sm-5 m-1   hoverDownEffect fontsm"
                      onClick={handleStartChat}
                    >
                      To Negotiations
                      <AiOutlineCheckCircle className="ms-1" size={16} />
                    </button>
                    <button
                      type="button"
                      className="text-light justify-content-center rounded d-flex align-items-center col-sm-5 m-1 btn btn-danger btn-sm   hoverDownEffect fontsm"
                      onClick={handleRejectOffer}
                    >
                      Reject Offer
                      <AiOutlineCloseCircle className="ms-1" size={16} />
                    </button>
                  </span>
                )}
            </div>
          </div>

          <div class="accordion p-0" id="accordionExample">
            <div class="accordion-item">
              <span class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="fontsm mb-1">
                    <b>Job Information</b>
                  </span>
                </button>
              </span>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body ">
                  <div className="d-flex flex-column p-0 m-0">
                    <span className="fontsm mt-2">
                      <b>Job title: </b>
                      {request.service.title}
                    </span>

                    <span className="fontsm mt-2">
                      <b>Work arrangement: </b>
                      {request.bookingType}
                    </span>
                    <span className="fontsm mt-2">
                      <b>Location: </b>
                      {request.client.placeOfResidence}
                    </span>
                    <span className="fontsm mt-2">
                      <b>
                        <span className="me-2">Start date:</span>
                      </b>
                      {moment(request?.schedule?.start).format("ll")}
                    </span>
                    <span className="fontsm mt-2">
                      <b>
                        <span className="me-2">End date:</span>
                      </b>
                      {moment(request?.schedule?.end).format("ll")}
                    </span>
                    <span className="fontsm mt-2">
                      <b>
                        <span className="me-2">Work start time:</span>
                      </b>
                      {moment(request?.schedule?.start).format("LT")}
                    </span>
                    <span className="fontsm mt-2">
                      <b>
                        <span className="me-2">Work end time:</span>
                      </b>
                      {moment(request?.schedule?.end).format("LT")}
                    </span>
                    {/* <span className="fontsm mt-2">
                      <b>Salary: </b>
                      {request.service.minSalary +
                        " - " +
                        request.service.salaryRange}
                    </span> */}
                    <span className="fontsm mt-2">
                      <b>Job description: </b>
                      {request.service.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            class="accordion p-0 mt-2"
            id="maxProfileBio2"
            style={{ display: "none" }}
          >
            <div class="accordion-item">
              <span class="accordion-header" id="bioHeading">
                <button
                  ref={bioRef}
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#bioCollapse"
                  aria-expanded="true"
                  aria-controls="bioCollapse"
                >
                  <span className="fontsm mb-1">
                    <b>My bio</b>
                  </span>
                </button>
              </span>
              <div
                id="bioCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="bioHeading"
                data-bs-parent="#maxProfileBio2"
              >
                <div class="accordion-body ">
                  {request?.bio && (
                    <span className="fontsm lightDark ms-0 ps-0">
                      <p className="text-dark mt-1 mb-2">
                        <b>Bio</b>
                      </p>
                      {request.client.bio}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div> */}
          <div class="accordion p-0 mt-2" id="accordionExample">
            <div class="accordion-item">
              <span class="accordion-header" id="headingOne">
                <button
                  ref={piRef}
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#professionalCollapse"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span className="fontsm mb-1">
                    <b>Client's deep dive (specific requirements)</b>
                  </span>
                </button>
              </span>
              <div
                id="professionalCollapse"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body ">
                  <div className=" p-1 mt-1">
                    <span
                      className="fontsm "
                      dangerouslySetInnerHTML={{ __html: request.requirements }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MaxRequestItem;
