import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";

// import axios from "axios";
import jwt_decode from "jwt-decode";

import { axiosWrapper } from "../../Utils/AxiosWrapper/index";

// const sessionStorageData = JSON.parse(sessionStorage.getItem("authStatus"));
const localStorageData = JSON.parse(localStorage.getItem("authStatus"));
const initialState = {
  isAuth: localStorageData?.isAuth,
  user: localStorageData?.user || "",
  refreshToken: localStorageData?.refreshToken,
  accessToken: localStorageData?.accessToken,
  isLoading: false,
  isError: false,
  errorMessage: "",
  prevPath: "",
  isProfileComplete: false,
  userPhone: "",
};

//async function to register user
export const registerUser = createAsyncThunk(
  "auth/registeruser",
  async (userData, { rejectWithValue }) => {
    const tokenData = await axiosWrapper({
      method: "post",
      url: "auth/signup",
      data: userData,
    });
    console.log("signup auth data");
    console.log(`####### ==> signup`, tokenData.data.success);
    if (!tokenData.data.success) {
      // return rejectWithValue(tokenData.data.data.message);
      // toast.error("You have been logged out");
      return rejectWithValue("Registration failing");
    } else {
      localStorage.setItem("refreshToken", tokenData?.data?.data?.refreshToken);
      localStorage.setItem("accessToken", tokenData?.data?.data?.accessToken);
      localStorage.setItem("otpToken", tokenData?.data?.data?.otpToken);

      const decoded = await jwt_decode(tokenData.data?.data?.accessToken);
      const userDetails = await fetchProfileDetails(decoded.sub);
      return userDetails;
    }
  }
);

//async function to authenticate user
export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    const tokenData = await axiosWrapper({
      method: "post",
      url: "auth/login",
      data: userData,
    });
    console.log(`####### ==> login`, userData, tokenData.data.success);
    let decoded;
    if (!tokenData.data.success) {
      // return rejectWithValue(tokenData.data.data.message);
      return rejectWithValue("Invalid email or password");
    } else {
      localStorage.setItem("refreshToken", tokenData?.data?.data?.refreshToken);
      localStorage.setItem("accessToken", tokenData?.data?.data?.accessToken);
      decoded = await jwt_decode(tokenData.data?.data?.accessToken);
      const userDetails = await fetchProfileDetails(decoded.sub);
      return userDetails;
    }
  }
);

export const fetchProfileDetails = async (userId) => {
  const response = await axiosWrapper({
    method: "get",
    url: `users/${userId}`,
  });

  const userData = response.data.data;
  delete userData._id;

  return { ...userData, sub: userId };
};

//check if profile is complete
export const checkIfProfileIsComplete = (userData) => {
  if (userData.role.title === "serviceProvider") {
    if (
      // userData.idFront &&
      // userData.idBack &&
      // (userData.conductCert || userData.conductCertReceipt) &&
      // userData.avatar &&
      userData.gender
      // userData.placeOfResidence &&
      // (userData?.prevEmployer?.prevEmployerName ||
      //   userData.yearsOfExperience === 0) 
      // userData.skills?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  } else if (userData.role.title === "client") {
    if (
      // userData.idFront &&
      // userData.idBack &&
      userData.avatar 
      // userData.skills?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
};
//async function to logout user
export const logoutUser = createAsyncThunk(
  "auth/logout",
  // async (userData, { rejectWithValue }) => {
  async () => {
    const logoutResponses = await axiosWrapper({
      method: "get",
      url: "auth/logout",
    });

    console.log("Logout from backend");
    console.log(logoutResponses.data);

    return logoutResponses.data;
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    register: (state, action) => {
      const authUser = {
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
      };

      state.user = authUser;
      state.isAuth = true;

      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: authUser })
      );
    },
    login: (state, action) => {
      const authUser = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
      };

      state.user = authUser;
      state.isAuth = true;

      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: authUser })
      );
    },
    logout: (state, action) => {
      state.user = "";
      state.isError = false;
      state.errorMessage = "";
      state.isLoading = false;
      state.prevPath = "";
      state.isAuth = false;
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: false, user: {} })
      );
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      // toast.info("You have been logged out");
      // window.location = "/";
    },
    setPrevPath: (state, action) => {
      state.prevPath = action.payload;
    },

    setUserFromSocialLogin: (state, action) => {
      let userData = action.payload;
      // let userSub = userData._id;
      // delete userData._id;

      // const {_id, ...rest} = userData
      state.isAuth = true;
      state.user = userData;
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: userData })
      );
    },
    setUserFromSocialRegistration: (state, action) => {
      const newAccessToken = action.payload.tokens.accessToken;
      const newRefreshToken = action.payload.tokens.refreshToken;
      let userData = action.payload.user;

      localStorage.setItem("refreshToken", newRefreshToken);
      localStorage.setItem("accessToken", newAccessToken);

      let userSub = userData._id;
      delete userData._id;

      // const {_id, ...rest} = userData
      console.log("===========Previous===================");
      console.log(state.user);
      console.log("====================================");
      state.isAuth = true;
      state.user = { ...userData, sub: userSub };
      console.log("============After===================");
      console.log(state.user);
      console.log("====================================");

      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: { ...userData, sub: userSub } })
      );
    },
    setUserPhone: (state, action) => {
      state.userPhone = action.payload;
    },

    updateUserDetails: (state, { payload }) => {
      console.log("package received");
      console.log(payload);
      let userSub = payload._id || payload.sub || payload.id;
      let userData = payload;
      delete userData._id;
      state.isAuth = true;

      const isProfileUpdated = checkIfProfileIsComplete(userData);
      state.isProfileComplete = isProfileUpdated;
      console.log("Profile complete test");
      console.log(isProfileUpdated);
      state.user = { ...userData, sub: userSub };
      console.log("sub");
      console.log(userSub);
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: { ...userData, sub: userSub } })
      );
    },

    clearState: (state, action) => {
      state.user = "";
      state.isError = false;
      state.errorMessage = "";
      state.isLoading = false;
      state.prevPath = "";
      state.isAuth = false;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [registerUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.isAuth = true;
      state.errorMessage = "";
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: action.payload })
      );
      toast.success("Registration successful");
      toast.success("Kindly check your email and verify your account!");
    },
    [registerUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = "Registration failed";
      toast.error("Registration failed, an account with the same email already exists ");
    },
    [loginUser.pending]: (state, action) => {
      state.isError = false;
      state.errorMessage = "";
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.isAuth = true;
      state.user = action.payload;
      let userData = action.payload;
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: true, user: action.payload })
      );
      if (userData.role.title === "serviceProvider") {
        if (
          // userData.idFront &&
          // userData.idBack &&
          // userData.conductCert &&
          // userData.avatar &&
          userData.placeOfResidence
        ) {
          state.isProfileComplete = true;
        } else {
          state.isProfileComplete = false;
        }
      } else if (userData.role.title === "client") {
        if (
          // userData.idFront &&
          // userData.idBack &&
          // userData.avatar &&
          userData.skills?.length > 0
        ) {
          state.isProfileComplete = true;
        } else {
          state.isProfileComplete = false;
        }
      }
      toast.success("Login successfull");
    },
    [loginUser.rejected]: (state, action) => {
      state.isAuth = false;
      state.isLoading = false;
      state.isError = true;
      // state.errorMessage = action.payload;
      state.errorMessage = "Invalid email or password";
      toast.error("Invalid email or password");
      localStorage.setItem(
        "authStatus",
        JSON.stringify({ isAuth: false, user: {} })
      );
    },
    [logoutUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [logoutUser.fulfilled]: (state, action) => {
      toast.success("You have been logged out.");
      localStorage.clear();
      console.log("action.payload.success");
      console.log(action.payload.success);
      state.isAuth = false;
      state.user = "";
      state.isError = false;
      state.errorMessage = "";
      state.isLoading = false;
      state.prevPath = "";

      // localStorage.setItem(
      //   "authStatus",
      //   JSON.stringify({ isAuth: false, user: {} })
      // );

      setTimeout(() => {
        // window.location = "/";
      }, 1000);
    },
    [logoutUser.rejected]: (state, action) => {
      state.isLoading = false;
      logout();
    },
  },
});

export const {
  register,
  login,
  logout,
  clearState,
  setPrevPath,
  setUserFromSocialLogin,
  setUserFromSocialRegistration,
  updateUserDetails,
  setUserPhone,
} = AuthSlice.actions;
export default AuthSlice.reducer;
