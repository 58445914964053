import React, { useState, useEffect } from "react";
import "../../../styles/Course/Courses.css";
import CourseDescriptionText from "./CourseDescriptionText";
import CoursePlaylist from "./CoursePlaylist";
import CourseVideoPlayer from "./CourseVideoPlayer";
import CurrentCourseDetails from "./CurrentCourseDetails";
import { useDispatch, useSelector } from "react-redux";
import Assessment from "../../assessment/Assessment";
import DashboardLayout from "../../Dashboard/DashboardLayout";
import {
  getAssessmentQuestions,
  setCurrentAssessment,
  getAssessmentScore,
} from "../../../features/slices/CourseSlice";
import CoursePaymentModal from "./CoursePaymentModal";
import PaymentModal from "../../../components/Payment/PaymentModal";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import ClientSubscriptionModal from "../../../components/Home/ClientSubscriptionModal";
import Spinner1 from "../../../components/spinners/Spinner1";
import { setSubscription } from "../../../features/slices/SubscriptionSlice";
import ReactPlayer from 'react-player'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Dialog, AppBar, DialogContent, DialogActions, Chip, Toolbar, List, Button, Box, Paper, Grid, Stack, DialogTitle, ListItemText, Tooltip, Divider, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/ArrowBackIos';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import intro from "../../../pics/intro.png"

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function CourseContent() {
  const dispatch = useDispatch();
  const { user, isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const {
    currentCourse,
    currentUnits,
    currentAssessments,
    currentQuestionIndex,
  } = useSelector((state) => state.courses);
  const { isSubscribed, subscription } = useSelector(
    (state) => state.subscription
  );
  const [showQuiz, setShowQuiz] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);
  const videoUrls = [
    {
      url: 'https://vimeo.com/875046449', logo: "https://res.cloudinary.com/de3eoq2qb/image/upload/v1674036258/shiko_thumbnail_txfd2s.png", title: "Introduction", tag: "Madam Shiko", info: "Mentorship", time: 2,
    },
    {
      url: 'https://www.youtube.com/watch?v=Qj8Joqv1zuU', logo: "https://quotefancy.com/media/wallpaper/3840x2160/98090-Jim-Rohn-Quote-Service-to-others-leads-to-greatness.jpg", title: "Service is greatness", tag: "Anonymous", info: "Mentorship ", time: 8,
    },
    {
      url: 'https://www.youtube.com/watch?v=B9V4rjaUIkw', logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN8DQGXLtnOOLYAvn_ElssRhpmJHwfsbqM856Yi8fwly--_r3_I0ZiTx_anwuhERYYVjs&usqp=CAU", title: "Faithfulness", tag: "Madam Shiko", info: "Mentorship ", time: 10,
    },
    {
      url: 'https://vimeo.com/864774108', logo: "https://res.cloudinary.com/de3eoq2qb/image/upload/v1674036258/shiko_thumbnail_txfd2s.png", title: "Behavior in the house", tag: "Madam Shiko", info: "Mentorship", time: 10,
    },
    {
      url: 'https://vimeo.com/875050461', logo: "https://blogassets.leverageedu.com/blog/wp-content/uploads/2020/05/08140736/Modes-of-Communication.png", title: "Communication", tag: "Madam Shiko", info: "Mentorship ", time: 12,
    },
    {
      url: 'https://www.youtube.com/watch?v=g6ftrGgSx7c', logo: "https://www.marketing91.com/wp-content/uploads/2020/07/What-is-Etiquette.jpg", title: "Etiquette", tag: "Madam Shiko", info: "Mentorship ", time: 7,
    },
    {
      url: 'https://www.youtube.com/watch?v=NOEXGoYyzwU', logo: "https://media.istockphoto.com/id/1214942513/video/housekeeper-washing-the-dishes-wearing-protective-mask.jpg?s=640x640&k=20&c=uxdsPFsxq-a5eACn7MKaw5TwsNbqHahFq_qG3uCh8mU=", title: "Good grooming", tag: "Madam Shiko", info: "Mentorship ", time: 10,
    },
    {
      url: 'https://www.youtube.com/watch?v=kvEBz1iLB_E', logo: "https://healthcompassng.com/wp-content/uploads/2023/05/personal_hygeine_hero.jpg", title: "Personal Hygiene", tag: "Madam Shiko", info: "Mentorship", time: 12,
    },

    {
      url: 'https://www.youtube.com/watch?v=R7E-rcuMUkQ', logo: "https://us.123rf.com/450wm/rudzhan/rudzhan2012/rudzhan201200494/161195537-male-housekeeper-in-yellow-overall-vacuum-cleaning-sofa.jpg?ver=6", title: "Cleaning the living room", tag: "Madam Shiko", info: "HouseKeeping ", time: 12,
    },

    {
      url: 'https://www.youtube.com/watch?v=o_WSbKeKmKU', logo: "https://us.123rf.com/450wm/rudzhan/rudzhan2012/rudzhan201200494/161195537-male-housekeeper-in-yellow-overall-vacuum-cleaning-sofa.jpg?ver=6", title: "Cleaning the dining room", tag: "Madam Shiko", info: "HouseKeeping ", time: 12,
    },
    {
      url: 'https://www.youtube.com/watch?v=w2mLErtOD0U', logo: "https://us.123rf.com/450wm/rudzhan/rudzhan2012/rudzhan201200494/161195537-male-housekeeper-in-yellow-overall-vacuum-cleaning-sofa.jpg?ver=6", title: "Cleaning the kitchen ", tag: "Madam Shiko", info: "HouseKeeping ", time: 12,
    },
    //  {
    //   url: 'https://www.youtube.com/watch?v=F0dmzlSYMeY&pp=ygUQbGF1bmRyeSB0cmFpbmluZw%3D%3D', logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4wPu6HUKsDus5-7zXu2hq8SL1PzOMMNpnZQ&usqp=CAU", title: "Laundry basics", tag: "Anonymous", info: "HouseKeeping", time: 8,
    // }
  ];

  const playNextVideo = () => {
    if (currentVideoIndex < videoUrls.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const playPreviousVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };

  const [currentlyPlaying, setCurrentlyPlaying] = useState(
    currentUnits[0]?.video
  );

  const [paymentType, setPaymentType] = useState("subscription");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentModal2, setShowPaymentModal2] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Please wait...");
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    if (currentCourse?.fee?.amount > 0) {
      const courseSubscription = await subscription?.rows?.find(
        (sub) => sub.type === currentCourse._id
      );
      setLoading(false);
      console.log(isSubscribed ? "is subscribed" : "Not subscribed");
      console.log("current payment ", courseSubscription);
      if ((isSubscribed === "false" || !isSubscribed) && !courseSubscription) {
        setShowPaymentModal(true);
      } else {
        setShowPaymentModal(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleCloseModal = async (details) => {
    if (!details.type) {
      setShowPaymentModal2(false);
      setShowPaymentModal(true);
      return;
    }
    //save course payment in the db
    setShowPaymentModal2(false);
    setShowPaymentModal(false);
    if (details.type === "success") {
      setLoading(true);
      setLoadingMessage("updating your subscription");

      //checkout course subscription
      try {
        let subscriptionData = {
          user: user.sub || user._id || user.id,
          subscription: "642c8a9223d0a71611b404ab",
          isPaid: true,
        };
        const response = await axiosWrapper({
          method: "post",
          url: `users/subscriptions`,
          data: subscriptionData,
        });

        setLoading(false);
        if (response.data.success) {
          dispatch(setSubscription(response.data.data));
          toast.success("You have successfully subscribed to our package!");
          navigate("/dashboard");
        } else {
          toast.error(
            "Some error occured, kindly contact our customer service for help."
          );
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error(error.message);
      }
      document.body.style.overflow = "visible";
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* {currentCourse && ( */}
        <div
          style={{ backgroundColor: "#f8f9fa", height: "100%" }}
        >
          <Box sx={{ flexGrow: 1, py: isSmallScreen ? 0 : 1, px: isSmallScreen ? 2 : 5 }}>
            <Grid container spacing={3}>
              <Grid item md={9} xs={12} sx={{ maxHeight: isSmallScreen ? 300 : "90vh" }} mb={3}>
                <ReactPlayer
                  url={videoUrls[currentVideoIndex].url}
                  style={{ borderRadius: "10px" }}
                  light={videoUrls[currentVideoIndex].logo}
                  controls
                  playing
                  width="100%"
                  height="90%"
                  onEnded={playNextVideo}
                />
                <ListItem
                  sx={{ p: 0.8, bgcolor: "background.paper", borderRadius: "0.7rem" }}
                  secondaryAction={
                    <Stack
                      direction="row" spacing={1}
                      justifyContent="space-between"
                      sx={{ p: 0.3 }}
                    >
                      <IconButton aria-label="previous" onClick={playPreviousVideo}>
                        <SkipPreviousIcon fontSize="large" sx={{ color: "text.primary" }} />
                      </IconButton>
                      <IconButton aria-label="next" onClick={playNextVideo}>
                        <SkipNextIcon fontSize="large" sx={{ color: "text.primary" }} />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemAvatar >
                    <Avatar sx={{ height: "3rem", width: "3rem", borderRadius: "0.3rem" }} src='/logo.png' />
                  </ListItemAvatar>
                  <ListItemText primary={<Typography variant="h6" component="div">
                    {videoUrls[currentVideoIndex].title}
                  </Typography>} secondary={`By ${videoUrls[currentVideoIndex].tag}`} />
                </ListItem >
              </Grid>
              <Grid item md={3} xs={12} mb={7}>
                <div>
                  <List sx={{ p: 1, borderRadius: "0.7rem", bgcolor: "background.paper" }}>

                    <Stack
                      direction="row" spacing={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2" sx={{ mr: 2, fontWeight: "bold" }}>
                        Courses Playlist
                      </Typography>
                      <ExpandMore
                        expand={expanded}
                        // onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Stack>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      {videoUrls?.map((video, index) => (
                        <>
                          <ListItem
                            key={index}
                            sx={{ cursor: "pointer" }}
                            onClick={() => setCurrentVideoIndex(index)}
                            // secondaryAction={
                            //   <Chip size="small" color="secondary" variant="outlined" sx={{ fontSize: "0.7rem", borderRadius: "5px" }} label={`${video.time} min`} />
                            // }
                          >
                            <ListItemAvatar >
                              <Avatar variant="square" sx={{ height: "3rem", width: "5rem", borderRadius: "0.3rem" }} src={video.logo} />
                            </ListItemAvatar>
                            <ListItemText sx={{ ml: 1 }} primary={
                              <Typography variant="subtitle2">{video.title}</Typography>
                            } secondary={
                              <Stack
                                direction="row" spacing={0.5}
                                justifyContent="flex-start"
                              >
                                <div>
                                  <Typography noWrap variant="caption" sx={{ textOverflow: "ellipsis" }}>{video.info} </Typography>
                                </div>
                              </Stack>
                            } />
                          </ListItem >
                          <Divider variant="inset" component="li" />
                        </>
                      ))}
                    </Collapse>
                  </List>
                </div>
                <Button color="primary" variant="outlined" size="small" fullWidth sx={{ my: 1 }}>View Assessment</Button>

              </Grid>
            </Grid>
          </Box>
          {/* <div className="col-sm-8 p-0 m-0">
              {!showQuiz ? (
                <>
                  <div className="ps-5 pe-5 pt-3" id="videoPlayerContainer">
                    <CourseVideoPlayer playingLink={currentlyPlaying} />
                  </div>
                  <div id="DTContainer" className="ps-5 pe-5 pb-2 pt-3 ">
                    <CourseDescriptionText
                      description={currentCourse.description}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Assessment
                    closeResults={() => {
                      // setShowQuiz(false);
                      navigate("/dashboard/courses");
                    }}
                  />
                </>
              )}
            </div>
            <div
              className="col-sm-4 p-2 mt-3"
              style={{ backgroundColor: "#f0f6ff" }}
            >
              <CurrentCourseDetails course={currentCourse} />
              <CoursePlaylist
                units={currentUnits}
                currentlyPlaying={currentlyPlaying}
                setCurrentlyPlaying={setCurrentlyPlaying}
                assessments={currentAssessments}
                showQuiz={showQuiz}
                setShowQuiz={(index, data) => {
                  dispatch(setCurrentAssessment(currentAssessments[index]));
                  dispatch(
                    getAssessmentQuestions(currentAssessments[index]._id)
                  );

                  dispatch(
                    getAssessmentScore({
                      assessmentId: currentAssessments[index]._id,
                      candidateId: user.sub || user._id || user.id,
                    })
                  );
                  setShowQuiz(data);
                }}
              />
            </div> */}
        </div>
        {/* )} */}

        {showPaymentModal && (
          <CoursePaymentModal
            closeModal={() => {
              setShowPaymentModal(false);
              navigate("/dashboard/courses");
            }}
            handlePayNow={(data) => {
              setPaymentType(data);
              setShowPaymentModal(false);
              setShowPaymentModal2(true);
            }}
          />
        )}
        {showPaymentModal2 && (
          <PaymentModal
            paymentDetails={{
              name:
                paymentType === "subscription"
                  ? " for your MOHA subscription"
                  : ` for ${currentCourse.title} `,
              amount:
                paymentType === "subscription"
                  ? 1000
                  : currentCourse.fee.amount,
              type: paymentType,
            }}
            closeModal={handleCloseModal}
          />
        )}
        {loading && <Spinner1 message={loadingMessage} />}
      </DashboardLayout>
    </>
  );
}

export default CourseContent;
