import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prevEmployer: JSON.parse(localStorage.getItem("prevEmployer")) || "",
};

const EmployerSlice = createSlice({
  name: "prevEmployer",
  initialState,
  reducers: {
    setPrevEmployer: (state, action) => {
      state.prevEmployer = action.payload;
      localStorage.setItem("prevEmployer", JSON.stringify(action.payload));
    },
  },
});

export const { setPrevEmployer } = EmployerSlice.actions;
export default EmployerSlice.reducer;
