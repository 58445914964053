import { useState, useRef, useEffect } from "react";
import "../../styles/Course/Courses.css";
import Home1 from "../../pics/home1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "../../styles/Swiper.css";
import {
  MdOutlineNavigateNext,
  MdOutlineNavigateBefore,
  MdTimer,
  MdStar,
} from "react-icons/md";

import { BiPlayCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import CoursePreview from "./CourseContent/CoursePreview";
import { useSelector, useDispatch } from "react-redux";
import { getCourses } from "../../features/slices/CourseSlice";
import Spinner1 from "../../components/spinners/Spinner1";

function TrendingCourses({ selectTab }) {
  const swiperRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, loading } = useSelector((state) => state.courses);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  let slidesPerGroup = swiperRef?.current?.swiper?.params?.slidesPerGroup;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperCont = useRef(null);
  useEffect(() => {
    dispatch(getCourses());
  }, []);

  return (
    <div
      id="trendingContainer"
      className="w-100 row p-3 m-0 pb-5 "
      style={{ backgroundColor:"#F4F7FE" }}
    >
      <div className="col-sm-10 mx-auto row m-0">
        <h5 className="col-sm-12  mt-1">
          <b>Trending Courses</b>
        </h5>
        <span id="line" className="m-3 "></span>

        {loading && (
          <div className="position-relative p-5 row m-0 d-flex justify-content-center">
            <Spinner1 message={"Fetching courses"} />
          </div>
        )}

        <Swiper
          // install Swiper modules
          // modules={[Navigation, Pagination, Scrollbar, A11y]}
          // modules={[Pagination, Scrollbar, A11y]}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          onSlideChange={() => {
            setCurrentSlideIndex(swiperRef?.current?.swiper?.realIndex);
          }}
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={window.screen.width > 700 ? 4 : 1}
          slidesPerGroup={window.screen.width > 700 ? 4 : 1}
          // navigation={true}
          ref={swiperRef}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => {}}
          className="pt-3 pb-5 ps-2 pe-2  mx-auto row d-flex justify-content-center"
        >
          {courses.length > 0 &&
            courses.map((course, index) => (
              <SwiperSlide key={index} className=" col-sm-4   p-0 ">
                {/* <div className="w-100 p-0 trendingCourseDiv">
                  <img
                    src={course.poster}
                    alt={"Course pic"}
                    className="w-100 rounded-top"
                  />
                  <div className="trendingCourseOverlay rounded-top">
                    <BiPlayCircle
                      color="white"
                      className=" playCourseIcon"
                      onClick={() => {
                        navigate("/courses");
                      }}
                      size={45}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column p-2">
                  <div className="w-100 mx-auto mb-1 sizeNormal">
                    <b>{course.title}</b>
                  </div>
                  <div className="w-100 mx-auto d-flex flex-wrap align-items-center justify-content-between">
                    <span className="fontsm1">By {course.author}</span>
                    <span className="fontsm1 d-flex align-items-center">
                      <MdTimer className="me-1" />
                      {course.duration}
                    </span>
                  </div>
                  <span className="fontsm1 d-flex align-items-center">
                    <MdStar className="me-1 text-warning" />
                    {course.rating} rating
                  </span>
                </div> */}
                <CoursePreview
                  key={index}
                  index={index}
                  course={course}
                  selectTab={selectTab}
                />
              </SwiperSlide>
            ))}
        </Swiper>

        {currentSlideIndex === 0 ? (
          <span ref={prevRef}>
            <MdOutlineNavigateBefore
              size={40}
              color={"rgb(128, 123, 123)"}
              className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light"
            />
          </span>
        ) : (
          <span ref={prevRef}>
            <MdOutlineNavigateBefore
              size={40}
              color={"black"}
              className=" swiper_prev_btn  swiper_btn rounded-circle p-2 bg-light"
            />
          </span>
        )}
        {currentSlideIndex === (slidesPerGroup === 3 ? 1 : 6) ? (
          <span ref={nextRef}>
            <MdOutlineNavigateNext
              size={40}
              color={"rgb(128, 123, 123)"}
              ref={prevRef}
              className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light"
            />
          </span>
        ) : (
          <span ref={nextRef}>
            <MdOutlineNavigateNext
              size={40}
              color={"black"}
              ref={prevRef}
              className=" swiper_next_btn  swiper_btn rounded-circle p-2 bg-light"
            />
          </span>
        )}
      </div>
    </div>
  );
}

export default TrendingCourses;
