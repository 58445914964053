import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ClientProfileDetails2Schema } from "../../../Utils/validations/ProfileValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../../features/slices/AuthSlice";
import {
  fetchServicesAutocomplete,
  fetchUserServicesAutocomplete,
} from "../../../features/slices/ServiceSlice";
import { useTranslation } from "react-i18next";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";

import "react-phone-input-2/lib/style.css";

import "../../../styles/ProfileDetails.css";

import { MultiSelect } from "react-multi-select-component";
import {
  Button,
  Avatar,
  Divider,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import { FaCheckSquare } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import FileUploadStatus from "../../../components/Forms/FileUploadStatus";
import Spinner2 from "../../../components/spinners/Spinner2";
import { toast } from "react-hot-toast";
import EasyImageCropper from "../../../components/ImageUtils/EasyImageCropper";
import ServicesSelector from "../../../components/Services/ServicesSelector";

function ClientDetailsTwo({ closeForm }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage, prevPath, isAuth, user } =
    useSelector((state) => state.auth);
  const colorSchema = useSelector((state) => state.color.scheme);
  const [skills, setSkills] = useState([]);
  const [skillError, setSkillError] = useState("");
  const [selected, setSelected] = useState([]);
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const [idFrontLoading, setIdFrontLoading] = useState(false);
  const [idFrontUploaded, setIdFrontUploaded] = useState(false);
  const [idBackLoading, setIdBackLoading] = useState(false);
  const [idBackUploaded, setIdBackUploaded] = useState(false);
  const [idFrontError, setIdFrontError] = useState("");
  const [idBackError, setIdBackError] = useState("");
  const [passport, setPassport] = useState("");
  const [passportLoading, setPassportLoading] = useState(false);
  const [passportUploaded, setPassportUploaded] = useState(false);
  const [passportError, setPassportError] = useState("");

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [changes, setChanges] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [rawUserPhoto, setRawUserPhoto] = useState();
  const [fullPhoto, setFullPhoto] = useState();
  const [croppedUserPhoto, setCroppedUserPhoto] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ClientProfileDetails2Schema) });

  useEffect(() => {
    dispatch(fetchServicesAutocomplete());
    let fetchUrl = "";
    const userSkills = user.skills;
    userSkills.forEach((skill, index) => {
      fetchUrl = fetchUrl + `${index !== 0 ? "&" : ""}skills=` + skill._id;
    });

    dispatch(fetchUserServicesAutocomplete(fetchUrl));
  }, []);
console.log(`SKILLZ`,skills)
  const handleUpdate = async (data) => {
    if (!selected || skillError) {
      handleSkillValidation();
    } else {
      if (changes) {
        window.scroll(0, 0);
        setIsUpdateLoading(true);
        console.log("submitted data");
        console.log({ ...data, skills, idFront: idFront, idBack: idBack });
        const response = await axiosWrapper({
          method: "patch",
          url: `users/${user.sub ? user.sub : user.id}`,
          data: {
            ...data,
            skills,
            avatar: user?.avatar,
          },
        });

        if (response.data.success) {
          const response2 = await axiosWrapper({
            method: "get",
            url: `users/${user.sub ? user.sub : user.id}`,
            data: "",
          });
          console.log("updated to this");
          console.log(response2.data.data);
          dispatch(updateUserDetails(response2.data.data));
          // dispatch(updateUserDetails(response.data.data));
          setIsUpdateLoading(false);
          toast.success("Your profile has been updated!");
          closeForm();
        } else {
          toast.error("Failed to update your profile, please try again!");
        }
      } else {
        closeForm();
      }
    }
  };

  const FILE_SIZE = 10240 * 10240;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const handleIdFrontchange = async (e) => {
    setChanges(true);
    setIdFrontLoading(false);
    setIdFrontError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setIdFrontError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setIdFrontError("Unsuported file format");
    } else {
      setIdFrontError("");
      setIdFrontLoading(true);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "idFront");

      const idFrontResponse = await axiosWrapper({
        method: "post",
        url: "storage/upload/image",
        data: formData,
      });

      setIdFrontLoading(false);
      console.log("id response");
      if (idFrontResponse.data.success) {
        setIdFrontUploaded(true);
        setIdFront(idFrontResponse.data.data.url);
      } else {
        setIdFrontUploaded(false);
        setIdFrontError("Failed to upload, kindly try again");
      }
    }
  };
  const handleIdBackchange = async (e) => {
    setChanges(true);
    setIdBackLoading(false);
    setIdBackError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setIdBackError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setIdBackError("Unsuported file format");
    } else {
      setIdBackError("");
      setIdBackLoading(true);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "idBack");

      const idBackResponse = await axiosWrapper({
        method: "post",
        url: "storage/upload/image",
        data: formData,
      });

      setIdBackLoading(false);
      console.log("id response");
      if (idBackResponse.data.success) {
        setIdBackUploaded(true);
        setIdBack(idBackResponse.data.data.url);
      } else {
        setIdBackUploaded(false);
        setIdBackError("Failed to upload, kindly try again");
      }
    }
  };

  const handlePassportchange = async (e) => {
    setChanges(true);
    setPassportLoading(false);
    setPassportError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setPassportError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setPassportError("Unsuported file format");
    } else {
      var path = (window.URL || window.webkitURL).createObjectURL(file);
      console.log("path created");
      console.log(path);
      setPassportError("");
      setFullPhoto(file);
      setRawUserPhoto(path);
      setShowImageCropper(true);

      // setPassportLoading(true);
      // let formData = new FormData();
      // formData.append("file", file);
      // formData.append("type", "profile");

      // const passportResponse = await axiosWrapper({
      //   method: "post",
      //   url: "storage/upload/image",
      //   data: formData,
      // });

      // setPassportLoading(false);

      // if (passportResponse.data.success) {
      //   setPassportUploaded(true);
      //   setPassport(passportResponse.data.data.url);
      // } else {
      //   setPassportUploaded(false);
      //   setPassportError("Failed to upload, kindly try again");
      // }
    }
  };

  const handleSkillValidation = () => {
    // window.scroll(0, 0);
    if (!selected?.length > 0) {
      setSkillError("Select atleast one skill");
    } else {
      setSkillError("");
    }
  };

  useEffect(() => {
    setSkills(
      selected.map((entry) => {
        return entry.value;
      })
    );
    if (selected?.length > 0) {
      handleSkillValidation();
    }
  }, [selected]);

  useEffect(() => {
    if (user.skills) {
      const selectedSkills = user.skills.map((skill) => {
        return { label: skill, value: skill._id };
      });

      setSelected(selectedSkills);
    }
  }, []);

  const finalizePassportUpload = async (croppedImage, photo) => {
    setShowImageCropper(false);
    setPassportLoading(true);
    console.log("cropped photo result");
    console.log("path", croppedImage);
    console.log("photo", photo);
    let formData = new FormData();
    formData.append("file", photo);
    formData.append("type", "profile");

    const passportResponse = await axiosWrapper({
      method: "post",
      url: "storage/upload/image",
      data: formData,
    });
    setPassportLoading(false);
    if (passportResponse.data.success) {
      setPassportUploaded(true);
      setPassport(passportResponse.data.data.url);
      console.log("====================================");
      console.log("Passport url");
      console.log(passportResponse.data.data.url);
      console.log("====================================");
    } else {
      setPassportUploaded(false);
      setPassportError("Failed to upload, kindly try again");
    }
  };

  return (
    <>
      {isUpdateLoading && <Spinner2 message={"Updating..."} />}
      <form onSubmit={handleSubmit(handleUpdate)}>
        {/* <div className="mt-">
          <label htmlFor="passportPhoto" className="form-label m-0">
            Profile Photo upload
          </label>
          <div>
            <input
              type="file"
              name="passportPhoto"
              className="form-control form_control"
              id="passportPhoto"
              onChange={handlePassportchange}
            />
            <FileUploadStatus
              isLoading={passportLoading}
              isUploaded={passportUploaded}
              isPresent={user?.avatar}
            />
          </div>

          <small>
            <span className="mt-1 mb-3 text-danger">{passportError}</span>
          </small>
        </div> */}

        <div className="mb-4 mt-1">
          <label htmlFor="spType" className="form-label ms-0 me-0 mb-2 mt-3">
            What kind of service are you looking for?
          </label>

          {/* <MultiSelect
            options={options}
            value={selected}
            onChange={(data) => {
              setSelected(data);
              setChanges(true);
            }}
            labelledBy="Select"
          /> */}
          <ServicesSelector
            setValue={(data) => {
              setChanges(true);
              console.log(data);
              setSelected(data);
            }}
            multiple={true}
          />

          <small>
            <span className="mt-1 mb-3 text-danger">{skillError}</span>
          </small>
        </div>

        <div className="mb-4 mt-1">

          <TextField
            label="Prefered age group"
            size="small"
            helperText="Select the Age group of your preferred service providers"
            select
            fullWidth
            name="ageGroup"
            defaultValue={user?.ageGroup}
            {...register("ageGroup")}
            onClick={() => {
              setChanges(true);
            }}
          >
            <MenuItem value={""}>Choose one</MenuItem>
            <MenuItem value="20-25">20-25</MenuItem>
            <MenuItem value="26-30">26-30</MenuItem>
            <MenuItem value="31-35">31-35</MenuItem>
            <MenuItem value="36-40">36-40</MenuItem>
            <MenuItem value="41-45">41-45</MenuItem>
            <MenuItem value="46 and above">46 and above</MenuItem>
          </TextField>
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.ageGroup?.message}
              </span>
            </small>
          )}
        </div>
        <div className="mb-4 mt-1">

          <TextField
            size="small"
            type="number"
            label="House Hold Members"
            fullWidth
            onKeyUp={() => {
              setChanges(true);
            }}
            defaultValue={user?.specialNeeds}
            id="specialNeeds"
            {...register("specialNeeds")}
            error={errors && errors?.specialNeeds?.message}
            helperText={errors && errors?.specialNeeds?.message}
          />
        </div>

        {/* <div className="mb-4 mt-1">
          <label htmlFor="changedHelp" className="form-label m-0">
            How many of your domestic staff (kindly indicate if Nanny, Cook,
            Driver, etc. ) have you changed in the last six months?
          </label>
          <input
            type="text"
            name="changedHelp"
            className="form_control"
            onKeyUp={() => {
              setChanges(true);
            }}
            defaultValue={user?.changedHelp}
            id="changedHelp"
            {...register("changedHelp")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.changedHelp?.message}
              </span>
            </small>
          )}
        </div> */}
        {/* <div className="mb-4 mt-1">
          <label htmlFor="changeReason" className="form-label m-0">
            If more than three times for each, kindly specify reasons :
          </label>
          <input
            type="text"
            name="changeReason"
            onKeyUp={() => {
              setChanges(true);
            }}
            className="form_control"
            defaultValue={user?.changeReason}
            id="changeReason"
            {...register("changeReason")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.reasonForChange?.message}
              </span>
            </small>
          )}
        </div> */}
        {/* <div className="mb-4 mt-1">
          <label htmlFor="specialNeeds" className="form-label m-0">
            Special needs of the family/ individual?
          </label>
          <input
            type="text"
            name="specialNeeds"
            onKeyUp={() => {
              setChanges(true);
            }}
            className="form_control"
            defaultValue={user?.specialNeeds}
            id="specialNeeds"
            {...register("specialNeeds")}
          />
          {errors && (
            <small>
              <span className="mt-1 mb-3 text-danger">
                {errors?.specialNeeds?.message}
              </span>
            </small>
          )}
        </div> */}

        {/* <div className="d-flex align-items-center m-3">
          <input type="radio" className="me-2" />
          <span className="tnc">
            By clicking on “Create an account” you agree to our Terms &
            Conditions and Privacy Statement.
          </span>
        </div> */}
        <div className="d-flex">
          <span
            onClick={() => {
              closeForm();
            }}
            type="submit"
            className="btn_skip mt-2 p-2 bg-white rounded m-2 d-flex justify-content-center align-items-center"
            style={{
              color: `${colorSchema?.primary}`,
              border: `1px solid ${colorSchema?.primary}`,
            }}
          >
            Skip
          </span>
          <button
            type="submit"
            className="btn_submit mt-2 p-2 text-light rounded m-2"
            onClick={handleSkillValidation}
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            Finish
          </button>
        </div>
      </form>
      {showImageCropper && (
        <EasyImageCropper
          fullPhoto={fullPhoto}
          rawImage={rawUserPhoto}
          uploadCroppedPhoto={finalizePassportUpload}
          closeCropper={() => {
            setShowImageCropper(false);
          }}
        />
      )}
    </>
  );
}

export default ClientDetailsTwo;
