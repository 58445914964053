import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPaymentID: "",
};

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setCurrentPaymentID: (state, action) => {
      state.currentPaymentID = action.payload;
      localStorage.setItem("currentPaymentID", JSON.stringify(action.payload));
    },
  },
});

export const { setCurrentPaymentID } = PaymentSlice.actions;
export default PaymentSlice.reducer;
