/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { v4 as uuidv4 } from "uuid";
import Spinner2 from "../../components/spinners/Spinner1";
import { MenuItem, Divider, TextField, Grid, Button, InputAdornment, IconButton } from "@mui/material";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import PhoneInput from "react-phone-input-2";
import { registerUser } from "../../features/slices/AuthSlice";
import { registerSchema } from "../../Utils/validations/AuthValidations";
import "../../styles/Auth.css";

function Registration() {
  const backendUrl = useSelector((state) => state.globals.backendUrl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isAuth } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword2(!showPassword2);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema) });

  const handleRegister = async (data) => {
    if (!phone || phoneError) {
      console.log("no phone1", data);
      handlePhoneValidation();
    } else {
      console.log("no phone2", data);
      let { firstName, lastName, email, password, referredBy, role } = data;
      console.log(data);
      let userData = {
        firstName,
        lastName,
        phone,
        role,
        email,
        deviceId: uuidv4(),
        password,
        referredBy,
      };

      if (!referredBy) {
        delete userData.referredBy;
        toast.error("Incorrect referral code.");
      }
      if (!conditionsAgreed) {
        toast.error(
          "You must read and agree to the terms and conditions and privacy policy."
        );
      } else if (!role) {
        toast.error("You must Select Your Role before proceeding.");
      } else {
        dispatch(registerUser(userData));
      }
    }
  };


  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/dashboard");
    }

    // if (phone.length > 0) {
    //   handlePhoneValidation();
    // }
  }, [isAuth]);

  const handleChange = (newPhone) => {
    setPhone(newPhone)
    const isValid = matchIsValidTel(newPhone) // boolean
    console.log('VALID', isValid, newPhone)
    if (isValid) {
      setPhone(newPhone)
    } else {
      setPhoneError('Invalid phone number')
    }
  }

  // const handleGoogleLogin = async () => {
  //   window.open(backendUrl + "auth/google", "_self");
  // };

  return (
    <React.Fragment>
      {isLoading && <Spinner2 message={"Creating an Account..."} />}
      <div>
        <form className="px-2" onSubmit={handleSubmit(handleRegister)}>
          <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
            Please fill in the following details
          </Divider>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 3, md: 5 }}
          >
            <Grid item xs={12}>
              <MuiTelInput
                size="small"
                value={phone}
                label="Phone Number"
                onChange={handleChange}
                helperText={phoneError}
                defaultCountry="KE"
                length={10}
                fullWidth
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="First Name"
                required
                type="text"
                name="firstName"
                className="form_control"
                id="first_name"
                {...register("firstName")}
                error={errors && errors?.firstName?.message}
                helperText={errors && errors?.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name"
                required
                size="small"
                type="text"
                name="lastName"
                className="form_control"
                id="last_name"
                {...register("lastName")}
                error={errors && errors?.lastName?.message}
                helperText={errors && errors?.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                name="role"
                label="Select your Role"
                fullWidth
                required
                {...register("role")}
                error={errors && errors?.role?.message}
                helperText={errors && errors?.role?.message}
              >
                <MenuItem value="client">Client </MenuItem>
                <MenuItem value="serviceProvider">Service Provider</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Email Address"
                required
                placeholder="example@gmail.com"
                fullWidth
                type="email"
                name="email"
                id="exampleInputName"
                {...register("email")}
                error={errors && errors?.email?.message}
                helperText={errors && errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Password"
                required
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                className="form_control"
                id="exampleInputPassword1"
                {...register("password")}
                error={errors && errors?.password?.message}
                helperText={errors && errors?.password?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Confirm Password"
                required
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type={`${showPassword2 ? "text" : "password"}`}
                name="password2"
                className="form_control"
                id="exampleInputPassword1"
                {...register("password2")}
                error={errors && errors?.password2?.message}
                helperText={errors && errors?.password2?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Referal Code (Optional)"
                placeholder="type referal code"
                type="text"
                name="referredBy"
                className="form_control"
                id="referredBy"
                {...register("referredBy")}
                error={errors && errors?.referredBy?.message}
                helperText={errors ? "The code is optional for accounts created through referals" : errors?.referredBy?.message}
              />
            </Grid>
          </Grid>


          <div className="mt-3 mb-2 d-flex justify-content-center ">
            <input
              type={"checkbox"}
              style={{ cursor: "pointer", transform: "scale(1.1)" }}
              className="mx-2"
              value={conditionsAgreed}
              onChange={() => {
                setConditionsAgreed(!conditionsAgreed);
              }}
            />
            <span className="fontsm1" style={{ width: "95%" }}>
              Check this box to indicate that you have read and agreed to our
              <b>
                <span
                  className="text-primary mx-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/terms_and_conditions");
                  }}
                >
                  terms and conditions
                </span>
              </b>
              and
              <b>
                <span
                  className="text-primary mx-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/privacy_policy");
                  }}
                >
                  privacy policy
                </span>
              </b>
            </span>
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handlePhoneValidation}
            fullWidth
          >
            {!isLoading ? (
              "Create Account"
            ) : (
              <>
                Authenticating...
                <div
                  className="spinner-border text-light ms-3 spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            )}
          </Button>
          <br />
          <small className="mt-2 d-flex flex-column align-items-center">
            <span className=" mx-auto mb-1"> {t("auth.has_account")}</span>
            <span className="d-flex justify-content-center">
              <b>
                <small
                  className="signup_text me-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  LOGIN
                </small>
              </b>
            </span>
          </small>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Registration;
