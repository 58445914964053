import { useEffect, useState } from "react";
import "../../styles/Contract.css";
import { BiExpand } from "react-icons/bi";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsPaperclip } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import Contract from "./Index";
function ContractListItem({ contract }) {
  const [showContract, setShowContract] = useState("");
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    console.log("my contract");
    console.log(contract);

    return () => {};
  }, []);

  return (
    <>
      <div
        id="contractListItem"
        className="shadow-sm m-2 rounded p-3 bg-white w-100 d-flex justify-content-between align-items-center border"
        style={{ height: "fit-content", position: "relative" }}
      >
        <BsPaperclip
          size={25}
          style={{ position: "absolute", left: "0px", top: "-5px" }}
        />
        <div className="ms-2 row m-0 w-100">
          <div className="col-sm-3">
            {user.role.title === "client"
              ? contract.provider.firstName +
                  " " +
                  contract.provider.lastName || "John Doe"
              : contract.client.firstName + " " + contract.client.lastName ||
                "John Doe"}
          </div>
          <div className="col-sm-3">
            <b>status:</b>
            {contract?.booking?.status === "pending" ||
            contract?.booking?.status === "active" ||
            contract?.booking?.status === "accepted" ? (
              <>
                {contract.clientSigned && contract.providerSigned ? (
                  <div
                    className={`badge ms-2 pt-1 pb-1 ps-2 pe-2  rounded-pill bg-success`}
                  >
                    active
                  </div>
                ) : (
                  <div
                    className={`badge ms-2 pt-1 pb-1 ps-2 pe-2 rounded-pill bg-warning`}
                  >
                    pending
                  </div>
                )}
              </>
            ) : (
              <>
                {contract?.booking?.status === "completed" && (
                  <div
                    className={`badge ms-2 pt-1 pb-1 ps-2 pe-2 rounded-pill bg-danger`}
                  >
                    completed
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-sm-6 d-flex fontsm">
            <b>Validity:</b>
            <div className=" ms-2 fontsm">
              From{" "}
              {moment(
                moment(contract?.schedule?.start)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .split(" ")[0]
              ).format("Do MMMM  YYYY")}
            </div>
            <div className="ms-2 fontsm">
              To{" "}
              {moment(
                moment(contract?.schedule?.end)
                  .format("YYYY-MM-DD HH:mm:ss")
                  .split(" ")[0]
              ).format("Do MMMM  YYYY")}
            </div>
          </div>
        </div>

        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip id={`tooltip-top}`} className="font-sm1">
              view contract
            </Tooltip>
          }
        >
          <span>
            <BiExpand
              className="contractExpandIcon hoverUpEffect text-primary"
              size={20}
              onClick={() => {
                setShowContract(true);
              }}
            />
          </span>
        </OverlayTrigger>
      </div>
      {showContract && (
        <Contract
          details={contract}
          closeContract={() => {
            document.body.style.overflow = "visible";
            setShowContract(false);
          }}
        />
      )}
    </>
  );
}

export default ContractListItem;
