import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export const registerSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter last name"),
  email: yup.string().email().required("Please enter email"),
  // phone: yup.string().required("Please enter phone number"),
  password: yup
    .string()
    .required("Please enter password")
    .min(6, "Password too short"),
  password2: yup.string().oneOf([yup.ref("password"), null]),
  referredBy: yup.string(),
});
