import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
function ServicesSelector({ setValue, multiple }) {
  const authUser = useSelector((state) => state.auth.user);
  let userAutoComplete = [];
  const { autoComplete, loading: servicesLoading } = useSelector(
    (state) => state.services
  );

  let initialRender = true;

  useEffect(() => {
    // authUser?.skills?.forEach((skill) => {
    //   setCurrentUserSkills(...currentUserSkills, {
    //     value: skill._id,
    //     label: skill.title,
    //   });
    // });
    if (initialRender) {
      authUser?.skills?.forEach((skill) => {
        userAutoComplete.push({ value: skill._id, label: skill.title });
      });
      initialRender = false;
    }
    setTimeout(() => {
      console.log("userAutoComplete",autoComplete);
      console.log(userAutoComplete);
    }, 1000);
  }, []);

  return (
    <>
      <Select
        defaultValue={userAutoComplete}
        options={autoComplete}
        placeholder={"Which service are you looking for?"}
        isLoading={servicesLoading}
        isMulti={multiple || false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100% !important",
          }),
        }}
        onChange={(e) => {
          setValue(e);
        }}
      />
    </>
  );
}

export default ServicesSelector;
