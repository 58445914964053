import React, { useState } from "react";
import NotificationItem from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Dashboard/Notifications.css";
import { AiOutlineClose } from "react-icons/ai";
import { closeTabs } from "../../features/slices/DashboardControlSlice";

function Notifications() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const { notifications, loading, error } = useSelector(
    (state) => state.notifications
  );
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("all");

  return (
    <>
      <div className="w-100 p-3 rounded m-0">
        <span>
          <div className="d-flex  justify-content-between">
            <h5 className="m-0 p-0">
              <b>Notifications</b>
            </h5>
            <AiOutlineClose
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(closeTabs());
              }}
            />
          </div>

          <div className="d-flex mt-1">
            <span
              className="badge me-2 mt-2 p-2 rounded-pill "
              style={{
                backgroundColor: `${
                  activeTab === "all" ? colorSchema?.primary : "transparent"
                }`,
                color: `${
                  activeTab === "all" ? "white" : colorSchema?.primary
                }`,
                border: `1px solid ${colorSchema?.primary}`,
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => setActiveTab("all")}
            >
              All
            </span>
            <span
              className="badge me-2 mt-2 p-2 rounded-pill "
              style={{
                border: `1px solid ${colorSchema?.primary}`,

                backgroundColor: `${
                  activeTab === "unread" ? colorSchema?.primary : "transparent"
                }`,
                color: `${
                  activeTab === "unread" ? "white" : colorSchema?.primary
                }`,
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => setActiveTab("unread")}
            >
              Unread
            </span>
          </div>
        </span>
      </div>
      <div className="notificationsScrollContainer">
        {loading ? (
          <div className="w-100 d-flex justify-content-center p-3">
            <div className="spinner spinner-border spinner-border-sm text-primary"></div>
          </div>
        ) : (
          <>
            {notifications?.length > 0 ? (
              <>
                {notifications.map((notification) => (
                  <NotificationItem
                    key={notification._id}
                    notification={notification}
                  />
                ))}
              </>
            ) : (
              <div className="w-100 d-flex justify-content-center p-3">
                <div className="alert alert-warning fontsm">
                  You don't have any notifications currently
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Notifications;
