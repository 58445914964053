import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import JobSearch from "../../pics/job_search.gif";
function Careers() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"about"} />
      <div className="row m-0 ">
        <div className="m-5 p-5 col-sm-5 mx-auto" style={{ minHeight: "80vh" }}>
          <h4>Become a Maids of Honour agent!</h4>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Careers;
