import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../Utils/AxiosWrapper";

const initialState = {
  notifications: JSON.parse(localStorage.getItem("notifications")) || [],
  loading: false,
  error: false,
};

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (userId) => {
    const notificationsResponse = await axiosWrapper({
      method: "GET",
      url: `comms/notifications?user=${userId}`,
    });

    if (notificationsResponse.data.success) {
      return notificationsResponse.data.data;
    }
  }
);

const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotifications: (state, action) => {
      state.notifications = [];
      localStorage.removeItem("notifications");
    },
  },
  extraReducers: {
    [fetchNotifications.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      console.log("Notifs received");
      console.log(action.payload);
      state.notifications = action.payload.rows;
      localStorage.setItem(
        "notifications",
        JSON.stringify(action.payload.rows)
      );
    },
    [fetchNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { clearNotifications } = NotificationsSlice.actions;
export default NotificationsSlice.reducer;
