import React from "react";
import "../../styles/assessment/index.css";
import { BsQuestionSquare } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { useSelector } from "react-redux";
function QuizIntro({ startQuiz }) {
  const colorSchema = useSelector((state) => state.color.scheme);
  const {
    currentAssessment,
    currentAssessmentQuestions,
    currentAssessmentScore,
  } = useSelector((state) => state.courses);

  return (
    <div className="row m-0 p-5 w-100 ">
      <div
        className="col-sm-10 shadow rounded mx-auto p-4 d-flex flex-column"
        style={{ backgroundColor: "#f0f6ff" }}
      >
        <div
          id="IntroHeader"
          className="pt-4 pb-4 ps-2 pe-2 d-flex justify-content-between w-100"
          style={{ borderBottom: "1px solid #dadada" }}
        >
          <span className="normalHeader fw-bold">
            {currentAssessment?.title}
          </span>
          <div className="d-flex">
            <span className="d-flex fontsm align-items-center me-3 fw-bold">
              <BsQuestionSquare className="me-1" />
              {currentAssessmentQuestions?.length} Question
              {currentAssessmentQuestions?.length !== 1 && "s"}
            </span>
            <span className="d-flex fontsm align-items-center fw-bold">
              <MdTimer size={15} className="me-1" />
              {currentAssessment.duration}
            </span>
          </div>
        </div>
        <div id="IntroBody" className="w-100 d-flex flex-column mt-2 mb-2">
          <span className=" fw-bold mt-2">
            Before taking this quiz, you should have done the following
            course(s).
          </span>
          <span className="fontsm mt-3 mb-3">
            <ul>
              {currentAssessment.prerequisites.map((course) => (
                <li>
                  <p>{course.title}</p>
                  <span>{course.description}</span>
                </li>
              ))}
            </ul>
            <br />
            <br />
            Please read the questions carefully before answering.
          </span>
        </div>
        <button
          className="rounded text-white fontsm pt-1 pb-1 hoverDownEffect"
          style={{ backgroundColor: `${colorSchema?.primary}`, border: "none" }}
          onClick={startQuiz}
        >
          {!currentAssessmentScore?.total ? (
            "Begin the quiz"
          ) : (
            <>
              {currentAssessmentScore?.answers ===
                currentAssessmentScore?.total && "Check your results"}
              {currentAssessmentScore?.answers !==
                currentAssessmentScore?.total &&
                currentAssessmentScore?.answers > 1 &&
                "Continue"}
            </>
          )}

          <AiOutlineRight className="ms-1 " />
        </button>
      </div>
    </div>
  );
}

export default QuizIntro;
