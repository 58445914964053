import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import {
  BsCheckCircleFill,
  BsArrowCounterclockwise,
  BsCrop,
} from "react-icons/bs";

import { AiOutlineClose } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import jb1 from "../../pics/jb1.jpg";

import getCroppedImg from "./cropImage";
import "../../styles/utils/ImageCropper.css";
import Spinner1 from "../spinners/Spinner1";
import { useSelector } from "react-redux";
const dogImg =
  "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

const EasyImageCropper = ({
  fullPhoto,
  rawImage,
  uploadCroppedPhoto,
  closeCropper,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [doneCropping, setDoneCropping] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [finalBlob, setFinalBlob] = useState(false);
  const authUser = useSelector((state) => state.auth.user);

  const zoomRef = useRef(null);
  const rotateRef = useRef(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true);
    try {
      const { myBlob, file: blobURL } = await getCroppedImg(
        rawImage,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(myBlob);
      setFinalBlob(blobURL);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const resetCrop = () => {
    window.scroll(0, 0);
    setCrop({ x: 0, y: 0 });
    // rotateRef.current?.value = 0;
    setRotation(0);
    // zoomRef?.current?.value = 1;
    setZoom(1);
    setDoneCropping(false);
  };

  useEffect(() => {
    // console.log("Starting with this image");
    // const fileExt = fullPhoto.name.split(".")[1];
    // console.log("file type");
    // console.log(fileExt);
  }, []);

  const handleFinish = async () => {
    const reader = new FileReader();

    // console.log("croppedImage");
    // console.log(croppedImage);
    // console.log("my blob");
    // console.log(finalBlob);
    // console.log("type of ");
    // console.log(typeof croppedImage);

    const myFile = new File([finalBlob], fullPhoto.name.toLowerCase(), {
      type: finalBlob.type,
      lastModified: new Date().getTime(),
    });

    // console.log("myFile created");
    // console.log(myFile);

    uploadCroppedPhoto(croppedImage, myFile);
  };

  return (
    <div
      className="w-100 m-0 p-2 row d-flex align-items-start "
      id="cropContainer"
      style={{ position: "absolute" }}
    >
      <div
        className="col-sm-5 bg-light mx-auto mt-5 rounded shadow"
        style={{ position: "relative" }}
      >
        <div
          className="d-flex justify-content-end"
          style={{ position: "absolute", right: "10px", top: "5px" }}
        >
          <AiOutlineClose
            className="hoverUpEffect m-2"
            size={25}
            onClick={closeCropper}
          />
        </div>
        {!doneCropping ? (
          <>
            <div className="p-3">
              <center>
                <h4>Crop your photo</h4>
                {authUser && authUser.role.title === "serviceProvider" && (
                  <span className="lightDark">
                    <small>
                      No makeup and no hair on face. Long hair should be neatly
                      held back and short hair should be neatly combed. Have a
                      plain black top on a plain background.
                    </small>
                  </span>
                )}
                <small>
                  <span>{rawImage.type}</span>
                </small>
              </center>

              <div
                className="col-md-6 mt-2 bg-light w-100"
                style={{ position: "relative", height: "350px" }}
              >
                <Cropper
                  image={rawImage && rawImage}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  // aspect={16 / 9}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  showGrid={true}
                  cropSize={{ width: 300, height: 250 }}
                />
              </div>

              <div className="mt-2">
                <label htmlFor="zoomRange">Zoom</label>

                <input
                  type="range"
                  class="form-range text-primary"
                  id="zoomRange"
                  ref={zoomRef}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  defaultValue={1}
                  onChange={(e, zoom) => {
                    // setZoom(zoom);
                    setZoom(e.target.value);
                  }}
                />
              </div>
              <div className="mt-2">
                <label htmlFor="rotateRange">Rotate</label>

                <input
                  type="range"
                  class="form-range text-primary"
                  id="rotationRange"
                  ref={rotateRef}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  defaultValue={1}
                  onChange={(e, zoom) => {
                    setRotation(e.target.value);
                  }}
                />
              </div>

              <span className="d-flex align-items-center p-3 justify-content-center  w-100 ">
                <button
                  className="d-flex align-items-center  me-3 btn btn-sm btn-warning"
                  onClick={() => {
                    resetCrop();
                    rotateRef.current.value = 0;
                    zoomRef.current.value = 1;
                  }}
                >
                  Reset
                  <BsArrowCounterclockwise
                    size={20}
                    className="ms-1"
                    // onClick={showCroppedImage}
                  />
                </button>
                <button
                  className="d-flex align-items-center btn btn-sm btn-success"
                  onClick={() => {
                    showCroppedImage();
                    setDoneCropping(true);
                    window.scroll(0, 0);
                  }}
                >
                  Crop
                  <BsCrop
                    size={20}
                    className="ms-2"
                    // onClick={showCroppedImage}
                  />
                </button>
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="p-3 d-flex flex-column">
              <center>
                <h4>Final result</h4>
              </center>
              <div
                className="mt-2 w-100 d-flex justify-content-center mt-3 position-relative"
                style={{ minHeight: "250px" }}
              >
                {loading ? (
                  <Spinner1 message={"Loading your photo..."} />
                ) : (
                  <img
                    src={croppedImage}
                    alt="crop result"
                    style={{ width: "300px", height: "250px" }}
                  />
                )}
              </div>
              <span className="d-flex align-items-center p-3  justify-content-center w-100 ">
                <button
                  className="d-flex align-items-center btn btn-sm btn-danger me-3"
                  onClick={resetCrop}
                >
                  Cancel <MdCancel size={20} className="ms-2" />
                </button>
                <button
                  className="d-flex align-items-center btn btn-sm btn-success"
                  onClick={handleFinish}
                >
                  Proceed
                  <BsCheckCircleFill
                    size={20}
                    className="ms-2"
                    // onClick={showCroppedImage}
                  />
                </button>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EasyImageCropper;
