import * as React from "react";
import {
  BottomNavigation,
  Paper,
  Badge,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import GavelIcon from "@mui/icons-material/Gavel";
import BadgeIcon from "@mui/icons-material/Badge";
import { useSelector } from "react-redux";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocalStorage } from "react-use";
import StorageIcon from "@mui/icons-material/Storage";

export default function BottomNavbar() {
  const [value, setValue] = useLocalStorage("bottomPath");
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.auth.user);
  const { data: allMessages } = useSelector((state) => state.messages.chats);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${newValue}`);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, mt: 16 }}
      elevation={3}
      pt={7}
    >
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Dashboard"
          value="/dashboard"
          icon={<HomeIcon />}
        />
        {authUser.role?.title === "serviceProvider" ? (
          <BottomNavigationAction
            label="Requests"
            value="/dashboard/requests"
            icon={<HomeRepairServiceIcon />}
          />
        ) : (
          <BottomNavigationAction
            label="Bookings"
            value="/dashboard/bookings"
            icon={<StorageIcon />}
          />
        )}

        {authUser.role?.title === "serviceProvider" ? (
          <BottomNavigationAction
            label="Courses"
            value="/dashboard/courses"
            icon={<SchoolIcon />}
          />
        ) : (
          <BottomNavigationAction
            label="Hire"
            value="/dashboard/hire"
            icon={<AddCircleOutlineIcon />}
          />
        )}
        <BottomNavigationAction
          label="Contracts"
          value="/dashboard/contracts"
          icon={<GavelIcon />}
        />
        <BottomNavigationAction
          label="Chats"
          value="/dashboard/chats"
          icon={
            <Badge badgeContent={allMessages.length} color="error">
              <ChatIcon />
            </Badge>
          }
        />
      </BottomNavigation>
    </Paper>
  );
}
