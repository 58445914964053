import { useState, useEffect } from "react";
import "../../styles/Course/Courses.css";

import CoursePreview from "./CourseContent/CoursePreview";
import { getCourses } from "../../features/slices/CourseSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner1 from "../../components/spinners/Spinner1";
import DashboardLayout from "../Dashboard/DashboardLayout";
import { Box, Grid, useMediaQuery, Container } from '@mui/material';

function Courses({ selectTab }) {
  const { courses, loading } = useSelector((state) => state.courses);
  return (
        <Container maxWidth="lg" component="main" sx={{ p: 2 }} >
          <div className="col-sm-9 d-flex flex-column mb-1 p-2">
            <span className="mb-1">OUR COURSES</span>
            <span id="line" className="mt-2 mb-1"></span>
          </div>
          <Grid container spacing={3} sx={{ mb: 6, p: 1 }}>
            <Grid item md={4} sm={12}>
              <CoursePreview/>
            </Grid>
          </Grid>
        </Container>
  );
}

export default Courses;
