import React, { useState, useEffect } from "react";
import "../../styles/assessment/index.css";
import { BsQuestionSquare } from "react-icons/bs";
import { MdTimer } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import QuestionItem from "./QuestionItem";
import Results from "./Results";
import ProgressBar2 from "../../components/ProgressBars/ProgressBar2";
import { toast } from "react-hot-toast";
import ResultsLoader from "./ResultsLoader";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import {
  setCurrentAssessmentScore,
  setCurrentQuestionIndex,
} from "../../features/slices/CourseSlice";

function Questions({ closeResults }) {
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const colorSchema = useSelector((state) => state.color.scheme);
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const [expiryCounter, setExpiryCounter] = useState(900);
  const {
    currentAssessment,
    currentAssessmentQuestions: questions,
    currentQuestionIndex,
    currentAssessmentScore,
  } = useSelector((state) => state.courses);

  const [currentQuestion, setCurrentQuestion] = useState(
    questions[currentQuestionIndex]
  );
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const [calculatingResults, setCalculatingResults] = useState(false);
  const [showResults, setShowResults] = useState(currentAssessmentScore);

  const [results, setResults] = useState(currentAssessmentScore);
  const [score, setScore] = useState(0);

  const [nextQuestionLoading, setNextQuestionLoading] = useState(false);

  const handleChosenAnswer = (chosenAnswer) => {
    console.log("chosen answer is ", chosenAnswer);
    setCurrentAnswer(chosenAnswer);
  };

  useEffect(() => {
    setCurrentQuestion("");
    setCurrentQuestion(questions[currentQuestionIndex]);
  }, [currentQuestionIndex]);

  const handleNextQuestion = async () => {
    //check if answered
    if (!currentAnswer) return toast.error("You must select an answer.");

    setNextQuestionLoading(true);
    try {
      //send answer to backend.
      let answerData = {
        assessment: currentAssessment._id,
        candidate: user.sub || user._id,
        question: currentQuestion._id,
        answer: currentAnswer,
      };

      const submitAnswer = await axiosWrapper({
        method: "post",
        url: "assessments/answers",
        data: answerData,
      });

      const results = submitAnswer.data.data.results;
      console.log("results results");
      console.log(results);
      setResults(results);
      dispatch(setCurrentAssessmentScore(results));

      if (submitAnswer.data.success) {
        const percentage = Math.floor(
          ((selectedAnswers.length + 1) / questions.length) * 100
        );
        setPercentageCompleted(percentage);

        selectedAnswers.push(currentAnswer);

        setCurrentAnswer("");
        if (questions.length === 1) {
          console.log("only one answer");
          return handleAnswers();
        }
        if (!isLastQuestion) {
          if (selectedAnswers.length === questions.length - 1) {
            setIsLastQuestion(true);
          }
          setNextQuestionLoading(false);

          dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
        } else {
          setNextQuestionLoading(false);
          handleAnswers();
        }
      }
    } catch (error) {
      setNextQuestionLoading(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleAnswers = () => {
    setCalculatingResults(true);
    setScore(results.percentage);
    setShowResults(true);
    setCalculatingResults(false);
  };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  useEffect(() => {
    setTimeout(() => {
      if (expiryCounter > 0 && percentageCompleted < 100) {
        setExpiryCounter(expiryCounter - 1);
      }
    }, 1000);
  }, [expiryCounter]);

  return (
    <div className="row m-0 pt-5">
      <div
        className="col-sm-9 shadow mx-auto p-4 d-flex flex-column align-items-center"
        style={{ backgroundColor: "#f0f6ff" }}
      >
        <div
          id="IntroHeader"
          className="pt-4 row m-0 pb-4 ps-2 pe-2 d-flex align-items-center justify-content-between w-100"
          style={{ borderBottom: "1px solid #dadada" }}
        >
          <div className="d-flex fontsm align-items-center justify-content-between ">
            <h5 className="normalHeader fw-bold col-sm-5">
              {currentAssessment.title}
            </h5>
            <div
              // style={{ width: "60%" }}
              className="d-flex  align-items-center col-sm-7 mt-2     justify-content-between "
            >
              <div
                className=" d-flex align-items-center"
                style={{ width: "80%" }}
              >
                <div className="" style={{ width: "60%" }}>
                  <ProgressBar2 completed={percentageCompleted} />
                </div>
                <span className="fontsm1 lightDark ms-1 ">
                  Completed {percentageCompleted}%
                </span>
              </div>
              <span
                className="d-flex fontsm align-items-center justify-content-center text-white p-2 "
                style={{
                  backgroundColor: `${colorSchema?.primary}`,
                  border: "none",
                  width: "80px",
                }}
              >
                <MdTimer className="me-1" size={18} />
                {fancyTimeFormat(expiryCounter)}
              </span>
            </div>
          </div>
        </div>
        <div className="w-100 m-0" style={{ minHeight: "40vh" }}>
          {calculatingResults ? (
            <ResultsLoader />
          ) : (
            <>
              {!showResults ? (
                <>
                  <QuestionItem
                    index={questions.indexOf(currentQuestion)}
                    question={currentQuestion}
                    handleNextQuestion={handleChosenAnswer}
                  />
                  <button
                    className="rounded w-100 text-white fontsm justify-content-center mt-3 mb-3 pt-1 pb-1 hoverDownEffect d-flex align-items-center"
                    style={{
                      backgroundColor: `${colorSchema?.primary}`,
                      border: "none",
                    }}
                    onClick={handleNextQuestion}
                  >
                    {!nextQuestionLoading ? (
                      <>
                        {isLastQuestion ? "Submit answers" : "Next question"}
                        <AiOutlineRight className="ms-1 " />
                      </>
                    ) : (
                      <>
                        Please wait
                        <span className="spinner-border spinner-border-sm text-white ms-2"></span>
                      </>
                    )}
                  </button>
                </>
              ) : (
                <Results
                  closeResults={closeResults}
                  score={results?.score}
                  percentageScore={results.percentage}
                  total={questions.length}
                  time={fancyTimeFormat(expiryCounter)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Questions;
