import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Dashboard/DashboardNav.css";
import { FaBars } from "react-icons/fa";
import Avatar from "../../pics/avatar.png";

import Logo2 from "../../pics/logo2.png";
function Policies({ currentPage }) {
  const navigate = useNavigate();
  const colorSchema = useSelector((state) => state.color.scheme);
  const [activePage, setActivePage] = useState("policy");

  useEffect(() => {
    window.scroll({
      left: "0",
      top: "0",
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <>
        <div className=" bg-light m-0">
          <nav
            className="navbar navbar-expand-lg pt-2 pb-2 "
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            <div className="container-fluid">
              <span
                className="navbar-brand d-flex align-items-center"
                id="navBrand"
                style={{ color: "#E91E63", cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  id="logo"
                  src={Logo2}
                  alt={"MOH logo"}
                  className="me-2"
                  style={{ width: "50px" }}
                />
                <span className="d-flex flex-column">
                  Maids of Honour Africa
                  <small className="fontsm1">Service is greatness</small>
                </span>
              </span>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FaBars className="text-white" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 pe-3 mb-lg-0">
                  <li className="nav-item ">
                    <Link
                      to={"/privacy_policy"}
                      className={`nav-link ${activePage === "policy" ? "activeTab" : ""
                        } `}
                      aria-current="page"
                    >
                      Privacy policy
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to={"/terms_of_use"}
                      className={`nav-link ${activePage === "terms_of_use" ? "activeTab" : ""
                        } `}
                      aria-current="page"
                    >
                      Terms of use
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to={"/terms_and_conditions"}
                      className={`nav-link ${activePage === "terms_and_conditions" ? "activeTab" : ""
                        } `}
                      aria-current="page"
                    >
                      Terms and conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </>
    </div>
  );
}

export default Policies;
