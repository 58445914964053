import { useState, useEffect, useRef } from "react";
import "../../styles/Contract.css";
import logo from "../../pics/logo2.png";
import LetterHeadTop from "../../pics/letter-head-top.png";
import moment from "moment";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import LiveIn from "./LiveIn";
import OneOff from "./OneOff";
import { MdClose, MdCheck } from "react-icons/md";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Spinner1 from "../../components/spinners/Spinner1";
import { useNavigate } from "react-router-dom";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint from "react-to-print";
import converter from "number-to-words";

function Contract({ signContract, closeContract, details }) {
  // import ReactToPrint from "react-to-print";
  const ContractRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const handleSignContract = async () => {
    setIsLoading(true);
    try {
      let data;
      if (user.role.title === "client") {
        data = { clientSigned: true };
      } else {
        data = { providerSigned: true };
      }

      const updateContractStatus = await axiosWrapper({
        method: "patch",
        url: `contracts/${details._id}`,
        data,
      });

      console.log("details");
      console.log(details);
      console.log("contract response");
      console.log(updateContractStatus);

      if (updateContractStatus.data.success) {
        if (
          updateContractStatus.data.data.clientSigned &&
          updateContractStatus.data.data.providerSigned
        ) {
          const updateBooking = await axiosWrapper({
            method: "patch",
            url: `bookings/${details.booking._id}`,
            data: { status: "accepted" },
          });

          console.log("update booking");
          console.log(updateBooking);
          if (updateBooking.data.success) {
            closeContract();
            toast.success("Contract accepted and signed successfully!");
            if (user.role.title === "client") {
              navigate("/dashboard/bookings");
              toast.success(
                "All parties have signed. Kindly update your booking status when the job has started."
              );
            }
          }
        } else {
          closeContract();
          toast.success("Contract accepted and signed successfully!");
        }
      }
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  // const handleGeneratePdf = async () => {
  //   const element = ContractRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF();
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("print.pdf");
  // };

  // const handleGeneratePdf = () => {
  //   let OPTIONS = {
  //     jsPDF: {
  //       orientation: "p",
  //       unit: "px",
  //       format: "a4",
  //       putOnlyUsedFonts: false,
  //       compress: false,
  //       precision: 2,
  //       userUnit: 1.0,
  //     },
  //     html2canvas: {
  //       allowTaint: false,
  //       backgroundColor: "#ffffff",
  //       canvas: null,
  //       foreignObjectRendering: false,
  //       imageTimeout: 15000,
  //       logging: false,
  //       onclone: null,
  //       proxy: null,
  //       removeContainer: true,
  //       scale: window.devicePixelRatio,
  //       useCORS: false,
  //     },
  //   };
  //   let fileName = "myContract";
  //   const targetElm = ContractRef.current;
  //   let totalHeight = targetElm.offsetHeight;
  //   const pdf = new jsPDF(
  //     OPTIONS.jsPDF.orientation,
  //     OPTIONS.jsPDF.unit,
  //     OPTIONS.jsPDF.format
  //   );
  //   const pdfWidth = pdf.internal.pageSize.width;
  //   const pdfHeight = pdf.internal.pageSize.height;
  //   window.scrollTo(0, 0);
  //   html2canvas(targetElm, OPTIONS.html2canvas).then((canvas) => {
  //     const widthRatio = pdfWidth / canvas.width;
  //     const sX = 0;
  //     const sWidth = canvas.width;
  //     const sHeight =
  //       pdfHeight + (pdfHeight - pdfHeight * widthRatio) / widthRatio;
  //     const dX = 0;
  //     const dY = 0;
  //     const dWidth = sWidth;
  //     const dHeight = sHeight;
  //     let pageCnt = 1;
  //     while (totalHeight > 0) {
  //       totalHeight -= sHeight;
  //       let sY = sHeight * (pageCnt - 1);
  //       const childCanvas = document.createElement("CANVAS");
  //       childCanvas.setAttribute("width", sWidth);
  //       childCanvas.setAttribute("height", sHeight);
  //       const childCanvasCtx = childCanvas.getContext("2d");
  //       childCanvasCtx.drawImage(
  //         canvas,
  //         sX,
  //         sY,
  //         sWidth,
  //         sHeight,
  //         dX,
  //         dY,
  //         dWidth,
  //         dHeight
  //       );
  //       if (pageCnt > 1) {
  //         pdf.addPage();
  //       }
  //       pdf.setPage(pageCnt);
  //       pdf.addImage(
  //         childCanvas.toDataURL("image/png"),
  //         "PNG",
  //         0,
  //         0,
  //         canvas.width * widthRatio,
  //         0
  //       );
  //       pageCnt++;
  //     }
  //     if (fileName == null) {
  //       fileName = "";
  //     } else {
  //       fileName += "_";
  //     }
  //     // fileName += this.getCurrentDateStr();
  //     pdf.save(fileName);
  //   });
  //   window.scrollTo(
  //     0,
  //     document.body.scrollHeight || document.documentElement.scrollHeight
  //   );
  // };

  // const handleGeneratePdf = () => {
  //   const pdf = new jsPDF({
  //     orientation: "p",
  //     unit: "pt",
  //     format: [1775, 1300],
  //   });
  //   // pdf.setFont()
  //   setTimeout(() => {
  //     pdf.html(ContractRef.current, {
  //       async callback(pdf) {
  //         //check if rendered before saved;
  //         // console.log(pdf);
  //         // console.log(pdf);

  //         await pdf.save("contract");
  //       },
  //     });
  //   }, 2000);
  // };

  return (
    <div id="contractModalContainer" className="row m-0">
      <>
        <div
          id="contractDiv"
          className="col-sm-8 ps-4 pe-4 pt-5 pb-5 bg-white animate__animated animate__fadeIn shadow"
          style={{ position: "relative" }}
        >
          <div
            className="d-flex w-100  justify-content-end"
            style={{ position: "absolute", right: "10px", top: "10px" }}
          >
            <MdClose
              size={30}
              className="hoverUpEffect"
              onClick={closeContract}
            />
          </div>

          <div>
            {details.clientSigned && details.providerSigned && (
              <>
                {/* <div className=" d-flex m-3 ">
                <button
                  className="btn btn-success me-3"
                  onClick={handleGeneratePdf}
                >
                  Download Contract
                </button>
              </div> */}
                <ReactToPrint
                  trigger={() => (
                    <button
                      className="btn btn-warning btn-sm "
                      style={{
                        position: "absolute",
                        right: "50px",
                        top: "10px",
                      }}
                    >
                      <FaDownload
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  )}
                  content={() => ContractRef.current}
                />
              </>
            )}
          </div>
          <div
            className="w-100  "
            style={{ position: "relative" }}
            ref={ContractRef}
          >
            <div className=" d-flex align-items-center justify-content-between">
              <div className=" d-flex align-items-center">
                {/* <span
                  style={{ color: "#e44977" }}
                  className="headerNormal fw-bold"
                >
                  Maids of Honour Africa
                </span> */}
              </div>
              <span className="fw-bold">
                Date: {moment(Date.now()).format("MMMM Do, YYYY")}
              </span>
            </div>
            {/* <div className="d-flex justify-content-center w-100 ps-2 pe-2">
              <span
                className="fw-bold mt-2  w-100 text-center"
                style={{ borderBottom: "1px solid #303030" }}
              >
                EMPLOYMENT CONTRACT
              </span>
            </div>
            {details.jobType === "oneOff" ? (
              <OneOff details={details} />
            ) : (
              <LiveIn details={details} />
            )} */}
          </div>
          {/* <img
            src={LetterHeadTop}
            alt="Logo"
            className="w-100"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
            }}
          /> */}

          <div
            style={{ lineHeight: "1.6" }}
            className="w-100 p-4"
            ref={ContractRef}
          >
            <center>
              <div className="mb-3">
                <b>
                  <u>SERVICE AGREEMENT</u>
                </b>
              </div>
            </center>
            <div className="">
              THIS CONTRACT FOR SERVICE IS MADE THIS DAY OF{" "}
              <span className="ms-2 me-2">
                <b>
                  <u>
                    {moment(`${details.createdAt}`).format("MMMM Do, YYYY")}
                  </u>
                </b>
              </span>
              AND BETWEEN:{" "}
              <span className="ms-2 me-2">
                <b>
                  <u>
                    {details.client.firstName + " " + details.client.lastName}
                  </u>
                </b>
              </span>
              , an individual of Passport/Identity card Number{" "}
              <span className="ms-2 me-2">
                <b>
                  <u>{details.client.idNo}</u>
                </b>
              </span>
              . whose residence is at{" "}
              <span className="ms-2 me-2">
                <b>
                  <u>{details.client.placeOfResidence}</u>
                </b>
              </span>
              . (Which expression shall wherever the context so admits includes
              its successors-in-title and assigns) of the one part; (hereinafter
              referred to as <b>‘the Client’</b>);
            </div>
            <center>
              <div className="m-3">
                <b>AND</b>
              </div>
            </center>
            <div>
              <span className="ms-2 me-2">
                <b>
                  <u>
                    {details.provider.firstName +
                      " " +
                      details.provider.lastName}
                  </u>
                </b>
              </span>
              an individual of passport/ID number
              <span className="ms-2 me-2">
                <b>
                  <u>{details.provider.idNo}</u>
                </b>
              </span>
              working and residing in the Republic of Kenya whose residing at
              <span className="ms-2 me-2">
                <b>
                  <u>{details.provider.placeOfResidence}</u>
                </b>
              </span>
              . (hereinafter referred to as <b>‘the Service Provider'</b>),
              which expression shall where the context so admits include his
              personal representatives and successors of the second part;
            </div>
            <div className="mt-2">
              (Each of whom shall hereinafter be referred to as a party and
              collectively as parties)
            </div>
            <div className="mt-2">
              <b>WHEAREAS:</b>
            </div>
            <div className="ps-3">
              <div className="mt-2 d-flex">
                <span className="me-2"> A.</span>
                <span>
                  The Service Provider is contracted in the capacity of
                  <span className="ms-2 me-2">
                    <b>
                      <u>{details.service.title}</u>
                    </b>
                  </span>{" "}
                  expected to offer such services as highlighted below.
                </span>
              </div>
              <div className="mt-2 d-flex">
                <span className="me-2"> B.</span>
                <span>
                  The Client has approached the Service Provider seeking his
                  services and the Service Provider has agreed to provide the
                  services.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> C.</span>
                <span>
                  The parties have thus, entered into this contract to regulate
                  the exercise of their respective rights and obligations.
                </span>
              </div>
            </div>
            <div className="mt-2">
              <b>NOW IT IS HEREBY AGREED AS FOLLOWS: -</b>
            </div>
            <div className="mt-2">
              <b>1. 1. OBLIGATIONS OF CLIENT </b>
            </div>
            <div className="mt-2">
              1.1. The Client shall have the following obligations:
            </div>
            <div className="ps-3">
              <div className="mt-2">
                <span className="me-2"> 1.1.1.</span>
                <span>
                  The parties have thus, entered into this contract to regulate
                  the exercise of their respective rights and obligations.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 1.1.2.</span>
                <span>
                  To facilitate, manage and provide the necessary information,
                  tools, documentation and support required by the Consultant to
                  enable him perform under this contract.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 1.1.3.</span>
                <span>
                  To provide any other resources as may be agreed upon by the
                  Parties.
                </span>
              </div>
            </div>
            <div className="mt-2">
              <b>2. OBLIGATIONS OF THE SERVICE PROVIDER </b>
            </div>
            <div className="mt-2">
              2.1. The Service Provider shall have the following obligations:
            </div>
            <div className="ps-3">
              <div className="mt-2">
                <span className="me-2"> 2.1.1.</span>
                <span>
                  To provide the services timely, professionally, diligently and
                  efficiently in accordance with the generally accepted
                  techniques and practices recognized in the industry. The
                  Service Provider will bear an obligation to perform to the
                  best of its ability and is not bound to a certain result.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 2.1.2.</span>
                <span>
                  To provide any other services that may be agreed upon in
                  writing by the Parties.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 2.1.3.</span>
                <span>
                  The Service Provider shall not be liable for any error of
                  judgment, mistake of law or for any other loss whatsoever
                  suffered by the Client in connection with the performance of
                  this Agreement, except a loss resulting from willful
                  misconduct, bad faith or gross negligence on the part of the
                  Consultant in the performance of its duties or from reckless
                  disregard by it of its obligations and duties under this
                  Agreement.
                </span>
              </div>
            </div>
            <div className="mt-2">
              <b>3. WARRANTIES </b>
            </div>
            <div className="mt-2"> 3.1. Each Party Warrants that:</div>
            <div className="ps-3">
              <div className="mt-2">
                <span className="me-2"> 3.1.1.</span>
                <span>
                  It has full power and authority to enter into and perform its
                  obligations under this agreement
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 3.1.2.</span>
                <span>
                  All acts, conditions and things required to be done, fulfilled
                  and performed in order to enable the Party to enter into,
                  exercise its rights under, and perform and comply with the
                  obligations expressed to be assumed by the Party in this
                  agreement, have been done, fulfilled and performed;
                </span>
              </div>
            </div>

            <div className="mt-2">
              <b> PAYMENT</b>
            </div>
            <div className="mt-2">
              4.1. During the continuity of this contract your remuneration for
              fees will be Kenya Shillings{" "}
              <span className=" me-2">
                <b>
                  <u>
                    {converter.toWords(details.payment.amount).toUpperCase()}{" "}
                    ONLY
                  </u>
                </b>
              </span>
              (Kshs.{" "}
              <span className=" me-2">
                <b>
                  <u>{details.payment.amount}</u>
                </b>
              </span>
              ) per month
            </div>
            <div className="mt-2">
              4.2. The consultant shall be paid on the date they started work Of
              every month.
            </div>

            <div className="mt-3">
              <b>5. TAX </b>
              <div>
                Each Party shall bear its own liability for any taxation arising
                in respect to this contract and undertakes to indemnify the
                other party in respect of any such taxation paid for which it is
                primarily liable.{" "}
              </div>
            </div>
            <div className="mt-3">
              <b>6. PLACE OF WORK </b>
              <div> The mode of work shall be on site. </div>
            </div>
            <div className="mt-3">
              <b>7. HOURS OF WORK </b>
              <div>
                {" "}
                The nature of Services provided is such that you will work
                flexible hours, weekends or over the public holidays. Support
                will also be provided from other consultants for flexibility.{" "}
              </div>
            </div>
            <div className="mt-2">
              <b>8. COMMENCEMENT AND TERM </b>
            </div>

            <div className="ps-3">
              <div className="mt-2 d-flex">
                <span className="me-2"> 8.1.1.</span>
                <span>
                  This contract shall come into effect immediately upon
                  signature by the last signing party and shall be valid upto
                  until{" "}
                  <span className="ms-2 me-2">
                    <b>
                      <u>
                        {moment(`${details.schedule.end}`).format(
                          "MMMM Do, YYYY"
                        )}
                      </u>
                    </b>
                  </span>
                </span>
              </div>
              <div className="mt-2 d-flex">
                <span className="me-2"> 8.1.2.</span>
                <span>
                  The Service Provider shall start the Services no later than
                  day of ,…………………. and shall complete them by day of …………………. or
                  any other period as may be subsequently agreed by the parties
                  in writing unless this Contract is terminated earlier in
                  accordance with its terms and conditions
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 8.1.3.</span>
                <span>
                  Notwithstanding the foregoing, the Agreement shall terminate
                  one month after the date either Party notifies the other Party
                  in writing that it wishes to terminate the Agreement.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 8.1.4.</span>
                <span>
                  8.1.4. However, this contract may be terminated forthwith by
                  written notice by either party to the other if the other party
                  commits any breach of any of the provisions of this Agreement
                  and, in the case of
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 8.1.5.</span>
                <span>
                  breach capable of remedy, fails to remedy the same within two
                  (2) business days after receipt of a written notice giving
                  full particulars of the breach and requiring it to be
                  remedied.
                </span>
              </div>
            </div>
            <div className="mt-3">
              <b>9. HOURS OF WORK </b>
              <div className="mt-3">
                The Service Provider acknowledges that in the ordinary course of
                his engagement under this contract, he will be exposed to
                information about Company or any of its subsidiaries and that of
                their suppliers, customers and clients which amounts to be trade
                secret, is confidential or is commercially sensitive and which
                may not be readily available to the general public and/or which
                if disclosed would be liable to cause significant harm to
                Client.
              </div>
              <div className="mt-3">
                The Service Provider undertakes to keep secret and shall not at
                any time either during the pendency of this contract, or after
                its termination, for whatever reason use, communicate or reveal
                to any person for his own or another's benefit, any secret or
                confidential information concerning Client or its
                representatives, about or related to its finances, suppliers,
                customers or clients or any other information that the Client
                prescribes as confidential, which shall have come to your
                knowledge during the course or as a result of his engagement
                under this contract.
              </div>
            </div>

            <div className="mt-2">
              <b> 10. UTMOST GOOD FAITH</b>
            </div>
            <div className="ps-3">
              <div className="mt-2">
                <span className="me-2"> 10.1.</span>
                <span>
                  The Parties undertake to do all such things, perform all such
                  acts and take all such steps to procure the doing of all such
                  things and the performance of all such acts as may be
                  necessary or incidental to give or conducive to the giving of
                  effect to the terms, conditions and import of this contract.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 10.2.</span>
                <span>
                  The Parties further agrees at all times during the currency of
                  this agreement to observe the principles of good faith towards
                  each other in the performance of its obligations in terms of
                  this agreement.
                </span>
              </div>
            </div>
            <div className="mt-3">
              <b>11. DISPUTE RESOLUTION </b>
              <div className="mt-3">
                Any dispute arising between the Parties here to and not settled
                in full by mutual agreement of the parties hereto evidenced in
                writing shall be referred to mediation.
              </div>
            </div>

            <div className="mt-2">
              <b>12. GENERAL </b>
            </div>

            <div className="ps-3">
              <div className="mt-2">
                <span className="me-2"> 12.1.</span>
                <span>
                  No indulgence which any Party (“grantor”) may grant to the
                  other Party shall constitute a waiver of any of the rights of
                  the grantor unless in writing signed by the grantor.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.2.</span>
                <span>
                  12.2. Nothing contained or implied in this Contract shall
                  constitute a partnership between the Parties and neither Party
                  shall have any authority to bind or commit the other in any
                  way with this regard{" "}
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.3.</span>
                <span>
                  This Agreement and its Schedules shall constitute the entire
                  agreement and understanding between the Parties with respect
                  to all matters which are referred to and shall supersede any
                  previous agreements between the Parties in relation to the
                  matters referred to in this Agreement.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.4.</span>
                <span>
                  No party shall be bound by any representation, warranty,
                  promise or the like not recorded in this agreement.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.5.</span>
                <span>
                  The invalidity or unenforceability of any of the provisions of
                  this Contract shall not affect the validity, legality and
                  enforceability of the remaining provisions of this Contract.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.6.</span>
                <span>
                  No Party shall assign or transfer or delegate or purport to
                  assign or transfer or delegate any of its rights or
                  obligations under this Agreement without the prior written
                  consent of the other Party.
                </span>
              </div>
              <div className="mt-2">
                <span className="me-2"> 12.7.</span>
                <span>
                  The terms of this Agreement shall only be amended with the
                  written consent of all the Parties.
                </span>
              </div>
            </div>
            <div className="mt-3">
              <b>13. GOVERNING LAW </b>
              <div className="mt-3">
                This Agreement shall be governed and construed in accordance
                with the laws of Kenya
              </div>
            </div>

            <center>
              <div className="mb-3 mt-5">
                <b>
                  <u>JOB DESCRIPTION</u>
                </b>
              </div>
            </center>
            <div className="mt-3">The below is your job description</div>
            <div className="mt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: details.booking.requirements,
                }}
              ></span>
            </div>

            <div className="mt-3">
              <b>
                <u>Acceptance Clause</u>
              </b>
            </div>
            <div className="mt-3">
              I
              <span className="ms-2 me-2">
                <b>
                  <u>
                    {details.provider.firstName +
                      " " +
                      details.provider.lastName}
                  </u>
                </b>
              </span>{" "}
              hereby understand the content of this contract and I will adhere
              to the terms and conditions stated above.
            </div>
            <div className="mt-5">
              <b>IN WITNESS WHEREOF </b> the Parties have affixed their
              respective signatures to this Agreement the day and year first
              hereinabove mentioned.{" "}
            </div>
            {details.clientSigned && details.providerSigned && (
              <div className="row m-0 mt-3">
                <div className="col-sm-6 d-flex flex-column">
                  <span className="">
                    <b>
                      <u>Signed by the client</u>
                    </b>
                  </span>
                  <span className="">
                    {details.client.firstName} {details.client.lastName}
                  </span>

                  <span className=" d-flex align-items-center">
                    Signed <MdCheck size={16} className="text-success ms-2" />
                  </span>
                </div>
                <div className="col-sm-6 d-flex flex-column">
                  <span className="">
                    <b>
                      <u>Signed by the service provider</u>
                    </b>
                  </span>
                  <span className="">
                    {details.provider.firstName} {details.provider.lastName}
                  </span>

                  <span className=" d-flex align-items-center">
                    Signed <MdCheck size={16} className="text-success ms-2" />
                  </span>
                </div>
              </div>
            )}
          </div>

          {((user.role.title === "client" && !details.clientSigned) ||
            (user.role.title === "serviceProvider" &&
              !details.providerSigned)) && (
            <div className="p-3 d-flex align-items-center justify-content-center">
              <button
                className="btn btn-success me-4 d-flex align-items-center"
                onClick={handleSignContract}
              >
                Accept and Sign Contract
                <AiOutlineCheck className="ms-2" size={18} />
              </button>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={closeContract}
              >
                Reject Contract <AiOutlineClose className="ms-2" size={18} />
              </button>
            </div>
          )}
        </div>
        {isLoading && <Spinner1 message={"Please wait..."} />}
      </>
    </div>
  );
}

export default Contract;
