import { createSlice } from "@reduxjs/toolkit";
import { io } from "socket.io-client";

const initialState = {
   backendUrl: "https://api.maidsofhonour.africa/",
  //  backendUrl: "http://localhost:4000/",
  // backendUrl: `http://100.102.253.69:4000/`,
  // backendUrl: `http://100.97.99.23:4000/`,
  // backendUrl: "http://100.124.212.1:4000/",
  //Moha support account
  // backendUrl: "http://100.111.30.110:4000/",
};

export const wssResolver = (namespace) => {
  const connectUrl = `wss://${
    initialState.backendUrl.split("//")[1]
  }${namespace}`;
  const socket = io.connect(connectUrl, {
    transports: ["websocket"],
  });

  return socket;
};

const GlobalSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {},
});

export const backendUrl = initialState.backendUrl;
export default GlobalSlice.reducer;
