import { useState, useEffect } from "react";
import Subscription from "./Subscription";
import PaymentModal from "../Payment/PaymentModal";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import { useDispatch, useSelector } from "react-redux";
import Spinner2 from "../spinners/Spinner2";
import Spinner1 from "../spinners/Spinner1";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setSubscription } from "../../features/slices/SubscriptionSlice";

function ClientSubscriptionModal({ closeModal }) {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, isAuth } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFinish = async (details) => {
    //TODO: Ensure subscriptions are working well.
    if (details.type === "success") {
      try {
        let subscriptionData = {
          user: user.sub || user._id || user.id,
          subscription: "642c8a9223d0a71611b404ab",
          isPaid: true,
        };
        const response = await axiosWrapper({
          method: "post",
          url: `users/subscriptions`,
          data: subscriptionData,
        });
        console.log("response data");
        console.log(response);
        setLoading(false);
        if (response.data.success) {
          dispatch(setSubscription(response.data.data));
          toast.success("You have successfully subscribed to our package!");
          navigate("/dashboard");
        } else {
          toast.error(
            "Some error occured, kindly contact our customer service for help."
          );
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error(error.message);
      }
      document.body.style.overflow = "visible";
      closeModal();
    } else {
      closeModal();
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: "50000",
        display: "flex",
        alignContent: "center",
        height: "100vh",
      }}
      className="row m-0 "
    >
      {!showPaymentModal ? (
        <Subscription
          isModal={true}
          closeModal={() => {
            document.body.style.overflow = "visible";
            closeModal();
          }}
          showPaymentModal={() => setShowPaymentModal(true)}
        />
      ) : (
        <PaymentModal
          paymentDetails={{
            name: ` for your Maids of Honour subscription`,

            amount: "1000",
          }}
          closeModal={handleFinish}
        />
      )}

      {loading && <Spinner1 message={"please wait..."} />}
    </div>
  );
}

export default ClientSubscriptionModal;
