import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import "../../styles/ProgressBar.css";
import { useSelector } from "react-redux";
function ProgressBar1({ completed }) {
  const colorSchema = useSelector((state) => state.color.scheme);
  return (
    <div className="d-flex align-items-center jusity-content-center mt-4 mb-4 ms-1 me-1">
      <span
        style={{ backgroundColor: `${colorSchema?.primary}` }}
        className="progressNum"
      >
        1
      </span>
      <span className="m-2 progress_cont">
        <ProgressBar
          completed={completed}
          //   className="wrapper"
          // barContainerClassName="container"
          // completedClassName="barCompleted"
          // labelClassName="label"
          // bgColor={"#9f7582"}
          // labelColor={"#9f7582"}
          bgColor={colorSchema?.primary}
          labelColor={colorSchema?.primary}
          labelSize={"1px"}
          height={"8px"}
        />
      </span>
      <span className="progressNum2">2</span>
    </div>
  );
}

export default ProgressBar1;
