import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsTelephone,
} from "react-icons/bs";
import { MdDone, MdClose, MdInfo, MdOutlineInfo } from "react-icons/md";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaBullseye, FaFileContract } from "react-icons/fa";
import { wssResolver } from "../../features/slices/GlobalsSlice";
import {
  closeTabs,
  setActivePage,
} from "../../features/slices/DashboardControlSlice";
import { useDispatch } from "react-redux";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index.js";
import { useNavigate } from "react-router-dom";

// let socket = wssResolver("rtc");

function ContractDetailsModal({ showContract, socket }) {
  const currentBooking = useSelector((state) => state.booking.currentBooking);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevEmployer, setPrevEmployer] = useState("");

  const [contractLoading, setContractLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState({
    client:
      currentBooking.client.firstName + " " + currentBooking.client.lastName,
    serviceProvider:
      currentBooking.provider.firstName +
      " " +
      currentBooking.provider.lastName,
    salary: currentBooking.service.minSalary,
    jobType: currentBooking.jobType,
    startDate: moment(currentBooking.schedule.start).format("MMMM Do, YYYY"),
    endDate: moment(currentBooking.schedule.start).format("MMMM Do, YYYY"),
    jobTitle: currentBooking.service.title,
  });

  const { user, isAuth } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(currentBooking.schedule.start);
  const [endDate, setEndDate] = useState(currentBooking.schedule.end);
  const [startTime, setStartTime] = useState(
    moment(currentBooking.schedule.start).format("LT")
  );
  const [endTime, setEndTime] = useState(
    moment(currentBooking.schedule.end).format("LT")
  );
  const [salary, setSalary] = useState(15000);
  const [salaryError, setSalaryError] = useState(false);

  const [datesAgreed, setDatesAgreed] = useState(false);
  const [timeAgreed, setTimeAgreed] = useState(false);
  const [salaryAgreed, setSalaryAgreed] = useState(false);

  const [activeTab, setActiveTab] = useState("");
  const [showSteps, setShowSteps] = useState(false);

  const [showDateBtn, setShowDateBtn] = useState(true);
  const [showTimeBtn, setShowTimeBtn] = useState(false);
  const [showSalaryBtn, setShowSalaryBtn] = useState(false);
  const [showProceedBtn, setShowProceedBtn] = useState(false);
  const [showDetailsBtn, setShowDetailsBtn] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [datesChanged, setDatesChanged] = useState(false);

  const [negotiationId, setNegotiationId] = useState("");
  const [currentNegotiation, setCurrentNegotiation] = useState("");
  const [negotiationUpdateLoading, setNegotiationUpdateLoading] =
    useState(false);

  const [loading, setLoading] = useState(false);

  //editing
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [isEditingSalary, setIsEditingSalary] = useState(false);

  //Agreements
  //date
  const [clientDateAgreed, setClientsDateAgreed] = useState(true);
  const [spDateAgreed, setSpDateAgreed] = useState(false);
  const [dateAgreed, setDateAgreed] = useState(false);
  //time
  const [clientTimeAgreed, setClientsTimeAgreed] = useState(true);
  const [spTimeAgreed, setSpTimeAgreed] = useState(false);
  //salary
  const [clientSalaryAgreed, setClientsSalaryAgreed] = useState(true);
  const [spSalaryAgreed, setSpSalaryAgreed] = useState(false);
  const recipient = useSelector((state) => state.messages.recipient);

  const [waiting, setWaiting] = useState("client");

  const fetchPreviousEmployer = async () => {
    const response = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${currentBooking?.provider?._id}`,
    });

    setPrevEmployer(response.data.data.rows[0]);
  };

  const fetchCurrentNegotiation = async () => {
    const currentNegotiationResponse = await axiosWrapper({
      method: "get",
      url: `bookings/negotiations?booking=${currentBooking._id}`,
    });

    console.log("negotiation proceedings");
    const currentNegotiation = currentNegotiationResponse.data.data.rows[0];
    console.log(currentNegotiationResponse);

    setNegotiationId(currentNegotiation?._id || "");
    setLoading(false);
    if (currentNegotiationResponse.data.data.count > 0) {
      if (!currentNegotiation.isDateAgreed || !currentNegotiation) {
        setShowDateBtn(true);
        setActiveTab("date");
        setShowSteps(false);
        setShowTimeBtn(false);
        setShowSalaryBtn(false);
      } else if (
        currentNegotiation.isDateAgreed &&
        !currentNegotiation.isTimeAgreed
      ) {
        setSpDateAgreed(true);
        setClientsDateAgreed(true);
        setDateAgreed(true);
        setShowSteps(true);
        setShowDateBtn(true);
        setShowTimeBtn(true);
        setActiveTab("time");
        setLoading(false);
      } else if (
        currentNegotiation.isDateAgreed &&
        currentNegotiation.isTimeAgreed
      ) {
        setLoading(false);
        setSpDateAgreed(true);
        setClientsDateAgreed(true);
        setDateAgreed(true);
        setSpTimeAgreed(true);
        setClientsTimeAgreed(true);
        setTimeAgreed(true);
        setShowSteps(true);
        setShowDateBtn(true);
        setShowTimeBtn(true);
        setShowSalaryBtn(true);
        setActiveTab("salary");
      } else {
      }
    } else {
      console.log("No negotiation exists");
      clearAgreements();
      setLoading(false);
      setShowDateBtn(true);
    }
  };

  useEffect(() => {
    fetchCurrentNegotiation();
    fetchPreviousEmployer();
  }, [currentBooking]);

  useEffect(() => {
    setLoading(true);
    clearAgreements();
    setLoading(true);
  }, [recipient]);

  const clearAgreements = () => {
    setShowSteps(false);

    setShowSalaryBtn(false);
    setClientsSalaryAgreed(false);
    setSpSalaryAgreed(false);
    setSalaryAgreed(false);
    // setClientsTimeAgreed(false);
    setSpTimeAgreed(false);
    setTimeAgreed(false);
    setShowTimeBtn(false);
    // setClientsDateAgreed(false);
    setSpDateAgreed(false);
    setDateAgreed(false);
    setShowDateBtn(false);
  };

  //Agree to date
  const agreeToDate = async () => {
    if (!startDate || !endDate) {
      toast.error("Please select dates");
    } else {
      if (user.role.title === "serviceProvider") {
        const newDates = {
          startDate,
          endDate,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newDates };
        setDateAgreed(true);
        setSpDateAgreed(true);
        setClientsDateAgreed(true);
        socket.emit("changeDate", payload);

        if (clientDateAgreed) {
          setNegotiationUpdateLoading(true);

          const negotiationResonse = await axiosWrapper({
            method: "post",
            url: "bookings/negotiations",
            data: {
              booking: currentBooking._id,
              isDateAgreed: true,
              isTimeAgreed: false,
              archived: false,
            },
          });

          setNegotiationId(negotiationResonse.data.data._id);
          setNegotiationUpdateLoading(false);
          if (negotiationResonse.data.success) {
            setActiveTab("time");
            setWaiting("client");
            setShowTimeBtn(true);
          } else {
            toast.error("Failed to update details");
            setSpDateAgreed(false);
            setDateAgreed(false);
          }
        }
      } else {
        const newDates = {
          startDate,
          endDate,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newDates };
        setDateAgreed(true);
        setSpDateAgreed(true);
        setClientsDateAgreed(true);
        setIsEditingTime(false);
        socket.emit("changeDate", payload);
        if (spDateAgreed) {
          setNegotiationUpdateLoading(true);
          const negotiationResonse = await axiosWrapper({
            method: "post",
            url: "bookings/negotiations",
            data: {
              booking: currentBooking._id,
              isDateAgreed: true,
              isTimeAgreed: false,
              archived: false,
            },
          });

          setNegotiationUpdateLoading(false);
          if (negotiationResonse.data.data.success) {
            setActiveTab("time");
            setWaiting("serviceProvider");
            setShowTimeBtn(true);
          } else {
            toast.error("Failed to update details");
          }
        }
      }
    }
  };

  //Review Dates
  const checkDates = () => {
    if (!startDate || !endDate) {
      toast.error("Please select dates");
    } else {
      if (user.role.title === "client") {
        setClientsDateAgreed(true);
        const newDates = {
          startDate,
          endDate,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newDates };
        setIsEditingDate(false);
        setWaiting("client");
        socket.emit("changeDate", payload);
      } else {
        setSpDateAgreed(true);
        const newDates = {
          startDate,
          endDate,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newDates };
        setIsEditingDate(false);
        setWaiting("client");
        socket.emit("changeDate", payload);
      }
    }
  };

  //Agree to time
  const agreeToTime = async () => {
    if (!startTime || !endTime) {
      toast.error("Please select times");
    } else {
      if (user.role.title === "serviceProvider") {
        const newTime = {
          startTime,
          endTime,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newTime };
        setTimeAgreed(true);
        setSpTimeAgreed(true);
        setClientsTimeAgreed(true);
        socket.emit("changeTime", payload);
        setWaiting("serviceProvider");
        if (clientTimeAgreed) {
          setNegotiationUpdateLoading(true);

          const negotiationResonse = await axiosWrapper({
            method: "patch",
            url: "bookings/negotiations/" + negotiationId,
            data: {
              booking: currentBooking._id,
              isDateAgreed: true,
              isTimeAgreed: true,
              archived: false,
            },
          });
          console.log("negotiationResonse after updating time");
          console.log(negotiationResonse.data.success);
          setNegotiationUpdateLoading(false);
          if (negotiationResonse.data.success) {
            setActiveTab("salary");
            setShowSalaryBtn(true);
            setWaiting("client");
          } else {
            toast.error("Failed to update details");

            setSpTimeAgreed(false);
            setTimeAgreed(false);
          }
        }
      } else {
        const newTime = {
          startTime,
          endTime,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newTime };
        setTimeAgreed(true);
        setSpTimeAgreed(true);
        setClientsTimeAgreed(true);
        setWaiting("client");
        socket.emit("changeTime", payload);
        if (spDateAgreed) {
          setActiveTab("salary");
          setShowSalaryBtn(true);
        }
      }
    }
  };

  //Review Times
  const checkTimes = () => {
    if (!startTime || !endTime) {
      toast.error("Please select times");
    } else {
      if (user.role.title === "client") {
        setClientsTimeAgreed(true);
        const newTime = {
          startTime,
          endTime,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newTime };
        setIsEditingTime(false);
        setWaiting("client");
        socket.emit("changeTime", payload);
      } else {
        setSpTimeAgreed(true);
        const newTime = {
          startTime,
          endTime,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newTime };
        setIsEditingTime(false);
        setWaiting("serviceProvider");
        socket.emit("changeTime", payload);
      }
    }
  };
  //Agreee to Salary
  const agreeToSalary = () => {
    if (!salary) {
      toast.error("Please select salary");
    } else {
      setWaiting("");

      if (user.role.title === "serviceProvider") {
        const newSalary = {
          salary,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newSalary };
        setSalaryAgreed(true);
        setSpSalaryAgreed(true);
        setClientsSalaryAgreed(true);
        socket.emit("changeSalary", payload);
        if (clientSalaryAgreed) {
          setActiveTab("");
          // setShowSteps(false);
          setShowProceedBtn(true);
          setShowDetailsBtn(true);
        }
      } else {
        const newSalary = {
          salary,
          role: user.role.title,
          agreed: true,
        };
        const payload = { userId: recipient._id, data: newSalary };
        setSalaryAgreed(true);
        setSpSalaryAgreed(true);
        setClientsSalaryAgreed(true);
        socket.emit("changeSalary", payload);
        if (spDateAgreed) {
          setActiveTab("");
          setShowProceedBtn(true);
          setShowDetailsBtn(true);
          // setShowSteps(false);
          setWaiting("");
        }
      }
    }
  };

  //Review Salary
  const checkSalary = () => {
    if (!salary) {
      toast.error("Please select salary");
    } else {
      if (user.role.title === "client") {
        setClientsSalaryAgreed(true);
        const newSalary = {
          salary,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newSalary };
        setIsEditingSalary(false);
        setWaiting("client");
        // setShowProceedBtn(true);
        socket.emit("changeSalary", payload);
      } else {
        setSpSalaryAgreed(true);
        const newSalary = {
          salary,
          role: user.role.title,
          agreed: false,
        };
        const payload = { userId: recipient._id, data: newSalary };
        setIsEditingSalary(false);
        setWaiting("serviceProvider");
        socket.emit("changeSalary", payload);
      }
    }
  };

  useEffect(() => {
    socket.on("updateDate", (data) => {
      //check if data is agreed upon

      if (data.agreed) {
        //an agreement
        setDateAgreed(true);
        setClientsDateAgreed(true);
        setSpDateAgreed(true);
        console.log("Agreed to  dates", startDate, endDate);
        setActiveTab("time");
        setWaiting("client");
        setShowTimeBtn(true);
        if (data.role !== "client") {
          setIsEditingTime(false);
        }
      } else {
        //an update
        setIsEditingDate(true);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        //check who is updating
        if (data.role === "client") {
          setDateAgreed(false);
          setClientsDateAgreed(true);
          setSpDateAgreed(false);
          setIsEditingDate(false);
          setWaiting("client");
        } else {
          setDateAgreed(false);
          setClientsDateAgreed(false);
          setSpDateAgreed(true);
          setIsEditingDate(false);
          setWaiting("serviceProvider");
        }
      }
    });
    socket.on("updateTime", (data) => {
      if (data.agreed) {
        //an agreement
        setTimeAgreed(true);
        setClientsTimeAgreed(true);
        setSpTimeAgreed(true);
        console.log("Agreed to  times", startTime, endTime);
        setActiveTab("salary");
        setWaiting("client");
        setShowSalaryBtn(true);
      } else {
        //an update
        console.log("time d");
        console.log(data);
        setIsEditingTime(true);
        setStartTime(data.startTime);
        setEndTime(data.endTime);
        //check who is updating
        if (data.role === "client") {
          setTimeAgreed(false);
          setClientsTimeAgreed(true);
          setSpTimeAgreed(false);
          setIsEditingTime(false);
          setWaiting("client");
        } else {
          setTimeAgreed(false);
          setClientsTimeAgreed(false);
          setSpTimeAgreed(true);
          setIsEditingTime(false);
          setWaiting("serviceProvider");
        }
      }
    });
    socket.on("updateSalary", (data) => {
      if (data.contractGenerated) {
        toast.success(
          "Contract generated successfully. Kindly review and sign."
        );
        dispatch(closeTabs());
        dispatch(setActivePage("contracts"));
        return navigate("/dashboard/contracts");
      }
      if (data.agreed) {
        //an agreement
        setSalaryAgreed(true);
        setClientsSalaryAgreed(true);
        setSpSalaryAgreed(true);
        console.log("Agreed to  salary", salary);
        //  setActiveTab("salary");
        setShowProceedBtn(true);
        setShowDetailsBtn(true);
        setWaiting("");
        setActiveTab("");
        // setShowSteps(false);
        // setWaiting("client");
      } else {
        //an update
        setIsEditingSalary(true);
        setSalary(data.salary);
        //check who is updating
        if (data.role === "client") {
          setSalaryAgreed(false);
          setClientsSalaryAgreed(true);
          setSpSalaryAgreed(false);
          setIsEditingSalary(false);
          setWaiting("client");
        } else {
          setSalaryAgreed(false);
          setClientsSalaryAgreed(false);
          setSpSalaryAgreed(true);
          setIsEditingSalary(false);
          setWaiting("serviceProvider");
        }
      }
    });

    return () => {
      // socket.off("connect");
      // socket.off("disconnect");

      socket.off("updateDate");
      socket.off("updateTime");
      socket.off("updateSalary");
    };
  }, [socket]);

  const proceedToTimes = () => {
    // socket.emit("toTimes");
    // setShowTimeBtn(true);
    // setActiveTab("time");
  };

  const handleShowContract = async () => {
    setContractLoading(true);
    try {
      //update schedule
      // const startDateString = moment(
      //   moment(startDate).format("MM-DD-YYYY") + " " + startTime + ":00"
      // ).format("YYYY-MM-DD HH:mm:ss");
      // const endDateString = moment(
      //   moment(endDate).format("MM-DD-YYYY") + " " + endTime + ":00"
      // ).format("YYYY-MM-DD HH:mm:ss");

      // let parsedStartDateTime = new Date(startDateString).toISOString();
      // let parsedEndDateTime = new Date(endDateString).toISOString();

      // console.log(moment(parsed).format("YYYY-MM-DD HH:mm:ss"));

      // update schedule
      // const updateSchedule = await axiosWrapper({
      //   method: "patch",
      //   url: `schedules/` + currentBooking.schedule._id,
      //   data: {
      //     user: currentBooking.provider._id,
      //     service: currentBooking.service._id,
      //     start: parsedStartDateTime,
      //     end: parsedEndDateTime,
      //   },
      // });

      // if (!updateSchedule.data.success) {
      //   throw new Error("Could not update schedule");
      // }

      // const updateBookingStatus = await axiosWrapper({
      //   method: "patch",
      //   url: `bookings/${currentBooking._id}`,
      //   data: {
      //     status: "accepted",
      //   },
      // });

      // console.log("response from updating status for booking");
      // console.log(updateBookingStatus);
      // if (!updateBookingStatus.data.success) {
      //   throw new Error("Could not update booking status");
      // }

      // create payment
      const createPayment = await axiosWrapper({
        method: "post",
        url: `payments`,
        data: {
          type: "salary",
          amount: Number(salary),
          currency: "ksh",
          status: "pending",
          payer: currentBooking.client._id,
          payee: currentBooking.provider._id,
          fee: currentBooking.service.serviceFee._id,
          booking: currentBooking._id,
        },
      });

      if (!createPayment.data.success) {
        throw new Error("Could not create payment schedule");
      }

      const paymentId = createPayment?.data?.data?._id;

      //now create contract
      const contractDetails = {
        client: currentBooking.client._id,
        provider: currentBooking.provider._id,
        service: currentBooking.service._id,
        schedule: currentBooking.schedule._id,
        payment: paymentId,
        clientSigned: false,
        providerSigned: false,
        booking: currentBooking._id,
      };

      const createContract = await axiosWrapper({
        method: "post",
        url: `contracts`,
        data: contractDetails,
      });

      if (createContract.data.success) {
        const newSalary = {
          salary,
          role: user.role.title,
          agreed: true,
          contractGenerated: true,
        };
        const payload = { userId: recipient._id, data: newSalary };
        socket.emit("changeSalary", payload);
        toast.success("Contract created successfully");
        dispatch(closeTabs());
        dispatch(setActivePage("contracts"));
        navigate("/dashboard/contracts");
      } else {
        throw new Error("Could not create contract");
      }

      console.log("create contract response");
      console.log(createContract);
    } catch (error) {
      console.log("Generating contract error");
      console.log(error);
      toast.error(error.messages);
    }

    //save contract to db
  };

  return (
    <>
      {loading ? (
        <div className="spinner-border spinner-border-sm text-success ms-3"></div>
      ) : (
        <div
          className="row m-0 mb-5"
          style={{ position: "absolute", bottom: "0", left: "0", right: "0" }}
        >
          <div className=" d-flex justify-content-end   row m-0 w-100">
            {showSteps && (
              <div className="d-flex flex-column align-items-center bg-white   rounded shadow border m-0 col-md-8 p-3 ms-auto ">
                <div className="d-flex w-100 justify-content-end m-1">
                  <AiOutlineClose
                    className="text-dark hoverUpEffect"
                    size={18}
                    onClick={() => {
                      setShowSteps(false);
                    }}
                  />
                </div>
                {activeTab === "date" && (
                  <div id="workDates" className="row m-0 ">
                    <div className="d-flex m-1">
                      {spDateAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          SP has agreed
                        </span>
                      )}
                      {clientDateAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          Client has agreed
                        </span>
                      )}
                    </div>
                    {!isEditingDate ? (
                      <div className="w-100  row mt-2">
                        <label htmlFor="startDate" className=" mb-2">
                          <b>Work Dates</b>
                        </label>
                        <span>
                          <b>Start Date</b>
                          <span className="ms-2">
                            {moment(startDate).format("Do MMMM  YYYY")}
                          </span>
                        </span>
                        <span>
                          <b>End Date</b>

                          <span className="ms-2">
                            {moment(endDate).format("Do MMMM  YYYY")}
                          </span>
                        </span>
                        {user.role.title !== waiting ? (
                          <div className="d-flex mt-3">
                            <button
                              className={`btn d-flex align-items-center btn-sm btn-success`}
                              onClick={agreeToDate}
                            >
                              {!negotiationUpdateLoading && (
                                <>
                                  {" "}
                                  Agree{dateAgreed && "d"}
                                  {dateAgreed && <MdDone size={16} />}
                                </>
                              )}
                              {negotiationUpdateLoading && (
                                <>
                                  Please wait
                                  <div className="spinner spinner-border spinner-border-sm me-2 text-white ms-2"></div>
                                </>
                              )}
                            </button>
                            {/* <button
                          className={`btn d-flex align-items-center btn-sm btn-danger ms-2`}
                          onClick={() => {
                            setIsEditingDate(true);
                            setClientsDateAgreed(false);
                            setSpDateAgreed(false);
                          }}
                        >
                          Change
                        </button> */}
                          </div>
                        ) : (
                          <div className="d-flex align-items-center m-1">
                            <div className="spinner spinner-border spinner-border-sm me-2 text-success"></div>
                            <div className="text-success fontsm1">
                              Waiting for
                              {user.sub === currentBooking.provider._id
                                ? " " + currentBooking.client.firstName + " "
                                : " " + currentBooking.provider.firstName + " "}
                              to accept or reject
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="w-100  row m-0">
                        <div className="w-100  row mt-2">
                          <label htmlFor="startDate" className=" mb-2">
                            <b>Currently set work Dates</b>
                          </label>
                          <div className="d-flex col-sm-6 ">
                            <span className="fontsm">
                              <b>From </b>
                              <span className="ms-2 fontsm1">
                                {moment(startDate).format("Do MMMM  YYYY")}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex col-sm-6 ">
                            <span className="fontsm">
                              <b>To</b>

                              <span className="ms-2 fontsm1">
                                {moment(endDate).format("Do MMMM  YYYY")}
                              </span>
                            </span>
                          </div>
                        </div>
                        <label htmlFor="startDate" className=" mb-1 mt-2">
                          <b>Set new Dates</b>
                        </label>
                        <div className="form-group col-sm-6">
                          <label htmlFor="startDate" className="fontsm">
                            Start Date
                          </label>
                          <input
                            id="startDate"
                            name="startDate"
                            className="form-control"
                            type="date"
                            style={{ cursor: "pointer" }}
                            value={moment(startDate).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setClientsDateAgreed(false);
                              setStartDate(
                                moment(e.target.value).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="endDate" className="fontsm">
                            End Date
                          </label>
                          <input
                            id="endDate"
                            name="endDate"
                            className="form-control"
                            value={moment(endDate).format("YYYY-MM-DD")}
                            type="date"
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              setClientsDateAgreed(false);
                              setEndDate(
                                moment(e.target.value).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </div>
                        <div className="d-flex mt-3">
                          <button
                            className={`btn d-flex align-items-center btn-sm btn-success`}
                            onClick={checkDates}
                          >
                            Send New Dates
                          </button>
                        </div>
                      </span>
                    )}
                  </div>
                )}

                {/* TAB FOR SHOWING TIME */}
                {activeTab === "time" && (
                  <div id="workTimes" className="row m-0 ">
                    <div className="d-flex m-1">
                      {spTimeAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          SP has agreed
                        </span>
                      )}
                      {clientTimeAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          Client has agreed
                        </span>
                      )}
                    </div>
                    {!isEditingTime ? (
                      <div className="w-100  row mt-2">
                        <label htmlFor="startDate" className=" mb-2">
                          <b>Working Time</b>
                        </label>
                        {startTime && endTime && (
                          <>
                            <span>
                              <b>Start Time</b>
                              <span className="ms-2">{startTime}</span>
                            </span>
                            <span>
                              <b>End Time</b>

                              <span className="ms-2">{endTime}</span>
                            </span>
                          </>
                        )}
                        {user.role.title !== waiting ? (
                          <div className="d-flex mt-3">
                            <button
                              className={`btn d-flex align-items-center btn-sm btn-success`}
                              onClick={agreeToTime}
                            >
                              {!negotiationUpdateLoading && (
                                <>
                                  Agree{timeAgreed && "d"}
                                  {timeAgreed && <MdDone size={16} />}
                                </>
                              )}
                              {negotiationUpdateLoading && (
                                <>
                                  Please wait
                                  <div className="spinner spinner-border spinner-border-sm me-2 text-white ms-2"></div>
                                </>
                              )}
                            </button>
                            {/* <button
                          className={`btn d-flex align-items-center btn-sm btn-danger ms-2`}
                          onClick={() => {
                            setIsEditingTime(true);
                            setClientsTimeAgreed(false);
                            setSpTimeAgreed(false);
                          }}
                        >
                          Change
                        </button> */}
                          </div>
                        ) : (
                          <div className="d-flex align-items-center m-1">
                            {!startTime || !endTime ? (
                              <div className="text-success fontsm1">
                                Waiting for
                                {user.sub === currentBooking.provider._id
                                  ? " " + currentBooking.client.firstName + " "
                                  : " " +
                                    currentBooking.provider.firstName +
                                    " "}
                                to suggest time.
                              </div>
                            ) : (
                              <>
                                {spTimeAgreed &&
                                clientTimeAgreed &&
                                timeAgreed ? (
                                  <div className="text-success fontsm1">
                                    {currentBooking.provider.firstName} agreed
                                  </div>
                                ) : (
                                  <div>
                                    <div className="spinner spinner-border spinner-border-sm me-2 text-success"></div>
                                    <div className="text-success fontsm1">
                                      Waiting for
                                      {user.sub === currentBooking.provider._id
                                        ? " " +
                                          currentBooking.client.firstName +
                                          " "
                                        : " " +
                                          currentBooking.provider.firstName +
                                          " "}
                                      to accept or reject
                                    </div>{" "}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="w-100  row m-0">
                        {startTime && endTime && (
                          <div className="w-100  row mt-2">
                            <label htmlFor="startDate" className=" mb-2">
                              <b>Currently set work time</b>
                            </label>
                            <div className="d-flex col-sm-6 ">
                              <span className="fontsm">
                                <b>From </b>
                                <span className="ms-2 fontsm1">
                                  {startTime}
                                </span>
                              </span>
                            </div>
                            <div className="d-flex col-sm-6 ">
                              <span className="fontsm">
                                <b>To</b>

                                <span className="ms-2 fontsm1">{endTime}</span>
                              </span>
                            </div>
                          </div>
                        )}
                        <label htmlFor="startDate" className=" mb-1 mt-2">
                          <b>Suggest new time</b>
                        </label>
                        <div className="form-group col-sm-6">
                          <label htmlFor="startDate" className="fontsm">
                            Start Time
                          </label>
                          <input
                            id="startTime"
                            name="startTime"
                            className="form-control"
                            type="time"
                            style={{ cursor: "pointer" }}
                            value={moment(startTime).format("HHmm")}
                            onChange={(e) => {
                              setClientsTimeAgreed(false);
                              setStartTime(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="endDate" className="fontsm">
                            Ending Time
                          </label>
                          <input
                            id="endTime"
                            name="endTime"
                            className="form-control"
                            value={moment(endTime).format("HHmm")}
                            type="time"
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              setClientsTimeAgreed(false);
                              setEndTime(e.target.value);
                            }}
                          />
                        </div>
                        {/* <div className="d-flex mt-3">
                      <button
                        className={`btn d-flex align-items-center btn-sm btn-success`}
                        onClick={checkTimes}
                      >
                        Send New times
                      </button>
                    </div> */}
                      </span>
                    )}
                  </div>
                )}
                {/* TAB FOR SHOWING SALARY */}
                {activeTab === "salary" && (
                  <div id="salary" className="row m-0 ">
                    <div className="d-flex m-1">
                      {spSalaryAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          SP has agreed
                        </span>
                      )}
                      {clientSalaryAgreed && (
                        <span className="badge rounded-pill m-1 bg-warning">
                          Client has agreed
                        </span>
                      )}
                    </div>
                    {!isEditingSalary ? (
                      <div className="w-100  row mt-2">
                        <label htmlFor="startDate" className=" mb-2">
                          <b>Monthly Salary</b>
                        </label>

                        <span>
                          <b>Ksh </b>

                          <span className="ms-2">{salary}/=</span>
                        </span>

                        {user.role.title !== waiting ? (
                          <div className="d-flex mt-3">
                            <button
                              className={`btn d-flex align-items-center btn-sm btn-success`}
                              onClick={agreeToSalary}
                            >
                              Agree{salaryAgreed && "d"}
                              {salaryAgreed && <MdDone size={16} />}
                            </button>
                            <button
                              className={`btn d-flex align-items-center btn-sm btn-danger ms-2`}
                              onClick={() => {
                                setIsEditingSalary(true);
                                setClientsSalaryAgreed(false);
                                setSpSalaryAgreed(false);
                              }}
                            >
                              Suggest new amount
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center m-1">
                            <div className="spinner spinner-border spinner-border-sm me-2 text-success"></div>

                            <div className="text-success fontsm1">
                              Waiting for
                              {user.sub === currentBooking.provider._id
                                ? " " + currentBooking.client.firstName + " "
                                : " " + currentBooking.provider.firstName + " "}
                              to accept or reject
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="w-100  row m-0">
                        {startTime && endTime && (
                          <div className="w-100  row mt-2">
                            <label htmlFor="startDate" className=" mb-2">
                              <b>Currently set salary</b>
                            </label>
                            <div className="d-flex col-sm-6 ">
                              <span className="fontsm">
                                <b>ksh </b>
                                <span className="ms-2 fontsm1">{salary}/=</span>
                              </span>
                            </div>
                          </div>
                        )}
                        <label htmlFor="startDate" className=" mb-1 mt-2">
                          <b>Suggest new salary</b>
                        </label>
                        <div className="d-flex justify-content-between">
                          <label htmlFor="startDate" className="fontsm">
                            Monthly Salary
                          </label>
                          <div>
                            <BsArrowLeftCircle
                              onClick={() => {
                                setSalaryError(false);
                                const newSalary = parseInt(salary) - 100;
                                if (newSalary < 15000) {
                                  setSalaryError(true);
                                  setSalary(15000);
                                } else {
                                  setSalary(newSalary);
                                }
                              }}
                              size={30}
                              className="text-dark m-1 hoverUpEffect"
                            />
                            <BsArrowRightCircle
                              onClick={() => {
                                setSalaryError(false);
                                if (!salary) {
                                  setSalary(15000);
                                } else {
                                  if (salary < 15000) {
                                    setSalary(parseInt(15000));
                                  } else {
                                    setSalary(parseInt(salary) + 100);
                                  }
                                }
                              }}
                              size={30}
                              className="text-dark m-1 hoverUpEffect"
                            />
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <input
                            id="salary"
                            name="salary"
                            className="form-control mt-1 mb-1"
                            type="number"
                            min={15000}
                            defaultValue={15000}
                            value={salary}
                            onChange={(e) => {
                              if (e.target.value < 15000) {
                                setSalaryError(true);
                              } else {
                                setSalaryError(false);
                              }
                              setSalary(e.target.value);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          {salaryError && (
                            <center>
                              <span className="text-danger fontsm1 w-100 text-center mx-auto">
                                Salary should not go below kes 15,000
                              </span>
                            </center>
                          )}
                        </div>

                        <div className="d-flex mt-3">
                          <button
                            className={`btn d-flex align-items-center btn-sm btn-${
                              salaryError ? "secondary" : "success"
                            }`}
                            onClick={() => {
                              if (!salaryError) {
                                checkSalary();
                              }
                            }}
                          >
                            Send New Salary
                          </button>
                        </div>
                      </span>
                    )}
                  </div>
                )}

                {/* {activeTab === "salary" && (
              <div id="salary" className="row m-0">
                <span className="fw-bold fontsm mb-1 mt-1">
                  Let's talk cash
                </span>
                <div className="d-flex m-1">
                  {spSalaryAgreed && (
                    <span className="badge rounded-pill m-1 bg-warning">
                      SP has agreed
                    </span>
                  )}
                  {clientSalaryAgreed && (
                    <span className="badge rounded-pill m-1 bg-warning">
                      Client has agreed
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <button
                    className={`btn d-flex align-items-center btn-sm btn-${
                      salaryAgreed ? "success" : "secondary"
                    }`}
                    onClick={checkSalary}
                  >
                    Agree{salaryAgreed && "d"}
                    {salaryAgreed && <MdDone size={16} />}
                  </button>
                </div>
              </div>
            )} */}
                {showProceedBtn && (
                  <div className="row m-0">
                    <h5 className="fw-bold mb-2 mt-1">Contract Details</h5>
                    <div className="d-flex flex-wrap">
                      <span className="fontsm mt-2 me-2">
                        <b>Start Date:</b>
                        <span className="ms-2">
                          {moment(startDate).format("ll")}
                        </span>
                      </span>
                      <span className="fontsm mt-2 ms-lg-2">
                        <b>End Date:</b>
                        <span className="ms-2">
                          {moment(endDate).format("ll")}
                        </span>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap">
                      <span className="fontsm mt-2">
                        <b>Starting Time:</b>
                        <span className="ms-2">
                          {moment(startTime, "HH:mm").format("hh:mm a")}
                        </span>
                      </span>
                      <span className="fontsm mt-2 ms-lg-2">
                        <b>Ending Time:</b>
                        <span className="ms-2">
                          {moment(endTime, "HH:mm").format("hh:mm a")}
                        </span>
                      </span>
                    </div>
                    <span className="fontsm mt-2">
                      <b>Salary:</b>
                      <span className="ms-2">{salary}</span>
                    </span>

                    <div className="d-flex flex-column align-items-start">
                      {user.role.title === "client" ? (
                        <>
                          <span>
                            <small>
                              While Maids of Honour has already conducted a
                              screening process for each domestic worker, we
                              highly recommend that you perform your own due
                              diligence by reaching out to the worker's previous
                              employer to gain a better understanding of their
                              qualifications before hiring them.
                            </small>
                          </span>
                          <div className="d-flex flex-wrap">
                            <a
                              href={`tel:+${currentBooking.provider.phone}`}
                              className="btn  btn-sm btn-dark mt-2 me-1"
                            >
                              <BsTelephone size={15} className="ms-1 me-2" />
                              Call {currentBooking.provider.firstName}
                            </a>
                            <a
                              href={`tel:+${prevEmployer?.prevEmployerPhone}`}
                              className="btn  btn-sm btn-dark mt-2 ms-lg-2"
                            >
                              <BsTelephone size={15} className="ms-1 me-2" />
                              Call previous employer
                            </a>
                          </div>

                          <button
                            className="btn btn-primary m-3  w-100 text-center mx-auto btn-sm d-flex align-items-center justify-content-center"
                            onClick={handleShowContract}
                          >
                            {contractLoading ? (
                              <div>
                                <div className="spinner spinner-border spinner-border-sm text-white me-1"></div>
                                <span>Generating Contract</span>
                              </div>
                            ) : (
                              <span>
                                Proceed to Generate Contract
                                <FaFileContract className="ms-1" />
                              </span>
                            )}
                          </button>
                        </>
                      ) : (
                        <span className="alert alert-success p-1 fontsm m-2 text-center">
                          Check your contracts page. You will soon receive a
                          contract for you to sign.
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="d-flex mt-4 mb-2 ps-4">
            {showDateBtn && (
              <button
                className={`btn btn-${
                  clientDateAgreed && spDateAgreed && dateAgreed
                    ? "success"
                    : "secondary"
                } btn-sm fontsm m-2`}
                onClick={() => {
                  if (!spDateAgreed && !dateAgreed) {
                    setShowSteps(true);
                    setActiveTab("date");
                  }
                  // setShowProceedBtn(false);
                  // setClientsDateAgreed(false);
                  // setDateAgreed(false);
                  // setTimeAgreed(false);
                  // setSalaryAgreed(false);
                  // setShowTimeBtn(false);
                  // setSpDateAgreed(false);
                }}
              >
                Dates
                {dateAgreed && clientDateAgreed && spDateAgreed && (
                  <MdDone size={16} />
                )}
              </button>
            )}
            {showTimeBtn && (
              <button
                className={`btn btn-${
                  clientTimeAgreed && spTimeAgreed && timeAgreed
                    ? "success"
                    : "secondary"
                } btn-sm fontsm m-2`}
                onClick={() => {
                  if (clientDateAgreed && spDateAgreed && dateAgreed) {
                    setShowSteps(true);
                    setActiveTab("time");
                  }

                  // setDatesAgreed(false);
                  // setShowProceedBtn(false);
                  // setShowSalaryBtn(false);
                  // setClientsTimeAgreed(false);
                  // setTimeAgreed(false);
                  // setSalaryAgreed(false);
                  // setShowSalaryBtn(false);
                  // // setSpDateAgreed(false);
                  // setShowSteps(true);
                }}
              >
                Time
                {timeAgreed && clientTimeAgreed && spTimeAgreed && (
                  <MdDone size={16} />
                )}
              </button>
            )}
            {showSalaryBtn && (
              <button
                className={`btn btn-${
                  salaryAgreed && spSalaryAgreed && clientSalaryAgreed
                    ? "success"
                    : "secondary"
                } btn-sm fontsm m-2`}
                onClick={() => {
                  if (!salaryAgreed && !spSalaryAgreed) {
                    setShowSteps(true);
                    setActiveTab("salary");
                  }

                  // setShowProceedBtn(false);
                  // //set for SP also
                  // setClientsSalaryAgreed(false);
                  // setSalaryAgreed(false);
                }}
              >
                Salary
                {salaryAgreed && clientSalaryAgreed && spSalaryAgreed && (
                  <MdDone size={16} />
                )}
              </button>
            )}
            {showDetailsBtn && (
              <button
                className={`btn btn-primary btn-sm fontsm m-2`}
                onClick={() => {
                  setShowProceedBtn(true);
                  setActiveTab("");
                  setShowSteps(true);
                  // setShowProceedBtn(false);
                  // //set for SP also
                  // setClientsSalaryAgreed(false);
                  // setSalaryAgreed(false);
                }}
              >
                Info
                {salaryAgreed && clientSalaryAgreed && spSalaryAgreed && (
                  <MdOutlineInfo size={16} className="ms-1" />
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ContractDetailsModal;
