import { useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";

import Footer from "../../components/Home/Footer";
import Nav from "../../components/Home/Nav";

function Faq() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"policy"} />
      <div className="row m-0 pb-4 ">
        <div className="col-sm-8 mx-auto p-2 mt-4 d-flex flex-column">
          <h4 className=" mt-4 fw-bold">FREQUENTLY ASKED QUESTIONS</h4>
          <h5 className=" mt-4 fw-bold">Service Providers</h5>

          <Accordion defaultActiveKey="" className="mt-4 p-1">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="text-dark">
                1. How do I register as a Service provider?
              </Accordion.Header>
              <Accordion.Body>
                <p>Click join Maids of Honour </p>
                <p>Fill out the details:</p>
                <ul className="mt-2">
                  <li className="mt-1">
                    Fill out the details:(First name, Second name etc).
                  </li>
                  <li className="mt-1">Put your active phone number</li>
                  <li className="mt-1">Put your active phone email address</li>
                  <li className="mt-1">
                    Create a strong password and confirm your password
                  </li>
                  <li className="mt-1">
                    If you have been referred by someone, put in their referral
                    code.
                  </li>
                </ul>
                <p>
                  A OTP( One time password) would be sent to the phone number
                  you had put on the registration process.{" "}
                </p>
                <ul>
                  <li>Enter that pin that is sent and click verify.</li>
                </ul>
                <p>You’ll be prompted to fill in more details including:</p>
                <ul>
                  <li>Birth date </li>
                  <li>ID number</li>
                  <li>Gender</li>
                  <li>Your education level</li>
                  <li>Whether you have had extra training</li>
                  <li>What you are skilled in</li>
                  <li>Your years of experience State</li>
                  <li>
                    which one you you will prefer between 'Live in' and 'come
                    and go'.
                  </li>
                  <li>Click next</li>
                </ul>
                <p>Step two will ask for the following documents:</p>
                <ul>
                  <li>Front photo or your id.</li>
                  <li>Back photo of your ID</li>
                  <li>Certificate of good conduct</li>
                  <li>Your passport photo</li>
                  <li>Detailed description of your past job</li>
                  <li>Input your previous salary</li>
                  <li>Click finish once you are done.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                2. Can I be approved without having my Good conduct or medicals?
              </Accordion.Header>
              <Accordion.Body className="lightDark">
                No, to insure safety for all parties on the platform, all
                service providers applying to be on the platform must have Good
                conduct certificate. <br />
                You can apply without medials, for medicals will come after you
                have gotten a job.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                3. Can I start training without the documents?
              </Accordion.Header>
              <Accordion.Body className="lightDark">
                You will not be able to access the training if you have not
                submitted all your documents.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="text-dark">
                4. What does “ Your profile is incomplete” Mean?
              </Accordion.Header>
              <Accordion.Body>
                This shows that they are some areas within the registration
                process that you have not fillin or uploaded yet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="text-dark">
                5. How do I complete my profile?
              </Accordion.Header>
              <Accordion.Body>
                <b>
                  <p>On the Website:</p>
                </b>
                <ul>
                  <li>
                    You will see you name of the top right, click your name and
                    then click “my profile”
                  </li>
                </ul>
                <b>
                  <p>On the Phone:</p>
                </b>
                <ul>
                  <li>
                    You will see the menu bar (three lines on the top right),
                    click that button, at the bottom of the button options,
                    you’ll see you name, click your name and you will see “my
                    profile”
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="text-dark">
                6. How long does it take to get a job?
              </Accordion.Header>
              <Accordion.Body>
                Maids of Honour is a platform that connects both clients and the
                domestic technicians but does not assure one a job in a period
                of time. The better your reviews the higher chance of you
                getting a job.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header className="text-dark">
                7. Do I have to have job experience to sign-up under maids of
                Honour?
              </Accordion.Header>
              <Accordion.Body>
                Yes, one must have a job experience, for us to know how
                qualified you are.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header className="text-dark">
                8. Why do I have to pay for registration?
              </Accordion.Header>
              <Accordion.Body>
                The registration fee is important due to
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header className="text-dark">
                9. Can I have more that one skill?
              </Accordion.Header>
              <Accordion.Body>
                Yes, You can pick more than one skill, kindly pick the ones that
                you are most known for.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header className="text-dark">
                10. How long is the training?
              </Accordion.Header>
              <Accordion.Body>
                The training is as long or as short as you want it, it is
                dependant how quick you go through the course.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h5 className=" mt-5 fw-bold">Client</h5>
          <Accordion defaultActiveKey="" className="mt-4">
            <Accordion.Item eventKey="11">
              <Accordion.Header className="text-dark">
                1. How do I pick the right nanny?
              </Accordion.Header>
              <Accordion.Body>
                <ul className="mt-2">
                  <li className="mt-1">
                    Hire someone who is honest and reliable and whom you connect
                    with. We often look for the perfect nanny – someone who is
                    totally reliable, extremely hard working, knows all there is
                    to know about caring for your little one and is able to keep
                    up with all the housework. All without complaining or
                    getting tired .
                  </li>
                  <li className="mt-1">
                    This is hardly ever achievable – rather look for someone who
                    is trustworthy, reliable and keen to learn.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header>
                2. Why choose a nanny to look after my child?
              </Accordion.Header>
              <Accordion.Body className="">
                <p>
                  A child’s brain begins to form within days of conception and
                  mostly within the first 7 years of their life. Experiences
                  within these years are what shape and build their
                  consciousness for years to come.
                </p>
                <p>
                  These early experiences are of vital importance as they affect
                  a child’s relationships, demeanor, academics and ultimately
                  whether they grow up to become a secure, happy and competent
                  adult.
                </p>
                <p>
                  The effects last forever, so the childcare you decide on is of
                  greatest importance for their future development.
                </p>
                <p>
                  Children who are provided with affectionate and stimulating
                  care are given a great start.
                </p>
                <p>
                  By choosing home based care, your child remains in their
                  familiar, comfortable and predictable environment in which to
                  grow and develop at their own pace. They have less risk of
                  being exposed to germs and infections as their home is a
                  controlled environment. They have more individual attention.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
