import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchChats = createAsyncThunk(
  "messages/fetchChats",
  async (userId) => {
    const chatsData = await axiosWrapper({
      method: "get",
      url: `comms/chats?participants=${userId}`,
    });

    return chatsData.data.data.rows;
  }
);

export const fetchMesages = createAsyncThunk(
  "messages/fetchMessages",
  async (chatId) => {
    try {
      const messagesData = await axiosWrapper({
        method: "get",
        url: `comms/chats/messages?chat=${chatId}`,
      });

      // console.log(messagesData.data.data.rows[0].messages);
      const reversed = messagesData.data.data?.rows.reverse();
      return reversed || [];
    } catch (error) {
      console.log("messages error");
      console.log(error);
    }
  }
);

const MessageSlice = createSlice({
  name: "messages",
  initialState: {
    recipient: JSON.parse(localStorage.getItem("recipient")) || "",
    chatId: JSON.parse(localStorage.getItem("chatId")) || null,
    chats: {
      loading: false,
      data: [],
      error: false,
    },
    messages: {
      loading: false,
      data: [],
      error: false,
    },
  },
  reducers: {
    setRecipient: (state, action) => {
      localStorage.setItem("recipient", JSON.stringify(action.payload));

      state.recipient = action.payload;
    },
    clearRecipient: (state, action) => {
      localStorage.removeItem("recipient");
      localStorage.removeItem("chatId");
      state.recipient = "";
      state.messages.data = "";
      state.chatId = null;
    },

    addNewMessage: (state, action) => {
      state.messages.data = [...state.messages.data, action.payload];
    },
    setChatId: (state, action) => {
      localStorage.setItem("chatId", JSON.stringify(action.payload));
      state.chatId = action.payload;
    },
  },
  extraReducers: {
    [fetchChats.pending]: (state, action) => {
      state.chats.loading = true;
      state.chats.error = false;
    },
    [fetchChats.fulfilled]: (state, action) => {
      state.chats.loading = false;

      const allChats = action.payload;

      state.chats.data = [...action.payload];

      state.chats.error = false;
    },
    [fetchChats.rejected]: (state, action) => {
      state.chats.loading = false;
      state.chats.error = true;
    },
    [fetchMesages.pending]: (state, action) => {
      state.messages.loading = true;
      state.messages.error = false;
    },
    [fetchMesages.fulfilled]: (state, action) => {
      state.messages.loading = false;

      const allMessages = action.payload;

      state.messages.data = [...action.payload];

      state.messages.error = false;
    },
    [fetchMesages.rejected]: (state, action) => {
      state.messages.loading = false;
      state.messages.error = true;
    },
  },
});

export const { setRecipient, clearRecipient, addNewMessage, setChatId } =
  MessageSlice.actions;
export default MessageSlice.reducer;
