import { useState, useEffect } from "react";
import Chat from "../chats/Chat";
import ChatHeader from "../chats/ChatHeader";
import Messages from "../Messages";
import MessagesContainer from "../MessagesContainer";
import SendMessage from "../chats/SendMessage";
import "../../../styles/Dashboard/Messages.css";
import moment from "moment";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ChatUserInfo from "../chats/ChatUserInfo";
import { wssResolver } from "../../../features/slices/GlobalsSlice";
import { closeTabs } from "../../../features/slices/DashboardControlSlice";
import {
  addNewMessage,
  fetchChats,
  clearRecipient,
} from "../../../features/slices/MessagesSlice";
import { useDispatch, useSelector } from "react-redux";
import Contract from "../../contracts/Index";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import Spinner1 from "../../../components/spinners/Spinner1";
import VideoChatModal from "../../../components/VideoCall/VideoChatModal";

// let socket = wssResolver("rtc");

function Messenger({ socket }) {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recipient = useSelector((state) => state.messages.recipient);
  const messages = useSelector((state) => state.messages.messages.data);
  const chatId = useSelector((state) => state.messages.chatId);
  const currentBooking = useSelector((state) => state.booking.currentBooking);
  const [showContract, setShowContract] = useState(false);
  const [contractDetails, setContractDetails] = useState({
    client:
      currentBooking.client.firstName + " " + currentBooking.client.lastName,
    serviceProvider:
      currentBooking.provider.firstName +
      " " +
      currentBooking.provider.lastName,
    salary: currentBooking.service.minSalary,
    jobType: currentBooking.jobType,
    startDate: moment(currentBooking.schedule.start).format("MMMM Do, YYYY"),
    endDate: moment(currentBooking.schedule.start).format("MMMM Do, YYYY"),
    jobTitle: currentBooking.service.title,
  });

  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  //video call modal
  const [showVideoCallModal, setShowVideoCallModal] = useState(false);

  useEffect(() => {
    //sending chat

    dispatch(fetchChats(user.sub || user._id));
    socket.on("connect", () => {
      //  setIsConnected(true);
    });

    // socket.on("mpesa", (data) => {
    //   //check if checkout request id===saved id
    //   console.log("object");
    //   //  setIsConnected(true);

    //   //data.status
    //   //success
    //   //insufficient
    //   //cancelled
    //   //pin

    //   //data.data
    //   //id:transactionId

    //   //data

    //   //switch case status
    // });

    socket.on("disconnect", () => {
      //  setIsConnected(false);
    });
    socket.on("message", (data) => {
      const newMessage = {
        initiator: data.initiator,
        message: data.message,
        ISOString: data.ISOString,
      };

      dispatch(addNewMessage(newMessage));
    });

    socket.on("endNego", (data) => {
      dispatch(closeTabs());
      if (user.role.title === "client") {
        swal(
          "Booking Terminated",
          `Unfortunately, ${data.firstName} terminated this conversation due to a disagreement of terms. Dont hesitate to request another service provider until you find your right fit.`,
          "error"
        ).then(() => {
          navigate("/dashboard/hire");
        });
      } else {
        swal(
          "Booking Terminated",
          `Unfortunately, ${data.firstName} terminated this conversation due to disagreement of terms. Check your requests page for other pending requests.`,
          "error"
        ).then(() => {
          navigate("/dashboard/requests");
        });
      }
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("message");
      socket.off("join");
      socket.off("endNegoHandler");
      // socket.off("endNego");
    };
  }, []);

  const joinRoom = (userId) => {
    socket.emit("join", { userId });
  };
  const dummyState = "";
  useEffect(() => {
    joinRoom(user.sub || user._id);
  }, [dummyState]);

  const sendText = (text) => {
    const newMessage = {
      recipient: recipient._id,
      chatId: chatId,
      initiator: user.sub || user._id,
      message: text,
      ISOString: new Date().toISOString(),
    };

    console.log("message sent");
    console.log(newMessage);
    // setChats([...chats, newMessage]);
    dispatch(
      addNewMessage({
        initiator: user.sub || user._id,
        message: text,
        ISOString: new Date().toISOString(),
      })
    );

    socket.emit("message", newMessage);
  };

  const handleShowContract = async (data) => {
    // alert(data);
    setShowContract(true);
  };

  const handleTermination = async () => {
    swal({
      title: "Are you sure?",
      text: "Terminating the negotiation means that you haven't come to an agreement, so this booking will be deleted.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((answer) => {
      if (answer) {
        updateBookingToTerminated();
      }
    });
  };

  const updateBookingToTerminated = async () => {
    setLoading(true);
    setLoadingMessage(`Terminating Negotiations with ${recipient.firstName}`);
    try {
      const updateStatus = await axiosWrapper({
        method: "patch",
        url: `bookings/${currentBooking._id}`,
        data: {
          status: "rejected",
        },
      });

      if (updateStatus.data.success) {
        toast.error("Negotiation Terminated");
        const payload = { userId: recipient._id || user.sub, data: user };
        socket.emit("endNego", payload);
        dispatch(closeTabs());
        if (user.role.title === "client") {
          navigate("/dashboard/hire");
        } else {
          navigate("/dashboard/requests");
        }
      } else {
        console.log("Failed to update booking status");
        console.log(updateStatus);
      }
    } catch (error) {
      console.log("updating termination error");
      console.log(error);
    }
  };

  return (
    <>
      <div className="row m-0 " id="messengerContainer">
        <div
          className="col-sm-4 border ps-3 pt-3 pb-5"
          id="messengerSideMessages"
        >
          <BiArrowBack
            className="ms-1"
            size={25}
            onClick={() => {
              dispatch(clearRecipient());
              navigate("/dashboard");
            }}
            style={{ cursor: "pointer" }}
          />
          <Messages />
        </div>
        <div
          id="chatAreaDiv"
          className={`col-sm-${sideBarOpen ? "5" : "8"} p-0 m-0 border-none`}
          style={{ position: "relative", height: "100vh", overflow: "hidden" }}
        >
          <ChatHeader
            setSideBarOpen={() => {
              setSideBarOpen(!sideBarOpen);
            }}
            showContract={() => {
              setShowContract(true);
            }}
            startVideoChat={() => setShowVideoCallModal(true)}
            handleTermination={handleTermination}
          />

          <Chat socket={socket} />

          <SendMessage
            sendText={sendText}
            socket={socket}
            // showContract={() => {
            //   // setShowContract(true);

            // }}
            showContract={handleShowContract}
          />
        </div>
        {sideBarOpen && (
          <div
            id="chatUserInfoDiv"
            // style={{ backgroundColor: "#f7f8f9" }}
            className="col-sm-3 bg-white"
          >
            <ChatUserInfo
              setSideBarOpen={() => {
                setSideBarOpen(!sideBarOpen);
              }}
            />
          </div>
        )}
      </div>
      {/* {showContract && (
        <Contract
          details={contractDetails}
          closeContract={() => {
            setShowContract(false);
          }}
        />
      )} */}
      {showVideoCallModal && (
        <VideoChatModal
          endVideoChat={() => setShowVideoCallModal(false)}
          socket={socket}
        />
      )}
      {loading && <Spinner1 message={loadingMessage} />}
    </>
  );
}

export default Messenger;
