import { useState } from "react";
import "../../../styles/Dashboard/Dashboard.css";
import { GrCertificate } from "react-icons/gr";
import { GiCash } from "react-icons/gi";
import { TbCertificate } from "react-icons/tb";
import { BsInfoCircle } from "react-icons/bs";
import {
  FaAward,
  FaBriefcase,
  FaHourglassStart,
  FaUserFriends,
} from "react-icons/fa";
import { AiFillTrophy, AiFillStar, AiOutlineCopy } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import { Box, Grid, useMediaQuery, Container } from '@mui/material';

function ClientStats() {
  const referralCode = useSelector((state) => state.referral.referralCode);
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const { user } = useSelector((state) => state.auth);
  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode?.code);
    setTooltipText("Code Copied!");
    setTimeout(() => {
      setTooltipText("Copy to clipboard");
    }, 2000);
  };
  return (
    <div className="w-100  m-0 " style={{backgroundColor:"#F4F7FE"}}>
      <div id="statContainerOne" className="w-100 row p-3 m-0 pb-3 pt-3">
        <div className="col-sm-10 mx-auto row m-0">
          <div className="d-flex w-100 flex-wrap justify-content-between align-items-center ">
            <h5>
              <b>Hello, {user.firstName}</b>
            </h5>
              {referralCode?.code && (
                <span className="d-flex align-items-center">
                  Your referral code is
                  <span className="ms-2 text-success">
                    <b>{referralCode.code}</b>
                  </span>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-left}`} className="font-sm1">
                        {tooltipText}
                      </Tooltip>
                    }
                  >
                    <span>
                      <AiOutlineCopy
                        className="ms-2 hoverUpEffect"
                        onClick={handleCopy}
                        size={18}
                      />
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`tooltip-left}`} className="font-sm1">
                        This referal code is very beneficial
                      </Tooltip>
                    }
                  >
                    <span>
                      <BsInfoCircle className="ms-2 hoverUpEffect" size={18} />
                    </span>
                  </OverlayTrigger>
                </span>
              )}
          </div>

          <span id="line" className="m-3"></span>
        </div>
        {/* <span className="w-100 mx-auto d-flex justify-content-end pe-3">
          {user?.referralCode && (
            <span className="d-flex align-items-center">
              Your referral code is
              <span className="ms-2">
                <b>{user.referralCode}</b>
              </span>
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-left}`} className="font-sm1">
                    {tooltipText}
                  </Tooltip>
                }
              >
                <span>
                  <AiOutlineCopy
                    className="ms-2 hoverUpEffect"
                    onClick={handleCopy}
                    size={18}
                  />
                </span>
              </OverlayTrigger>
            </span>
          )}
        </span> */}
        <div className="col-sm-10 mx-auto mt-2 p-0 m-0  statContainerTwo">
          <div className="row m-0  w-100 d-flex justify-content-center">
            {/* <div className="col-sm-6 row d-flex justify-content-around statBoxContainer">
              <div className="border statBox bg-white p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <TbCertificate size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  CERTIFICATIONS
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
              <div className="border statBox bg-white p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <FaAward size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  COURSES ENROLLED
                </span>
                <h3 className="lightDark mt-2 text-dark">0</h3>
              </div>
            </div> */}
            {/* <div className="col-sm-6 row d-flex justify-content-around ">
              <div className="border statBox bg-white p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <AiFillTrophy size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  COURSES COMPLETED
                </span>
                <h3 className="lightDark mt-2 text-success">0</h3>
              </div>
              <div className="border statBox bg-white p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <AiFillStar size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  RATINGS
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-sm-10 mx-auto mt-2 p-0 m-0 statContainerTwo ">
          <div className="row m-0  w-100 d-flex justify-content-center">
            <div className="col-sm-6 row d-flex justify-content-around">
              <div className="border statBox bg-white col-5 p-2 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <FaBriefcase size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  Service Providers Hired
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
              <div className="border statBox bg-white col-5 p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <FaHourglassStart size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  Booking Requests
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
            </div>
            <div className="col-sm-6 row d-flex justify-content-around">
              <div className="border statBox bg-white p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <AiFillStar size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  RATINGS
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
              <div className="border statBox bg-white col-5 p-4 rounded shadow-sm d-flex flex-column align-items-center justify-content-center mt-2">
                <GiCash size={30} className="lightDark mb-2" />
                <span className="fontsm lightDark fw-bold text-center">
                  MoHA POINTS
                </span>
                <h3 className="lightDark mt-2 text-primary">0</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientStats;
