import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import "../../styles/ProgressBar.css";
import { useSelector } from "react-redux";
function ProgressBar2({ completed }) {
  const colorSchema = useSelector((state) => state.color.scheme);
  return (
    <>
      <ProgressBar
        completed={completed}
        //   className="wrapper"
        // barContainerClassName="container"
        // completedClassName="barCompleted"
        // labelClassName="label"
        // bgColor={"#9f7582"}
        // labelColor={"#9f7582"}
        bgColor={colorSchema?.primary}
        labelColor={colorSchema?.primary}
        labelSize={"1px"}
        height={"8px"}
      />
   
    </>
  );
}

export default ProgressBar2;
