import React, { useMemo, useEffect, useState } from 'react';
import { BsSearch, BsChevronDown } from "react-icons/bs";
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import Spinner1 from "../../components/spinners/Spinner1.jsx";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index.js";
import ReactPaginate from "react-paginate";
import ContractListItem from "./ContractListItem.jsx";
import DashboardNav from "../Dashboard/DashboardNav.jsx";
import DashboardFooter from "../Dashboard/DashboardFooter.jsx";
import { useSelector } from "react-redux";
import DashboardLayout from "../Dashboard/DashboardLayout.jsx";
import "../../styles/Contract.css";
import { FaFileContract } from "react-icons/fa";
import { Box, Grid, useMediaQuery, Container } from '@mui/material';
import { useLocalStorage } from 'react-use';
import toast from 'react-hot-toast';
import moment from "moment";
import Contract from "./Index";
import { Avatar, Chip, ListItem, Typography, ListItemText, ListItemAvatar, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


function AllContracts() {
  const [contractsLoading, setContractsLoading] = useState(false);
  const [contracts, setContracts] = useState([
  ]);
  const [showContract, setShowContract] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );


  const [paginationModel, setPaginationModel] = useLocalStorage("pagination", {
    pageSize: 5,
    page: 0,
  });
  const [data, setData] = React.useState();




  const columns = useMemo(
    () => [
      {
        field: "provider",
        headerName: "Share Holder",
        flex: 2,
        sortable: false,
        renderCell: (params) => (
          <ListItem alignItems="flex-start" >
            <ListItemAvatar>
              <Avatar alt="avatar" src={user.role.title === "client" ? params.row.provider.avatar : params.row.client.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  {user.role.title === "client" ?
                    (<Typography color="text.primary" sx={{ fontWeight: "bold" }}>
                      {params.row.provider.firstName +
                        " " +
                        params.row.provider.lastName}
                    </Typography>) : (

                      <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
                        {params.row.client.firstName +
                          " " +
                          params.row.client.lastName}
                      </Typography>
                    )}
                </>
              }
              secondary={
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {user.role.title === "client" ? params.row.provider.phone : params.row.client.phone}
                </Typography>
              }
            />
          </ListItem>
        ),
      },
      {
        field: "booking.status", headerName: "Contract Status", flex: 1, sortable: false, filter: true,
        renderCell: (params) => (
          <Chip color="primary" variant={params.row.booking?.status === "active " || "accepted" ? "contained" : "outlined"} size="small" label={params.row.booking?.status === "active " || "accepted" ? "Active" : params.row.booking?.status} />
        ),
      },
      {
        field: "schedule.start", headerName: "Valid From", flex: 1, sortable: false, renderCell: (params) => (
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="caption">
            {moment(
              moment(params.row.schedule?.start)
                .format("YYYY-MM-DD HH:mm:ss")
                .split(" ")[0]
            ).format("Do MMMM  YYYY")}

          </Typography>
        ),
      },
      {
        field: "schedule.end", headerName: "Valid To", flex: 1, sortable: false, renderCell: (params) => (
          <Typography
            variant="caption"
            sx={{ fontWeight: "bold" }}
          >
            {moment(
              moment(params.row?.schedule?.end)
                .format("YYYY-MM-DD HH:mm:ss")
                .split(" ")[0]
            ).format("Do MMMM  YYYY")}
          </Typography>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 10,
        getActions: (params) => [
          <GridActionsCellItem
            // icon={<RemoveRedEyeIcon color="secondary" />}
            label="View Contract"
            showInMenu={true}
            onClick={() => onRowClick(params)}
          />,
          <GridActionsCellItem
            label="Accept"
            showInMenu={true}
            icon={<CheckIcon color="success" />}
          // onClick={() => onRowClick(params)}
          />,
          <GridActionsCellItem
            label="Reject"
            showInMenu={true}
            icon={<ClearIcon color="warning" />}
          // onClick={() => onRowClick(params)}
          />,
        ],
      },
    ], [user.role.title]);


  const filterProps = {
    toolbar: {
      csvOptions: { disableToolbarButton: true },
      printOptions: { disableToolbarButton: true },
      showQuickFilter: true,
      quickFilterProps: { debounceMs: 500 },
    }
  }


  //redirect to edit view
  const onRowClick = (
    params
  ) => {
    setShowContract(true);
    setData(params.row)
  };




  const [queryParam, setQueryParam] = useState("all");

  useEffect(() => {
    fetchContracts();
    return () => { };
  }, []);

  const fetchContracts = async () => {
    setContractsLoading(true);
    let url = "";
    if (user.role.title === "client") {
      url = `contracts/?client=${user.sub || user._id || user.id}`;
    } else {
      url = `contracts/?provider=${user.sub || user._id || user.id}`;
    }
    const response = await axiosWrapper({
      method: "get",
      url,
      data: "",
    });
    console.log("My contracts requests");
    console.log(response);
    console.log(response.data.data.rows);
    // setRequests(response.data.data.rows);
    setContracts(response.data.data.rows);
    setContractsLoading(false);
  };

  const filterContracts = async () => {
    setContractsLoading(true);
    if (queryParam === "all") {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?provider=${user.sub || user._id || user.id}`,
        data: "",
      });
      console.log("My  contracts");
      console.log(response.data.data.rows);
      setContracts(response.data.data.rows);
      setContractsLoading(false);
    } else {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?provider=${user.sub || user._id || user.id
          }&status=${queryParam}`,
        data: "",
      });
      console.log("My contracts");
      console.log(response.data.data.rows);

      setContracts(response.data.data.rows);
      setContractsLoading(false);
    }
  };

  useEffect(() => {
    // setRequestsLoading(true);
    // filterContracts();
  }, [queryParam]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  //PAGINATION
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = contracts.slice(itemOffset, endOffset);
  console.log("CONTOs", contracts);
  const pageCount = Math.ceil(contracts.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % contracts.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Container maxWidth="lg" component="main" sx={{ p: 2 }} >
      <div className="row m-0 pt-2 pb-5">
        <div className="col-sm-0 mx-auto d-flex flex-column">
          <span>MY CONTRACTS</span>
          <span id="line" className="mt-2 mb-1"></span>
        </div>
        <div className="col-sm-12 mx-auto d-flex row m-0">
          <div className="w-100 mt-2 mb-2 d-flex flex-wrap justify-content-start">
            <div className="btn-group fontsm ">
              <button
                type="button"
                className={`${queryParam === "all" ? "activeBookingTab" : ""
                  } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setQueryParam("all");
                }}
              >
                All
              </button>
            </div>

            <div className="btn-group ">
              <button
                type="button"
                className={`${queryParam === "pending" ? "activeBookingTab" : ""
                  } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setQueryParam("pending");
                }}
              >
                Pending
              </button>
            </div>

            <div className="btn-group ">
              <button
                type="button"
                className={`${queryParam === "accepted" ? "activeBookingTab" : ""
                  } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setQueryParam("accepted");
                }}
              //   style={{ backgroundColor: `${colorSchema?.primary}` }}
              >
                Accepted
              </button>
            </div>
            <div className="btn-group ">
              <button
                type="button"
                className={`${queryParam === "rejected" ? "activeBookingTab" : ""
                  } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setQueryParam("rejected");
                }}
              //   style={{ backgroundColor: `${colorSchema?.primary}` }}
              >
                Rejected
              </button>
            </div>
          </div>
          <div className="col-sm-12 row m-0">
            {!contractsLoading ? (
              <>
                {!contractsLoading && contracts.length > 0 ? (
                  <>
                    <div
                      className="row d-flex m-0 pt-3 flex-column "
                      style={{ minHeight: "50vh" }}
                    >
                      {isSmallScreen ? (
                        <>
                          {currentItems.map((contract, index) => (
                            <ContractListItem key={index} contract={contract} />
                          ))}
                        </>
                      ) : (
                        <DataGrid
                          disableColumnMenu
                          disableDensitySelector
                          disableColumnSelector
                          autoHeight
                          pagination
                          sortingOrder={['asc', 'desc']}
                          rows={contracts}
                          loading={contractsLoading}
                          columns={columns}
                          getRowId={(row) => row._id}
                          onRowClick={onRowClick}
                          pageSizeOptions={[5, 10]}
                          paginationModel={paginationModel}
                          onPaginationModelChange={setPaginationModel}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={filterProps}
                          sx={{ borderRadius: "1rem", bgcolor: 'background.paper' }}
                        />
                      )}
                    </div>
                    {isSmallScreen && <div className="w-100  p-2 d-flex justify-content-center">
                      <ReactPaginate
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="prev"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                    }
                  </>
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: "50vh" }}
                  >
                    <div className="text-center m-2 alert alert-info fontsm ">
                      You currently dont have any Contracts.
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div style={{ position: "relative", minHeight: "70vh" }}>
                <Spinner1 message={"Fetching your contracts..."} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showContract && (
        <Contract
          details={data}
          closeContract={() => {
            document.body.style.overflow = "visible";
            setShowContract(false);
          }}
        />
      )}
    </Container>

  );
}

export default AllContracts;
