import React from "react";
import { useSelector } from "react-redux";
import Shiko1 from "../../../pics/shiko1.jpg";
import "../../../styles/Dashboard/Messages.css";
import { MdClose } from "react-icons/md";
function ChatUserInfo({ setSideBarOpen }) {
  const { user } = useSelector((state) => state.auth);
  const recipient = useSelector((state) => state.messages.recipient);
  return (
    <div className=" d-flex flex-column align-items-center p-2 ">
      <div className="w-100 d-flex justify-content-end mb-4 pt-2">
        <MdClose size={25} className="contactIcon" onClick={setSideBarOpen} />
      </div>
      <img
        className="m-3"
        src={recipient.avatar}
        alt="user pic"
        style={{ height: "100px", width: "100px", borderRadius: "50px" }}
      />
      <h6>
        <b>
          {recipient.firstName} {recipient.lastName}
        </b>
      </h6>
      <span className="fontsm1 lightDark">Active 2hrs ago</span>
    </div>
  );
}

export default ChatUserInfo;
