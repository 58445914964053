import { Avatar, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Logo from "../../pics/logo2.png";
import SideLogo from "../../pics/sp/chef3.jpg";


export default function AuthLayout({ children,title,sec1,sec2 }) {
    return (
        <Grid container component="main" sx={{ height: '100vh' }} >
            <CssBaseline />
            <Grid item xs={12} md={sec1} component={Paper} square px={{ xs: 1, sm: 2, md: 8 }}>
                <Box
                    sx={{
                        my: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{ mb: 2, height: "5.5rem", width: "5.5rem" }}
                        src={Logo}
                    >
                    </Avatar>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {title}
                    </Typography>
                </Box>
                {children}
            </Grid>
            <Grid item xs={false} sm={4} md={sec2}
                sx={{
                    backgroundImage: `url(${SideLogo})`,
                    backgroundRepeat: 'repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />

        </Grid>
    );
}