import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdClose } from "react-icons/md";

function Subscription({ showPaymentModal, closeModal, isModal }) {
  const colorSchema = useSelector((state) => state.color.scheme);
  const { isSubscribed } = useSelector((state) => state.subscription);
  const { user, isAuth } = useSelector((state) => state.auth);

  const description = [
    'Connect & Hire Service Providers',
    'Rating of Service Providers',
    'Generate Automated Contracts',
    'A.I Recommended Service Providers',
    'Access to Monthly News Letters',
    'Access to Client Community',
    'Referral code discounts',
  ]

  const spdescription = [
    'Access to Training videos',
    'Referral code discounts',
    'Online Assessment & Certification',
    'Downloadable working tools',
    'Access to Service Provider Community',
  ]

  return (
    <div
      className="pricing-card bg-light shadow-sm p-4 col-sm-6 mx-auto d-flex flex-column border m-2"
      style={{ position: "relative" }}
    >
      <span>
        <span className="mt-2">
          <span className="largerText">Subscribe to get the following services </span>{" "}
          {/* <span>/Subscription Fee</span> */}
        </span>
      </span>

      {/* <span className="headerNormal mt-2 fw-bold">
        What you will be getting
      </span> */}
      {isModal && (
        <MdClose
          size={25}
          className="hoverUpEffect"
          onClick={closeModal}
          style={{ position: "absolute", right: "20px", top: "10px" }}
        />
      )}

      <span className="border-top d-flex flex-column mt-2">
        {user?.role?.title === "client" ?
          (
            <>
              {description.map((tier) => (
                <span className="mt-3">
                  <FaCheckCircle color={colorSchema?.primary} className="me-2" />
                  {tier}
                </span>
              ))}
            </>) : (
            <>
              {spdescription.map((tier) => (
                <span className="mt-3">
                  <FaCheckCircle color={colorSchema?.primary} className="me-2" />
                  {tier}
                </span>
              ))}
            </>
          )}
        {/* <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" />
          The ability to get a new trained domestic help from our platform with
          no extra charges
        </span>
        <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" />
          Resources: Automated Time planners, Menu planners, Shopping list and
          more
        </span>
        <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" />
          Access to all our monthly development training content and courses at
          the tap of a button for your staff and home.
        </span>
        <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" /> Access
          to our community, and have the ability to connect and learn from
          different people.
        </span>
        <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" /> Access
          to all our Trained Domestic Help
        </span>
        <span className="mt-3">
          <FaCheckCircle color={colorSchema?.primary} className="me-2" />{" "}
          Personalized newsletter to meet the needs of your home.
        </span> */}
      </span>
      {/* {!isSubscribed ||
        (isSubscribed === "false" && ( */}
      <button
        className="ctaBtn p-2 mt-3 text-light rounded"
        style={{
          border: `1px solid ${colorSchema?.primary}`,
          backgroundColor: `${colorSchema?.primary}`,
        }}
        onClick={showPaymentModal}
      >
        Subscribe to Access Profile
      </button>
      {/* ))} */}
    </div>
  );
}

export default Subscription;
