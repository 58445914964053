import React from "react";
import { IoMdNotifications } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import "../../styles/Dashboard/Notifications.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { closeTabs, setTab } from "../../features/slices/DashboardControlSlice";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Divider,Chip} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function NotificationItem({ notification }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{p:2}}>
      <List >
        <ListItem alignItems="flex-between"
          onClick={() => {
            dispatch(closeTabs());
            dispatch(setTab(""));
            navigate("/notifications/" + notification._id);
          }}
          secondaryAction={
            <DoneAllIcon />
          }
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'background.default' }}>
              <NotificationsIcon sx={{ fill: '#000' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ fontWeight: "bold" }}
              >
                {notification.entity} 
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline',mb:1 }}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                >
                  {notification.message.length > 40
                    ? notification.message.substring(0, 30) + "..."
                    : notification.message}
                </Typography>
                <br></br>
                <Chip label={moment(notification.createdAt).fromNow()} size="small"/>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
    </Box>
    // <div
    //   className="w-100 p-2 notificationItemContainer border-bottom d-flex justify-content-between"
    //   onClick={() => {
    //     dispatch(closeTabs());
    //     dispatch(setTab(""));
    //     navigate("/notifications/" + notification._id);
    //   }}
    // >
    //   <div className="d-flex w-100 align-items-center ">
    //     <div className="notificationIcon p-2 rounded-circle m-2 border">
    //       <IoMdNotifications size={25} className="" />
    //     </div>
    //     <div className="d-flex w-100 p-2 flex-column ">
    //       <div className="d-flex justify-content-between align-items-center">
    //         <span className="fontsm">
    //           <b> {notification.entity}</b>
    //         </span>
    //         <span className="fontsm1 lightDark">
    //           {moment(notification.createdAt).fromNow()}
    //         </span>
    //       </div>
    //       <div className="w-100 row justify-content-between align-items-center">
    //         <span className="notificationGlimpseCont col-11">
    //           <span className="fontsm1 lightDark mt-1 notificationGlimpse">
    //             {notification.message.length > 40
    //               ? notification.message.substring(0, 40) + "..."
    //               : notification.message}
    //           </span>
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default NotificationItem;
