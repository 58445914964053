import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SPProfileDetails2Schema } from "../../../Utils/validations/ProfileValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import "react-phone-input-2/lib/style.css";
import { Grid, TextField,  useMediaQuery } from '@mui/material';
import "../../../styles/ProfileDetails.css";
import storage from '../../../Utils/firebaseConfig';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { MultiSelect } from "react-multi-select-component";

import { FaCheckSquare } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import FileUploadStatus from "../../../components/Forms/FileUploadStatus";
import Spinner2 from "../../../components/spinners/Spinner2";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import EasyImageCropper from "../../../components/ImageUtils/EasyImageCropper";
import { setPrevEmployer } from "../../../features/slices/EmployerSlice";
import { useLocation } from 'react-router-dom'

function SPDetailsTwo({ closeForm }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage, prevPath, isAuth, user } =
    useSelector((state) => state.auth);
  const colorSchema = useSelector((state) => state.color.scheme);

  const FILE_SIZE = 10240 * 10240;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(SPProfileDetails2Schema) });
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const [conductCert, setConductCert] = useState("");
  const [passport, setPassport] = useState("");
  const [idFrontLoading, setIdFrontLoading] = useState(false);
  const [idFrontUploaded, setIdFrontUploaded] = useState(false);
  const [idBackLoading, setIdBackLoading] = useState(false);
  const [idBackUploaded, setIdBackUploaded] = useState(false);
  const [gCLoading, setGCLoading] = useState(false);
  const [gCUploaded, setGCUploaded] = useState(false);
  const [passportLoading, setPassportLoading] = useState(false);
  const [passportUploaded, setPassportUploaded] = useState(false);
  const [phone, setPhone] = useState(
    user.yearsOfExperience === 0 ? "num" : user.prevEmployer?.prevEmployerPhone
  );

  //Whether cert of good conduct is cert or receipt
  const [isConductReceipt, setIsConductReceipt] = useState(false);

  //TODO
  const [nextOfKin, setNextOfKin] = useState(
    user?.nextOfKin?.length > 0 ? user.nextOfKin[0] : ""
  );
  const [nextOfKin2, setNextOfKin2] = useState(
    user?.nextOfKin?.length > 1 ? user.nextOfKin[1] : ""
  );

  const [phoneError, setPhoneError] = useState("");
  const [nextOfKinError, setNextOfKinError] = useState("");
  const [nextOfKin2Error, setNextOfKin2Error] = useState("");

  const [idFrontError, setIdFrontError] = useState("");
  const [idBackError, setIdBackError] = useState("");
  const [GCError, setGCError] = useState("");
  const [passportError, setPassportError] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [changes, setChanges] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [rawUserPhoto, setRawUserPhoto] = useState();
  const [fullPhoto, setFullPhoto] = useState();
  const [croppedUserPhoto, setCroppedUserPhoto] = useState();

  const handleRegister = async (data) => {
    window.scroll(0, 0);
    if (!nextOfKin) {
      return setNextOfKinError("Kindly provide your next of kin's number");
    }
    if (changes) {
      setIsUpdateLoading(true);
      console.log("submited data");
      console.log({
        ...data,
        // conductCert: conductCert,
        // idFront: idFront,
        // idBack: idBack,
        avatar: passport,
      });
      let workUnits = data.prevWorkDurationUnit;
      delete data.prevWorkDurationUnit;

      let uploadData = "";
      if (user.yearsOfExperience > 0) {
        const employersData = {
          serviceProvider: user.sub ? user.sub : user.id,
          prevEmployerName: data.prevEmployerName,
          prevEmployerPhone: phone,
          prevSalary: data.prevSalary,
          prevWorkDuration: `${data.prevWorkDuration} ${workUnits}`,
          prevJobDescription: data.prevJobDescription,
          prevJobTitle: data.prevJobTitle,
        };
        const { prevEmployer, success, count } = await getPrevEmployer();
        if (success && count === 0) {
          //Creating employer details
          const employerDataResponse = await axiosWrapper({
            method: "post",
            url: `referrals/employers`,
            data: employersData,
          });

          if (!employerDataResponse.data.success) {
            setIsUpdateLoading(false);
            return toast.error("Failed to update your previous employer data");
          }
        } else if (success && count > 1) {
          dispatch(setPrevEmployer(prevEmployer));
        }
      }

      delete data.prevEmployerName;
      delete data.prevEmployerPhone;
      delete data.prevSalary;
      delete data.prevWorkDuration;
      delete data.prevJobDescription;
      delete data.prevJobTitle;

      uploadData = {
        ...data,
        idFront: idFront ? idFront : user?.idFront,
        idBack: idBack ? idBack : user?.idBack,
        avatar: passport ? passport : user?.avatar,
        nextOfKin: [nextOfKin, nextOfKin2 && nextOfKin2],
      };

      //check if good conduct is receipt and add the photo url to the receipt field

      if (isConductReceipt) {
        uploadData.conductCertReceipt = conductCert
          ? conductCert
          : user?.conductCertReceipt;
      } else {
        uploadData.conductCert = conductCert
          ? conductCert
          : user?.conductCertReceipt;
      }
      const response = await axiosWrapper({
        method: "patch",
        url: `users/${user.sub ? user.sub : user.id}`,
        data: uploadData,
      });

      if (response.data.success) {
        //upload employers data
        const response2 = await axiosWrapper({
          method: "get",
          url: `users/${user.sub ? user.sub : user.id}`,
          data: "",
        });

        const { prevEmployer, success, count } = await getPrevEmployer();

        let fullUserData = {
          ...response2.data.data,
        };

        if (success && count > 0) {
          fullUserData = {
            ...response2.data.data,
            prevEmployer: prevEmployer,
          };
        }

        dispatch(updateUserDetails(fullUserData));
        dispatch(setPrevEmployer(prevEmployer));

        setIsUpdateLoading(false);
        
        if(location.pathname === "/serviceProvider-onboarding"){
          toast.success("Your profile has been updated!");
          navigate('/dashboard')
        }else{
          toast.success("Your profile has been updated!");
          closeForm()
        } 
      } else {
        toast.error("Failed to update your profile, please try again!");
      }
    } else {
        closeForm()
    }
  };

  const getPrevEmployer = async () => {
    try {
      const employersData = await axiosWrapper({
        method: "get",
        url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
          }`,
      });

      const prevEmployerData = employersData.data.data.rows[0];
      const count = employersData.data.data.count;
      return { prevEmployer: prevEmployerData, success: true, count };
    } catch (error) {
      console.log(error);
      return { prevEmployer: null, success: false, count: 0 };
    }
  };

  const handleIdFrontchange = async (e) => {
    setChanges(true);
    setIdFrontLoading(false);
    setIdFrontError("");
    const file = e.target.files[0];
    console.log("file is here");
    console.log(file);
    if (file.size > FILE_SIZE) {
      setIdFrontError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setIdFrontError("Unsuported file format");
    } else {
      setIdFrontError("");
      setIdFrontLoading(true);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "idFront");

      console.log("file sent");
      console.log(formData);

      const idFrontResponse = await axiosWrapper({
        method: "post",
        url: "storage/upload/image",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      setIdFrontLoading(false);
      console.log("id response");
      if (idFrontResponse.data.success) {
        setIdFrontUploaded(true);
        setIdFront(idFrontResponse.data.data.url);
      } else {
        setIdFrontUploaded(false);
        setIdFrontError("Failed to upload, kindly try again");
      }
    }
  };
  const handleIdBackchange = async (e) => {
    setChanges(true);
    setIdBackLoading(false);
    setIdBackError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setIdBackError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setIdBackError("Unsuported file format");
    } else {
      setIdBackError("");
      setIdBackLoading(true);

      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "idBack");

      const idBackResponse = await axiosWrapper({
        method: "post",
        url: "storage/upload/image",
        data: formData,
      });

      setIdBackLoading(false);
      console.log("id response");
      if (idBackResponse.data.success) {
        setIdBackUploaded(true);
        setIdBack(idBackResponse.data.data.url);
      } else {
        setIdBackUploaded(false);
        setIdBackError("Failed to upload, kindly try again");
      }
    }
  };
  const handleGCchange = async (e) => {
    setChanges(true);
    setGCLoading(false);
    setGCError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setGCError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setGCError("Unsuported file format");
    } else {
      setGCError("");
      setGCLoading(true);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "conductCert");

      const conductCertResponse = await axiosWrapper({
        method: "post",
        url: "storage/upload/image",
        data: formData,
      });

      setGCLoading(false);

      if (conductCertResponse.data.success) {
        setGCUploaded(true);
        setConductCert(conductCertResponse.data.data.url);
      } else {
        setGCUploaded(false);
        setGCError("Failed to upload, kindly try again");
      }
    }
  };
  const handlePassportchange = async (e) => {
    setChanges(true);
    setPassportLoading(false);
    setPassportError("");
    const file = e.target.files[0];
    if (file.size > FILE_SIZE) {
      setPassportError("The selected file is too large");
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      setPassportError("Unsuported file format");
    } else {
      var path = (window.URL || window.webkitURL).createObjectURL(file);
      console.log("path created");
      console.log(path);
      setPassportError("");
      setFullPhoto(file);
      setRawUserPhoto(path);
      setShowImageCropper(true);
    }
  };

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };
  const handleNextOfKinValidation = () => {
    if (!nextOfKin) {
      setNextOfKinError("Phone number required");
    } else if (nextOfKin?.length < 8) {
      setNextOfKinError("Invalid phone length");
    } else {
      setNextOfKinError("");
    }
  };
  const handleNextOfKin2Validation = () => {
    if (!nextOfKin2) {
      setNextOfKin2Error("Phone number required");
    } else if (nextOfKin2?.length < 8) {
      setNextOfKin2Error("Invalid phone length");
    } else {
      setNextOfKin2Error("");
    }
  };

  useEffect(() => {
    if (user.prevEmployer?.length > 0) {
      if (user.prevEmployer?.charAt(0) === "+") {
        setPhone(user.prevEmployer);
      } else {
        setPhone("+" + user.prevEmployer);
      }
    }
  }, []);

  const finalizePassportUpload = async (croppedImage, photo) => {
    setShowImageCropper(false);
    setPassportLoading(true);
    console.log("cropped photo result");
    console.log("path", croppedImage);
    console.log("photo0000", photo);
    let formData = new FormData();
    formData.append("file", photo);
        const myFiles = Array.from(photo);
        const filePath = `profile/${myFiles[0]?.name}`
        const storageRef = ref(storage, filePath);
        // upload image to firebase
        uploadBytes(storageRef, myFiles[0]).then((snapshot) => {
            // get uploaded image
            const bucketRef = ref(storage, snapshot.metadata.fullPath);
            getDownloadURL(bucketRef)
                .then(async (avatar) => {
                    // update user profile
                   setPassport(avatar);
                   console.log("photo0000 2", avatar);
                    setPassportUploaded(false)
                    toast.success("Profile photo  successfully updated");
                    const response = await axiosWrapper({
                        method: "patch",
                        url: `users/${user.sub ? user.sub : user.id}`,
                        data: { avatar },
                    });

                    setPassportUploaded(false)
                    dispatch(updateUserDetails({
                        ...user,
                        avatar,
                    }))
                })
        });

    formData.append("type", "profile");

    // const passportResponse = await axiosWrapper({
    //   method: "post",
    //   url: "storage/upload/image",
    //   data: formData,
    // });
    // setPassportLoading(false);
    // if (passportResponse.data.success) {
    //   setPassportUploaded(true);
    //   setPassport(passportResponse.data.data.url);
    //   console.log("====================================");
    //   console.log("Passport url");
    //   console.log(passportResponse.data.data.url);
    //   console.log("====================================");
    // } else {
    //   setPassportUploaded(false);
    //   setPassportError("Failed to upload, kindly try again");
    // }
  };

  return (
    <>
      {isUpdateLoading && <Spinner2 message={"Updating..."} />}
      <form onSubmit={handleSubmit(handleRegister)} autoComplete="off">
        <Grid
          container
          mb={2}
          mt={2}
          py={!isSmallScreen && location.pathname === "/serviceProvider-onboarding" ? 1: 3}
          px={!isSmallScreen && location.pathname === "/serviceProvider-onboarding" ? 1: 15}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 3, md: 6 }}
        >
          <Grid item xs={12} md={4}>
            <div >
              <label htmlFor="idFront" className="form-label m-0">
                ID Front Part
                {/* (Kindly upload the front part of your ID Card) */}
              </label>
              <div className="border inputDiv  p-2 w-100">
                <input
                  type="file"
                  name="idFront"
                  className="form-control form_control"
                  id="idFront"
                  onChange={handleIdFrontchange}
                />
                <FileUploadStatus
                  isLoading={idFrontLoading}
                  isUploaded={idFrontUploaded}
                  isPresent={user?.idFront}
                />
              </div>

              <small>
                <span className="mt-1 mb-3 text-danger">{idFrontError}</span>
              </small>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div >
              <label htmlFor="idBack" className="form-label m-0">
                ID Back Part
                {/* <br /> */}
                {/* (Kindly upload the front part of your ID card) */}
              </label>
              <div className="border inputDiv  p-2 w-100">
                <input
                  type="file"
                  name="idBack"
                  className="form-control form_control"
                  id="idBack"
                  onChange={handleIdBackchange}
                />
                <FileUploadStatus
                  isLoading={idBackLoading}
                  isUploaded={idBackUploaded}
                  isPresent={user?.idBack}
                />
              </div>

              <small>
                <span className="mt-1 mb-3 text-danger">{idBackError}</span>
              </small>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div >
              <label htmlFor="passportPhoto" className="form-label m-0">
                Photo upload
              </label>
              <div className="border inputDiv p-2 w-100">
                <input
                  type="file"
                  name="passportPhoto"
                  className="form-control form_control"
                  id="passportPhoto"
                  onChange={handlePassportchange}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                />
                <FileUploadStatus
                  isLoading={passportLoading}
                  isUploaded={passportUploaded}
                  isPresent={user?.avatar}
                />
              </div>

              <small>
                <span className="mt-1 mb-3 text-danger">{passportError}</span>
              </small>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <div className=" w-100">
              <label htmlFor="conductCert" className="form-label m-0">
                CERTIFICATE OF GOOD CONDUCT 
                <br />
                (Choose the receipt option if you are still waiting for your
                certificate and you have been issued with a receipt.)
              </label>
              <ul className="nav nav-tabs ">
                <li
                  className="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsConductReceipt(false)}
                >
                  <span
                    style={{ fontSize: "12px" }}
                    className={`nav-link form-label ${!isConductReceipt ? "active fw-bold" : ""
                      } text-black`}
                    aria-current="page"
                    href="#"
                  >
                    Certificate
                  </span>
                </li>
                <li
                  className="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsConductReceipt(true)}
                >
                  <span
                    style={{ fontSize: "12px" }}
                    className={`nav-link form-label text-black ${isConductReceipt ? "active fw-bold" : ""
                      }`}
                    href="#"
                  >
                    Receipt
                  </span>
                </li>
              </ul>
              <div
                className="border-start border-end  ps-2 w-100"
                style={{ width: "100% !important" }}
              >
                <small>{`Uploading ${isConductReceipt ? "Receipt" : "Certificate"
                  }`}</small>
              </div>
              <div className="border-start  border-end border-bottom inputDiv p-2 w-100">
                <input
                  type="file"
                  name="conductCert"
                  className="form-control form_control"
                  id="conductCert"
                  onChange={handleGCchange}
                />
                <FileUploadStatus
                  isLoading={gCLoading}
                  isUploaded={gCUploaded}
                  isPresent={
                    !isConductReceipt ? user?.conductCert : user?.conductCertReceipt
                  }
                />
              </div>

              <small>
                <span className="mt-1 mb-3 text-danger">{GCError}</span>
              </small>
            </div>
            </Grid> */}

          {/* <Grid item xs={12} md={6}>
            <div className="mb-4 mt-1">
              <label htmlFor="prevEmployerName" className="form-label m-0">
                NHIF Number (Optional)
              </label>
              <input
                type="text"
                name="nhif"
                className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={user?.nhif}
                id="nhif"
                {...register("nhif")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{errors?.nhif}</span>
                </small>
              )}
            </div>
            </Grid>
            <Grid item xs={12} md={6}>
            <div className="mb-4 mt-1">
              <label htmlFor="prevEmployerName" className="form-label m-0">
                NSSF Number (Optional)
              </label>
              <input
                type="text"
                name="nssf"
                className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={user?.nssf}
                id="nssf"
                {...register("nssf")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{errors?.nssf}</span>
                </small>
              )}
            </div>
            </Grid> */}
          <Grid item xs={12} md={6}>
              <TextField
                label="Location"
                size="small"
                name="placeOfResidence"
                className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={user?.placeOfResidence}
                id="placeOfResidence"
                {...register("placeOfResidence")}
              />
              {/* {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.placeOfResidence?.message}
                  </span>
                </small>
              )}
            </div> */}
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <div>
              <label htmlFor="phoneInput" className="form-label m-0 mb-1">
                Immediate and available next of kin
              </label>

              <PhoneInput
                country={"ke"}
                onChange={(n) => {
                  setNextOfKin("+" + n);
                  handleNextOfKinValidation();
                  setChanges(true);
                }}
                className="form_control w-100"
                containerClass={"tel_container"}
                inputClass={"form_controls"}
                autocompleteSearch={false}
                inputProps={{
                  name: "nextOfKin",
                  required: true,
                  value: nextOfKin,
                  placeholder: "eg +254...",
                }}
              />

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{nextOfKinError}</span>
                </small>
              )}
            </div>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <div className="mb-4 mt-1">
              <label htmlFor="phoneInput" className="form-label m-0 mb-1">
                Another next of kin (optional)
              </label>

              <PhoneInput
                country={"ke"}
                onChange={(n) => {
                  setNextOfKin2("+" + n);
                  handleNextOfKin2Validation();
                  setChanges(true);
                }}
                className="form_control w-100"
                containerClass={"tel_container"}
                inputClass={"form_controls"}
                autocompleteSearch={false}
                inputProps={{
                  name: "nextOfKin",
                  required: false,
                  value: nextOfKin2,
                  placeholder: "eg +254...",
                }}
              />

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{nextOfKin2Error}</span>
                </small>
              )}
            </div>
            </Grid> */}
          {/* <Grid item xs={12} md={6}> */}

          {/* <div
            className={`${user?.yearsOfExperience === 0 ? "d-none" : ""} w-100`}
          > */}
          <Grid item xs={12} md={6}>

              <TextField
                label="Previous Work Duration"
                size="small"
                type="number"
                name="prevWorkDuration"
                className="form_control fontsm"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={
                  user.yearsOfExperience > 0
                    ? Number(user?.prevEmployer?.prevWorkDuration?.split(" ")[0])
                    : 100
                }
                id="prevWorkDuration"
                // {...register("prevWorkDuration")}
                {...register("prevWorkDuration")}
              />

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.prevWorkDuration &&
                      "Kindly specify your previous work duration"}
                  </span>
                </small>
              )}
          </Grid>
          <Grid item xs={12} md={6}>

              <select
                className="form-select"
                id="prevWorkDurationUnit"
                name="prevWorkDurationUnit"
                onClick={() => {
                  setChanges(true);
                }}
                style={{ cursor: "pointer", outline: "none" }}
                {...register("prevWorkDurationUnit")}
              >
                <option
                  className="p-2 border rounded"
                  style={{ cursor: "pointer" }}
                  value=""
                >
                  Choose one
                </option>
                <option
                  className="p-2"
                  style={{ cursor: "pointer" }}
                  value={"months"}
                  selected={
                    user.yearsOfExperience === 0 ||
                    user.prevEmployer?.prevWorkDuration?.split(" ")[1] ===
                    "months"
                  }
                >
                  Months
                </option>
                <option
                  className="p-2"
                  style={{ cursor: "pointer" }}
                  value={"years"}
                  selected={
                    user?.prevEmployer?.prevWorkDuration.split(" ")[1] === "years"
                  }
                >
                  Years
                </option>
              </select>
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                label="Previous employer's Name"
                size="small"  
                type="text"
                name="prevEmployerName"
                className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={user?.prevEmployer?.prevEmployerName}
                id="prevEmployerName"
                {...register("prevEmployerName")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.prevEmployerName?.message}
                  </span>
                </small>
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <label htmlFor="phoneInput" className="form-label m-0 mb-1">
                Immediate and available next of kin
              </label>

              <PhoneInput
                country={"ke"}
                onChange={(n) => {
                  setNextOfKin("+" + n);
                  handleNextOfKinValidation();
                  setChanges(true);
                }}
                className="form_control w-100"
                containerClass={"tel_container"}
                inputClass={"form_controls"}
                autocompleteSearch={false}
                inputProps={{
                  name: "nextOfKin",
                  required: true,
                  value: nextOfKin,
                  placeholder: "eg +254...",
                }}
              />

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{nextOfKinError}</span>
                </small>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div >
              <label htmlFor="phoneInput" className="form-label m-0 mb-1">
                Previous employer's phone
                <b>
                  {/* (Must be a verifiable number. This number will be contacted by
                    the company and previous employer.Please do not put numbers of
                    friends or of colleagues. Incase of any doubt about the
                    authenticity, you risk being blacklisted. ) */}
                </b>
              </label>

              <PhoneInput
                country={"ke"}
                onChange={(n) => {
                  setPhone("+" + n);
                  handlePhoneValidation();
                  setChanges(true);
                }}
                className="form_control w-100"
                containerClass={"tel_container"}
                inputClass={"form_controls"}
                autocompleteSearch={false}
                inputProps={{
                  name: "phone",
                  required: true,
                  value: phone,
                  placeholder: "eg +254...",
                }}
              />

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">{phoneError}</span>
                </small>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
              fullWidth
                label=" Previous Job Title"
                size="small"
                type="text"
                name="prevJobTitle"
                // className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={
                  user.yearsOfExperience > 0
                    ? user?.prevEmployer?.prevJobTitle
                    : "Title"
                }
                id="prevJobTitle"
                {...register("prevJobTitle")}
              />
              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.prevJobTitle?.message}
                  </span>
                </small>
              )}
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
              fullWidth
                label=" Previous salary"
                size="small"
                type="number"
                name="prevSalary"
                // className="form_control"
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={
                  user.yearsOfExperience > 0
                    ? user?.prevEmployer?.prevSalary
                    : 1000
                }
                id="prevSalary"
                {...register("prevSalary")}
              />
          </Grid>
          <Grid item xs={12} md={12}>
              <TextField
              fullWidth
                label=" Previous job description"
                size="small"
                multiline
                type="text"
                rows={2}
                name="prevJobDescription"
                // className="rounded p-2 w-100 mt-1"
                // style={{ border: "1px solid #c2c2c2" }}
                onKeyUp={() => {
                  setChanges(true);
                }}
                defaultValue={
                  user.yearsOfExperience > 0
                    ? user?.prevEmployer?.prevJobDescription
                    : "description"
                }
                id="prevJobDescription"
                {...register("prevJobDescription")}
              ></TextField>

              {errors && (
                <small>
                  <span className="mt-1 mb-3 text-danger">
                    {errors?.prevJobDescription?.message}
                  </span>
                </small>
              )}

          </Grid>
          {/* </div> */}
         
        </Grid>
        <div className="d-flex">
          <span
            type="submit"
            className="btn_skip mt-2 p-2 bg-white d-flex justify-content-center align-items-center rounded m-2"
            onClick={() => {
              closeForm();
            }}
            style={{
              color: `${colorSchema?.primary}`,
              border: `1px solid ${colorSchema?.primary}`,
            }}
          >
            Skip
          </span>
          <button
            type="submit"
            className="btn_submit mt-2 p-2 text-light rounded m-2"
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            Finish
          </button>
        </div>

        {/* </Grid> */}
        {/* <div className="d-flex align-items-center m-3">
          <input type="radio" className="me-2" />
          <span className="tnc">
            By clicking on “Create an account” you agree to our Terms &
            Conditions and Privacy Statement.
          </span>
        </div> */}
        
      </form>
      {showImageCropper && (
        <EasyImageCropper
          fullPhoto={fullPhoto}
          rawImage={rawUserPhoto}
          uploadCroppedPhoto={finalizePassportUpload}
          closeCropper={() => {
            setShowImageCropper(false);
          }}
        />
      )}
    </>
  );
}

export default SPDetailsTwo;
