/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/Dashboard/Dashboard.css";
import {
  updateUserDetails,
  checkIfProfileIsComplete,
} from "../../features/slices/AuthSlice";
import {
  MdOutlineChair,
  MdChildFriendly,
  MdOtherHouses,
  MdBabyChangingStation,
} from "react-icons/md";
import { TbSchool } from "react-icons/tb";
import { GrHomeRounded } from "react-icons/gr";
import {
  FaScrewdriver,
  FaShieldAlt,
  FaBabyCarriage,
  FaBaby,
  FaHandHoldingMedical,
  FaBroom,
  FaDog,
  FaHandsWash
} from "react-icons/fa";

import {
  GiCook,
  GiPerson,
  GiSteeringWheel,
  GiCookingPot,
} from "react-icons/gi";
import { RiPlantFill, RiNurseFill } from "react-icons/ri"
import RequestFormModal from "./serviceProvider/Requests/RequestFormModal";
import SPProfileSlider from "../profile/sp/SPProfileSlider";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import MessagesContainer from "../Messages/MessagesContainer";
import NotificationsContainer from "../Notifications/NotificationsContainer";
import { fetchChats } from "../../features/slices/MessagesSlice";
import { setActivePage } from "../../features/slices/DashboardControlSlice";
import { fetchServicesAutocomplete } from "../../features/slices/ServiceSlice";
import { setReferralCode } from "../../features/slices/ReferralSlice";
import { setSubscription } from "../../features/slices/SubscriptionSlice";
import { fetchNotifications } from "../../features/slices/NotificationsSlice";
import { toast } from "react-hot-toast";
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Typography, Rating, MenuItem, Paper, Grid, Card, Divider, Button, Chip, Stack, List, Container, CardHeader } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SchoolIcon from '@mui/icons-material/School';
import StarRateIcon from '@mui/icons-material/StarRate';
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import StorageIcon from "@mui/icons-material/Storage";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import profileAvatar from "../../pics/avatar.png";
import butler from "../../pics/services/butler.jpg";
import chef from "../../pics/services/chef.png";
import driver from "../../pics/services/driver.png";
import gardener from "../../pics/services/gardener.png";
import house from "../../pics/services/house-keeper.jpg";
import nanny from "../../pics/services/nanny.jpg";
import pet from "../../pics/services/pet.png";
import laundry from "../../pics/services/laundry.png";
import special from "../../pics/services/special.png";
import repair from "../../pics/services/repair.png";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export const data =
  // [
  //   {
  //     icon: house,
  //     title: "Cleaning Services",
  //     body: "House cleaning of living spaces, including dusting, vacuuming, mopping, and tidying up.",
  //   },
  //   {
  //     icon: "https://media.istockphoto.com/id/1300081308/vector/disabled-elderly-woman-and-her-grandchildren-visiting-nursing-home-nursing-home-concept.jpg?s=612x612&w=0&k=20&c=T8XSP7rt7pq9YYYQa2C-GAhDZv00qYieZjaY5iPvtEI=",
  //     title: "Elderly Care",
  //     body: "Assisting senior citizens with daily activities, such as bathing, dressing, and medication management.",
  //   },
  //   {
  //     icon: chef,
  //     title: "Cooking ",
  //     body: "Catering for events or special occasions. Meal planning and grocery shopping as well as preparing daily meals.",
  //   },
  //   {
  //     icon: nanny,
  //     title: "Childcare & Babysit",
  //     body: "Caring for children, including feeding, bathing, and playing with them.Supervising and assisting with homework.",
  //   },
  //   {
  //     icon: pet,
  //     title: "Pet Sitting",
  //     body: "Feeding and providing water for pets.Walking dogs and pet exercise.Cleaning and maintaining pet enclosures ",
  //   },
  //   {
  //     icon: "https://media.istockphoto.com/id/509586837/vector/african-american-nurse-and-her-patient.jpg?s=612x612&w=0&k=20&c=wtsrcADn_38CI-RB93CV6PK85MCjNfWG-Zr9gW4ijeE=",
  //     title: "Home Nurse",
  //     body: "Medical and nursing care within the home for individuals with medical conditions.Rehabilitation services.Rehabilitation services.",
  //   },
  //   {
  //     icon: butler,
  //     title: "Butler",
  //     body: "Coordinates all domestic staff members, including housekeepers, chefs, gardeners, and chauffeurs.",
  //   },

  //   {
  //     icon: driver,
  //     title: "Driver",
  //     body: "Drives individuals or families to daily commutes, special occasions, or as needed ",
  //   },
  //   {
  //     icon: gardener,
  //     title: "Gardening",
  //     body: "Planting flowers, lawn mowing and maintaining gardens ",
  //   },
  //   {
  //     icon: special,
  //     title: "Special Needs Care",
  //     body: "Its main focus is to provide care to disabled babies, teens and adults. ",
  //   },
  //   {
  //     icon: laundry,
  //     title: "Laundry & Ironing",
  //     body: "Washing, drying, and folding clothes.Ironing and steaming garments.Dry cleaning services for delicate items.",
  //   },
  //   {
  //     icon: repair,
  //     title: "Home Repair",
  //     body: "From plumbing to electrical, we've got you covered with reliable home repair services. ",
  //   },
  // ];
  [
    {
      icon: <FaBroom size={30} style={{ color: "#fff" }} />,
      title: "Household Manager",
      body: "Household Managers undertake all of the general household tasks that the homeowner will otherwise do. These include housekeeping/cleaning informed by a time planner, general cooking, and she could double up as a nanny too.",
    },
    {
      icon: <MdChildFriendly size={30} style={{ color: "#fff" }} />,
      title: "Childminder",
      body: " Maids of Honour childminders are meant to undertake all tasks related to childcare, including preparing the children’s meals and snacks, doing the children’s laundry, and keeping the children’s areas neat, clean and tidy. She/he is also expected to engage the child in activities that would enhance their cognitive development, educational games and crafts.",
    },
    {
      icon: <MdBabyChangingStation size={30} style={{ color: "#fff" }} />,
      title: "Newborn Babycare",
      body: "These service providers come with proven experience in this field while any extra training is a plus. They are expected to know how to handle a newborn and the mother during the delicate process of a baby's development from birth ,weaning and  until toddler stage. They could be deployed during the pregnancy stage or even after the birth of a child.",
    },
    {
      icon: <RiNurseFill size={30} style={{ color: "#fff" }} />,
      title: "Home Nurse",
      body: "Home nurses must have specific training to enable them to handle various ailments professionally. Their roles include providing treatment and procedures ordered by the doctor,e.g. giving injections and medication, drawing blood, changing catheters, providing care to patients in their homes, they make sure that the patient is getting the necessary treatment.",
    },
    {
      icon: <MdOtherHouses size={30} style={{ color: "#fff" }} />,
      title: "Eldery Care",
      body: "Elderly care is the provision of healthcare service to the senior citizens.We take care of the elderly in the society",
    },
    {
      icon: <FaHandHoldingMedical size={30} style={{ color: "#fff" }} />,
      title: "Special Needs Care",
      body: "Its main focus is to provide care to babies, teens and adults that have any disabilities. ",
    },
    {
      icon: <FaBabyCarriage size={30} style={{ color: "#fff" }} />,
      title: "Nanny",
      body: "Our Nannies must possess a strong desire to nurture children, have at least 2 years of proven experience be willing to  and make a life-long difference in a child’s life, and support the child in different capacities like education, emotional and all round  ",
    },
    {
      icon: <FaBaby size={30} style={{ color: "#fff" }} />,
      title: "Newborn Mothercare",
      body: "Newborn Mothercare are professionals that understand the needs of a newborn mother, and supports them through advising on the needed nutrition as well as best practices.",
    },
    {
      icon: <GiPerson size={30} style={{ color: "#fff" }} />,
      title: "Home Assistants",
      body: "Home personal Assistants work very closely with the homeowner to manage the home in its different capacities, from shopping to overseeing the running of the house. ",
    },
    {
      icon: <GiCook size={30} style={{ color: "#fff" }} />,
      title: "Professional Chef",
      body: "The Professional Chef will be expected to take full responsibility for the running of the kitchen and planning of menus in order to cater for all the requirements of the family and their guests. They are trained to prepare different traditional and international dishes. ",
    },
    {
      icon: <GiCookingPot size={30} style={{ color: "#fff" }} />,
      title: "General Cook",
      body: "A general chef will have basic food Preparation skills and is not well trained in international dishes. They are still skilled in cooking and assist in the running of the kitchen.",
    },
    {
      icon: <FaDog size={30} style={{ color: "#fff" }} />,
      title: "Pet Sitting",
      body: "Feeding and providing water for pets. Walking dogs and pet exercise. Cleaning and maintaining pet enclosures ",
    },
    {
      icon: <GiPerson size={30} style={{ color: "#fff" }} />,
      title: "Butler",
      body: "A butler oversees the household staff usually of one residence, knowledge of high social etiquette and formal service, knowledge of proper maintenance for fine dining equipment amongst other things.",
    },

    {
      icon: <GiSteeringWheel size={30} style={{ color: "#fff" }} />,
      title: "Driver",
      body: "Drivers are focused on dropping and picking up of members of the family, and collecting various packages. ",
    },
    {
      icon: <RiPlantFill size={30} style={{ color: "#fff" }} />,
      title: "Gardener",
      body: "Understand the importance of cleanliness. They are focused on how the exterior of the house looks.",
    },
    {
      icon: <FaScrewdriver size={30} style={{ color: "#fff" }} />,
      title: "Repair",
      body: "A butler oversees the household staff usually of one residence, knowledge of high social etiquette and formal service, knowledge of proper maintenance for fine dining equipment amongst other things.",
    },

    {
      icon: <FaHandsWash size={30} style={{ color: "#fff" }} />,
      title: "Laundry",
      body: "Drivers are focused on dropping and picking up of members of the family, and collecting various packages. ",
    },
    {
      icon: <FaBroom size={30} style={{ color: "#fff" }} />,
      title: "Cleaning",
      body: "Understand the importance of cleanliness. They are focused on how the exterior of the house looks.",
    },

  ]

const videoUrls = [
  {
    url: 'https://vimeo.com/864774108', logo: "https://res.cloudinary.com/de3eoq2qb/image/upload/v1674036258/shiko_thumbnail_txfd2s.png", title: "Behavior in the home", tag: "Madam Shiko", info: "How to behaviour at home ", time: 10,
  },
  {
    url: 'https://vimeo.com/875050461', logo: "https://blogassets.leverageedu.com/blog/wp-content/uploads/2020/05/08140736/Modes-of-Communication.png", title: "Communication", tag: "Madam Shiko", info: "Communication with employers ", time: 12,
  },
  {
    url: 'https://vimeo.com/875046449', logo: "https://www.marketing91.com/wp-content/uploads/2020/07/What-is-Etiquette.jpg", title: "Etiquette", tag: "Madam Shiko", info: "How to be courteous ", time: 7,
  },
  {
    url: 'https://vimeo.com/875046449', logo: "https://media.istockphoto.com/id/1214942513/video/housekeeper-washing-the-dishes-wearing-protective-mask.jpg?s=640x640&k=20&c=uxdsPFsxq-a5eACn7MKaw5TwsNbqHahFq_qG3uCh8mU=", title: "Good grooming", tag: "Madam Shiko", info: "How to dress well ", time: 10,
  },

];

function Dashboard({ socket }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { user, isAuth } = useSelector((state) => state.auth);
  const [bookings, setBookings] = useState();
  const [profiles, setProfiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [justify, setJustify] = React.useState('one time');
  const [maxSwiperCurrentIndex, setMaxSwiperCurrentIndex] = useState(0);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [currentSp, setCurrentSp] = useState("");
  const [showMaxProfileSlider, setShowMaxProfileSlider] = useState(false);
  const [searchBy, setSearchBy] = useState("service");
  const [searchParam, setSearchParam] = useState("");
  const [searchLimit, setSearchLimit] = useState(4);
  const [searchOffset, setSearchOffset] = useState(0);
  const [microProfiles, setMicroProfiles] = useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openIt, setOpenIt] = React.useState(false);

  const handleClickOpenIt = () => {
    setOpenIt(true);
  };

  const handleCloseIt = () => {
    setOpenIt(false);
  };

  const handleBook = () => {
    setOpen(false);
    setOpenIt(true);
    toast.success("Service booked successfully")
  }

  const onSwipe = (direction) => {
    console.log('You swiped: ' + direction)
  }

  const onCardLeftScreen = (myIdentifier) => {
    console.log(myIdentifier + ' left the screen')
  }


  const fetchProfileDetails = async () => {
    // setLoading(true);
    document.body.style.overflow = "hidden";
    const response = await axiosWrapper({
      method: "get",
      url: `users/${user.sub ? user.sub : user.id}`,
      data: "",
    });
    const referralCode = await axiosWrapper({
      method: "get",
      url: `referrals/codes?referrer=${user.sub ? user.sub : user.id}`,
    });

    const subscriptionData = await axiosWrapper({
      method: "get",
      url: `users/subscriptions?user=${user.sub ? user.sub : user.id}`,
    });

    // console.log("subscription data");
    console.log("subscription data", subscriptionData.data.data);

    dispatch(setSubscription(subscriptionData.data.data));

    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${user.sub ? user.sub : user.id}`,
    });

    const spProfiles = await axiosWrapper({
      method: "get",
      url: `users?role=serviceProvider&limit=${4}`,
      data: "",
    });
    const reqProfiles = await axiosWrapper({
      method: "get",
      url: `users?role=serviceProvider&limit=${6}&offset=${1}${searchParam && `&skills=${searchParam}`
        }`,
      data: "",
    });
    console.log(`SPS1`, spProfiles)
    setProfiles(spProfiles.data.data.rows);
    setMicroProfiles(reqProfiles.data.data.rows)
    const mybookings = await axiosWrapper({
      method: "get",
      url: `bookings/?client=${user.sub || user._id || user.id}`,
      data: "",
    });
    console.log("My bookings", mybookings.data.data.rows);
    setBookings(mybookings.data.data.rows);

    dispatch(setReferralCode(referralCode.data.data.rows[0]));
    const employersData = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
        }`,
    });
    const prevEmployerData = employersData.data.data.rows[0];
    const userData = {
      ...response.data.data,
      prevEmployer: prevEmployerData,
      rating: getReviews.data.data.rows,
    };
    dispatch(updateUserDetails(userData));

    const isProfileComplete = checkIfProfileIsComplete(userData);
    if (
      user.role.title === "serviceProvider" &&
      isProfileComplete &&
      user.yearsOfExperience > 0
    ) {
      const prevEmployerAnswers = await axiosWrapper({
        method: "get",
        url: `referrals/employers/answers?employer=${userData.prevEmployer._id}`,
        data: "",
      });

      // if (prevEmployerAnswers.data.data.count === 0) {
      //   return navigate("/vetting-pending");
      // }
    }

    if (isAuth && !isProfileComplete) {
      document.body.style.overflow = "visible";
      dispatch(setActivePage("profile"));
      navigate(`/${user?.role?.title}-onboarding`);
    } else {
      // setLoading(false);
      document.body.style.overflow = "visible";
    }
  };

  useEffect(() => {
    dispatch(fetchChats(user.sub || user._id || user.id));
    dispatch(fetchNotifications(user.sub || user._id || user.id));
    dispatch(fetchServicesAutocomplete());
    return () => { };
  }, []);

  useEffect(() => {
    // if (!user.phoneVerified) {
    //   navigate("/verify-phone");
    // }
    fetchProfileDetails();
  }, []);


  const handleShowRequestForm = async (user) => {
    setCurrentSp(user);
    setShowMaxProfileSlider(false);
    setOpenIt(false);
    setShowRequestForm(true);
    // console.log("showing request for ");
    // console.log(user);
  };

  useEffect(() => {
    // console.log("Auth status changed bana!");
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);

  //join room
  const dummyState = "";

  const joinRoom = (userId) => {
    socket.emit("join", { userId });
  };

  useEffect(() => {
    joinRoom(user.sub || user._id);
  }, [dummyState, joinRoom, user._id, user.sub]);



  //handle toast notifications
  useEffect(() => {
    socket.on("notification", (data) => {
      toast.success(data.message);
    });
    return () => {
      socket.off("notification");
    };
  }, []);


  //dashboard stats
  const userStats = [
    {
      title: user.role.title === "serviceProvider" ? "Total Jobs" : "Service Providers Hired",
      count: 0,
      icon: <HomeRepairServiceIcon fontSize="small" sx={{
        color: "text.secondary"
      }} />
    },
    {
      title: "  Booking Requests",
      count: bookings?.length,
      icon: <StorageIcon fontSize="small" sx={{
        color: "text.secondary"
      }} />
    },
    {
      title: user.role.title === "serviceProvider" ? "Courses Enrolled" : "Referals",
      count: 0,
      icon: user.role.title === "client" ? <GroupAddIcon fontSize="small" sx={{
        color: "text.secondary"
      }} /> : <SchoolIcon fontSize="small" sx={{
        color: "text.secondary"
      }} />
    },
    {
      title: "Ratings",
      count: 0,
      icon: <StarRateIcon fontSize="small" sx={{
        color: "text.secondary"
      }} />
    },

  ];


  return (
    <Container maxWidth="lg" component="main" >
      <div >
        <Stack direction="row" justifyContent="space-between" spacing={3} mt={1}>
          <div className="col-sm-0 d-flex flex-column">
            <Typography variant="h6" >Hello, {user.firstName}</Typography>
            <span id="line" className="mt-2 mb-1"></span>
          </div>
          {!isSmallScreen &&
            <div>
              {user.role.title === "serviceProvider" ? (
                <Button
                  startIcon={<SchoolIcon />}
                  sx={{ borderRadius: "8px", px: 1, bgcolor: "text.secondary" }}
                  size="small"
                  variant="contained"
                  color='secondary'
                  onClick={() => navigate('/dashboard/courses')}
                >
                  View Our Courses
                </Button>
              ) : (
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{ borderRadius: "10px", bgcolor: "text.secondary" }}
                  size="small"
                  variant="contained"
                  color='secondary'
                  onClick={handleClickOpen}

                >
                  Book A Service
                </Button>
              )}
            </div>
          }
          {openIt && (
            <SPProfileSlider
              currentIndex={maxSwiperCurrentIndex}
              profiles={profiles}
              handleShowRequestForm={handleShowRequestForm}
              closeModal={() => {
                document.body.style.overflow = "visible";
                setOpenIt(false);
              }}
            />
          )}
          {showRequestForm && (
            <RequestFormModal
              sp={currentSp}
              closeRequestForm={() => {
                setShowRequestForm(false);
                document.body.style.overflow = "visible";
              }}
            />
          )}
          {/* {openIt && (
            <Dialog
              open={openIt}
              onClose={handleCloseIt}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            > */}
          {/* <DialogTitle id="alert-dialog-title">
                {"Your Service Provider"}
              </DialogTitle>
              <DialogContent> */}
          {/* <TinderCard onSwipe={onSwipe} onCardLeftScreen={() => onCardLeftScreen('fooBar')} preventSwipe={['right', 'left']}>Hello, World!</TinderCard> */}
          {/* <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div>
                    <Avatar
                      alt="SP"
                      src="https://firebasestorage.googleapis.com/v0/b/nomadic-coast-307006.appspot.com/o/profile%2FSheila%20(2).png?alt=media&token=b0d1eadd-6a5b-4be2-8e71-32264d3082fb"
                      sx={{
                        justifyContent: "center",
                        height: "7.5rem",
                        width: "7.5rem",
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      component="div"
                      gutterBottom
                    >
                     Sheila Chepkngeno
                    </Typography>
                  </div>
                  <div>
                  <Rating name="read-only" value={4} readOnly />
                  </div>
                </Stack>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  Your Services
                </Divider>
                <DialogContentText id="alert-dialog-description">
                 House Cleaning, Elderly Care
                </DialogContentText> */}
          {/* </DialogContent>
              <DialogActions sx={{ px: 2 }}>
              <Button fullWidth variant="outlined" onClick={handleCloseIt}>Cancel</Button>
                <Button fullWidth variant="contained" onClick={handleCloseIt}>Pay Now</Button>

              </DialogActions> */}
          {/* </Dialog>
          )} */}
          {open && (
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Request a microservice</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You can book a quick one off service for a desired  period of time.
                </DialogContentText>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  What type of service are you looking for
                </Divider>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  select
                  label="Select Tasks"
                  fullWidth
                >
                  <MenuItem value="cleaning">House Cleaning</MenuItem>
                  <MenuItem value="laundry">Laundry & Ironing</MenuItem>
                  <MenuItem value="chef">Meal Preparation</MenuItem>
                  <MenuItem value="baby sitting">Baby sitting</MenuItem>
                  <MenuItem value="airbnb">AirBnb Services</MenuItem>
                  <MenuItem value="pet sitting">Pet sitting & Pet Care</MenuItem>
                </TextField>
                <Divider orientation="horizontal" flexItem sx={{ m: 1 }}>
                  When would you like the service to be done
                </Divider>

                <Grid
                  container
                  mb={2}
                  mt={2}
                  rowSpacing={2}
                  columnSpacing={{ xs: 2, sm: 3, md: 6 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} md={12}>
                      <DateTimePicker
                        fullWidth
                        slotProps={{ textField: { fullWidth: true } }}
                        label="Date"
                      />

                    </Grid>
                    <Grid item xs={12} md={12}>

                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">How Often</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="onetime" control={<Radio />} label="One Time" />
                          <FormControlLabel value="regular" control={<Radio />} label="Regular" />

                        </RadioGroup>
                      </FormControl>

                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button fullWidth variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button fullWidth variant="contained" onClick={handleBook}>Book</Button>
              </DialogActions>
            </Dialog>
          )}
        </Stack>
        <Grid container spacing={3} mt={0.3} alignItems="flex-end" mb={2}>
          {userStats.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              md={6}
              lg={3}
            >
              <Card sx={{ borderRadius: "0.6rem", bgcolor: 'background.paper', boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} elevation={0}>
                <CardHeader
                  sx={{ fontWeight: "bold" }}
                  avatar={
                    <Avatar sx={{ bgcolor: "#fff7f5" }} aria-label="label">
                      {tier.icon}
                    </Avatar>
                  }
                  title={
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>{tier.count}</Typography>
                  } subheader={tier.title}
                />
              </Card>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            md={8}
          >
            <Paper sx={{ borderRadius: "0.6rem", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%) ' }} elevation={0}>
              {user.role.title === "client" ? (
                <List sx={{ p: 3.5, borderRadius: "0.7rem", minHeight: 360 }}>
                  <Typography sx={{ fontWeight: "bold" }}>Recommended Service Providers</Typography>
                  {profiles.map((profile, index) => (
                    <div key={index}>
                      <ListItem
                        secondaryAction={
                          <>
                            {!isSmallScreen &&
                              <Chip size="small" color="primary" onClick={()=> navigate("/dashboard/hire")}sx={{ fontSize: "0.7rem", borderRadius: "4px",cursor:"pointer" }} label='Hire' />
                            }
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar variant="square" sx={{ height: "3rem", width: "3rem", borderRadius: "0.1rem" }} src={profile.avatar || profileAvatar} />
                        </ListItemAvatar>
                        <ListItemText primary={profile.firstName + " " + profile.lastName} secondary={
                          <Stack
                            direction="row" spacing={0.5}
                            justifyContent="flex-start"
                            divider={<Divider orientation="vertical" flexItem />}

                          >
                            {profile.skills.map((skill, index) => (
                              <div key={index}>
                                <Chip size="small" sx={{ fontSize: "0.7rem", borderRadius: "8px", bgcolor: "#FDEDED" }} label={skill.title} />
                              </div>
                            ))
                            }
                          </Stack>
                        } />
                      </ListItem>

                      <Divider variant="inset" component="li" />
                    </div>
                  ))
                  }
                </List >
              ) : (
                <List sx={{ p: 3.5, borderRadius: "0.7rem" }}>
                  <Typography sx={{ fontWeight: "bold" }}>Recommended Courses</Typography>
                  {videoUrls?.map((video, index) => (
                    <div key={index}>
                      <ListItem
                        sx={{ cursor: "pointer" }}
                        secondaryAction={
                          <>
                            {!isSmallScreen && <Chip onClick={() => navigate("/dashboard/courses/course-content")} size="small" color="primary" sx={{ fontSize: "0.7rem", borderRadius: "5px" }} label='View Course' />}
                          </>}

                      >
                        <ListItemAvatar>
                          <Avatar variant="square" sx={{ height: "3rem", width: "3rem", borderRadius: "0.1rem" }} src={video.logo} />
                        </ListItemAvatar>
                        <ListItemText primary={video.title} secondary={
                          <Stack
                            direction="row" spacing={0.5}
                            justifyContent="flex-start"
                          >
                            <div>
                              <Typography noWrap variant="caption" sx={{ textOverflow: "ellipsis" }}>{video.info.substring(0, 20)}..</Typography>
                            </div>
                          </Stack>
                        } />
                      </ListItem>

                      <Divider variant="inset" component="li" />
                    </div>
                  ))
                  }
                </List >
              )}
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Paper sx={{ borderRadius: "0.6rem", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} elevation={0}>
              <Typography sx={{ px: 2, pt: 1, fontWeight: "bold" }}>My Calender</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar />
              </LocalizationProvider>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
          >
            <Paper sx={{ borderRadius: "0.6rem", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)' }} elevation={0}>
              <Typography sx={{ px: 2, pt: 1, fontWeight: "bold" }}>Our Services</Typography>
              <Grid container spacing={2} alignItems="flex-end" sx={{ p: 2 }}>
                {data.map((service) => (
                  <Grid
                    item
                    key={service.icon}
                    xs={6}
                    md={2}
                  >
                    <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 1 }}>
                      <Avatar sx={{ bgcolor: "#CF2680" }}>{service.icon}</Avatar>
                      <Typography component="div" sx={{ fontWeight: "420" }} >{service.title}</Typography>
                      {/* <Typography component="div" color="text.secondary" variant="caption">{service.body.substr(0, 50)}..</Typography> */}
                    </Card>
                  </Grid>
                ))
                }
              </Grid>
            </Paper>
          </Grid>

        </Grid>
      </div>
    </Container>
  );
}

export default Dashboard;
