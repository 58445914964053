import React from "react";
import "../../styles/Contract.css";
import ContractDetailsModal from "./ContractDetailsModal";
function ContractDetails() {
  return (
    <div id="contractModalContainer" className="row m-0">
      <div
        id="contractDiv"
        className="bg-white rounded col-sm-6"
        style={{ position: "relative" }}
      >
        <ContractDetailsModal />
      </div>
    </div>
  );
}

export default ContractDetails;
