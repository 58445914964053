import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
function QuestionItem({ question, index, handleNextQuestion }) {
  const inputRef = useRef();
  const formRef = useRef();
  const [initChecked, setInitChecked] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    let parentElement = document.getElementById("formGroup");
    let radioButtons = parentElement.querySelectorAll("input[name='radio']");

    let L = radioButtons.length;

    for (let i = 0; i < L; i++) {
      radioButtons[i].checked = false; //Un-check all checked radios
    }
  }, [question]);

  return (
    <>
      <div id="QuestionBody" className="w-100 d-flex flex-column mt-1 mb-3 p-3">
        <div>
          <div className="mb-3">
            <span className="">
              {index + 1}. {question.query}
            </span>
          </div>
          <div className="mt-2" id="formGroup">
            {question.choices.map((choice, index) => (
              <div
                // key={`${String(choice).trim().replace(" ", "")}`}
                key={index}
                class="form-check mt-2 "
                onClick={() => {
                  document.getElementById(`${choice}`).click();
                  handleNextQuestion(choice);
                }}
              >
                <input
                  ref={inputRef}
                  className="form-check-input"
                  type="radio"
                  name={"radio"}
                  id={`${choice}`}
                  style={{ cursor: "pointer" }}
                  defaultChecked={false}
                />
                <label
                  class="form-check-label"
                  htmlFor={"radio"}
                  style={{ cursor: "pointer" }}
                >
                  {choice}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionItem;
