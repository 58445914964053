import axios from "axios";
import { toast } from "react-hot-toast";
import { backendUrl } from "../../features/slices/GlobalsSlice";

export const axiosWrapper = async (details) => {
  try {
    const response = await axios({
      method: details.method,
      url: backendUrl + details.url,
      data: details.data ? details.data : "",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });

    return response;
  } catch (error) {
    console.log("error");
    console.log("refreshing error");
    console.log(error);
    if (
      error.response.data.statusCode === 401 &&
      error.response.data.message === "Unauthorized"
    ) {
      try {
        const tokenData = await axios({
          method: "get",
          url: backendUrl + "auth/refresh",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("refreshToken"),
          },
        });
        const { accessToken } = tokenData.data.data;

        localStorage.setItem("accessToken", accessToken);

        const response = await axios({
          method: details.method,
          url: backendUrl + details.url,
          data: details.data ? details.data : "",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        console.log("Refreshed");

        return response;
      } catch (error) {
        console.log("Refresh token expired!");
        localStorage.clear();
        const toastMessage = new Promise((resolve, reject) => {
          try {
            toast.success("Session expired! Kindly login again.");
            resolve(true);
          } catch (error) {
            reject(error.message);
          }
        });
        const hasToasted = await toastMessage;
        if (hasToasted) return (window.location = "/");
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      }
    } else {
      // toast.error(error.response.data.message);
      return error;
    }
  }
};

// export const axiosWrapper = async (details) => {

//   try {
//     const response = await axios({
//       method: details.method,
//       url: details.url,
//       data: details.data ? details.data : "",
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("accessToken"),
//       },
//     });

//     return response;
//   } catch (error) {
//     console.log("error");
//     console.log("refreshing");
//     console.log(error);
//     if (error.response.data.statusCode === 401) {
//       const tokenData = await axios({
//         method: "post",
//         url: "auth/refresh",
//         headers: {
//           Authorization: "Bearer " + localStorage.getItem("refreshToken"),
//         },
//       });
//       const { accessToken, refreshToken } = tokenData.data.data;
//       localStorage.setItem("refreshToken", refreshToken);
//       localStorage.setItem("accessToken", accessToken);

//       const response = await axios({
//         method: details.method,
//         url: details.url,
//         data: details.data ? details.data : "",
//         headers: {
//           Authorization: "Bearer " + localStorage.getItem("accessToken"),
//         },
//       });

//       return response;
//     }
//   }
// };
