import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {  useDispatch } from "react-redux";
import { setPrevPath } from "../features/slices/AuthSlice";
function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const { prevPath, user } = useSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    console.log("Auth status ", isAuth);
    const localStorageData = JSON.parse(localStorage.getItem("authStatus"));
    setIsAuth(localStorageData?.isAuth ? localStorageData.isAuth : false);
    setIsLoading(false);
  }, [isAuth]);

  if (!isLoading) {
    if (!isAuth) {
      dispatch(setPrevPath(location.pathname));
      console.log(location.pathname);
      return navigate("/login");
    } else {
      // if (!user.phoneVerified && user) {
      //   return navigate("/verify-phone");
      // } else {
      //   return children;
      // }
      return children;
    }
  }
}

export default ProtectedRoute;
