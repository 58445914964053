import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import AllCourses from "../Courses/AllCourses";
import ELearningCourses from "../Courses/ELearningCourses";
function ELearning() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"resources"} />
      <div className="" style={{ minHeight: "100vh" }}>
        <ELearningCourses />
      </div>
      <Footer />
    </>
  );
}

export default ELearning;
