import { useState, useEffect } from "react";
import Questions from "./Questions";
import QuizIntro from "./QuizIntro";

function Assessment({ closeResults }) {
  const [showQuestions, setShowQuestions] = useState(false);
  return (
    <>
      <div className=" w-100">
        {!showQuestions ? (
          <QuizIntro
            startQuiz={() => {
              window.scroll(0, 0);
              setShowQuestions(true);
            }}
          />
        ) : (
          <Questions closeResults={closeResults} />
        )}
      </div>
    </>
  );
}

export default Assessment;
