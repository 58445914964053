import { useEffect, useState } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import hospitality1 from "../../pics/sp/sps_portrait_2.jpg";
import hospitality2 from "../../pics/sp/client_served1.png";
import "../../styles/Services.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import { BsFillCheckCircleFill, BsFillTelephoneFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import Photo from "../../pics/home1.jpg";
function Hospitality() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const allServices = useSelector((state) => state.services.services);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const [services, setServices] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const filterServices = async () => {
    setServices(
      allServices.filter((service) =>
        service.serviceCategory.find((category) => category.title === "Home")
      )
    );
  };

  useEffect(() => {
    filterServices();
  }, []);

  return (
    <>
      <Nav currentPage={"services"} />
      <div style={{ backgroundColor: "#FFF3EF" }} className="m-0 p-0">
        <div
          className="w-100 m-0 p-0"
          style={{
            backgroundImage: `url(${hospitality1})`,
            height: "40vh",
            backgroundPosition: "top",
            backgroundSize: "cover",
            position: "relative",
            backgroundAttachment: "fixed",
          }}
        >
          <div
            className="row m-0 d-flex align-items-center"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
          >
            <div className="col-sm-9 mx-auto">
              <h1 className="text-white text-center mx-auto">Hospitality</h1>
            </div>
          </div>
        </div>
        <div
          className="row m-0 w-100 pt-3 pb-3"
          style={{ backgroundColor: "#FFF3EF" }}
        >
          <div className="col-sm-9 mx-auto row pb-3 m-0">
            <div className="col-sm-6 p-2">
              <img
                src={hospitality2}
                alt=""
                className="w-100 mt-2 mb-2 rounded"
              />
              <p className="lightDark mt-4">
                Maids of Honour is a company that understands the importance of
                having trained, vetted, and certified domestic help. In today's
                busy world, it can be difficult for companies to find the time
                to properly train their domestic staff or thoroughly vet them
                for reliability and trustworthiness. This is where Maids of
                Honour comes in. Our team of professionals is dedicated to
                providing top-quality domestic workers who have been thoroughly
                trained and vetted to ensure that they are reliable,
                trustworthy, and capable of providing the high level of service
                that companies expect. By choosing to work with Maids of Honour,
                companies can trust that they are getting the best possible
                domestic staff, and front office staff that will ensure a smooth
                and successful operation. Maids of Honour is focused on
                providing domestic workers and front office staff who are
                trained to be hospitable and provide excellent service to
                guests.
              </p>

              <h4 className="fw-bold mt-4">What we can do for them</h4>
              <div className="row m-0">
                <div className="col-5">
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Gardener
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Driver
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Buttler
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Governess
                  </span>
                </div>
                <div className="col-7">
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Nanny
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> General Chef
                  </span>
                  <span className="d-flex align-items-center mt-2">
                    <BsFillCheckCircleFill className="me-2" /> Personal Home
                    assistants
                  </span>
                </div>
                {!isAuth && (
                  <div className="mt-3">
                    <button
                      className="joinBtn ps-3 pe-3 pt-1 pb-1 w-100  mt-3 rounded fontsm "
                      style={{ backgroundColor: `${colorSchema?.primary}` }}
                      onClick={() => {
                        navigate("/client-register");
                      }}
                    >
                      Sign up now
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-1  "></div>
            <div className="col-sm-5  p-3">
              <div className="p-4 d-flex flex-column rounded bg-white w-100">
                <h5>
                  <b>Having Questions?</b>
                </h5>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <BsFillTelephoneFill size={16} className="me-2" />{" "}
                  +254795930905
                </span>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <FaEnvelope size={16} className="me-2" />{" "}
                  info@maidsofhonour.africa
                </span>
                <span className="d-flex align-items-center mt-3 fontsm">
                  <MdLocationOn size={22} className="me-2" /> Personal Home
                  assistants
                </span>
                <button
                  className="joinBtn ps-3 pe-3 pt-1 pb-1 w-100  mt-3 rounded fontsm"
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  contact us
                </button>
              </div>
              <br />
              <br />
              <h4>
                <b>Our Hospitality Services </b>
              </h4>
              <br />
              <div
                className="p-2"
                style={{ height: "90vh", overflowY: "auto" }}
              >
                {services &&
                  services.map((service) => (
                    <div className="p-4 d-flex mt-3  flex-column rounded bg-white w-100">
                      <div className="w-100 d-flex align-items-center">
                        <div className="ms-2" style={{ position: "relative" }}>
                          <img
                            src={service.banner}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50px",
                            }}
                          />
                          {/* <span
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "-20%",
                              zIndex: 4,
                              width: "40px",
                              height: "40px",
                              borderRadius: "20px",
                              backgroundColor: "#fde4dd",
                            }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <GiGraduateCap size={25} />
                          </span> */}
                        </div>
                        <div className="d-flex flex-column ms-2">
                          <span>
                            <b>{service.title}</b>
                          </span>
                          <span className="fontsm1 lightDark">
                            {service.description.substr(0, 100)}...
                          </span>
                        </div>
                      </div>
                      <button
                        className="joinBtn ps-3 bg-white pe-3 pt-1 pb-1 w-100 fw-bold  mt-3 rounded fontsm"
                        style={{
                          border: `2px solid ${colorSchema?.primary}`,
                          color: `${colorSchema?.primary}`,
                        }}
                        onClick={() => {
                          navigate(`/services/${service._id}`);
                        }}
                      >
                        show details
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* <div className="col-sm-9 mx-auto mt-2">
            <h4>
              <b>Become a partner</b>
            </h4>
            <span className="mt-3">
              To learn more about how Maids of Honour can partner with your
              company through its Corporate Childcare Solutions program, reach
              out to us.
            </span>
          </div> */}
        </div>
        <br />

        {/*
         *
         *
         *
         *
         *
         *
         * */}
      </div>
      <Footer />
    </>
  );
}

export default Hospitality;
