import React, { useMemo, useEffect, useState } from 'react';

import "../../../../styles/Dashboard/Booking.css";
import { BsSearch, BsChevronDown } from "react-icons/bs";
import Spinner1 from "../../../../components/spinners/Spinner1";
import { axiosWrapper } from "../../../../Utils/AxiosWrapper/index.js";
import ReactPaginate from "react-paginate";
import MaxRequestSlider from "../../serviceProvider/Requests/MaxRequestSlider";
import BookingItem from "./BookingItem";
import DashboardNav from "../../DashboardNav";
import DashboardFooter from "../../DashboardFooter";
import { useSelector } from "react-redux";
import DashboardLayout from "../../DashboardLayout";
import { SPRequiredProfileDetailsSchema } from "../../../../Utils/validations/ProfileValidations";
import { Box, Grid, useMediaQuery, Avatar, Chip, ListItem, TextField, Stack, Typography, ListItemText, ListItemAvatar, Button, Container } from '@mui/material';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import moment from "moment";
import { useLocalStorage } from 'react-use';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PhoneInput from "react-phone-input-2";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function Bookings() {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [bookings, setBookings] = useState("");
  const [rate, setRate] = useState();
  const [showMaxRequestSlider, setShowMaxRequestSlider] = useState(false);
  const [maxSwiperCurrentIndex, setMaxSwiperCurrentIndex] = useState(0);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [contracts, setContracts] = useState([
  ]);
  const [showContract, setShowContract] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState("");

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  console.log(`MAX`, showMaxRequestSlider, maxSwiperCurrentIndex)

  const [paginationModel, setPaginationModel] = useLocalStorage("pagination", {
    pageSize: 5,
    page: 0,
  });
  const [data, setData] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [hover, setHover] = React.useState(-1);
  const [openPay, setOpenPay] = React.useState(false);
  const [pay, setPay] = React.useState();

  const handlePayOpen = (userData) => {
    setOpenPay(true);
    setPay(userData)
    console.log(`PAID`, userData)
  };
  const handlePayClose = () => {
    setOpenPay(false);
  };

  const handleClickOpen = (userData) => {
    setOpen(true);
    setRate(userData)
    console.log(`RATED`, userData)
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [queryParam, setQueryParam] = useState("all");

  useEffect(() => {
    fetchBookings();
    return () => { };
  }, []);

  const fetchBookings = async () => {
    setRequestsLoading(true);
    const response = await axiosWrapper({
      method: "get",
      url: `bookings/?client=${user.sub || user._id || user.id}`,
      data: "",
    });
    console.log("My bookings");
    console.log(response.data.data.rows);
    // setRequests(response.data.data.rows);
    setBookings(response.data.data.rows);
    console.log(`bookings`, response.data.data.rows)
    setFilteredBookings(response.data.data.rows);
    setRequestsLoading(false);
  };

  const filterBookings = async () => {
    if (queryParam === "all") {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?client=${user.sub || user._id || user.id}`,
        data: "",
      });

      setBookings(response.data.data.rows);
      setRequestsLoading(false);
    } else {
      const response = await axiosWrapper({
        method: "get",
        url: `bookings?client=${user.sub || user._id || user.id
          }&status=${queryParam}`,
        data: "",
      });

      setBookings(response.data.data.rows);
      setRequestsLoading(false);
    }
  };

  useEffect(() => {
    setRequestsLoading(true);
    filterBookings();
  }, [queryParam]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const showCurrentProfile = async (sub, inx) => {
    const currentBooking = bookings.find((sp) => sp._id === sub);
    const index = bookings.indexOf(currentBooking);
    console.log("index tes" + index);
    console.log("sp profile");
    console.log(currentBooking);

    // const sp = await axiosWrapper({
    //   method: "get",
    //   url: `users/${
    //     currentBooking.provider._id || currentBooking.provider.sub
    //   }`,
    //   data: "",
    // });
    // const cl = await axiosWrapper({
    //   method: "get",
    //   url: `users/${currentBooking.client._id || currentBooking.client.sub}`,
    //   data: "",
    // });

    // console.log("sp");
    // console.log(sp.data.data);
    // console.log("client");
    // console.log(cl.data.data);

    setMaxSwiperCurrentIndex(index);
    setShowMaxRequestSlider(true);
    document.body.style.overflow = "hidden";
  };

  //PAGINATION
  const [itemsPerPage, setItemsPerPage] = useState(4);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = bookings.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(bookings.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % bookings.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  const filterProps = {
    toolbar: {
      csvOptions: { disableToolbarButton: true },
      printOptions: { disableToolbarButton: true },
      showQuickFilter: true,
      quickFilterProps: { debounceMs: 500 },
    }
  }

  const columns = useMemo(
    () => [
      {
        field: "provider",
        headerName: "Name",
        flex: 2,
        sortable: false,
        renderCell: (params) => (
          <ListItem alignItems="flex-start" >
            <ListItemAvatar>
              <Avatar alt="avatar" src={user.role.title === "client" ? params.row.provider.avatar : params.row.client.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  {user.role.title === "client" ?
                    (<Typography color="text.primary" sx={{ fontWeight: "bold" }}>
                      {params.row.provider.firstName +
                        " " +
                        params.row.provider.lastName}
                    </Typography>) : (

                      <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
                        {params.row.client.firstName +
                          " " +
                          params.row.client.lastName}
                      </Typography>
                    )}
                </>
              }
              secondary={
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {user.role.title === "client" ? params.row.provider.phone : params.row.client.phone}
                </Typography>
              }
            />
          </ListItem>
        ),
      },
      {
        field: "status", headerName: "Status", flex: 1, sortable: false, filter: true,
        renderCell: (params) => (
          <Chip color="primary" variant="outlined" size="small" label={params.row.status} />
        ),
      },
      // {
      //   field: "client.placeOfResidence", headerName: "Location", flex: 1, sortable: false, filter: true,
      //   renderCell: (params) => (
      //     <Typography>{params.row.client.placeOfResidence} </Typography>
      //   ),
      // },
      {
        field: "schedule.start", headerName: "Start Date", flex: 1, sortable: false, renderCell: (params) => (
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="caption">
            {moment(
              moment(params.row.schedule?.start)
                .format("YYYY-MM-DD HH:mm:ss")
                .split(" ")[0]
            ).format("Do MMMM  YYYY")}

          </Typography>
        ),
      },
      {
        field: "schedule.end", headerName: "End Date", flex: 1, sortable: false, renderCell: (params) => (
          <Typography
            variant="caption"
            sx={{ fontWeight: "bold" }}
          >
            {moment(
              moment(params.row?.schedule?.end)
                .format("YYYY-MM-DD HH:mm:ss")
                .split(" ")[0]
            ).format("Do MMMM  YYYY")}
          </Typography>
        ),
      },

      {
        field: "schedule", headerName: "Placement Fee", flex: 1, sortable: false, renderCell: (params) => (
          <>
            {user.role.title === "client" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  handlePayOpen(params.row)
                }}
              >
                Pay Fee
              </Button>
            ) : (<Chip color="primary" variant="outlined" size="small" label="Pending" />)}
          </>
        ),
      },
      {
        field: "service", headerName: "Rate", flex: 1, sortable: false, renderCell: (params) => (
          <>
            {user.role.title === "client" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  handleClickOpen(params.row)
                }}
              >
                Rate Service
              </Button>
            ) : (<Chip color="primary" variant="outlined" size="small" label="Pending" />)}
          </>
        ),
      },
      {
        field: "service.banner", headerName: "About", flex: 1, sortable: false, renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              showCurrentProfile(params.row._id)
            }}
          >
            View Profile
          </Button>
        ),
      },
    ], [showCurrentProfile, user.role.title]);


  //redirect to edit view
  const onRowClick = (
    params
  ) => {
    console.log(`show prof`, showMaxRequestSlider)
    showMaxRequestSlider(true)
    // showCurrentProfile()
    setData(params.row)
  };

  return (
    // <DashboardLayout>
      <>
        {/* <DashboardNav currentPage={"requests"} /> */}
        <div
          style={{
            backgroundColor: "#F4F7FE", height: "90vh"
          }}
        >
          <Container maxWidth="lg" component="main" sx={{ p: 2 }} >
            <div className="row m-0 pt-2 pb-5">
              <div className="col-sm-0 mx-auto d-flex flex-column">
                <span>MY BOOKINGS</span>
                <span id="line" className="mt-2 mb-1"></span>
              </div>
              <div className="col-sm-12 mx-auto d-flex row m-0">
                {isSmallScreen && (
                  <div className="w-100 mt-2 mb-2 d-flex flex-wrap justify-content-start">
                    <div className="btn-group fontsm ">
                      <button
                        type="button"
                        className={`${queryParam === "all" ? "activeBookingTab" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          setQueryParam("all");
                        }}
                      >
                        All
                      </button>
                    </div>
                    <div className="btn-group ">
                      <button
                        type="button"
                        className={`${queryParam === "active" ? "bg-primary text-white" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          setQueryParam("active");
                        }}
                      >
                        Active
                      </button>
                    </div>
                    <div className="btn-group ">
                      <button
                        type="button"
                        className={`${queryParam === "pending" ? "bg-warning text-white" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          setQueryParam("pending");
                        }}
                      >
                        Pending
                      </button>
                    </div>

                    <div className="btn-group ">
                      <button
                        type="button"
                        className={`${queryParam === "accepted" ? "bg-success text-white" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          setQueryParam("accepted");
                        }}
                      //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                      >
                        Accepted
                      </button>
                    </div>
                    <div className="btn-group  d-flex align-items-center justify-content-between">
                      <button
                        type="button"
                        className={`${queryParam === "completed" ? "bg-dark text-white" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setQueryParam("completed");
                        }}
                        style={{ fontSize: "12px" }}
                      //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                      >
                        Completed
                      </button>
                    </div>
                    <div className="btn-group  d-flex align-items-center justify-content-between">
                      <button
                        type="button"
                        className={`${queryParam === "rejected" ? "bg-danger text-white" : ""
                          } btn  light-dark rounded d-flex align-items-center justify-content-center text-center `}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setQueryParam("rejected");
                        }}
                        style={{ fontSize: "12px" }}
                      //   style={{ backgroundColor: `${colorSchema?.primary}` }}
                      >
                        Rejected
                      </button>
                    </div>
                  </div>
                )}
                {!requestsLoading ? (
                  <>
                    {!requestsLoading && bookings.length > 0 ? (
                      <>
                        <div
                          className="row d-flex m-0 pt-3"
                          style={{ minHeight: "60vh" }}
                        >
                          {currentItems.map((booking, index) => (
                                <BookingItem
                                  key={index}
                                  index={index}
                                  booking={booking}
                                  showProfile={showCurrentProfile}
                                />
                              ))}
                          {/* {isSmallScreen ? (
                            <>
                              {currentItems.map((booking, index) => (
                                <BookingItem
                                  key={index}
                                  index={index}
                                  booking={booking}
                                  showProfile={showCurrentProfile}
                                />
                              ))}
                            </>
                          ) : (
                            <DataGrid
                              disableColumnMenu
                              disableDensitySelector
                              disableColumnSelector
                              autoHeight
                              pagination
                              sortingOrder={['asc', 'desc']}
                              rows={bookings}
                              loading={requestsLoading}
                              columns={columns}
                              getRowId={(row) => row._id}
                              onRowClick={onRowClick}
                              pageSizeOptions={[5, 10]}
                              paginationModel={paginationModel}
                              onPaginationModelChange={setPaginationModel}
                              slots={{ toolbar: GridToolbar }}
                              slotProps={filterProps}
                              sx={{ borderRadius: "1rem", bgcolor: 'background.paper' }}
                            />
                          )} */}
                        </div>
                        <div className="w-100  p-2 d-flex justify-content-center">
                          <ReactPaginate
                            nextLabel="next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="prev"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                       
                      </>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: "70vh" }}
                      >
                        <div className="text-center m-2 alert alert-info fontsm ">
                          You currently dont have any{" "}
                          {queryParam !== "all" && <span>{queryParam}</span>}{" "}
                          bookings. Kindly check in later.
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div style={{ position: "relative", minHeight: "70vh" }}>
                    <Spinner1 message={"Fetching your requests..."} />
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        {showMaxRequestSlider && (
          <MaxRequestSlider
            currentIndex={maxSwiperCurrentIndex}
            requests={bookings}
            closeModal={() => {
              document.body.style.overflow = "visible";
              setShowMaxRequestSlider(false);
            }}
          />
        )}

        {open && (
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Rate your Service Provider
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                ml: 2,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ justifyContent: "center" }} >
              <Box
                sx={{
                  width: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center"
                }}
              >

                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                  <div>
                    <Avatar alt="Service Provider" src={rate.provider.avatar} sx={{ justifyContent: "center", height: "3.5rem", width: "3.5rem" }} />
                    <Typography variant="caption" gutterBottom sx={{ color: "grey" }}>
                      {rate.provider.firstName} {rate.provider.lastName}
                    </Typography>
                  </div>
                  <Rating
                    name="hover-feedback"
                    size="large"
                    value={value}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    sx={{
                      fontSize: "3rem"
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                  />
                  <Typography align="center" component="div" sx={{ mt: 2, fontSize: "0.9rem" }} gutterBottom>
                    {labels[hover !== -1 ? hover : value]}
                  </Typography>
                </Stack>

              </Box>
              <TextField fullWidth variant="filled" label="Add Feedback" helperText="Describe how your experience was with our service provider" />

            </DialogContent>
            <DialogActions >
              <Button variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleClose}>
                Done
              </Button>
            </DialogActions>
          </BootstrapDialog>
        )}
        {openPay && (
          <BootstrapDialog
            onClose={handlePayClose}
            aria-labelledby="customized-dialog-title"
            open={openPay}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Mpesa Payment 
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handlePayClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                ml: 2,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ justifyContent: "center" }} >
              <Box
                sx={{
                  width: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center"
                }}
              >

                <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                  <div>
                    <ListItem
                    >
                      <ListItemAvatar>
                        <Avatar alt="mpesa" src="https://i0.wp.com/techweez.com/wp-content/uploads/2023/08/mpesa.png?fit=1600%2C900&ssl=1" sx={{ justifyContent: "center", height: "3.5rem", width: "3.5rem" }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="caption" component="div" gutterBottom >
                            Paybill No: 4109467
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" component="div" gutterBottom >
                            Account No: MOH-A
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                  <Typography align="center" component="div" sx={{ mt: 2, fontSize: "0.9rem",color:"grey" }} gutterBottom>
                    <em>Pay ksh <strong>15000/= </strong><br></br>for {pay.provider.firstName} {pay.provider.lastName}'s placement fee</em>
                  </Typography>
                </Stack>

              </Box>
              {/* <TextField fullWidth variant="filled" label="Phone Number" helperText="You will receive a popup on your phone that will prompt you to enter your pin." /> */}
              <div className="mb-2 mt-4">
                <PhoneInput
                  country={"ke"}
                  onChange={(n) => {
                    handlePhoneValidation();
                    setPhone("+" + n);
                  }}
                  className="form_control w-100"
                  containerClass={"tel_container"}
                  inputClass={"form_controls"}
                  inputProps={{
                    name: "phone",
                    required: true,
                    value: phone,
                  }}
                />
                <small>
                  <span className="mt-1 mb-3 text-danger">{phoneError}</span>
                </small>
                <small>
                  <Typography align="center"  sx={{ color: "grey" }} variant="caption">You will receive a popup on your phone {phone} that <br></br>  will prompt you to enter your pin.</Typography>
                </small>
              </div>
            </DialogContent>
            <DialogActions >
              <Button variant="contained"
                color="primary"
                size="small"
                sx={{ bgColor: "green" }}
                fullWidth
                onClick={handlePayClose}>
                Pay Now
              </Button>
            </DialogActions>
          </BootstrapDialog>
        )}
        {/* <DashboardFooter /> */}
      </>
    // </DashboardLayout>
  );
}

export default Bookings;
