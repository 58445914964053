import { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/Rating.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdStar, MdCancel, MdClose } from "react-icons/md";
import { toast } from "react-hot-toast";
import PreviousRatings from "./PreviousRatings";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Nav from "../Home/Nav";
import swal from "sweetalert";

import { axiosWrapper } from "../../Utils/AxiosWrapper";
import Spinner1 from "../spinners/Spinner1";
function RateReview({ closeModal, booking }) {
  const [attitude, setAttitude] = useState("");
  const [conduct, setConduct] = useState("");
  const [etiquette, setEtiquette] = useState("");
  const [communication, setCommunication] = useState("");
  const [grooming, setGrooming] = useState("");
  const [hygiene, setHygiene] = useState("");
  const [comments, setComments] = useState("");
  const [rated, setRated] = useState(false);
  const navigate = useNavigate("");
  const colorSchema = useSelector((state) => state.color.scheme);
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const [showPreviousRatings, setShowPreviousRatings] = useState(false);
  //errors
  const [groomingError, setGroomingError] = useState("");
  const [conductError, setConductError] = useState("");
  const [etiquetteError, setEtiquetteError] = useState("");
  const [communicationError, setCommunicationError] = useState("");
  const [attitudeError, setAttitudeError] = useState("");
  const [hygieneError, setHygieneError] = useState("");
  const [commentsError, setCommentsError] = useState("");

  //loaders
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Please wait...");

  const handleValidations = async () => {
    clearErrors();
    if (
      !grooming ||
      !conduct ||
      !etiquette ||
      !attitude ||
      !hygiene ||
      !comments ||
      !communication
    ) {
      if (!grooming) {
        setGroomingError("Kindly provide a rating for grooming");
      }
      if (!conduct) {
        setConductError("Kindly provide a rating for conduct");
      }
      if (!etiquette) {
        setEtiquetteError("Kindly provide a rating for etiquette");
      }
      if (!attitude) {
        setAttitudeError("Kindly provide a rating for manners");
      }
      if (!hygiene) {
        setHygieneError("Kindly provide a rating for hygiene");
      }
      if (!comments) {
        setCommentsError("Kindly provide a rating for comments");
      }
      if (!communication) {
        setCommunicationError("Kindly provide a rating for communication");
      }
    } else {
      handleSubmitRating();
    }
  };

  //To be continued
  const handleSubmitRating = async () => {
    try {
      setLoading(true);
      let ratingData = {
        attitude,
        communication,
        conduct,
        etiquette,
        grooming,
        hygiene,
        review: comments,
        reviewer: booking.client._id,
        reviewee: booking.provider._id,
      };

      const createReview = await axiosWrapper({
        method: "post",
        url: `reviews`,
        data: ratingData,
      });

      console.log(createReview.data);

      if (createReview.data.success) {
        closeModal();
        setLoading(false);
        swal("Success", "Rating was successfully!", "success");
      } else {
        closeModal();
        setLoading(false);
        toast.error("Failed to complete your vetting, please try again!");
      }
    } catch (error) {
      closeModal();
      console.log(error.message);
      setLoading(false);
      toast.error("Failed to complete your vetting, please try again!");
    }
  };

  const clearErrors = () => {
    setGroomingError("");
    setConductError("");
    setEtiquetteError("");
    setAttitudeError("");
    setHygieneError("");
    setCommentsError("");
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  return (
    <>
      <div className="w-100 ratingContainerDiv">
        <div
          className="row m-0  d-flex justify-content-center align-items-center "
          style={{ height: "100vh" }}
        >
          {!rated ? (
            <div
              style={{ height: "95vh", overflowY: "auto" }}
              className="col-sm-6 card mx-auto shadow-sm m-3 p-0  rounded
       border-bottom"
            >
              <div className="card-header d-flex flex-wrap align-items-center justify-content-between p-3">
                <div className="mt-2 d-flex justify-content-between align-items-center w-100 mb-4 ">
                  <button
                    type="button"
                    className="rounded p-1 rateBtns d-flex align-items-center fontsm bg-dark "
                    onClick={() => {
                      setShowPreviousRatings(true);
                    }}
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <MdStar className="text-warning me-1" />
                    Previous Ratings
                  </button>
                  <MdClose
                    className="hoverUpEffect"
                    size={25}
                    onClick={closeModal}
                  />
                </div>
                <span className="fw-bold w-100 headerNormal d-flex justify-content-center">
                  {" "}
                  <h5 className="fw-bold mx-auto">
                    Rate your service provider
                  </h5>
                </span>
                {/* <OverlayTrigger
                placement={"bottom"}
                overlay={<Tooltip>Show previous ratings</Tooltip>}
              >
                <button
                  type="button"
                  className="rounded p-1 rateBtns d-flex align-items-center fontsm"
                  data-bs-toggle="modal"
                  data-bs-target="#previousRatingsModal"
                  style={{
                    backgroundColor: `${colorSchema?.primary}`,
                    width: "fit-content",
                  }}
                >
                  <MdStar className="text-warning me-1" />
                  Previous Ratings
                </button>
              </OverlayTrigger> */}
                {/* <button
                type="button"
                className="rounded p-1 rateBtns d-flex align-items-center fontsm bg-dark ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#previousRatingsModal"
                style={{
                  width: "fit-content",
                }}
              >
                <MdStar className="text-warning me-1" />
                Previous Ratings
              </button> */}
              </div>
              <div className="card-body d-flex flex-column align-items-center">
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className=" ">Attitude</span>
                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setAttitude(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>
                  {attitudeError && (
                    <span className="text-danger">
                      <small>{attitudeError}</small>
                    </span>
                  )}
                </div>
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className="  ">Behavior/Conduct</span>
                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setConduct(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>{" "}
                  {conductError && (
                    <span className="text-danger">
                      <small>{conductError}</small>
                    </span>
                  )}
                </div>
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className="">Etiquette/Manners</span>
                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setEtiquette(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>
                  {etiquetteError && (
                    <span className="text-danger">
                      <small>{etiquetteError}</small>
                    </span>
                  )}
                </div>

                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className="  ">Communication</span>

                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setCommunication(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>
                  {communicationError && (
                    <span className="text-danger">
                      <small>{communicationError}</small>
                    </span>
                  )}
                </div>
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className="  ">Good grooming</span>

                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setGrooming(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>
                  {groomingError && (
                    <span className="text-danger">
                      <small>{groomingError}</small>
                    </span>
                  )}
                </div>

                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between  align-items-center ">
                    <span className="  ">Hygiene</span>
                    <ReactStars
                      count={5}
                      onChange={(rating) => {
                        setHygiene(rating);
                      }}
                      classNames={
                        "w-100 d-flex align-items-center justify-content-around mx-auto m-1"
                      }
                      size={30}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                  </div>
                  {hygieneError && (
                    <span className="text-danger">
                      <small>{hygieneError}</small>
                    </span>
                  )}
                </div>

                <textarea
                  placeholder="...write your review here..."
                  className="form-control m-3 text-center pt-2"
                  value={comments}
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                  rows={5}
                ></textarea>
                <span className="text-danger">
                  <small>{commentsError}</small>
                </span>
                <button
                  onClick={handleValidations}
                  style={{
                    backgroundColor: `${colorSchema?.primary}`,
                  }}
                  className="rounded p-1 mt-3 rateBtns"
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div
              className="col-sm-5 mx-auto d-flex justify-content-center align-items-center flex-column m-3 p-0  rounded
       "
            >
              <AiFillCheckCircle size={80} className="text-success m-5" />
              <h4>Your response has been saved successfully!</h4>
              <button
                onClick={() => {
                  navigate("/dashboard");
                }}
                style={{
                  backgroundColor: `${colorSchema?.primary}`,
                }}
                className="rounded p-1 m-5 rateBtns"
              >
                proceed
              </button>
            </div>
          )}
        </div>
        {loading && <Spinner1 message={loadingMessage} />}

        <PreviousRatings
          provider={booking.provider._id}
          showModal={showPreviousRatings}
          handleClose={() => {
            setShowPreviousRatings(false);
          }}
        />
      </div>
    </>
  );
}

export default RateReview;
