import React from "react";
import Loader from "../../pics/loaderAnimation.gif";
import { ImSpinner2 } from "react-icons/im";
function ResultsLoader() {
  return (
    <>
      <div className="row m-0 w-100 p-5 d-flex flex-column align-items-center justify-content-center">
        <ImSpinner2 id="spinnerIcon1" size={40} className="m-4" />

        <h5 className="mx-auto text-center">Calculating your results...</h5>
      </div>
    </>
  );
}

export default ResultsLoader;
