import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentBooking: JSON.parse(localStorage.getItem("currentBooking")) || "",
};

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setCurrentBooking: (state, action) => {
      state.currentBooking = action.payload;
      localStorage.setItem("currentBooking", JSON.stringify(action.payload));
    },
  },
});

export const { setCurrentBooking } = BookingSlice.actions;
export default BookingSlice.reducer;
