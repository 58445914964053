import React from "react";
import "../../../styles/Dashboard/Messages.css";
import Shiko1 from "../../../pics/shiko1.jpg";
import { MdCall, MdClose, MdVideocam } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import { BsLayoutSidebarReverse } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { clearRecipient } from "../../../features/slices/MessagesSlice";
function ChatHeader({
  setSideBarOpen,
  showContract,
  handleTermination,
  startVideoChat,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const colorSchema = useSelector((state) => state.color.scheme);
  const recipient = useSelector((state) => state.messages.recipient);

  return (
    <div
      id="chatHeaderContainer"
      className="w-100 bg-danger d-flex align-items-center justify-content-between m-0 ps-3 pe-3 pt-2 pb-2 bg-white shadow-sm"
    >
      <div className="d-flex align-items-center">
        <BiArrowBack
          className="me-3"
          size={20}
          id="chatBackArrow"
          onClick={() => {
            dispatch(clearRecipient());
            navigate("/dashboard");
          }}
          style={{ cursor: "pointer", display: "none" }}
        />
        <img src={recipient.avatar} className="chatHeaderIcon" alt="user pic" />
        <div className="d-flex flex-column ms-2">
          <span className="fontsm">
            <b>
              {recipient.firstName} {recipient.lastName}
            </b>
          </span>
          <span className="lightDark fontsm1">Active 2hr ago</span>
        </div>
      </div>
      <div
        className="d-flex justify-content-end me-3 align-items-center"
        id="chatHeaderIcons"
      >
        {/* <OverlayTrigger
          placement={"bottom"}
          overlay={
            <Tooltip id={`tooltip`} className="font-sm1">
              Generate contract
            </Tooltip>
          }
        >
          <span>
            <button
              className="btn btn-sm btn-primary me-3"
              onClick={showContract}
            >
              Contract
              <AiOutlineFileText className="ms-1 text-white" size={18} />
            </button>
          </span>
        </OverlayTrigger> */}
        <button
          className="btn btn-sm btn-danger terminateNegoBtn me-2"
          onClick={handleTermination}
        >
          <span className="negoBtnTxt"> Terminate Negotiations</span>
          <span className="negoBtnIcon" style={{ display: "none" }}>
            End
          </span>
        </button>

        <MdCall
          size={20}
          className="me-2 text-primary  contactIcon"
          // color={`${colorSchema?.primary}`}
        />
        <MdVideocam
          size={20}
          className="me-2 text-primary  contactIcon"
          onClick={startVideoChat}
          // color={`${colorSchema?.primary}`}
        />
        <BsLayoutSidebarReverse
          onClick={setSideBarOpen}
          size={18}
          // color={`${colorSchema?.primary}`}
          className="me-2 text-primary  contactIcon"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Tooltip on bottom"
        />
      </div>
    </div>
  );
}

export default ChatHeader;
