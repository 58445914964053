import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import faith from "../../pics/faith.jpg";
import Shiko1 from "../../pics/shiko1.jpg";
import jeff from "../../pics/jeff.jpg";

function OurStaff() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"about"} />
      <div
        data-aos="fade-up"
        className="row m-0 w-100 pt-5 pb-5 mb-3"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-sm-10 row mx-auto">
          <div className="col-sm-12 mx-auto m-2 ">
            <h3 className="mb-1">Our team</h3>
            <p>Our top and dedicated professionals.</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2 ml-1">
            <span className="w-100 p-2 teamImageContainer">
              <img src={Shiko1} alt="CET" className="w-100 mb-2 teamImage" />
            </span>
            <h5>
              <b>Everlyn Kihia</b>
            </h5>
            <p>Chief Executive Officer</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img
                src={faith}
                alt="PC"
                className="w-100 mb-2 teamImage"

              />
            </span>
            <h5>
              <b>Faith Omwaga</b>
            </h5>
            <p>Project Coordinator</p>
          </div>
          <div className="col-sm-3 d-flex flex-column align-items-center mt-2">
            <span className="w-100 p-2 teamImageContainer">
              <img
                src={jeff}
                alt="SE"
                className="w-100 mb-2 teamImage"

              />
            </span>
            <h5>
              <b>Jeff Ubayi</b>
            </h5>
            <p>Software Developer</p>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurStaff;
