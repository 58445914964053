import React from "react";
// import couch1 from "../../pics/couch1.jpg";
// import couch3 from "../../pics/couch3.png";
// import staffOne from "../../pics/staffOne.png";
import "../../styles/Banner.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import pinkPrint from "../../pics/sp/banner1.png";
// import Banner1 from "../../pics/sp/sps_portrait.jpg";
import { Box, Stack, Typography, Avatar, useMediaQuery, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Banner() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  // const [bg, setBg] = useState(Banner1);
  return (
    <>
      {!isSmallScreen ? (
        <div
          className="row m-0 bg-light "
          style={{ minHeight: "90vh", position: "relative" }}
        >
          <div
            className="col-sm-5 pt-4 d-flex justify-content-center  flex-column p-5"
            id="ctaMainContainer"
          >
            <div className=" w-100 ps-3 ms-2  mx-auto" id="ctaContainer">
              <h1 style={{ fontSize: isSmallScreen ? "2rem" : "2.8rem" }} className="cta1txt mx-auto mt-4 ms-2 animate__animated animate__fadeInDown">
                Helping{" "}
                <span style={{ color: "#CF2680" }}>
                  employers<span className="text-dark">,</span>
                </span>
                <br /> find the
                <span style={{ color: "#CF2680" }}> right fit</span>
              </h1>
              <p
                style={{ fontSize: "18px" }}
                className="mt-3 animate__animated ms-2  animate__fadeInUp"
              >
                We provide skilled, efficient and reliable domestic workers in Kenya and the rest of Africa.
              </p>

              <Stack spacing={2} direction={!isSmallScreen ? "row" : "column"}>
              
                <Button   sx={{ borderRadius: "0.4rem" ,backgroundColor: `${colorSchema?.primary}`}} fullWidth variant="contained" onClick={() => navigate('/login')}>Hire</Button>
                <Button fullWidth variant="outlined" color="primary"   sx={{ borderRadius: "0.4rem" }} onClick={() => navigate('/register')}>Join</Button>
              </Stack>
            </div>
          </div>
          <div
            className="col-sm-7 m-0 animate__animated animate__fadeIn d-flex align-items-center  p-0"
            style={{
              objectFit: "contain",
            }}
          >
            <img
              style={{
                height: "100%",
              }}
              src={pinkPrint}
              alt="banner pic"
              className="w-100 m-0 bannerImage animate__animated  animate__zoomIn"
            />
          </div>
          <div id="banner_gradient"></div>
        </div >
      ) : (
        <Box sx={{ flexGrow: 1, py: 2, px: 1, minHeight: "60vh", mt: 4 }}>
          <Container disableGutters maxWidth="lg" component="main" sx={{ p: 2, }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>

              <Grid item sm={12} md={5} mt={2}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
                  Helping <span style={{ color: "#CF2680" }}>employers</span> <br></br>find the <span style={{ color: "#CF2680" }}>right fit</span>
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ mb: 4, color: "grey" }}>
                  We provide skilled, efficient and reliable domestic workers in Kenya and the rest of Africa.
                </Typography>
                <Stack spacing={2} direction={!isSmallScreen ? "row" : "column"} >
                  <Button   sx={{ borderRadius: "0.4rem" ,backgroundColor: `${colorSchema?.primary}`}} fullWidth variant="contained" onClick={() => navigate('/login')}>Hire</Button>
                  <Button fullWidth variant="outlined" color="warning"   sx={{ borderRadius: "0.4rem" }} onClick={() => navigate('/register')}>Join</Button>
                </Stack>

              </Grid>
              <Grid item sm={12} md={7}>
                <Avatar variant="square"
                  sx={{ height: "100%", width: "100%", borderRadius: "0.3rem" }}
                  src={pinkPrint}
                  alt="moh"
                />
              </Grid>

            </Grid>
          </Container>
        </Box>
      )}
    </>

  );
}

export default Banner;
