import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaBriefcase } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
// import "../../../../styles/Dashboard/Request.css";
import { axiosWrapper } from "../../../../Utils/AxiosWrapper/index";
import { toast } from "react-hot-toast";
import Spinner1 from "../../../../components/spinners/Spinner1";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import ServicesSelector from "../../../../components/Services/ServicesSelector";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function RequestFormModal({ sp, closeRequestForm }) {
  const colorSchema = useSelector((state) => state.color.scheme);
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  const { autoComplete, loading: servicesLoading } = useSelector(
    (state) => state.services
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [jobType, setJobType] = useState("");

  const [requirements, setRequirements] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [failedResponse, setFailedResponse] = useState(false);

  const [selectedService, setSelectedService] = useState("");

  //Errors
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");
  const [serviceError, setServiceError] = useState("");
  // const [serviceError, setServiceError] = useState("");
  const [requirementsError, setRequirementsError] = useState("");
  const [providerServices, setProviderServices] = useState([]);
  // const [servicesLoading, setServicesLoading] = useState(false);

  const clearErrors = () => {
    setStartDateError("");
    setEndDateError("");
    setStartTimeError("");
    setEndTimeError("");
    setJobTypeError("");
    setRequirementsError("");
    setFailedResponse(false);
    setSuccessResponse(false);
  };

  const sendRequest = async () => {
    clearErrors();
    if (
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime ||
      !jobType ||
      !requirements ||
      !selectedService
    ) {
      if (!startDate) {
        setStartDateError("You must specify the starting date");
      }
      if (!endDate) {
        setEndDateError("You must specify the ending date");
      }
      if (!startTime) {
        setStartTimeError(
          "You must specify the time work would typically commence."
        );
      }
      if (!endTime) {
        setEndTimeError("You must specify the time work would typically end");
      }
      if (!jobType) {
        setJobTypeError("You must specify the job type");
      }

      if (!requirements) {
        setRequirementsError(
          "You must specify the requirements, kindly be as specific as possible."
        );
      }
      if (!selectedService) {
        setServiceError("Kindly select the service you require.");
      }
      return;
    }

    const startDateString = moment(
      moment(startDate).format("MM-DD-YYYY") + " " + startTime + ":00"
    ).format("YYYY-MM-DD HH:mm:ss");
    const endDateString = moment(
      moment(endDate).format("MM-DD-YYYY") + " " + endTime + ":00"
    ).format("YYYY-MM-DD HH:mm:ss");

    let parsedStartDateTime = new Date(startDateString).toISOString();
    let parsedEndDateTime = new Date(endDateString).toISOString();

    const scheduleData = {
      user: sp.sub || sp._id || sp.id,
      service: selectedService,
      start: parsedStartDateTime,
      end: parsedEndDateTime,
    };

    setRequestLoading(true);

    try {
      ///first we create a schedule
      const response = await axiosWrapper({
        method: "post",
        url: `schedules`,
        data: scheduleData,
      });
      // setRequestLoading(false);
      console.log("schedule response");
      console.log(response);
      if (response.data.success) {
        const scheduleID = response.data.data._id;
        console.log("scheduleID");
        console.log(scheduleID);
        //then we create the booking
        const bookingData = {
          schedule: scheduleID,
          client: user.sub || user.id || user._id,
          provider: sp.sub || sp._id || sp.id,
          service: selectedService,
          bookingType: jobType,
          requirements,
          status: "pending",
        };
        console.log("sending");
        console.log(bookingData);
        const bookingResponse = await axiosWrapper({
          method: "post",
          url: `bookings`,
          data: bookingData,
        });
        setRequestLoading(false);
        console.log("booking response");
        console.log(bookingResponse);
        if (bookingResponse.data.success) {
          setSuccessResponse(true);
          console.log("The response");
          console.log(bookingResponse.data);
        } else {
          setRequestLoading(false);
          setFailedResponse(true);
        }
      } else {
        setRequestLoading(false);
        setFailedResponse(true);
      }
      //alaaa
    } catch (error) {
      setRequestLoading(false);
      setFailedResponse(true);
    }
  };

  let initialRender = true;

  useEffect(() => {
    console.log("services");
    console.log(autoComplete);
  });

  useEffect(() => {
    // setServicesLoading(true);
    // authUser?.skills?.forEach((skill) => {
    //   setCurrentUserSkills(...currentUserSkills, {
    //     value: skill._id,
    //     label: skill.title,
    //   });
    // });
    let userAutoComplete = [];
    if (initialRender) {
      sp.skills.forEach((skill) => {
        userAutoComplete.push({ value: skill._id, label: skill.title });
      });
      setProviderServices(userAutoComplete);
      console.log("sp services");
      console.log(userAutoComplete);
    }
    // setTimeout(() => {
    //   setServicesLoading(false);
    // }, 1000);
  }, []);

  const editorModules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { header: "3" }, { font: [] }],
      // [{ size: [] }],
      ["bold"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["link", "image", "video"],
      // ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [{ list: "ordered" }];

  return (
    <>
      <div
        className="row d-flex align-items-center   justify-content-center m-0"
        id="requestFormContainer"
        style={{ backgroundColor: "rgba(0,0,0,0.9", zIndex: 8000 }}
      >
        <div
          style={{ overflowY: "scroll", maxHeight: "90vh" }}
          className=" col-sm-8 bg-white shadow rounded p-3 pb-5 ms-3 me-3 mb-3 mt-1 mx-auto d-flex flex-column"
        >
          <span className="d-flex justify-content-end">
            <MdOutlineClose
              size={30}
              className="hoverUpEffect"
              onClick={() => {
                closeRequestForm();
              }}
            />
          </span>
          {!successResponse && !failedResponse ? (
            <>
              <div className="w-100 d-flex justify-content-center">
                <div className="d-flex align-items-center">
                  <img
                    src={sp.avatar}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "20px",
                    }}
                    alt="user"
                    className="me-2"
                  />
                  <span className="headerNormal">
                    Request for {sp.firstName}'s services
                  </span>
                </div>
              </div>

              <div className="form-group row mt-4">
                <div className="col-sm-10 mx-auto ">
                  <label htmlFor="dob" className="form-label fontsm m-0">
                    Your required service
                  </label>

                  {providerServices && (
                    <Select
                      options={providerServices}
                      placeholder={"Which service are you looking for?"}
                      isMulti={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100% !important",
                          // overflowY: "auto !important",
                          // overflow: "visible",
                          zIndex: 999999,
                        }),
                      }}
                      onChange={(e) => {
                        setSelectedService(e.value);
                      }}
                    />
                  )}

                  {/* <MultiSelect
                    options={providerServices}
                    hasSelectAll={false}
                    // value={selected}
                    onChange={(data) => {
                      setSelectedService(data);
                      alert(data);
                    }}
                    labelledBy="Type or select service..."
                  /> */}

                  <small>
                    <span className="text-danger">{serviceError}</span>
                  </small>
                </div>
                <div className="col-sm-10 mx-auto ">
                  <label htmlFor="dob" className="form-label fontsm m-0">
                    Starting date
                  </label>

                  <input
                    type="date"
                    name="dob"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className="form_control fontsm"
                    id="dob"
                  />
                  <small>
                    <span className="text-danger">{startDateError}</span>
                  </small>
                </div>
                {startDate && (
                  <div className="col-sm-10 mx-auto mt-4 d-flex ">
                    <button
                      className="me-2 text-light rounded   pt-1 pb-1 ps-4 pe-4 hoverDownEffect fontsm border-none"
                      style={{
                        backgroundColor: `${colorSchema?.primary}`,
                        border: "none",
                      }}
                      onClick={() => {
                        const startDateObj = new Date(startDate);
                        const endDateObj = new Date(
                          startDateObj.setFullYear(
                            startDateObj.getFullYear() + 1
                          )
                        );
                        setEndDate(endDateObj.toISOString().slice(0, 10));
                      }}
                    >
                      Long Term
                    </button>
                    <button
                      className="me-2 text-light rounded   pt-1 pb-1 ps-4 pe-4 hoverDownEffect fontsm border-none "
                      style={{
                        backgroundColor: `${colorSchema?.primary}`,
                        border: "none",
                      }}
                      onClick={() => {
                        const startDateObj = new Date(startDate);
                        const endDateObj = new Date(
                          startDateObj.setMonth(startDateObj.getMonth() + 6)
                        );
                        setEndDate(endDateObj.toISOString().slice(0, 10));
                      }}
                    >
                      Short Term
                    </button>
                  </div>
                )}
                <div className="col-sm-10 mx-auto mt-4">
                  <label htmlFor="dob" className="form-label fontsm m-0">
                    Ending date
                  </label>
                  <input
                    type="date"
                    name="end dob"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    className="form_control fontsm"
                    id="dob"
                  />
                  <small>
                    <span className="text-danger">{endDateError}</span>
                  </small>
                </div>
                <div className="col-sm-10 mx-auto mt-4">
                  <label htmlFor="dob" className="form-label fontsm m-0">
                    Work starting time
                  </label>
                  <input
                    type="time"
                    name="start time"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                    className="form_control fontsm"
                    id="dob"
                  />
                  <small>
                    <span className="text-danger">{startTimeError}</span>
                  </small>
                </div>
                <div className="col-sm-10 mx-auto mt-4">
                  <label htmlFor="dob" className="form-label fontsm m-0">
                    Work ending Time
                  </label>
                  <input
                    type="time"
                    name="end time"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                    className="form_control fontsm"
                    id="dob"
                  />
                  <small>
                    <span className="text-danger">{endTimeError}</span>
                  </small>
                </div>
                <div className="col-sm-10 mx-auto mt-4">
                  <label htmlFor="spType" className="form-label fontsm m-0">
                    Job Type
                  </label>

                  <select
                    id="spType"
                    name="workArrangement"
                    className=" form-select  form_control "
                    onChange={(e) => {
                      setJobType(e.target.value);
                    }}
                  >
                    <option selected value={""}>
                      Choose one
                    </option>
                    <option value="live in">Live in</option>
                    <option value="come and go">Come and go</option>
                  </select>
                  <small>
                    <span className="text-danger">{jobTypeError}</span>
                  </small>
                </div>

                <div className="col-sm-10 mx-auto ">
                  <label
                    htmlFor="requirements"
                    className="form-label fontsm m-0 mb-1"
                  >
                    Requirements/Deep dive (Kindly write them in point form. Be
                    as clear as possible as these will appear in the contract.)
                  </label>

                  <ReactQuill
                    theme="snow"
                    modules={editorModules}
                    value={requirements}
                    // formats={formats}
                    onChange={setRequirements}
                    className="fontsm1"
                    style={{ minHeight: "40px" }}
                    placeholder="...Kindly specify more details about the service you require. This will help you find your right fit..."
                  />
                  {/* <textarea
                    type="text"
                    name="requirements"
                    value={requirements}
                    onChange={(e) => {
                      setRequirements(e.target.value);
                    }}
                    className="form-control fontsm1"
                    id="requirements"
                    rows={3}
                    style={{ outline: "none" }}
                    placeholder="...Kindly specify more details about the service you require. This will help you find your right fit..."
                  ></textarea> */}
                  <small>
                    <span className="text-danger">{requirementsError}</span>
                  </small>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center mt-2">
                <button
                  className="text-light rounded   pt-1 pb-1 ps-4 pe-4 hoverDownEffect fontsm"
                  style={{
                    backgroundColor: `${colorSchema?.primary}`,
                    border: "none",
                  }}
                  onClick={sendRequest}
                >
                  Submit request
                </button>
              </div>
              {requestLoading && (
                <Spinner1 message={"Sending request, please wait..."} />
              )}
            </>
          ) : (
            <>
              {successResponse ? (
                <div className="d-flex flex-column align-items-center pt-4 pb-4 ps-1 pe-1">
                  <BsFillCheckCircleFill
                    className="text-success mb-4"
                    size={50}
                  />
                  <center>
                    <h4>Request sent successfully</h4>
                  </center>
                  <button
                    className="btn btn-secondary m-3"
                    onClick={closeRequestForm}
                  >
                    Continue
                  </button>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center pt-4 pb-4 ps-1 pe-1">
                  <AiFillCloseCircle className="text-danger mb-4" size={50} />
                  <h4>Failed to send request</h4>
                  <button
                    className="btn btn-secondary m-3"
                    onClick={closeRequestForm}
                  >
                    Continue
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default RequestFormModal;
