import { useState, useEffect } from "react";
import "../../styles/payment.css";
import StripeModal from "./StripeModal";
import MpesaPayment from "./Mpesa/MpesaPayment";
import Pricing from "../Home/Pricing";
import { MdClose } from "react-icons/md";

function PaymentModal({ closeModal, paymentDetails }) {
  const [paymentMethod, setPaymentMethod] = useState("mpesa");

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <div className="w-100 row m-0" id="modalContainer">
        <div
          className="bg-white rounded p-3 mt-2 col-sm-8 shadow "
          style={{ minHeight: "70vh" }}
        >
          <div className="pt-2" style={{ position: "relative" }}>
            <center>
              <span className="mb-3 mt-3" style={{ fontWeight: "bold" }}>
                Our payment Options
              </span>
            </center>
            <MdClose
              size={25}
              className="hoverUpEffect"
              onClick={() => {
                closeModal("");
              }}
              style={{ position: "absolute", right: "10px", top: "0px" }}
            />
            <ul
              class="nav mt-2 mb-3 nav-tabs mx-auto text-center d-flex justify-content-center"
              style={{ cursor: "pointer" }}
            >
              <li
                class="nav-item"
                onClick={() => {
                  setPaymentMethod("mpesa");
                }}
              >
                <span
                  class={`nav-link text-black ${
                    paymentMethod === "mpesa" ? "active fw-bold" : ""
                  }`}
                  aria-current="page"
                >
                  Mpesa
                </span>
              </li>
              <li
                class="nav-item"
                onClick={() => {
                  setPaymentMethod("stripe");
                }}
              >
                <span
                  class={`nav-link text-black ${
                    paymentMethod === "stripe" ? "active fw-bold" : ""
                  }`}
                >
                  Stripe
                </span>
              </li>
            </ul>

            {paymentMethod === "mpesa" ? (
              <MpesaPayment
                paymentDetails={paymentDetails}
                closeModal={closeModal}
              />
            ) : (
              <StripeModal />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentModal;
