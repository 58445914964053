import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { registerSchema } from "../../../Utils/validations/AuthValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { registerUser } from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import Spinner1 from "../../../components/spinners/Spinner1";
import Spinner2 from "../../../components/spinners/Spinner2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import StepButton from '@mui/material/StepButton';
import "../../../styles/ProfileDetails.css";
import { AiOutlineClose } from "react-icons/ai";
import { SPRequiredProfileDetailsSchema } from "../../../Utils/validations/ProfileValidations";
import SPRequiredDetails from "../../profile/sp/SPRequiredDetails";
import SPAdditionalDetails from "../../profile/sp/SPAdditionalDetails";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Alert, Divider, Typography, IconButton, Stack, Grid, Avatar, Badge, Tooltip, TextField, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ServicesSelector from "../../../components/Services/ServicesSelector";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import { updateUserDetails } from "../../../features/slices/AuthSlice";
import EditIcon from '@mui/icons-material/AddAPhoto';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SPProfileDetails2Schema } from "../../../Utils/validations/ProfileValidations";
import { setPrevEmployer } from "../../../features/slices/EmployerSlice";
import EasyImageCropper from "../../../components/ImageUtils/EasyImageCropper";
import SPProfileDetails from "../../profile/sp/SPProfileDetails";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const steps = [
    'Personal Details',
    'Service Details',
    'Employee Vetting',
    'Upload Files',
];


function ClientDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isLoading, isError, errorMessage, prevPath, isAuth } = useSelector(
        (state) => state.auth
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(SPProfileDetails2Schema) });
    const [phoneError, setPhoneError] = useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [passport, setPassport] = useState("");
    const [passportError, setPassportError] = useState("");
    const [completed, setCompleted] = React.useState({});
    const [changes, setChanges] = useState(false);
    const [skills, setSkills] = useState([]);
    const [skillError, setSkillError] = useState("");
    const [selected, setSelected] = useState([]);
    const [showImageCropper, setShowImageCropper] = useState(false);
    const [rawUserPhoto, setRawUserPhoto] = useState();
    const [fullPhoto, setFullPhoto] = useState();
    const [phone, setPhone] = useState("");
    const [passportUploaded, setPassportUploaded] = useState(false);
    const [passportLoading, setPassportLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const { autoComplete, loading: servicesLoading } = useSelector(
        (state) => state.services
    );


    console.log("SERVICES", autoComplete);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);

        // { handleSubmit(handleRegister) }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const isStepOptional = (step) => {
        return step === 3;
    };

    const handleSkillValidation = () => {
        // window.scroll(0, 0);
        if (!selected?.length > 0) {
            setSkillError("Select atleast one skill");
        } else {
            setSkillError("");
        }
    };

    const getPrevEmployer = async () => {
        try {
          const employersData = await axiosWrapper({
            method: "get",
            url: `referrals/employers?serviceProvider=${
              user.sub ? user.sub : user.id
            }`,
          });
    
          const prevEmployerData = employersData.data.data.rows[0];
          const count = employersData.data.data.count;
          return { prevEmployer: prevEmployerData, success: true, count };
        } catch (error) {
          console.log(error);
          return { prevEmployer: null, success: false, count: 0 };
        }
      };

    const handleUpdate = async (data) => {
        console.log("****SERVICES****", data,phone,changes);
        // if (!selected || skillError) {
        //     handleSkillValidation();

        // } else {
            let skillData;
            if (changes) {
                if (skills[0]?._id) {
                    skillData = skills.map((skill) => {
                        return skill._id;
                    });
                    console.log("skills parsed");

                    console.log(skillData);
                } else {
                    console.log("skills original");
                    console.log(skills);
                    skillData = skills;
                }
                // window.scroll(0, 0);
                setIsUpdateLoading(true);
                let fullData = {
                    ...data,
                    prevEmployerPhone: phone,
                    skills: skillData,
                };
                if (!fullData.trainingDetails) {
                    delete fullData.trainingDetails;
                }
                const response = await axiosWrapper({
                    method: "patch",
                    url: `users/${user.sub ? user.sub : user.id}`,
                    data: { ...fullData, dob: fullData.dob.toISOString() },
                });
                console.log("****SERVICES****",fullData.dob.toISOString());
                // let workUnits = data.prevWorkDurationUnit;
                if (data.yearsOfExperience > 0) {
                    const employersData = {
                      serviceProvider: user.sub ? user.sub : user.id,
                      prevEmployerName: data.prevEmployerName,
                      prevEmployerPhone: phone,
                      prevSalary: data.prevSalary,
                    //   prevWorkDuration: `${data.prevWorkDuration} ${workUnits}`,
                      prevJobDescription: data.prevJobDescription,
                      prevJobTitle: data.prevJobTitle,
                    };
                    const { prevEmployer, success, count } = await getPrevEmployer();
                    if (success && count === 0) {
                      //Creating employer details
                      const employerDataResponse = await axiosWrapper({
                        method: "post",
                        url: `referrals/employers`,
                        data: employersData,
                      });
            
                      if (!employerDataResponse.data.success) {
                        setIsUpdateLoading(false);
                        return toast.error("Failed to update your previous employer data");
                      }
                    } else if (success && count > 1) {
                      dispatch(setPrevEmployer(prevEmployer));
                    }
                  }

                if (response.data.success) {
                    const response2 = await axiosWrapper({
                        method: "get",
                        url: `users/${user.sub ? user.sub : user.id}`,
                        data: "",
                    });

                    const employersData = await axiosWrapper({
                        method: "get",
                        url: `referrals/employers?serviceProvider=${user.sub ? user.sub : user.id
                            }`,
                    });

                    const prevEmployerData = employersData.data.data.rows[0];
                    dispatch(setPrevEmployer(prevEmployerData));

                    const fullUserData = {
                        ...response2.data.data,
                        prevEmployer: prevEmployerData,
                    };

                    dispatch(updateUserDetails(fullUserData));
                    setIsUpdateLoading(false);
                    toast.success("Profile details updated!");
                    navigate("/dashboard")
                } else {
                    toast.error("Failed to update your profile, please try again!");
                }
            }
      
    };

    const finalizePassportUpload = async (croppedImage, photo) => {
        setShowImageCropper(false);
        setPassportLoading(true);
        console.log("cropped photo result");
        console.log("path", croppedImage);
        console.log("photo", photo);
        let formData = new FormData();
        formData.append("file", photo);
        formData.append("type", "profile");

        const passportResponse = await axiosWrapper({
            method: "post",
            url: "storage/upload/image",
            data: formData,
        });
        setPassportLoading(false);
        if (passportResponse.data.success) {
            setPassportUploaded(true);
            setPassport(passportResponse.data.data.url);
            console.log("====================================");
            console.log("Passport url");
            console.log(passportResponse.data.data.url);
            console.log("====================================");
        } else {
            setPassportUploaded(false);
            setPassportError("Failed to upload, kindly try again");
        }
    };
    const handlePhoneValidation = () => {
        if (!phone) {
          setPhoneError("Phone number required");
        } else if (phone?.length < 8) {
          setPhoneError("Invalid phone length");
        } else {
          setPhoneError("");
        }
      };



    return (
        <SPProfileDetails/>
        // <Box sx={{ width: '100%' }}>
        //     <Stepper nonLinear activeStep={activeStep}>
        //         {steps.map((label, index) => {
        //             const stepProps = {};
        //             const labelProps = {};
        //             if (isStepOptional(index)) {
        //                 labelProps.optional = (
        //                     <Typography variant="caption">Optional</Typography>
        //                 );
        //             }
        //             return (
        //                 <Step key={label} {...stepProps}>
        //                     <StepLabel {...labelProps}>{label}</StepLabel>
        //                 </Step>
        //             );
        //         })}
        //     </Stepper>
        //     <div>
        //         {activeStep === steps.length ? (
        //             <React.Fragment>
        //                 <Alert icon={false} severity="success" sx={{ mt: 2 }}>
        //                     Thanks  {user?.firstName},well use your answers to create a more personalized onboarding experience for you
        //                 </Alert>
        //                 <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        //                     <Box sx={{ flex: '1 1 auto' }} />
        //                     <Button onClick={handleReset}>Go to dashboard</Button>
        //                 </Box>
        //             </React.Fragment>
        //         ) : (
        //             <form onSubmit={handleSubmit(handleUpdate)}>
        //                 <Alert icon={false} severity="success" sx={{ mt: 2 }}>
        //                     Hi {user?.firstName},  for a more tailored onboarding experience <br></br>Please answer the following questions and we'll do the rest
        //                 </Alert>

        //                 {activeStep === 0 && (
        //                     <>
        //                         <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
        //                             Please tell us more about yourself
        //                         </Divider>
        //                         <Grid
        //                             container
        //                             mb={2}
        //                             mt={2}
        //                             rowSpacing={2}
        //                             columnSpacing={{ xs: 2, sm: 3, md: 6 }}
        //                         >

        //                             <Grid item xs={12} md={12}>
        //                                 <TextField
        //                                     label="Location"
        //                                     fullWidth
        //                                     size="small"
        //                                     placeholder="Where do you live"
        //                                     error={errors && errors?.placeOfResidence?.message}
        //                                     helperText={errors && errors?.placeOfResidence?.message}
        //                                     type="text"
        //                                     name="placeOfResidence"
        //                                     value={user?.placeOfResidence}
        //                                     id="exampleInputName"
        //                                     {...register("placeOfResidence")}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
        //                                     <DatePicker
        //                                         fullWidth
        //                                         type="date"
        //                                         name="dob"
        //                                         slotProps={{ textField: { size: 'small', fullWidth: true } }}
        //                                         error={errors && errors?.dob?.message}
        //                                         helperText={errors && errors?.dob?.message}
        //                                         label="Date of Birth"
        //                                         onClick={() => {
        //                                             setChanges(true);
        //                                           }}
        //                                         {...register("dob")}
        //                                     />
        //                                 </LocalizationProvider>
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="ID Number"
        //                                     size="small"
        //                                     error={errors && errors?.idNo?.message}
        //                                     helperText={errors && errors?.idNo?.message}
        //                                     type="text"
        //                                     name="idNo"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     className="form_control"
        //                                     defaultValue={user?.idNo}
        //                                     id="idNo"
        //                                     {...register("idNo")}
        //                                 />
        //                             </Grid>

        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Gender"
        //                                     size="small"
        //                                     select
        //                                     fullWidth
        //                                     id="gender"
        //                                     name="gender"
        //                                     {...register("gender")}
        //                                 >
        //                                     <MenuItem value="male">Male</MenuItem>
        //                                     <MenuItem value="female">Female</MenuItem>
        //                                 </TextField>
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Marital Status"
        //                                     select
        //                                     fullWidth
        //                                     size="small"
        //                                     id="maritalStatus"
        //                                     name="maritalStatus"
        //                                     onClick={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     defaultValue={user?.maritalStatus}
        //                                     {...register("maritalStatus")}
        //                                 >
        //                                     <MenuItem value="single">Single</MenuItem>
        //                                     <MenuItem value="married">Married</MenuItem>
        //                                 </TextField>
        //                             </Grid>

        //                             <Grid item xs={12} md={12}>
        //                                 <TextField
        //                                     fullWidth
        //                                     label="Tell us about yourself"
        //                                     placeholder={`What type of person are you/What are your job interests`}
        //                                     multiline
        //                                     type="text"
        //                                     name="bio"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     rows={2}
        //                                     id="bio"
        //                                     {...register("bio")}
        //                                 />
        //                             </Grid>
        //                         </Grid>
        //                     </>
        //                 )}
        //                 {activeStep === 1 && (
        //                     <>
        //                         <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
        //                             What kind of Services are you providing
        //                         </Divider>
        //                         <Grid
        //                             container
        //                             mb={2}
        //                             mt={2}
        //                             rowSpacing={2}
        //                             columnSpacing={{ xs: 2, sm: 3, md: 6 }}
        //                         >
        //                             <Grid item xs={12} md={12}>
        //                                 <ServicesSelector
        //                                     setValue={(data) => {
        //                                         setChanges(true);
        //                                         console.log(data);
        //                                         setSelected(data);
        //                                     }}
        //                                     multiple={true}
        //                                 />
        //                                 <small>
        //                                     <span className="mt-1 mb-3 text-danger">{skillError}</span>
        //                                 </small>
        //                             </Grid>

        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Years of Experience"
        //                                     size="small"
        //                                     error={errors && errors?.yearsOfExperience?.message}
        //                                     helperText={errors && errors?.yearsOfExperience?.message}
        //                                     type="number"
        //                                     name="yearsOfExperience"
        //                                     className="form_control"
        //                                     defaultValue={user?.yearsOfExperience}
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     id="totalExp"
        //                                     {...register("yearsOfExperience")}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Work Arrangement"
        //                                     select
        //                                     fullWidth
        //                                     size="small"
        //                                     id="spType"
        //                                     name="workArrangement"
        //                                     {...register("workArrangement")}
        //                                     onChange={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     defaultValue={user?.workArrangement}
        //                                 >
        //                                     <MenuItem value="live in">Live in (live with the client)</MenuItem>
        //                                     <MenuItem value="come and go">Come and go (daily)</MenuItem>
        //                                 </TextField>
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Level Of Education"
        //                                     select
        //                                     size="small"
        //                                     id="loe"
        //                                     fullWidth
        //                                     error={errors && errors?.educationLevel?.message}
        //                                     helperText={errors && errors?.educationLevel?.message}
        //                                     {...register("educationLevel")}
        //                                     defaultValue={user?.educationLevel}
        //                                     onClick={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                 >
        //                                     <MenuItem value="primary">Primary</MenuItem>
        //                                     <MenuItem value="highschool">High School</MenuItem>
        //                                     <MenuItem value="degree">University</MenuItem>
        //                                 </TextField>
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Training Experience"
        //                                     size="small"
        //                                     select
        //                                     fullWidth
        //                                     error={errors && errors?.idNo?.message}
        //                                     helperText={errors && errors?.idNo?.message}
        //                                     name="previousTraining"
        //                                     id="training"
        //                                     {...register("previousTraining")}
        //                                     defaultValue={user?.previousTraining}
        //                                     onClick={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                 >
        //                                     <MenuItem value="yes">Yes</MenuItem>
        //                                     <MenuItem value="no">No</MenuItem>
        //                                 </TextField>
        //                             </Grid>
        //                         </Grid>
        //                     </>
        //                 )}
        //                 {activeStep === 2 && (
        //                     <>
        //                         <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
        //                             Please tell us about your previous job experience
        //                         </Divider>
        //                         <Grid
        //                             container
        //                             mb={2}
        //                             mt={2}
        //                             rowSpacing={2}
        //                             columnSpacing={{ xs: 2, sm: 3, md: 2 }}
        //                         >
        //                             <Grid item xs={12} md={12}>
        //                                 <label htmlFor="phoneInput" className="form-label m-0 mb-1">
        //                                     Previous employer's phone

        //                                 </label>
        //                                 <PhoneInput
        //                                     country={"ke"}
        //                                     onChange={(n) => {
        //                                         setPhone("+" + n);
        //                                         handlePhoneValidation();
        //                                         setChanges(true);
        //                                     }}
        //                                     className="form_control w-100"
        //                                     containerClass={"tel_container"}
        //                                     inputClass={"form_controls"}
        //                                     autocompleteSearch={false}
        //                                     inputProps={{
        //                                         name: "phone",
        //                                         required: true,
        //                                         value: phone,
        //                                         placeholder: "eg +254...",
        //                                     }}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} >
        //                                 <Typography variant="caption" sx={{ color: "grey" }}>
        //                                     Kindly note that your previous employer will be contacted by
        //                                     the company.Please do not put numbers of
        //                                     friends or of colleagues. Incase of any doubt about the
        //                                     authenticity, you risk being blacklisted.
        //                                 </Typography>
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Previous Employee Name"
        //                                     size="small"
        //                                     type="text"
        //                                     fullWidth
        //                                     name="prevEmployerName"
        //                                     className="form_control"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     // defaultValue={
        //                                     //     user.yearsOfExperience > 0
        //                                     //         ? user?.prevEmployer?.prevEmployerName
        //                                     //         : "John Doe"
        //                                     // }
        //                                     id="prevEmployerName"
        //                                     {...register("prevEmployerName")}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Previous Job Title"
        //                                     size="small"
        //                                     type="text"
        //                                     fullWidth
        //                                     name="prevJobTitle"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     // defaultValue={
        //                                     //     user.yearsOfExperience > 0
        //                                     //         ? user?.prevEmployer?.prevJobTitle
        //                                     //         : "Title"
        //                                     // }
        //                                     id="prevJobTitle"
        //                                     {...register("prevJobTitle")}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Previous Job Salary"
        //                                     size="small"
        //                                     fullWidth
        //                                     error={errors && errors?.prevSalary?.message}
        //                                     helperText={errors && errors?.prevSalary?.message}
        //                                     type="number"
        //                                     name="prevSalary"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     id="prevSalary"
        //                                     {...register("prevSalary")}
        //                                 />
        //                             </Grid>
        //                             <Grid item xs={12} md={6}>
        //                                 <TextField
        //                                     label="Previous Job Description"
        //                                     size="small"
        //                                     type="text"
        //                                     fullWidth
        //                                     name="prevJobDescription"
        //                                     onKeyUp={() => {
        //                                         setChanges(true);
        //                                     }}
        //                                     id="prevJobDescription"
        //                                     {...register("prevJobDescription")}
        //                                 />
        //                             </Grid>

        //                         </Grid>
        //                     </>


        //                 )}
        //                 {activeStep === 3 && (
        //                     <>
        //                         <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
        //                             Please Upload the following files to complete setup
        //                         </Divider>
        //                         <Stack
        //                             direction="row"
        //                             justifyContent="center"
        //                             alignItems="center"
        //                             mb={1}
        //                             spacing={1}>

        //                             <Badge
        //                                 overlap="circular"
        //                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        //                                 badgeContent={
        //                                     <Tooltip title="Select Image">
        //                                         <IconButton aria-label="upload picture" component="label" >
        //                                             <input hidden accept="image/*" type="file"
        //                                             />
        //                                         </IconButton>
        //                                     </Tooltip>
        //                                 }
        //                             >
        //                                 <Avatar variant="square" alt="user" sx={{ width: "6.5rem", height: "3.5rem", m: 1, borderRadius: "10px" }} src="" />

        //                             </Badge>
        //                             <Button component="label" size="small" variant="outlined" startIcon={<CloudUploadIcon />}>
        //                                 Upload profile photo
        //                                 <VisuallyHiddenInput type="file" />
        //                             </Button>
        //                         </Stack>
        //                         <Stack
        //                             direction="row"
        //                             justifyContent="center"
        //                             alignItems="center"
        //                             mb={1}
        //                             spacing={1}>

        //                             <Badge
        //                                 overlap="circular"
        //                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        //                                 badgeContent={
        //                                     <Tooltip title="Select Image">
        //                                         <IconButton aria-label="upload picture" component="label" >
        //                                             <input hidden accept="image/*" type="file"
        //                                             />
        //                                         </IconButton>
        //                                     </Tooltip>
        //                                 }
        //                             >
        //                                 <Avatar variant="square" alt="user" sx={{ width: "6.5rem", height: "3.5rem", m: 1, borderRadius: "10px" }} src="" />

        //                             </Badge>
        //                             <Button component="label" size="small" variant="outlined" startIcon={<CloudUploadIcon />}>
        //                                 Upload front ID card
        //                                 <VisuallyHiddenInput type="file" />
        //                             </Button>
        //                         </Stack>
        //                         <Stack
        //                             direction="row"
        //                             justifyContent="center"
        //                             alignItems="center"
        //                             mb={1}
        //                             spacing={1}>

        //                             <Badge
        //                                 overlap="circular"
        //                                 anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        //                                 badgeContent={
        //                                     <Tooltip title="Select Image">
        //                                         <IconButton aria-label="upload picture" component="label" >
        //                                             <input hidden accept="image/*" type="file"
        //                                             />
        //                                         </IconButton>
        //                                     </Tooltip>
        //                                 }
        //                             >
        //                                 <Avatar variant="square" alt="user" sx={{ width: "6.5rem", height: "3.5rem", m: 1, borderRadius: "10px" }} src="" />

        //                             </Badge>
        //                             <Button component="label" size="small" variant="outlined" startIcon={<CloudUploadIcon />}>
        //                                 Upload back ID card
        //                                 <VisuallyHiddenInput type="file" />
        //                             </Button>
        //                         </Stack>

        //                     </>
        //                 )}
        //                 <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

        //                     <Box sx={{ flex: '1 1 auto' }} />
        //                     {activeStep !== steps.length - 1 &&
        //                         <>
        //                             <Button
        //                                 fullWidth
        //                                 color="inherit"
        //                                 disabled={activeStep === 0}
        //                                 onClick={handleBack}
        //                                 variant="outlined"
        //                                 sx={{ mr: 0.5 }}
        //                             >
        //                                 Back
        //                             </Button>
        //                             <Button onClick={handleNext} fullWidth variant="contained">
        //                                 Continue
        //                             </Button>
        //                         </>
        //                     }
        //                     {activeStep === steps.length - 1 && (
        //                         <Button type="submit" fullWidth variant="contained">Complete Profile Setup</Button>
        //                     )
        //                     }
        //                 </Box>
        //             </form>
        //         )}
        //         {showImageCropper && (
        //             <EasyImageCropper
        //                 fullPhoto={fullPhoto}
        //                 rawImage={rawUserPhoto}
        //                 uploadCroppedPhoto={finalizePassportUpload}
        //                 closeCropper={() => {
        //                     setShowImageCropper(false);
        //                 }}
        //             />
        //         )}
        //     </div>
        // </Box>
    );
}

export default ClientDetails;