import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import saccoPic from "../../pics/community/9.jpg";
import saccoPic2 from "../../pics/community/1.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function Sacco() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const navigate = useNavigate();
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"services"} />
      <div className="row m-0 pb-5 w-100" style={{ minHeight: "100vh" }}>
        <div
          className="w-100 m-0 p-0"
          style={{
            backgroundImage: `url(${saccoPic})`,
            height: "60vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div
            className="row m-0 d-flex align-items-center"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div className="col-sm-9 mx-auto d-flex justify-content-center">
              <h1
                className="text-white text-center mx-auto p-2 rounded"
                style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              >
                SACCO
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-8 mx-auto s mt-4  ">
          <div className="mt-4 row m-0">
            <div className=" col-sm-6">
              <div>
                Maids of Honour is a platform that not only provides domestic
                workers with the opportunity to improve their skills and find
                employment, but we also offer a unique benefit to our workers in
                the form of a savings platform called the "sacco." The goal of
                the sacco is to help our domestic workers achieve financial
                independence and freedom through consistent saving.
              </div>
              <div className="mt-2">
                At Maids of Honour, we understand that financial stability is a
                crucial aspect of leading a happy and fulfilling life. That's
                why we strongly encourage all of our domestic workers to join
                the sacco and take advantage of its benefits. By consistently
                setting aside a portion of their income and saving it through
                the sacco, our workers can build a strong foundation for their
                future and work towards their financial goals.
              </div>
            </div>
            <div className=" col-sm-6 p-0">
              <img src={saccoPic2} className="w-100" alt="" />
            </div>
          </div>

          <div className=" mt-4">
            We are passionate about the positive impact that the sacco can have
            on the lives of domestic workers and are dedicated to helping our
            workers reach financial independence. If you are a domestic worker
            interested in joining the sacco and taking control of your financial
            future, please follow the provided link to sign up. We look forward
            to helping you achieve financial freedom and stability.
            {!user?.firstName && (
              <>
                <div className="mt-2">
                  If you are interested in joining the Maids of Honour
                  community, please sign up using the button below.
                </div>
                <button
                  className="joinBtn ps-3 pe-3 pt-1 pb-1  mt-3 rounded"
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Join Our Sacco
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sacco;
