import React from "react";
import devices from "../../pics/devicesAi.png";
import Lines from "../../pics/linesInMotion.svg";
import "../../styles/ourDifference.css";
import Wave from "react-wavify";
import { Grid, ListItemIcon, ListItem, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StarsIcon from '@mui/icons-material/Stars';

const offers = [
  {
    icon:< ArrowForwardIcon />,
    name:"Contracts",
    title: "Automated and legally binding Contracts."
  },
  // {
  //   icon:< ArrowForwardIcon />,
  //   name:"Rating/Vetting",
  //   title: "Provide feedback on the quality of service. "
  // },
  {
    icon:< ArrowForwardIcon />,
    name:"Rating & Vetting",
    title: "User friendly rating and vetting system. "
  },
  {
    icon:< ArrowForwardIcon />,
    name:"Courses",
    title: "Mentoring videos, training classes, and testing."
  },
]
function OurDifference() {
  const patternUrl = `url(
    "data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill='%23fde4dd' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E"
  )`;
  return (
    <>
      <Wave
        fill="#ffff"
        paused={true}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3,
        }}
        style={{ height: "30px", width: "100%" }}
      />
      <div
        className="w-100 row m-0  pt-5 pb-5"
        style={{
          backgroundColor: "white",
          //   backgroundImage: `url(${Lines})`,

          backgroundImage: [patternUrl, patternUrl, patternUrl],
          backgroundRepeat: "repeat-y,no-repeat,no-repeat",
          backgroundPosition: "left top,right bottom,right top",
        }}
      >
        <div className="row mx-auto col-sm-10 p-3 mt-3 mb-3" data-aos="fade-up">
        {/* <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: "bold" }}
          color="text.primary"
          gutterBottom
        >
          Why We Are Different
        </Typography> */}
        {/* <Typography variant="h6" align="center" sx={{ color: "grey" }} component="p">
        Maids of Honour is revolutionizing the domestic hiring process with
            its innovative platform that boasts several unique features,
            distinguishing it from its competitors.
        </Typography> */}
          <h2 className="mb-3">
            <b>Why We Are Different</b>
          </h2>
          <div className="col-sm-7">
            Maids of Honour is revolutionizing the domestic hiring process with
            our innovative platform that boasts several unique features,
            distinguishing it from its competitors.
            {/* <Grid container spacing={5} alignItems="flex-end"> */}
            {offers.map((tier) => (
              // <Grid
              //   item
              //   key={tier.title}
              //   xs={12}
              // >
              <ListItem>
                <ListItemIcon>
                  {tier.icon}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="primary">
                      {tier.name}
                    </Typography>}
                  secondary={
                    <Typography variant="body2"  color="text.primary">
                      {tier.title}
                    </Typography>}
                />
              </ListItem>
              // </Grid>
            ))}
            {/* </Grid> */}
            {/* Its advanced technology,
            including AI, is one of the most impressive features, used to
            conduct a comprehensive screening process for all applicants.
            Additionally, the platform offers mandatory online mentoring,
            training classes, and testing, ensuring that all domestic
            technicians have a thorough understanding of the concepts. Its
            user-friendly rating system enables clients and domestic help to
            provide feedback on the quality of service. */}
            {/* <br /> Finally, Maids of Honour provides automated and legally
            binding contracts, offering convenience to both parties involved. We
            are different by transforming traditional hiring methods, making it
            efficient, reliable, and convenient for everyone involved. */}
          </div>
          <div className="col-sm-5 p-1" data-aos="zoom-in">
            {/* <div id="deviceImg " className=""> */}
            <img src={devices} alt="" id="" className="w-100" />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OurDifference;
