export const calculateUserRating = (user) => {
  let sumRating = 0;
  let averageRating = 0;
  let numOfRatings = user?.rating?.length;

  if (numOfRatings > 0) {
    user.rating.forEach((ratingItem) => {
      console.log("item");
      console.log(ratingItem);
      sumRating += ratingItem.rating;
    });

    averageRating = sumRating / numOfRatings;
  }

  return averageRating.toFixed(1);
};
