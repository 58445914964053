import React, { useState } from "react";
import { useSelector } from "react-redux";
import avatar from "../../../../pics/avatar.png";
import { MdLocationPin, MdStar } from "react-icons/md";
import { BsDot, BsExclamation } from "react-icons/bs";
import "../../../../styles/Dashboard/Request.css";
import { useNavigate } from "react-router-dom";
import { axiosWrapper } from "../../../../Utils/AxiosWrapper/index.js";
import moment from "moment";
import RateReview from "../../../../components/Rating/RateReview";
import PaymentModal from "../../../../components/Payment/PaymentModal";
import Spinner from "../../../../components/spinners/Spinner1";
import Spinner1 from "../../../../components/spinners/Spinner1";
import { AiOutlineCheck } from "react-icons/ai";
import { BiDollar } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";

function RequestItem({ booking, index, showProfile }) {
  const navigate = useNavigate();
  const colorSchema = useSelector((state) => state.color.scheme);
  const [showRateModal, setShowRateModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [contract, setContract] = useState();
  const [payment, setPayment] = useState("");

  const handleJobStarted = async () => {
    console.log("current booking");
    console.log(booking);
    setLoadingMessage("Please waiting...");
    setShowLoading(true);
    // try {
    //   const updateBookingStatus = await axiosWrapper({
    //     method: "patch",
    //     url: `bookings/${booking._id}`,
    //     data: {
    //       status: "active",
    //     },
    //   });

    //   if (updateBookingStatus.data.success) {
    //     setLoadingMessage("");
    //     setShowLoading(false);
    //     setShowPaymentModal(true);
    //   }
    // } catch (error) {
    //   console.log("error");
    //   console.log(error);
    // }

    //fetch contract
    const contractResponse = await axiosWrapper({
      method: "get",
      url: `contracts?booking=${booking._id}`,
    });

    console.log("contract yeah");
    console.log(contractResponse.data.data.rows[0]);

    const paymentResponse = await axiosWrapper({
      method: "get",
      url: `payments/${contractResponse.data.data.rows[0].payment._id}`,
    });

    setContract({
      ...contractResponse.data.data.rows[0],
      payer: paymentResponse.data.data.payer,
      payee: paymentResponse.data.data.payee,
    });

    console.log("our contract");
    console.log({
      ...contractResponse.data.data.rows[0],
      payer: paymentResponse.data.data.payer,
      payee: paymentResponse.data.data.payee,
    });

    setShowLoading(false);
    setShowPaymentModal(true);
  };

  //Job is complete
  const handleJobCompleted = async () => {
    setLoadingMessage("Please waiting...");
    setShowLoading(true);
    try {
      const updateBookingStatus = await axiosWrapper({
        method: "patch",
        url: `bookings/${booking._id}`,
        data: {
          status: "completed",
        },
      });

      if (updateBookingStatus.data.success) {
        setLoadingMessage("");
        setShowLoading(false);
        setShowPaymentModal(true);
      }
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  return (
    <>
      <div className="p-2 mt-2 col-sm-4">
        <div
          className=" shadow-sm rounded bg-white p-3 profileStatBox"
          style={{ position: "relative" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <span>
              <img
                src={booking?.provider?.avatar}
                alt="pic"
                style={{ height: "40px", width: "40px", borderRadius: "20px" }}
              />
              <span className="fw-bold ms-2">
                {booking?.provider?.firstName} {booking?.provider?.lastName}
              </span>
            </span>
          </div>
          {booking.status === "active" && (
            <div
              className="spinner spinner-grow spinner-grow-sm text-primary"
              style={{ position: "absolute", right: "14px", top: "10px" }}
            ></div>
          )}
          {booking.status === "pending" && (
            <BsExclamation
              className="text-warning"
              size={24}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            />
          )}
          {booking.status === "accepted" && (
            <AiOutlineCheck
              className="text-success"
              size={20}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            />
          )}
          {booking.status === "completed" && (
            <BiDollar
              className="text-success"
              size={20}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            />
          )}
          {booking.status === "rejected" && (
            <GoPrimitiveDot
              className="text-danger"
              size={24}
              style={{ position: "absolute", right: "10px", top: "0px" }}
            />
          )}

          <div className="w-100 mt-2">
            <div className="d-flex justify-content-between row mt-2">
              <span className=" fontsm1 fw-bold col-6 d-flex align-items-center w-100">
                <MdLocationPin size={15} className="me-2" />
                {booking?.client?.placeOfResidence || "unknown location"}
              </span>
            </div>

            <div className="d-flex justify-content-between row mt-2">
              <span className=" fontsm1 fw-bold col-6">Start date</span>
              <span className="lightDark fontsm1  col-6 text-start">
                {moment(booking?.schedule?.start).format("ll")}
              </span>
            </div>
            <div className="d-flex justify-content-between row mt-2">
              <span className=" fontsm1 fw-bold col-6">End date</span>
              <span className="lightDark fontsm1  col-6 text-start">
                {moment(booking?.schedule?.end).format("ll")}
              </span>
            </div>
          </div>
          {(booking.status === "active" || booking.status === "accepted") && (
            <div className="row ms-0 me-0 mt-2 mb-2 d-flex justify-content-center">
              {booking.status === "accepted" && (
                <button
                  className={`btn btn-sm btn-success fontsm1 p-0 col-5 m-1`}
                  onClick={handleJobStarted}
                >
                  Placement Fee
                </button>
              )}
              {booking.status === "active" && (
                <button
                  className={`btn btn-sm btn-success fontsm1 p-0 col-5 m-1`}
                  onClick={handleJobStarted}
                >
                  Pay now
                </button>
              )}
              {booking.status === "completed" && (
                <button
                  className={`btn btn-sm btn-danger fontsm1 p-0 col-5 m-1`}
                  onClick={handleJobCompleted}
                >
                  completed
                </button>
              )}

              <button
                className="btn btn-sm btn-dark fontsm1 col-5 m-1"
                onClick={() => {
                  setShowRateModal(true);
                }}
              >
                Rate <MdStar className="text-warning ms-1" size={18} />
              </button>
            </div>
          )}
          {booking.status === "pending" && (
            <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-warning text-center pt-2 pb-2">
              <center>Waiting for {booking?.provider?.firstName}</center>
            </div>
          )}
          {booking.status === "rejected" && (
            <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-danger text-center pt-2 pb-2">
              <center>Booking rejected.</center>
            </div>
          )}
          {booking.status === "completed" && (
            <div className="row ms-0 me-0 mt-2 mb-2 d-flex align-items-center fontsm1 text-center alert alert-success text-center pt-2 pb-2">
              <center>Job completed</center>
            </div>
          )}
          <div
            className="w-100 fontsm1 mt-2 p-1 text-center rounded text-light requestBtn"
            style={{ backgroundColor: `${colorSchema?.primary}` }}
            onClick={() => {
              showProfile(booking.sub || booking._id || booking.id, index);
            }}
          >
            view
          </div>
        </div>
      </div>
      {showRateModal && (
        <RateReview
          booking={booking}
          closeModal={() => {
            document.body.style.overflow = "visible";
            setShowRateModal(false);
          }}
        />
      )}
      {showPaymentModal && contract && (
        <PaymentModal
          paymentDetails={{
            name: ` to ${contract.payee.firstName}  ${contract.payee.lastName}`,

            amount: contract.payment.amount,
          }}
          closeModal={() => {
            document.body.style.overflow = "visible";
            setShowPaymentModal(false);
          }}
        />
      )}
      {showLoading && <Spinner1 message={loadingMessage} />}
    </>
  );
}

export default RequestItem;
