import { useState, useEffect } from "react";
import Shiko1 from "../../pics/shiko1.jpg";
import "../../styles/Rating.css";
import Rating from "./Rating";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import Spinner2 from "../spinners/Spinner2";
import Modal from "react-bootstrap/Modal";

function PreviousRatings({ provider, showModal, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    fetchPreviousReviews();
  }, []);

  const fetchPreviousReviews = async () => {
    setLoading(true);
    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${provider}`,
    });

    console.log("review for this sp");
    console.log(getReviews.data.data.count);
    setRatings(getReviews.data.data.rows);
    setLoading(false);
  };
  // const [ratings, setRatings] = useState([
  //   {
  //     photo: Shiko1,
  //     name: "Evelyn Kihia",
  //     rating: "5",
  //     review:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua incididunt ut labore et dolore magna aliqua.",
  //     time: "1 month ago",
  //   },

  // ]);
  return (
    <>
      {!loading ? (
        <Modal
          show={showModal}
          onHide={handleClose}
          style={{ zIndex: "40000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title" id="staticBackdropLabel">
                All ratings ({ratings.length})
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="w-100">
            <div id="modalRatingsBody">
              {ratings.length > 0 ? (
                ratings.map((rating, i) => (
                  <Rating
                    key={i}
                    index={i}
                    ratingData={rating}
                    count={ratings.length}
                  />
                ))
              ) : (
                <div className="w-100 d-flex">
                  <span className="fontsm alert alert-warning m-2 text-center w-100 ">
                    No ratings yet
                  </span>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Spinner2 message={"Please wait..."} />
      )}
    </>
  );
}

export default PreviousRatings;
