import React from "react";
import swingLoading from "../../pics/waiting_swing.gif";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../pics/logo2.png";
function VettingPending() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="row w-100 m-0 ">
      <div className="col-sm-9 row m-0  mx-auto p-3 d-flex flex-column">
        <div className="col-sm-3 mx-auto d-flex justify-content-center">
          <img
            src={Logo}
            alt="logo"
            className=" m-2"
            style={{ width: "70px", height: "70px" }}
          />
        </div>
        <div className="col-sm-7 d-flex flex-column align-items-center mx-auto">
          <h4>
            <b>
              Hello {user.firstName} {user.lastName},
            </b>
          </h4>
        </div>
        <div className="col-sm-3 mx-auto">
          <img src={swingLoading} alt="Loading" className="w-100" />
        </div>
        <div className="col-sm-7 d-flex flex-column align-items-center mx-auto">
          <h4>
            <b>Unfortunately your vetting is still pending</b>
          </h4>
          <span className="mt-3 text-center">
            We have sent your previous employer {""}
            <strong>{user?.prevEmployer?.prevEmployerName}, ({user?.prevEmployer?.prevEmployerPhone})</strong> an sms with guidlines on how
            they can help you complete your verification process. As of right
            now, your employer has not yet responded.
          </span>

          <span className="mt-4 text-center">
            We will also do a follow up check based on the profile you have provided to verify your account accordingly.
          </span>

          <div className="w-100 d-flex justify-content-center mt-3">
            <button
              type="submit"
              className="btn_submit mt-2 pt-2 pb-2 ps-4 pe-4 fontsm text-light rounded m-2"
              style={{
                backgroundColor: `${colorSchema?.primary}`,
                width: "fit-content",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Go to homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VettingPending;
