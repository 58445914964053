import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ClientRequiredProfileDetailsSchema } from "../../../Utils/validations/ProfileValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  registerUser,
  refreshMyToken,
  updateUserDetails,
} from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../../../styles/ProfileDetails.css";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";

import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { Button, Avatar, Divider, TextField, Grid } from "@mui/material";
import { FaCheckSquare } from "react-icons/fa";
import ProgressBar1 from "../../../components/ProgressBars/ProgressBar1";
import moment, { HTML5_FMT } from "moment";
import Spinner2 from "../../../components/spinners/Spinner2";
import MenuItem from "@mui/material/MenuItem";

function ClientRequiredDetails({ submitDetails }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    isError,
    errorMessage,
    prevPath,
    isAuth,
    user,
    isProfileComplete,
  } = useSelector((state) => state.auth);
  const colorSchema = useSelector((state) => state.color.scheme);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneRef = useRef();
  const [phoneError, setPhoneError] = useState("");
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [changes, setChanges] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ClientRequiredProfileDetailsSchema) });

  console.log(`USER`,user)
  const handleRegister = async (data) => {
    window.scroll(0, 0);
    if (!phone || phoneError) {
      handlePhoneValidation(phone);
    } else {
      if (changes) {
        setIsUpdateLoading(true);
        let fullData = { ...data, phone };
        console.log("data");
        console.log(data);

        const response = await axiosWrapper({
          method: "patch",
          url: `users/${user.sub ? user.sub : user.id}`,
          data: { ...fullData, dob: fullData.dob.toISOString() },
        });

        console.log("Update response");
        console.log(response.data.data);

        if (response.data.success) {
          const response2 = await axiosWrapper({
            method: "get",
            url: `users/${user.sub ? user.sub : user.id}`,
            data: "",
          });
          dispatch(updateUserDetails(response2.data.data));
          setIsUpdateLoading(false);
          toast.success("Details updated!");
          setChanges(false);
          submitDetails();
        } else {
          toast.error("Failed to update your profile, please try again!");
        }
      } else {
        submitDetails();
      }
    }
  };

  // const handleNext = (data) => {
  //   submitDetails();
  // };

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  useEffect(() => {
    if (phone?.length > 0) {
      handlePhoneValidation();
    }
  }, []);
  
  useEffect(() => {
    if (user.phone?.length > 0) {
      if (user.phone?.charAt(0) === "+") {
        setPhone(user.phone);
      } else {
        setPhone("+" + user.phone);
      }
    }
  }, []);


  return (
    <>
      {isUpdateLoading && <Spinner2 message={"Updating..."} />}
      <form onSubmit={handleSubmit(handleRegister)} className="bg-white">
        <Grid
          container
          mb={2}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 3, md: 6 }}
        >
          <Grid item xs={12}>
            <div style={{ marginBottom: "-1px" }}>
              <small>
                <span>Phone Number</span>
              </small>
              <PhoneInput
                country={"ke"}
                label="Phone Number"
                onChange={(n) => {
                  handlePhoneValidation(n);
                  setPhone("+" + n);
                  setChanges(true);
                }}
                inputStyle={{
                  width: "100%",
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  value: phone,
                }}
              />
              <small>
                <span className="mt-1 mb-1 text-danger">{phoneError}</span>
              </small>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="First Name"
              size="small"
              error={errors && errors?.firstName?.message}
              helperText={errors && errors?.firstName?.message}
              type="text"
              name="firstName"
              onKeyUp={() => {
                setChanges(true);
              }}
              className="form_control"
              id="first_name"
              defaultValue={user?.firstName}
              {...register("firstName")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Last Name"
              size="small"
              error={errors && errors?.lastName?.message}
              helperText={errors && errors?.lastName?.message}
              type="text"
              name="lastName"
              onKeyUp={() => {
                setChanges(true);
              }}
              className="form_control"
              id="first_name"
              defaultValue={user?.lastName}
              {...register("lastName")}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              label="Email Address"
              size="small"
              error={errors && errors?.email?.message}
              helperText={errors && errors?.email?.message}
              readOnly
              type="email"
              name="email"
              value={user?.email}
              className="form_control"
              id="exampleInputName"
              {...register("email")}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              label="Place of Residence"
              size="small"
              error={errors && errors?.placeOfResidence?.message}
              helperText={errors && errors?.placeOfResidence?.message}
              type="text"
              name="placeOfResidence"
              value={user?.placeOfResidence}
              className="form_control"
              id="exampleInputName"
              {...register("placeOfResidence")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Gender"
              select
              fullWidth
              size="small"
              id="gender"
              name="gender"
              // error={errors && errors?.gender.message}
              // helperText={errors && errors?.gender?.message}
              defaultValue={user?.gender}
              onClick={() => {
                setChanges(true);
              }}
              {...register("gender")}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Marital Status"
              select
              fullWidth
              size="small"
              // error={errors && errors?.maritalStatus.message}
              // helperText={errors && errors?.maritalStatus?.message}
              id="maritalStatus"
              name="maritalStatus"
              onClick={() => {
                setChanges(true);
              }}
              defaultValue={user?.maritalStatus}
              {...register("maritalStatus")}
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label="Tell us about yourself"
              helperText={
                errors
                  ? errors?.bio?.message
                  : `Do you have any children? If yes, how
              many and what are their ages? Do you own any pets?`
              }
              multiline
              type="text"
              name="bio"
              onKeyUp={() => {
                setChanges(true);
              }}
              rows={4}
              defaultValue={user?.bio}
              id="bio"
              {...register("bio")}
            />
          </Grid>
        </Grid>
        
        <div className="d-flex">
          <span
            type="submit"
            className="btn_skip mt-2 p-2 bg-white d-flex justify-content-center align-items-center rounded m-2"
            onClick={() => {
              submitDetails();
            }}
            style={{
              color: `${colorSchema?.primary}`,
              border: `1px solid ${colorSchema?.primary}`,
            }}
          >
            Next
          </span>

          <button
            type="submit"
            className="btn_submit mt-2 p-2 text-light rounded m-2"
            // onClick={handlePhoneValidation}
            style={{ backgroundColor: `${colorSchema?.primary}` }}
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
}

export default ClientRequiredDetails;
