import React, { useState, useEffect } from "react";
import "../../../styles/Profile.css";
import { useSelector } from "react-redux";
import { BsGenderMale, BsGenderFemale } from "react-icons/bs";
import {
  AiFillStar,
  AiOutlineClose,
  AiOutlineRight,
  AiFillTrophy,
} from "react-icons/ai";
import { GiExpand } from "react-icons/gi";
import { MdOutlineMoreHoriz, MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Avatar from "boring-avatars";
import AvatarPic from "../../../pics/avatar.png";
import { axiosWrapper } from "../../../Utils/AxiosWrapper";
import { calculateUserRating } from "../../../Utils/userStats";

function ProfileCard({ user, showProfile, index }) {
  const navigate = useNavigate();
  const [showAllSkills, setShowAllSkills] = useState(false);
  const colorSchema = useSelector((state) => state.color.scheme);
  const [isVerified, setIsVerified] = useState(false);

  // const { user, isAuth, isProfileComplete } = useSelector(
  //   (state) => state.auth
  // );
  const [userRating, setUserRating] = useState("wait");

  const fetchRating = async () => {
    const getReviews = await axiosWrapper({
      method: "get",
      url: `reviews?reviewee=${user.sub || user._id || user.id}`,
    });

    let rating = calculateUserRating({
      ...user,
      rating: getReviews.data.data.rows,
    });

    setUserRating(`${rating}(${getReviews.data.data?.rows?.length})`);
  };

  const checkIfVerified = async () => {
    const employersData = await axiosWrapper({
      method: "get",
      url: `referrals/employers?serviceProvider=${user._id}`,
    });
    const prevEmployerData = employersData.data.data.rows[0];

    if (prevEmployerData) {
      //checking employer answers
      const prevEmployerAnswers = await axiosWrapper({
        method: "get",
        url: `referrals/employers/answers?employer=${prevEmployerData?._id}`,
        data: "",
      });

      if (prevEmployerAnswers?.data?.data?.count > 0) {
        setIsVerified(true);
      }
    }
  };

  useEffect(() => {
    fetchRating();

    if (user.yearsOfExperience > 0) {
      checkIfVerified();
    }
  }, []);

  return (
    <>
      <div style={{ position: "relative", borderRadius: "20px" }} className=""  onClick={() => {
                showProfile(user.sub || user._id || user.id, index);
              }}>
        <div
          className="w-100 d-flex justify-content-center p-2 profileCardDiv"
          style={{ position: "relative", borderRadius: "20px" }}
          onMouseLeave={() => {
            setShowAllSkills(false);
          }}
        >
          <img
            src={user.avatar || AvatarPic}
            alt={"profile pic"}
            className="profileCardPhoto mt-1 shadow"
          />

          {user.gender && (
            <>
              {user.gender === "male" ? (
                <BsGenderMale className="genderIcon text-primary" size={20} />
              ) : (
                <BsGenderFemale
                  className="genderIcon"
                  size={20}
                  style={{ color: "#e44977" }}
                />
              )}
            </>
          )}
        </div>
        <div className="d-flex flex-column p-2">
          <div
            className="w-100 mx-auto text-center mb-1 d-flex align-items-center justify-content-center"
            // style={{ fontSize: "20px" }}
          >
            <b>
              {user.firstName + " " + user.lastName}{" "}
              {isVerified && (
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-verified`} className="fontsm">
                      user is verified
                    </Tooltip>
                  }
                >
                  <span>
                    <MdVerified className="text-primary ms-2" size={22} />
                  </span>
                </OverlayTrigger>
              )}
            </b>
          </div>
          <div className="d-flex align-items-center mb-1 mx-auto text-center fontsm1">
            <AiFillStar className="me-1 text-warning" />
            {userRating === "wait" ? (
              <div className="spinner-border spinner spinner-border-sm text-success me-1"></div>
            ) : (
              userRating
            )}
          </div>
          <div
            className="w-100 d-flex align-items-center justify-content-center"
            style={{ position: "relative" }}
          >
            <span className="d-flex align-items-center me-2">
              <AiFillTrophy className="me-1 " />
              <span className="fontsm">
                {user.skills?.length}{" "}
                {user.skills?.length === 1 ? "skill" : "skills"}
              </span>
            </span>
            <OverlayTrigger
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-top`} className="fontsm">
                  Show skills
                </Tooltip>
              }
            >
              <span>
                <GiExpand
                  className="ms-2 hoverUpEffect"
                  size={18}
                  onClick={() => {
                    setShowAllSkills(true);
                  }}
                />
              </span>
            </OverlayTrigger>
          </div>

          <div className="w-100 d-flex justify-content-center">
            <button
              onClick={() => {
                showProfile(user.sub || user._id || user.id, index);
              }}
              className="fontsm1 p-1 mt-3"
              style={{
                backgroundColor: `${colorSchema?.primary}`,
                color: "white",
                border: "none",
                borderRadius: "10px",
                width: "150px",
              }}
            >
              view profile
            </button>
          </div>
        </div>
        {showAllSkills && (
          <div
            className={`bg-white rounded d-flex flex-column flex-wrap align-items-start bg-light pt-2 pb-2 animate__${
              showAllSkills ? "zoomIn" : "zoomOut"
            } animate__animated`}
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
              top: "0px",
              borderRadius: "20px",
            }}
          >
            <div className="w-100 d-flex justify-content-center flex-column align-items-center p-2">
              <AiOutlineClose
                size={20}
                style={{ cursor: "pointer" }}
                className="hoverUpEffect mb-2"
                onClick={() => {
                  setShowAllSkills(false);
                }}
              />
              <span>
                <b>{user.firstName}'s skills</b>
              </span>
            </div>
            <div className="p-2 ">
              {user.skills &&
                user.skills.map((skill) => (
                  <span
                    className="badge text-light fontsm1  rounded-pill"
                    style={{
                      backgroundColor: `#242b51`,
                      margin: "2px",
                      fontSize: "10px",
                    }}
                  >
                    {skill.title}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileCard;
