import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
function ServiceApartments() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"services"} />
      <div className="m-5 p-5">ServiceApartments</div>
      <Footer />
    </>
  );
}

export default ServiceApartments;
