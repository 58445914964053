import {
  Box,
  Container,
  useMediaQuery,
  Button,
  AlertTitle,
  Alert,
  Typography,
  DialogTitle,
  IconButton,
  ListItemAvatar,
  Dialog,
  Stack,
  ListItem,
  ListItemText,
  Avatar,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import HeaderNavbar from "../NavBars/header";
import FooterNavbar from "../NavBars/footer";
import { axiosWrapper } from "../../Utils/AxiosWrapper";
import { setCurrentPaymentID } from "../../features/slices/PaymentSlice.js";
import Spinner2 from "../../components/spinners/Spinner2";
import { toast } from "react-hot-toast";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import { TroubleshootRounded } from "@mui/icons-material";

const tiers = [
  {
    title: "Basic Plan",
    subheader: "Basic",
    price: "500",
    description: [
      'Registration and Profiling of domestic staff',
      'Administration of payments- NHIF, NSSF',
      "Online Assessment & Certification",
      "Access to Service Provider Profile",
      'Medical and Conduct Compliances',
      "Rate & Vet Service Providers",
      "Access to Mentorship videos",
      "Downloadable working tools",
      'Monthly Newsletter',
      'Webinars',
    ],
    buttonText: "Sign up Today",
    buttonVariant: "outlined",
  },
  {
    title: "Premium Plan",
    subheader: "Premium",
    price: "1000",
    description: [
      'Registration and Profiling of domestic staff',
      'Administration of payments- NHIF, NSSF',
      "Online Assessment & Certification",
      "Access to Service Provider Profile",
      "Access to Mentorship/Training videos",
      'Access to monthly practical training',
      "Rate & Vet Service Providers",
      'Discounted E-books and recipes',
      'Medical and Conduct Compliances',
      "Downloadable working tools",
      'Access Sacco Savings',
      "Join Community",
      'Monthly Newsletter',
      'Webinars',
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AppLayout({ children }) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const [myType, setMyType] = useState("Basic");
  const { isSubscribed } = useSelector((state) => state.subscription);
  const { currentPaymentID } = useSelector((state) => state.payment);
  const [payment, setPayment] = useState(false);
  const member = localStorage.getItem("member");
  const sub = localStorage.getItem("isSubscribed");
  const [open, setOpen] = useState(false);
  const [mpesaCode, setMpesaCode] = useState("");
  const [correct, setCorrect] = useState(true);
  const [isSub, setIsSub] = useState(isSubscribed);
  const { user, isAuth } = useSelector((state) => state.auth);
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkoutRequestID, setCheckoutRequestID] = useState("");

  const handlePhoneValidation = () => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const paymentResponse = await axiosWrapper({
        method: "post",
        url: "payments/mobile/express",
        data: { amount: 1000, phone },
      });

      console.log(`********MPESA********`, paymentResponse);

      if (paymentResponse.data.success) {
        setCheckoutRequestID(paymentResponse.data.data.CheckoutRequestID);
        dispatch(
          setCurrentPaymentID(paymentResponse.data.data.CheckoutRequestID)
        );
        console.log("checkout request ID");
        console.log(paymentResponse.data.data.CheckoutRequestID);
        setLoading(false);
        toast.Success(
          `Payment transaction was successful,receipt ${paymentResponse.data.data.CheckoutRequestID}`
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("Payment error");
      toast.error("Payment transaction failed,Please try again");
      console.log(error);
    }
  };

  const filtered = tiers.reduce(
    (result, { description, subheader, title, price }) =>
      subheader === myType
        ? result.push(description, title, price) && result
        : result,
    []
  );

  console.log(`SNAP`, filtered[0], myType);

  const handleRegistrationFee = () => {
    setOpen(false);
    if (mpesaCode === "MoH-A") {
      setPayment(true);
      localStorage.setItem("member", true);
      setOpen(false);
    } else {
      setCorrect(false);
      setPayment(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleShow = () => {
    if (user.role.title === "client") {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseSub = () => {
    setIsSub(false);
  };

  return (
    <div>
      <HeaderNavbar />
      <Box
        component="main"
        sx={{ bgcolor: "background.default", height: "100vh" }}
      >
        {member && (
          <Alert
            severity="error"
            onClose={() => setPayment(false)}
            action={
              <Button
                color="error"
                variant="outlined"
                onClick={handleClickOpen}
                size="small"
              >
                Pay Now
              </Button>
            }
          >
            {/* <AlertTitle>Registration Fee </AlertTitle> */}
            Your account is still pending a registration fee! Kindly click pay
            now to gain access to a variety of our services
          </Alert>
        )}
        {user.role.title === "client" && (
          <Alert
            severity="error"
            onClose={() => setPayment(false)}
            action={
              <Button
                color="error"
                variant="outlined"
                onClick={() => setIsSub(true)}
                size="small"
              >
                Pay Now
              </Button>
            }
          >
            {/* <AlertTitle>Subscription Fee </AlertTitle> */}
            Your account is still pending a registration fee! Kindly click pay
            now to gain access to a variety of our services
          </Alert>
        )}
        <Container disableGutters maxWidth="xl" component="main">
          {children}
        </Container>
      </Box>

      {isSmallScreen ? (
        <FooterNavbar />
      ) : (
        <Box
          component="footer"
          sx={{
            backgroundColor: "text.secondary",
            position: "fixed",
          }}
        >
          <Container maxWidth="sm">
            <Typography
              variant="body2"
              sx={{ color: "#fff", fontWeight: "bold" }}
            >
              {"Copyright © "}
              <Link color="inherit" href="/">
                Maids of Honour Africa
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Container>
        </Box>
      )}
      {open && (
        <BootstrapDialog
          disableBackdropClick
          // fullScreen
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, alignItems: "center", justifyContent: "center" }}
            id="customized-dialog-title"
          >
            You are required to pay a Registration fee to continue
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              ml: 2,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ justifyContent: "center" }}>
            <Box
              sx={{
                // width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 4,
              }}
            >
              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <div>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="mpesa"
                        src="https://i0.wp.com/techweez.com/wp-content/uploads/2023/08/mpesa.png?fit=1600%2C900&ssl=1"
                        sx={{
                          justifyContent: "center",
                          height: "3.5rem",
                          width: "3.5rem",
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="caption"
                          component="div"
                          gutterBottom
                        >
                          Paybill No: 4109467
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="caption"
                          component="div"
                          gutterBottom
                        >
                          Account No: MOH-A
                        </Typography>
                      }
                    />
                  </ListItem>
                </div>
                <Typography
                  align="center"
                  component="div"
                  sx={{ mt: 2, fontSize: "0.9rem", color: "grey" }}
                  gutterBottom
                >
                  <em>
                    Pay ksh{" "}
                    <strong>
                      {user.role.title === "client" ? 800 : 500}/={" "}
                    </strong>
                    for your
                    <br></br> MOH-A {user.role.title}'s registration fee
                  </em>
                </Typography>
              </Stack>
            </Box>
            <div>
              <div>
                <PhoneInput
                  country={"ke"}
                  onChange={(n) => {
                    handlePhoneValidation();
                    setPhone("+" + n);
                  }}
                  className="form_control w-100"
                  containerClass={"tel_container"}
                  inputClass={"form_controls"}
                  inputProps={{
                    name: "phone",
                    required: true,
                    value: phone,
                  }}
                />

                <small>
                  <span className="mt-1 mb-3 text-danger">{phoneError}</span>
                </small>
              </div>
              <Typography
                align="center"
                component="div"
                sx={{ color: "grey" }}
                variant="caption"
              >
                You will receive a popup on your phone that will prompt you to
                enter your pin.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              size="small"
              color="success"
              fullWidth
              loading={loading}
              onClick={handleRegistrationFee}
            >
              Pay Now
            </LoadingButton>
          </DialogActions>
        </BootstrapDialog>
      )}
      {isSub && (
        <BootstrapDialog
          fullScreen={isSmallScreen}
          disableBackdropClick
          onClose={handleCloseSub}
          aria-labelledby="customized-dialog-title"
          open={isSub}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Choose  Plan
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseSub}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              ml: 2,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  value={myType}
                  onChange={(e) => {
                    setMyType(e.target.value);
                  }}
                  name="role"
                  label="Choose a Plan"
                  fullWidth
                  required
                >
                  <MenuItem value="Basic">Basic Plan </MenuItem>
                  <MenuItem value="Premium">
                    Premuim  Plan{" "}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}  md={12}>
                <Card sx={{ minHeight: 350 }}>
                  <CardHeader
                    title={filtered[1]}
                  
                    titleTypographyProps={{ align: "center" }}
                    action={filtered[1] === "Premium" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h4"
                        variant="h4"
                        color="text.primary"
                      >
                        Ksh. {filtered[2]}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                    <ul>
                      {filtered[0]?.map((line) => (
                        <Typography
                          sx={{ fontWeight: "bold", color: "grey" }}
                          variant="body2"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="success"
              size="small"
              fullWidth
              loading={loading}
              onClick={handlePayment}
            >
              Register to {myType} plan
            </LoadingButton>
          </DialogActions>
          <small>
            <Typography
              align="center"
              component="div"
              sx={{ color: "grey" }}
              variant="caption"
            >
              You will receive an M-pesa popup on your phone {phone} that will
              prompt you to enter your pin.
            </Typography>
          </small>
        </BootstrapDialog>
      )}
    </div>
  );
}
