/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Spinner1 from "../../../components/spinners/Spinner1";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import {
  updateUserDetails,
  fetchProfileDetails,
} from "../../../features/slices/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import PhoneInput from "react-phone-input-2";

import "../../../styles/Auth.css";
import "react-phone-input-2/lib/style.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { toast } from "react-hot-toast";

function GoogleRedirect() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();

  const { isAuth, user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userId, setUserId] = useState("");
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let initRequest = true;
  useEffect(() => {
    setParams();
  }, []);

  const colorSchema = useSelector((state) => state.color.scheme);

  const setParams = async () => {
    const accessToken = query.get("access");
    const refreshToken = query.get("refresh");
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    const userSocialData = await jwtDecode(accessToken);
    console.log("Decoded data for sign in from google auth");
    console.log(userSocialData);
    setUserId(userSocialData.sub);

    
    const userData = await fetchProfileDetails(userSocialData.sub);
    console.log("Updated Decoded from google auth");
    console.log(userData);
    localStorage.setItem(
      "authStatus",
      JSON.stringify({ isAuth: true, user: userData })
    );

    if (userData.role.title === "temp") {
      setIsLoading(false);
      console.log("No role");
    } else {
      if (initRequest) {
        initRequest = false;
        const updateResponse = await axiosWrapper({
          method: "patch",
          url: `users/${userData.sub || userData._id}`,
          data: { deviceId: uuidv4() },
        });

        if (updateResponse.data.success) {
          const { accessToken, refreshToken } = updateResponse.data.data.tokens;
          setUserId(updateResponse.data.data.user._id);
          console.log("session data updated");
          console.log(updateResponse);
          console.log(updateResponse.data.data);

          console.log("accessToken");
          console.log(accessToken);
          console.log("Uriri user id");
          console.log(updateResponse.data.data.user._id);
          const userData = await fetchProfileDetails(
            updateResponse.data.data.user._id
          );
          console.log("uriri dataa");
          console.log(userData);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem(
            "authStatus",
            JSON.stringify({
              isAuth: true,
              user: userData,
            })
          );
          toast.success("Logged in successfully.");
          dispatch(updateUserDetails(userData));
        } else {
          toast.error("Failed to update data");
        }

        // console.log("Has a role");
      }
    }
  };

  useEffect(() => {
    if (isAuth) {
      // if (user.phoneVerified) {
        navigate("/dashboard");
      // } else {
      //   navigate("/verify-phone");
      // }
    }
  }, [isAuth]);

  const handleUpdateRole = async () => {
    console.log("update details",userId,phone,role);
    if (!role) {
      toast.error("Please select a role");
    } else if (!phone) {
      toast.error("Please enter your phone numberz");
    } else {
      
      let data = { role, phone, deviceId: uuidv4() };
      if (referredBy) {
        data = { ...data, referredBy };
      }

      setIsUpdateLoading(true);
      const updateResponse = await axiosWrapper({
        method: "patch",
        url: `users/${userId}`,
        data,
      });

      console.log("updateResponse from google redirect");
      console.log(updateResponse);
      console.log(updateResponse.data);
      setIsUpdateLoading(false);
      if (updateResponse.data.success) {
        const { accessToken, refreshToken } = updateResponse.data.data.tokens;
        navigate("/dashboard")

        console.log("accessToken");
        console.log(accessToken);
        const userData = await fetchProfileDetails(userId);
        console.log("data from role update social auth");
        console.log(userData);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem(
          "authStatus",
          JSON.stringify({
            isAuth: true,
            user: userData,
          })
        );
        dispatch(updateUserDetails(userData));
      } else {
        toast.error("Failed to update data");
      }
    }
  };

  const handlePhoneValidation = (phone) => {
    if (!phone) {
      setPhoneError("Phone number required");
    } else if (phone?.length < 8) {
      setPhoneError("Invalid phone length");
    } else {
      setPhoneError("");
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner1 message={"Google authenticating"} />
      ) : (
        <div className="row w-100 ms-0 me-0 p-0 auths authCont">
          {/* {isUpdateLoading && <Spinner1 message={"Updating your details"} />} */}
          <div
            className="d-flex col-sm-6 m-0 flex-column justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <h5 className="mb-5 txt-lighter">Who are you signing up as?</h5>
            <div className="d-flex  col-sm-10 row m-0 justify-content-center align-items-center">
              <button
                className=" mx-auto   pt-2 pb-2 col-sm-5 position-relative d-flex align-items-center justify-content-center rounded role-btn"
                onClick={() => {
                  setRole("client");
                }}
                style={{
                  backgroundColor: `${
                    role === "client" ? colorSchema?.primary : "white"
                  }`,
                  color: `${
                    role === "client" ? "white" : colorSchema?.primary
                  }`,
                }}
              >
                Client
                {role === "client" && (
                  <BsCheckCircleFill
                    style={{ position: "absolute", right: "10px" }}
                    className="text-success  ms-auto"
                    size={18}
                  />
                )}
              </button>
              <h6 className=" col-sm-1 text-center mx-auto">or</h6>
              <button
                className=" pt-2 pb-2 mx-auto col-sm-5 position-relative d-flex align-items-center justify-content-center ms-auto rounded role-btn"
                onClick={() => {
                  setRole("serviceProvider");
                }}
                style={{
                  backgroundColor: `${
                    role === "serviceProvider" ? colorSchema?.primary : "white"
                  }`,
                  color: `${
                    role === "serviceProvider" ? "white" : colorSchema?.primary
                  }`,
                }}
              >
                Service Provider
                {role === "serviceProvider" && (
                  <BsCheckCircleFill
                    style={{ position: "absolute", right: "10px" }}
                    className="uploadStatus text-success ms-auto"
                    size={18}
                  />
                )}
              </button>
            </div>
            <div className="row m-0 w-100 mt-3">
              <div className="mb-4 mt-1 mx-auto d-flex flex-column align-items-center col-sm-8 m-3">
                <h6 className="mb-3 mt-5 txt-lighter">
                  Enter your phone number
                </h6>

                <PhoneInput
                  country={"ke"}
                  onChange={(n) => {
                    // handlePhoneValidation(n);
                    setPhone("+" + n);
                  }}
                  className="form_control w-100 m-2"
                  containerClass={"tel_container"}
                  inputClass={"form_controls"}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                />

                <small>
                  <span className="mt-1 mb-3 text-danger">{phoneError}</span>
                </small>
              </div>
            </div>
            <div className="row m-0 w-100">
              <div className="mb-4 mt-1 mx-auto d-flex flex-column align-items-center col-sm-8 m-3">
                <h6 className="mb-3 txt-lighter">Referral Code (optional)</h6>

                <input
                  type="text"
                  name="referredBy"
                  className="form_control"
                  id="referredBy"
                  value={referredBy}
                  onChange={(e) => setReferredBy(e.target.value)}
                />
              </div>
            </div>

            <center>
              <button
                type="submit"
                onClick={handleUpdateRole}
                className="btn_submit mt-2 p-2 text-light rounded d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: `${colorSchema?.primary}`,
                  width: "200px",
                }}
              >
                {!isUpdateLoading ? (
                  "Proceed"
                ) : (
                  <>
                    Authenticating
                    <div
                      className="spinner-border text-light ms-3 spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </>
                )}
              </button>
            </center>
          </div>
          <div className="col-sm-6 p-0 m-0" id="loginPicArea"></div>
          <div id="auth_gradient"></div>
        </div>
      )}
    </>
  );
}

export default GoogleRedirect;
