import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { registerSchema } from "../../../Utils/validations/AuthValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { registerUser } from "../../../features/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import Spinner1 from "../../../components/spinners/Spinner1";
import Spinner2 from "../../../components/spinners/Spinner2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import StepButton from '@mui/material/StepButton';
import "../../../styles/ProfileDetails.css";
import { AiOutlineClose } from "react-icons/ai";
import { SPRequiredProfileDetailsSchema } from "../../../Utils/validations/ProfileValidations";
import SPRequiredDetails from "../../profile/sp/SPRequiredDetails";
import SPAdditionalDetails from "../../profile/sp/SPAdditionalDetails";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Alert, Divider, Typography, IconButton, Stack, Grid, Avatar, Badge, Tooltip, TextField, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ServicesSelector from "../../../components/Services/ServicesSelector";
import { axiosWrapper } from "../../../Utils/AxiosWrapper/index";
import { updateUserDetails } from "../../../features/slices/AuthSlice";
import EditIcon from '@mui/icons-material/AddAPhoto';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ClientProfileDetails2Schema } from "../../../Utils/validations/ProfileValidations";
import storage from '../../../Utils/firebaseConfig';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const steps = [
    'Personal Details',
    'Service Details',
    'Upload Photo',
];


function ClientDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isLoading, isError, errorMessage, prevPath, isAuth } = useSelector(
        (state) => state.auth
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(ClientProfileDetails2Schema) });
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [changes, setChanges] = useState(false);
    const [skills, setSkills] = useState([]);
    const [skillError, setSkillError] = useState("");
    const [selected, setSelected] = useState([]);
    const [avatar, setAvatar] = useState("")
    const [uploading, setUploading] = useState(false)
    const { autoComplete, loading: servicesLoading } = useSelector(
        (state) => state.services
    );


    console.log("SERVICES", autoComplete);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);

        // { handleSubmit(handleRegister) }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };


    const isStepOptional = (step) => {
        return step === 4;
    };

    const handleSkillValidation = () => {
        // window.scroll(0, 0);
        if (!selected?.length > 0) {
            setSkillError("Select atleast one skill");
        } else {
            setSkillError("");
        }
    };

    const handleUpload = (event) => {
        setUploading(true)
        const files = event.target.files;
        const myFiles = Array.from(files);
        const filePath = `profile/${myFiles[0]?.name}`
        const storageRef = ref(storage, filePath);
        // upload image to firebase
        uploadBytes(storageRef, myFiles[0]).then((snapshot) => {
            // get uploaded image
            const bucketRef = ref(storage, snapshot.metadata.fullPath);
            getDownloadURL(bucketRef)
                .then(async (avatar) => {
                    // update user profile
                    setAvatar(avatar);
                    setUploading(false)
                    toast.success("Profile photo  successfully updated");
                    const response = await axiosWrapper({
                        method: "patch",
                        url: `users/${user.sub ? user.sub : user.id}`,
                        data: { avatar },
                    });

                    dispatch(updateUserDetails({
                        ...user,
                        avatar,
                    }))
                })
        });
    };

    console.log(`SKILLZ`,skills)

    const handleUpdate = async (data) => {
        console.log("****SERVICES****", changes, selected, data);
        // if (!selected || skillError) {
        //     handleSkillValidation();

        // } else {
        if (changes) {
            // setIsUpdateLoading(true);
            console.log("submitted data");
            console.log({ ...data, skills });
            const response = await axiosWrapper({
                method: "patch",
                url: `users/${user.sub ? user.sub : user.id}`,
                data: {
                    ...data,
                    skills,
                    avatar: user?.avatar,
                },
            });

            if (response.data.success) {
                const response2 = await axiosWrapper({
                    method: "get",
                    url: `users/${user.sub ? user.sub : user.id}`,
                    data: "",
                });
                console.log("updated to this");
                console.log(response2.data.data);
                dispatch(updateUserDetails(response2.data.data));
                toast.success("Your profile has been updated!");
                navigate('/dashboard')
            } else {
                toast.error("Failed to update your profile, please try again!");
            }
        }
        // }
    };



    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {/* {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))} */}
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Alert icon={false} severity="success" sx={{ mt: 2 }}>
                            Thanks  {user?.firstName},well use your answers to create a more personalized onboarding experience for you
                        </Alert>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Go to dashboard</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <form onSubmit={handleSubmit(handleUpdate)}>
                        <Alert icon={false} severity="success" sx={{ mt: 2 }}>
                            Hi {user?.firstName},  for a more tailored onboarding experience <br></br>Please answer the following questions and we'll do the rest
                        </Alert>

                        {activeStep === 0 && (
                            <>
                                <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
                                    Please tell us more about yourself
                                </Divider>
                                <Grid
                                    container
                                    mb={2}
                                    mt={2}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 2, sm: 3, md: 6 }}
                                >

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            label="Location"
                                            fullWidth
                                            placeholder="Place of residence"
                                            error={errors && errors?.placeOfResidence?.message}
                                            helperText={errors && errors?.placeOfResidence?.message}
                                            type="text"
                                            name="placeOfResidence"
                                            value={user?.placeOfResidence}
                                            id="exampleInputName"
                                            {...register("placeOfResidence")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                fullWidth
                                                defaultValue={user?.dob}
                                                error={errors && errors?.dob?.message}
                                                slotProps={{ textField: { fullWidth: true } }}
                                                helperText={errors && errors?.dob?.message}
                                                label="Date of Birth"
                                                {...register("dob")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Gender"
                                            select
                                            fullWidth
                                            id="gender"
                                            name="gender"
                                            defaultValue={user?.gender}
                                            // size="small"
                                            // error={errors && errors?.gender.message}
                                            // helperText={errors && errors?.gender?.message}
                                            {...register("gender")}
                                        >
                                            <MenuItem value="male">Male</MenuItem>
                                            <MenuItem value="female">Female</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Tell us about yourself"
                                            placeholder={`Do you have any children/Family? Do you own any pets?`}
                                            multiline
                                            type="text"
                                            defaultValue={user?.bio}
                                            name="bio"
                                            onKeyUp={() => {
                                                setChanges(true);
                                            }}
                                            rows={2}
                                            id="bio"
                                            {...register("bio")}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <Divider orientation="horizontal" flexItem sx={{ my: 4 }}>
                                    What kind of Service Provider are you looking for
                                </Divider>
                                <Grid
                                    container
                                    mb={2}
                                    mt={2}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 2, sm: 3, md: 6 }}
                                >
                                    <Grid item xs={12} md={12}>
                                        <ServicesSelector
                                            setValue={(data) => {
                                                setChanges(true);
                                                console.log(data);
                                                setSelected(data);
                                            }}
                                            multiple={true}
                                        />
                                        <small>
                                            <span className="mt-1 mb-3 text-danger">{skillError}</span>
                                        </small>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Preffered Age Group"
                                            select
                                            helperText="Service provider age group"
                                            fullWidth
                                            name="ageGroup"
                                            defaultValue={user?.ageGroup}
                                            {...register("ageGroup")}
                                            onClick={() => {
                                                setChanges(true);
                                            }}
                                        >
                                            {/* <MenuItem value={""}>Choose one</MenuItem> */}
                                            <MenuItem value="20-25">20-25</MenuItem>
                                            <MenuItem value="26-30">26-30</MenuItem>
                                            <MenuItem value="31-35">31-35</MenuItem>
                                            <MenuItem value="36-40">36-40</MenuItem>
                                            <MenuItem value="41-45">41-45</MenuItem>
                                            <MenuItem value="46 and above">46 and above</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            type="number"
                                            label="House Hold Members"
                                            fullWidth
                                            onKeyUp={() => {
                                                setChanges(true);
                                            }}
                                            defaultValue={user?.specialNeeds}
                                            id="specialNeeds"
                                            {...register("specialNeeds")}
                                            error={errors && errors?.specialNeeds?.message}
                                            helperText={errors && errors?.specialNeeds?.message}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {activeStep === 2 && (
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                mb={3}
                                spacing={1}>

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                // badgeContent={
                                //     <Tooltip title="Select Image">
                                //         <IconButton aria-label="upload picture" component="label" >
                                //             <input hidden accept="image/*" type="file"
                                //                 onChange={handleUpload}
                                //                 disabled={uploading}
                                //             />
                                //             <EditIcon color="secondary" />
                                //         </IconButton>
                                //     </Tooltip>
                                // }
                                >
                                    <Avatar alt="user" sx={{ width: "6.5rem", height: "6.5rem", m: 1 }} src={avatar || user.avatar} />

                                </Badge>
                                <Button onChange={handleUpload}
                                    component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                                    {uploading ? "Uploading photo..." : "Upload profile photo"}
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                            </Stack>

                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            {/* <Button
                                fullWidth
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="outlined"
                                sx={{ mr: 0.5 }}
                            >
                                Back
                            </Button> */}
                            <Box sx={{ flex: '1 1 auto' }} />
                            {activeStep !== steps.length - 1 &&
                                <Button onClick={handleNext} fullWidth variant="contained">
                                    Continue
                                </Button>
                            }
                            {activeStep === steps.length - 1 && (
                                <Button type="submit" disabled={uploading} fullWidth variant="contained">Complete Profile</Button>
                            )
                            }
                        </Box>
                    </form>
                )}
            </div>
        </Box>
    );
}

export default ClientDetails;