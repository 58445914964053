import { useEffect } from "react";
import Nav from "../../components/Home/Nav";
import Footer from "../../components/Home/Footer";
import { useSelector } from "react-redux";
import {  BsWhatsapp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CommunityPic from "../../pics/community/9.jpg";
function Community() {
  const colorSchema = useSelector((state) => state.color.scheme);
  const navigate = useNavigate();
  const { user, isAuth, isProfileComplete } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Nav currentPage={"services"} />
      <div className="row m-0 pb-5 w-100" style={{ minHeight: "100vh" }}>
        <div className="col-sm-8 mx-auto p-2 mt-4 d-flex flex-column">
          <h4 className="mt-4 mb-2">MAIDS OF HONOUR COMMUNITY</h4>
          <span
            style={{ height: "3px", width: "7vw", backgroundColor: "#9f7582" }}
            className="mt-1 mb-1"
          ></span>
          {user?.firstName && (
              <>
          <div className="infoDiv mt-4 p-2 rounded d-flex align-items-center">
            <a href="https://wa.me/+254795930905" className="call-btn">
              <BsWhatsapp size={15} className="ms-1 me-2" /> Click Here to Join our WhatsApp Community
            </a>
          </div>
          </>
            )}

          <div className="mt-4 ">
            At Maids of Honour, we understand the importance of creating a
            supportive community for domestic workers. That's why we have
            established a community that is dedicated to empowering and
            uplifting domestic workers within our platform. Our community is a
            place where domestic workers can stay informed about new training
            opportunities, advancements, and other important updates. It is also
            a place where they can share their thoughts and experiences, and
            have their voices heard.
          </div>
          
          <div className="mt-3 mb-2 p-2">
            <img src={CommunityPic} alt="" className="w-100" />
          </div>
          <div className="mt-4 ">
            But the Maids of Honour community is not just for domestic workers -
            it is also a valuable resource for clients. By staying connected to
            the community, clients can stay up to date on the latest training
            offerings and have a better understanding of the needs and concerns
            of domestic workers.
          </div>
          <div className="mt-4 ">
            In addition to providing information and resources, the Maids of
            Honour community is a place for domestic workers to connect with and
            support one another. It is a place where they can build each other
            up and work towards a common goal of growth and success. We believe
            that by fostering a sense of community and mutual support, we can
            help domestic workers achieve their full potential and reach their
            goals. We encourage all domestic workers and clients to become
            active members of the Maids of Honour community and experience the
            benefits of being part of a supportive network.
            {!user?.firstName && (
              <>
                <div className="mt-2">
                  If you are interested in joining the Maids of Honour
                  community, please sign up using the button below.
                </div>
                <button
                  className="joinBtn ps-3 pe-3 pt-1 pb-1  mt-3 rounded"
                  style={{ backgroundColor: `${colorSchema?.primary}` }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Join Community
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Community;
