import { useEffect, useState } from "react";
import "../../styles/Services.css";
import Wave from "react-wavify";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../features/slices/ServiceSlice";
import ServiceItem from "./ServiceItem";
import Spinner1 from "../spinners/Spinner1";
import butler from "../../pics/services/butler.jpg";
import chef from "../../pics/services/chef.png";
import driver from "../../pics/services/driver.png";
import gardener from "../../pics/services/gardener.png";
import house from "../../pics/services/house-keeper.jpg";
import nanny from "../../pics/services/nanny.jpg";
import pet from "../../pics/services/pet.png";
import nurse from "../../pics/services/nurse.png";
import elderly from "../../pics/services/elderly.png";
import laundry from "../../pics/services/laundry.png";
import special from "../../pics/services/special.png";
import repair from "../../pics/services/repair.png";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Avatar, ListItem, Typography, Paper, ListItemText, ListItemAvatar } from '@mui/material';
import { BiDish, BiBookBookmark } from "react-icons/bi";
import {
  MdOutlineChair,
  MdChildFriendly,
  MdOtherHouses,
  MdBabyChangingStation,
} from "react-icons/md";
import { TbSchool } from "react-icons/tb";
import { GrHomeRounded } from "react-icons/gr";
import {

  FaShieldAlt,
  FaBabyCarriage,
  FaBaby,
  FaHandHoldingMedical,
  FaBroom,
  FaDog
} from "react-icons/fa";

import {
  GiCook,
  GiPerson,
  GiSteeringWheel,
  GiCookingPot,
} from "react-icons/gi";
import { RiPlantFill, RiNurseFill } from "react-icons/ri"

export const data =
  // [
  //   {
  //     icon: house,
  //     title: "Cleaning Services",
  //     body: "House cleaning of living spaces, including dusting, vacuuming, mopping, and tidying up.",
  //   },
  //   {
  //     icon: "https://media.istockphoto.com/id/1300081308/vector/disabled-elderly-woman-and-her-grandchildren-visiting-nursing-home-nursing-home-concept.jpg?s=612x612&w=0&k=20&c=T8XSP7rt7pq9YYYQa2C-GAhDZv00qYieZjaY5iPvtEI=",
  //     title: "Elderly Care",
  //     body: "Assisting senior citizens with daily activities, such as bathing, dressing, and medication management.",
  //   },
  //   {
  //     icon: chef,
  //     title: "Cooking ",
  //     body: "Catering for events or special occasions. Meal planning and grocery shopping as well as preparing daily meals.",
  //   },
  //   {
  //     icon: nanny,
  //     title: "Childcare & Babysit",
  //     body: "Caring for children, including feeding, bathing, and playing with them.Supervising and assisting with homework.",
  //   },
  //   {
  //     icon: pet,
  //     title: "Pet Sitting",
  //     body: "Feeding and providing water for pets.Walking dogs and pet exercise.Cleaning and maintaining pet enclosures ",
  //   },
  //   {
  //     icon: "https://media.istockphoto.com/id/509586837/vector/african-american-nurse-and-her-patient.jpg?s=612x612&w=0&k=20&c=wtsrcADn_38CI-RB93CV6PK85MCjNfWG-Zr9gW4ijeE=",
  //     title: "Home Nurse",
  //     body: "Medical and nursing care within the home for individuals with medical conditions.Rehabilitation services.Rehabilitation services.",
  //   },
  //   {
  //     icon: butler,
  //     title: "Butler",
  //     body: "Coordinates all domestic staff members, including housekeepers, chefs, gardeners, and chauffeurs.",
  //   },

  //   {
  //     icon: driver,
  //     title: "Driver",
  //     body: "Drives individuals or families to daily commutes, special occasions, or as needed ",
  //   },
  //   {
  //     icon: gardener,
  //     title: "Gardening",
  //     body: "Planting flowers, lawn mowing and maintaining gardens ",
  //   },
  //   {
  //     icon: special,
  //     title: "Special Needs Care",
  //     body: "Its main focus is to provide care to disabled babies, teens and adults. ",
  //   },
  //   {
  //     icon: laundry,
  //     title: "Laundry & Ironing",
  //     body: "Washing, drying, and folding clothes.Ironing and steaming garments.Dry cleaning services for delicate items.",
  //   },
  //   {
  //     icon: repair,
  //     title: "Home Repair",
  //     body: "From plumbing to electrical, we've got you covered with reliable home repair services. ",
  //   },
  // ];
  [
    {
      icon: <FaBroom size={40} style={{color: "#CF2680"}} />,
      title: "Household Manager",
      body: "Household Managers undertake all of the general household tasks that the homeowner will otherwise do. These include housekeeping/cleaning informed by a time planner, general cooking, and she could double up as a nanny too.",
    },
    {
      icon: <MdChildFriendly size={40} style={{color: "#CF2680"}}  />,
      title: "Childminder",
      body: " Maids of Honour childminders are meant to undertake all tasks related to childcare, including preparing the children’s meals and snacks, doing the children’s laundry, and keeping the children’s areas neat, clean and tidy. She/he is also expected to engage the child in activities that would enhance their cognitive development, educational games and crafts.",
    },
    {
      icon: <MdBabyChangingStation size={40} style={{color: "#CF2680"}}  />,
      title: "Newborn Babycare",
      body: "These service providers come with proven experience in this field while any extra training is a plus. They are expected to know how to handle a newborn and the mother during the delicate process of a baby's development from birth ,weaning and  until toddler stage. They could be deployed during the pregnancy stage or even after the birth of a child.",
    },
    {
      icon: <RiNurseFill size={40} style={{color: "#CF2680"}} />,
      title: "Home Nurse",
      body: "Home nurses must have specific training to enable them to handle various ailments professionally. Their roles include providing treatment and procedures ordered by the doctor,e.g. giving injections and medication, drawing blood, changing catheters, providing care to patients in their homes, they make sure that the patient is getting the necessary treatment.",
    },
    {
      icon: <MdOtherHouses size={40} style={{color: "#CF2680"}}  />,
      title: "Eldery Care",
      body: "Elderly care is the provision of healthcare service to the senior citizens.We take care of the elderly in the society",
    },
    {
      icon: <FaHandHoldingMedical size={40} style={{color: "#CF2680"}} />,
      title: "Special Needs Care",
      body: "Its main focus is to provide care to babies, teens and adults that have any disabilities. ",
    },
    {
      icon: <FaBabyCarriage size={40} style={{color: "#CF2680"}} />,
      title: "Nanny",
      body: "Our Nannies must possess a strong desire to nurture children, have at least 2 years of proven experience be willing to  and make a life-long difference in a child’s life, and support the child in different capacities like education, emotional and all round  ",
    },
    {
      icon: <FaBaby size={40} style={{color: "#CF2680"}} />,
      title: "Newborn Mothercare",
      body: "Newborn Mothercare are professionals that understand the needs of a newborn mother, and supports them through advising on the needed nutrition as well as best practices.",
    },
    {
      icon: <GiPerson size={40} style={{color: "#CF2680"}} />,
      title: "Home Personal Assistants",
      body: "Home personal Assistants work very closely with the homeowner to manage the home in its different capacities, from shopping to overseeing the running of the house. ",
    },
    {
      icon: <GiCook size={40} style={{color: "#CF2680"}} />,
      title: "Professional Chef",
      body: "The Professional Chef will be expected to take full responsibility for the running of the kitchen and planning of menus in order to cater for all the requirements of the family and their guests. They are trained to prepare different traditional and international dishes. ",
    },
    {
      icon: <GiCookingPot size={40} style={{color: "#CF2680"}} />,
      title: "General Cook",
      body: "A general chef will have basic food Preparation skills and is not well trained in international dishes. They are still skilled in cooking and assist in the running of the kitchen.",
    },
    {
      icon: <FaDog size={40} style={{color: "#CF2680"}} />,
      title: "Pet Sitting",
      body: "Feeding and providing water for pets. Walking dogs and pet exercise. Cleaning and maintaining pet enclosures ",
    },
    {
      icon: <GiPerson size={40} style={{color: "#CF2680"}}  />,
      title: "Butler",
      body: "A butler oversees the household staff usually of one residence, knowledge of high social etiquette and formal service, knowledge of proper maintenance for fine dining equipment amongst other things.",
    },

    {
      icon: <GiSteeringWheel size={40} style={{color: "#CF2680"}}  />,
      title: "Driver",
      body: "Drivers are focused on dropping and picking up of members of the family, and collecting various packages. ",
    },
    {
      icon: <RiPlantFill size={40} style={{color: "#CF2680"}}  />,
      title: "Gardener",
      body: "Understand the importance of cleanliness. They are focused on how the exterior of the house looks.",
    },
  ]

function ServicesMin() {
  const dispatch = useDispatch();
  const allServices = useSelector((state) => state.services.services);
  const [services, setServices] = useState([]);
  const [activeTab, setActiveTab] = useState("Home");
  const [loading] = useState(false);
  const [loadingMessage] = useState(`Fetching ${activeTab} services...`);

  //PAGINATION
  const [itemsPerPage] = useState(8);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = services.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(services.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % services.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  useEffect(() => {
    dispatch(fetchServices());
  }, []);

  const filterServices = async () => {
    console.log("filtering from ");
    console.log(allServices);
    setServices(
      allServices.filter(
        (service) =>
          service?.serviceCategory?.find(
            (category) => category?.title === activeTab
          )
        // service.serviceCategory.title === activeTab;
      )
    );
  };

  useEffect(() => {
    filterServices();
  }, [activeTab, allServices]);
  return (
    <>
      <Wave
        fill="#f0f6ff"
        paused={true}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.15,
          points: 3,
        }}
        style={{ height: "30px", width: "100%" }}
      />
      <div
        className="w-100 row m-0  pt-5 pb-5"
        style={{ backgroundColor: "#f0f6ff" }}
      >
        <div className="row mx-auto col-sm-9 p-3">
          <h2 className="">
            <b>Our Services</b>
          </h2>

          <span className="mt-2 sizeNormal">
            We Provide Vetted. Trained. Certified. Domestic Staff suited for
            your needs, A vast amount of services to match the corners of your
            home.
          </span>
        </div>

        <div className="col-sm-9 mx-auto row m-0" style={{ minHeight: "50vh" }}>
          <Grid container rowSpacing={1} columnSpacing={1}>
            {data.map((service, index) => (
              <Grid
                item
                key={service.name}
                xs={12}
                md={4}
              ><Paper sx={{ borderRadius: "0.5rem" }}>
                  <ListItem alignItems="flex-start" >
                    <ListItemAvatar>
                      {service.icon}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography component="div" sx={{ fontWeight: "bold" }} color="text.secondary" variant="subtitle1">{service.title}</Typography>
                      }
                      secondary={
                        <Typography component="div" color="text.primary" variant="caption"> {service.body.substr(0, 105)}...</Typography>
                      }
                    />
                  </ListItem>
                </Paper>
              </Grid>
              // <Grid
              //   item
              //   key={service.name}
              //   xs={6}
              //   md={2}
              // >
              //   <Card sx={{ display: "flex", boxShadow: '10px 10px 8px rgb(157 168 189 / 17%)', flexDirection: "column", justifyContent: "center", alignItems: "center", p: 1, cursor: "pointer" }}>
              //     {service.icon}
              //     <Typography component="div" sx={{ fontWeight: "bold" }} >{service.title}</Typography>
              //     <Typography component="div" color="text.secondary" align="center" variant="caption"> {service.body.substr(0, 60)}...</Typography>
              //   </Card>
              // </Grid>
            ))}
          </Grid>
        </div>
        <br />
      </div>
      {loading && <Spinner1 message={loadingMessage} />}
    </>
  );
}

export default ServicesMin;
