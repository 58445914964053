import React from "react";
import VideoPlayer2 from "../../components/videoPlayer/VideoPlayer2";
import PlayerContainer from "./PlayerContainer";
function TestVideo() {
  return (
    <div className="row mt-3">
      <div className="col-sm-5 mx-auto bg-light rounded">
        <h3 className="text-center mt-5">My video</h3>
        <PlayerContainer />
      </div>
    </div>
  );
}

export default TestVideo;
