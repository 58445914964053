import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../Utils/AxiosWrapper/index";
import { toast } from "react-hot-toast";
const initialState = {
  courses: [],
  currentCourse: JSON.parse(localStorage.getItem("currentCourse")),
  currentUnits: JSON.parse(localStorage.getItem("currentUnits")),
  currentAssessments: JSON.parse(localStorage.getItem("currentAssessments")),
  currentAssessment: JSON.parse(localStorage.getItem("currentAssessment")),
  currentAssessmentQuestions: JSON.parse(
    localStorage.getItem("currentAssessmentQuestions")
  ),
  currentAssessmentScore: JSON.parse(
    localStorage.getItem("currentAssessmentScore")
  ),
  currentQuestionIndex: localStorage.getItem("currentQuestionIndex") || 0,
  loading: false,
  offset: 0,
  limit: 8,
};

export const getCourses = createAsyncThunk(
  "courses/getCourses",
  async (offset, { getState }) => {
    const state = getState().courses;
    try {
      const coursesReponse = await axiosWrapper({
        method: "get",
        url: `courses?limit=${state.limit}&offset=${offset}`,
      });

      return coursesReponse.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUnits = createAsyncThunk(
  "courses/getUnits",
  async (courseId) => {
    try {
      const unitsResponse = await axiosWrapper({
        method: "get",
        url: `courses/units?course=${courseId}`,
      });
      console.log("course response");
      console.log(unitsResponse);

      return unitsResponse.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAssessments = createAsyncThunk(
  "courses/getAssessments",
  async (courseId) => {
    try {
      const assessmentsResponse = await axiosWrapper({
        method: "get",
        url: `assessments?course=${courseId}`,
      });

      return assessmentsResponse.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAssessmentQuestions = createAsyncThunk(
  "courses/getAssessmentQuestions",
  async (assessmentId) => {
    try {
      const questionsResponse = await axiosWrapper({
        method: "get",
        url: `assessments/questions/?assessment=${assessmentId}`,
      });

      return questionsResponse.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAssessmentScore = createAsyncThunk(
  "courses/getAssessmentScore",
  async ({ assessmentId, candidateId }) => {
    try {
      const scoreResponse = await axiosWrapper({
        method: "get",
        url: `assessments/scores?assessment=${assessmentId}&candidate=${candidateId}`,
      });

      console.log("assesment id ", assessmentId);
      console.log("candidate id ", candidateId);
      console.log("user assessment score");
      console.log(scoreResponse.data);

      return scoreResponse.data;
    } catch (error) {
      return error;
    }
  }
);

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCurrentCourse: (state, action) => {
      state.currentCourse = action.payload;
      localStorage.setItem("currentCourse", JSON.stringify(action.payload));
    },
    setCurrentUnits: (state, action) => {
      state.currentUnits = action.payload;
      localStorage.setItem("currentUnits", JSON.stringify(action.payload));
    },
    setCurrentAssessment: (state, action) => {
      state.currentAssessment = action.payload;
      localStorage.setItem("currentAssessment", JSON.stringify(action.payload));
    },
    setCurrentAssessmentScore: (state, action) => {
      state.currentAssessmentScore = action.payload;
      localStorage.setItem(
        "currentAssessmentScore",
        JSON.stringify(action.payload)
      );
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
      localStorage.setItem(
        "currentQuestionIndex",
        JSON.stringify(action.payload)
      );
    },
  },
  extraReducers: {
    [getCourses.pending]: (state, action) => {
      state.loading = true;
    },
    [getCourses.fulfilled]: (state, { payload }) => {
      state.loading = false;
      if (payload.success) {
        state.courses = payload.data.rows;
      } else {
        console.log("Failed to get courses");
        console.log(payload);
      }
    },
    [getCourses.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Failed to get assessment");
    },
    [getAssessments.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentAssessments = payload.data.rows;
      localStorage.setItem(
        "currentAssessments",
        JSON.stringify(payload.data.rows)
      );
    },
    [getAssessments.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Failed to get assessment");
    },
    [getAssessmentQuestions.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentQuestions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentAssessmentQuestions = payload.data.rows;
      localStorage.setItem(
        "currentAssessmentQuestions",
        JSON.stringify(payload)
      );
    },
    [getAssessmentQuestions.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Failed to get assessment questions");
    },
    [getAssessmentScore.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentScore.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentAssessmentScore = payload.data.rows[0];
      localStorage.setItem("currentAssessmentScore", JSON.stringify(payload));
    },
    [getAssessmentScore.rejected]: (state, action) => {
      state.loading = false;
      toast.error("Failed to get assessment scores");
    },
  },
});

export const {
  setCurrentCourse,
  setCurrentUnits,
  setCurrentAssessment,
  setCurrentAssessmentScore,
  setCurrentQuestionIndex,
} = courseSlice.actions;
export default courseSlice.reducer;
