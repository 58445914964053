import React from "react";
import "../../styles/spinner.css";
import { ImSpinner2 } from "react-icons/im";
import Logo from "../../pics/logo2.png";
import { Typography } from '@mui/material';

function Spinner1({ message }) {
  return (
    <div className="cont pt-4 " style={{ minHeight: "30vh" }}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src={Logo}
          alt="logo"
          className="animate__animated animate__pulse mb-2 mt-3  animate__infinite"
          style={{ width: "50px", height: "50px" }}
        />
        <span style={{ color: "#CF2680" }}>{message}</span>
        <ImSpinner2 id="spinnerIcon1" size={30} className="m-3"></ImSpinner2>
      </div>
    </div>
  );
}

export default Spinner1;
