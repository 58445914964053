import { createSlice } from "@reduxjs/toolkit";

let luna = {
  primary: "#CF2680",
  secondary: "#ff51a8",
};
let gray = {
  primary: "#15192e",
  secondary: "#df9c45",
};
let darkGray = {
  // primary: "#4d5164",
  primary: "#414454",
  secondary: "#df9c45",
};

let orange = {
  primary: "#e7a44c",
  secondary: "#727aa1",
};
let brown = {
  primary: "#3d1c0b",
  secondary: "#727aa1",
};
let pink = {
  primary: "#e44977",
  secondary: "#5d5e5d",
};

const ColorSlice = createSlice({
  name: "color",
  initialState: {
    scheme: luna,
  },
  reducers: {
    setLuna: (state, action) => {
      state.scheme = luna;
    },
    setGray: (state, action) => {
      state.scheme = gray;
    },
    setDarkGray: (state, action) => {
      state.scheme = darkGray;
    },
  },
});

export const { setLuna, setGray, setDarkGray } = ColorSlice.actions;
export default ColorSlice.reducer;
