import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  referralCode: JSON.parse(localStorage.getItem("referralCode")) || "",
};

const ReferralSlice = createSlice({
  name: "referralCode",
  initialState,
  reducers: {
    setReferralCode: (state, action) => {
      state.referralCode = action.payload;
      localStorage.setItem("referralCode", JSON.stringify(action.payload));
    },
  },
});

export const { setReferralCode } = ReferralSlice.actions;
export default ReferralSlice.reducer;
